import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PromoCodeVM } from 'src/app/model/basket-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { InitialiseBasketTracking } from '../../../../assets/shared/gtm.js';
import { Basket, BasketPageViewModel } from '../../../model/basketpage-viewmodel';
import { ShoppingBasket } from '../../../model/shopping-basket';
import { Label } from '../../models/Labels';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingToClickAndCollect, ShippingVM } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { CartService } from '../../service/cart.service';
import { SharedService } from '../../shared.service';

@Component({
	selector: 'basket-summary-dir,[basket-summary-dir]',
	templateUrl: './checkout-basket-summary.component.html',
	styleUrls: ['./checkout-basket-summary.component.scss']
})
export class CheckoutBasketSummaryComponent implements OnInit, OnChanges {

	@Input() sitecontent: SiteContentDetail;
	@Input() regExLibrary: RegExLibrary;
	@Input() label: Label;
	@Input() shippingToClickAndCollect: ShippingToClickAndCollect;
	@Input() shippingVM: ShippingVM[];
	@Input() selectedShippingId: number = 40;
	@Input() isDutyFree: boolean;
	@Input() basket: Basket;

	@Output() shippingIdChanged = new EventEmitter<any>();
	@Output() promoCodeChanged = new EventEmitter<any>();

	promocodeVM: PromoCodeVM;
	promocode: string;
	LpAppSetting: LpAppSetting;

	cartItems: any[];
	totalAmount = 0;
	promoCodeForm: UntypedFormGroup;
	showPromoCodeMessage: Boolean = false;
	messagesForPromoCode;
	shoppingBasket: ShoppingBasket;
	basketPageViewModel: BasketPageViewModel;
	hidePrice: boolean = false;
	showdeliveryoption: boolean = true;
	canToggle: boolean = false;

	constructor(
		private cartService: CartService,
		private sharedService: SharedService,
		private fb: UntypedFormBuilder,
		private router: Router) {
	}

	ngOnInit() {
		this.getLpAppSetting();
		this.initiatePromoForm();
	}

	ngOnChanges(changes: SimpleChanges) {

	}

	applyPromoCode() {

		if (!this.promocode) {
			return;
		}

		this.promocodeVM = {} as PromoCodeVM;
		this.promocodeVM.PromoCode = this.promocode;

		this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {

			if (res) {
				if (res.Result && +res.Result === 2 || res.Result == 2) {
					if (this.basket) {
						this.callGetBasket();
					}

					this.showPromoCodeMessage = false;
					this.messagesForPromoCode = '';
				} else {
					this.messagesForPromoCode = res.Message;
					this.showPromoCodeMessage = true;
				}
			}
		});
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	removePromoCode() {

		this.promocodeVM = {} as PromoCodeVM;

		this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {

			if (res) {
				if (res.Result && +res.Result === 0 || res.Result == 0) {
					if (this.basket) {
						this.showPromoCodeMessage = false;
						this.messagesForPromoCode = res.Message;
						this.promocode = null;

						this.callGetBasket();
					}
				}
			}
		});
	}

	callGetBasket() {
		this.basket.PromoCode = this.promocode;

		this.cartService.GetBasket().subscribe(data => {
			if (data) {
				this.basket = data;

				//gmt start
				if (data && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
					InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data, false, false);
				}
				//gmt end

				this.promoCodeChanged.emit(true);
			}
		})
	}

	grandTotal() {
		let total = 0;

		if (this.basket) {
			total = this.basket.SubTotal - this.basket.ActualDiscount;
		}

		if (this.shippingToClickAndCollect) {
			if (!this.shippingToClickAndCollect.showdeliveryoption) {
				total += this.shippingToClickAndCollect.price;
				return total;
			}
		}

		if (this.shippingVM && this.shippingVM.length > 0) {
			let shippingprice = this.shippingVM.filter(x => x.Id == this.selectedShippingId);

			if (shippingprice && shippingprice.length > 0) {
				total += shippingprice[0].Price;
			}
		}

		return total;
	}

	initiatePromoForm() {
		this.promoCodeForm = this.fb.group({
			promoCodeText: ['', Validators.required]
		});
	}

	submitPromoCodeForm() {
		this.cartService.checkPromoCode(this.promoCodeForm.value).subscribe(res => {
			this.showPromoCodeMessage = true;
			this.messagesForPromoCode = res.Message;
		});
	}

	onOptionSelect(shippingId) {
		this.shippingIdChanged.emit(shippingId);
	}
}
