import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildBowTieGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		BowTieTitle: title,
		BowTieMobileBannerImageUrl: mobileBannerImage,
		BowTieHowToTieTitle: howToTieTitle,
		BowTieHowToTieVideoUrl: howToTieVideoUrl,
		StepName1Text: stepNameOne,
		StepName2Text: stepNameTwo,
		StepName3Text: stepNameThree,
		StepName4Text: stepNameFour,
		StepName5Text: stepNameFive,
		StepName6Text: stepNameSix,
		StepName7Text: stepNameSeven,
		StepName8Text: stepNameEight,
		BowTieHowToTieMainDescription: howToTieMainDescription,
		BowTieHowToTieDescription1: howToTieDescription1,
		BowTieHowToTieDescription2: howToTieDescription2,
		BowTieHowToTieDescription3: howToTieDescription3,
		BowTieHowToTieDescription4: howToTieDescription4,
		BowTieHowToTieDescription5: howToTieDescription5,
		BowTieHowToTieDescription6: howToTieDescription6,
		BowTieHowToTieDescription7: howToTieDescription7,
		BowTieHowToTieDescription8: howToTieDescription8,
		BowTieHowToTieDesktopImageUrl1: howToTieDesktopImageUrl1,
		BowTieHowToTieMobileImageUrl1: howToTieMobileImageUrl1,
		BowTieHowToTieDesktopImageUrl2: howToTieDesktopImageUrl2,
		BowTieHowToTieMobileImageUrl2: howToTieMobileImageUrl2,
		BowTieHowToTieDesktopImageUrl3: howToTieDesktopImageUrl3,
		BowTieHowToTieMobileImageUrl3: howToTieMobileImageUrl3,
		BowTieHowToTieDesktopImageUrl4: howToTieDesktopImageUrl4,
		BowTieHowToTieMobileImageUrl4: howToTieMobileImageUrl4,
		BowTieHowToTieDesktopImageUrl5: howToTieDesktopImageUrl5,
		BowTieHowToTieMobileImageUrl5: howToTieMobileImageUrl5,
		BowTieHowToTieDesktopImageUrl6: howToTieDesktopImageUrl6,
		BowTieHowToTieMobileImageUrl6: howToTieMobileImageUrl6,
		BowTieHowToTieDesktopImageUrl7: howToTieDesktopImageUrl7,
		BowTieHowToTieMobileImageUrl7: howToTieMobileImageUrl7,
		BowTieHowToTieDesktopImageUrl8: howToTieDesktopImageUrl8,
		BowTieHowToTieMobileImageUrl8: howToTieMobileImageUrl8,
		BowTieHowToTieDesktopImageUrl11: howToTieDesktopImageUrl11,
		BowTieHowToTieMobileImageUrl11: howToTieMobileImageUrl11,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: howToTieTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						videoLink: howToTieVideoUrl,
						isActive: true,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: howToTieDesktopImageUrl11,
								mobile: howToTieMobileImageUrl11,
							},
							{
								desktop: howToTieDesktopImageUrl1,
								mobile: howToTieMobileImageUrl1,
							},
							{
								desktop: howToTieDesktopImageUrl2,
								mobile: howToTieMobileImageUrl2,
							},
							{
								desktop: howToTieDesktopImageUrl3,
								mobile: howToTieMobileImageUrl3,
							},
							{
								desktop: howToTieDesktopImageUrl4,
								mobile: howToTieMobileImageUrl4,
							},
							{
								desktop: howToTieDesktopImageUrl5,
								mobile: howToTieMobileImageUrl5,
							},
							{
								desktop: howToTieDesktopImageUrl6,
								mobile: howToTieMobileImageUrl6,
							},
							{
								desktop: howToTieDesktopImageUrl7,
								mobile: howToTieMobileImageUrl7,
							},
							{
								desktop: howToTieDesktopImageUrl8,
								mobile: howToTieMobileImageUrl8,
							},
						],
						imageDetails: [
							{
								name: null,
								description: howToTieMainDescription,
							},
							{
								name: stepNameOne,
								description: howToTieDescription1,
							},
							{
								name: stepNameTwo,
								description: howToTieDescription2,
							},
							{
								name: stepNameThree,
								description: howToTieDescription3,
							},
							{
								name: stepNameFour,
								description: howToTieDescription4,
							},
							{
								name: stepNameFive,
								description: howToTieDescription5,
							},
							{
								name: stepNameSix,
								description: howToTieDescription6,
							},
							{
								name: stepNameSeven,
								description: howToTieDescription7,
							},
							{
								name: stepNameEight,
								description: howToTieDescription8,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2 top3 right3',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildBowTieGuideViewModel;
