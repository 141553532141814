exports.SlickPromoLineBarHeader = function SlickPromoLineBarHeader() {
	if (document.getElementsByClassName("slick-carousel-promobar").length > 0) {
		$('.slick-carousel-promobar').slick({
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			fade: false,
			centerMode: false,
			variableWidth: false,
			arrows: false,
		});
	}
}

exports.SplidePromoLineBarHader = function SplidePromoLineBarHader() {
	if (document.getElementsByClassName("splide-carousel-promobar").length > 0) {
		new Splide('.splide-carousel-promobar', {
			arrows: false,
			pagination: false,
			autoplay: true,
			type: "loop",
			drag: "free",
			snap: true,
			autoWidth: true,
			flickPower: 200,
			gap: "0.5em",
			perMove: 1
		}).mount();
	}
}

/* ####### Slick Slider Carousel Initialisation ####### */
exports.slickSliderRefresh = function slickSliderRefresh() {

	$(function () {
		$('.slick-slider').slick('setPosition');
		$('.slick-dots').appendTo('.top-line');

		setTimeout(function () { $('.slick-slider').slick('setPosition'); }, 1500);
	});

}

exports.slickSliderRefreshInstant = function slickSliderRefreshInstant() {

	$(function () {
		$('.slick-slider').slick('setPosition');
		$('.slick-dots').appendTo('.top-line');

		setTimeout(function () { $('.slick-slider').slick('setPosition'); }, 100);
	});

}

exports.slickSliderThumbnailRefresh = function slickSliderThumbnailRefresh(imageCount) {

	$(function () {
		$('.slick-slider').slick('setPosition');

		if (imageCount > 0) {
			var thumbImageElement = $(".thumbnail-carousel");
			var thumbnailsElement = $(".thumbnails");

			if (thumbnailsElement && thumbnailsElement.css('display') !== 'none') {

				if (thumbImageElement && thumbImageElement.height() < 70) {
					setTimeout(slickSliderThumbnailRefresh, 500, imageCount);
				}
			}
		}
	});

}

exports.GetTieCurrentSlide = function GetTieCurrentSlide() {
	try {
		var currentSlide = $('#product-ties').slick('slickCurrentSlide');
		return currentSlide;
	} catch (ex) { }
}

exports.GetCufflinkCurrentSlide = function GetCufflinkCurrentSlide() {
	try {
		var currentSlide = $('#product-cufflinks').slick('slickCurrentSlide');
		return currentSlide;
	} catch (ex) { }
}

exports.CufflinkTabClick = function CufflinkTabClick() {
	setTimeout(function () {
		$('.cufflink-slide, .cufflink-text-slide').slick('refresh');
	}, 50);
}

exports.TieTabClick = function TieTabClick() {

	setTimeout(function () {
		$('.tie-slide, .tie-text-slide').slick('refresh');
	}, 50);
}











exports.postcodeAutocomplete = function postcodeAutocomplete() {
	$(function () {
		setTimeout(function () {
			$("#street-address-id").attr('autocomplete', 'qwertgf');
		}, 1000)
	});
}

exports.accordianTriggerToggle = function accordianTriggerToggle(id) {
	var accId = '#accordianTrigger' + id;
	$(accId).slideToggle(300);
}

exports.mobilemenuTriggerToggle = function mobilemenuTriggerToggle(id) {
	var menuId = '#level-' + id;
	$(menuId).slideToggle(300);
}

exports.prodDescriptionToggle = function prodDescriptionToggle() {
	$('#prodDescriptionToggle').slideToggle(300);
}

exports.goProdAttributeToggle = function goProdAttributeToggle(id) {
	var menuId = '#ProdAttr_' + id;
	$(menuId).slideToggle(300);
}

exports.findOrRetrieveAddress = function findOrRetrieveAddress(params) {

	var result;
	$.ajax({
		type: "POST",
		url: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.10/json3.ws' + params,
		contentType: "application/json; charset=utf-8",
		dataType: "json",
		//async: false,
		success: function (data) {
			result = data;

		},
		error: function () {

		}
	});

	return result;
}

exports.ProductSearchLookup = function ProductSearchLookup() {
	setupEanFinder();
}

exports.PrimeAISettings = function PrimeAISettings(BundleProductItems, code) {

	try {
		var link;
		var primeAiSettingLinkId = "primeAiSettingLinkId";
		link = document.getElementById(primeAiSettingLinkId);
		if (link) {
			link.remove();
		}
		link = document.createElement('link');
		var href = "https://www.primeai2.org/CUSTOMERS/hawescurtis/fitwidget/css/widget.css";
		link.type = 'text/css';
		link.setAttribute("rel", "stylesheet");
		link.setAttribute("ID", primeAiSettingLinkId)
		link.setAttribute("href", href);
		document.body.appendChild(link);

		var dataproductid = "";
		var src = "https://www.primeai2.org/CUSTOMERS/hawescurtis/fitwidget/002-use-widget.js";
		if (BundleProductItems) {
			if (BundleProductItems.length == 1) {
				dataproductid = BundleProductItems[0].Code;

			} else if (BundleProductItems.length > 1) {

				for (var i = 0; i < BundleProductItems.length; i++) {
					dataproductid += BundleProductItems[i].Code + ",";

				}
				if (dataproductid) {
					dataproductid = dataproductid.substring(0, dataproductid.length - 1);
				}

			}

		} else {
			dataproductid = code;
		}
		var script;
		var PrimeAiScriptId = "PrimeAiScriptId";
		script = document.getElementById(PrimeAiScriptId);
		if (script) {
			script.remove();

		}
		script = document.createElement('script');


		script.type = 'text/javascript';
		script.setAttribute("ID", PrimeAiScriptId);
		script.setAttribute("src", src);
		script.setAttribute("async", "");
		script.setAttribute("data-retailerid", "hawescurtis");
		script.setAttribute("data-productid", dataproductid);

		document.body.appendChild(script);

	}
	catch (ex) { }
}

exports.datascroll = function datascroll(event) {
	if (event && event.target && event.target.nodeName === 'A' ||
		(event.target.nodeName === 'SPAN' && event.target.parentElement && event.target.parentElement.nodeName == 'A' && event.target.parentElement.getAttribute('datascrollto')) ||
		(event.target.nodeName === 'I' && event.target.parentElement && event.target.parentElement.nodeName == 'SPAN' && event.target.parentElement.getAttribute('data-scrolltostick'))) {
		var className;
		if (event.target.nodeName === 'A') {
			className = event.target.getAttribute('datascrollto');
		} else {
			if (event.target.nodeName === 'SPAN') {

				className = event.target.parentElement.getAttribute('datascrollto');

			} else {
				className = event.target.parentElement.getAttribute('data-scrolltostick');
			}
		}
		if (className) {
			var scrollSelector = className;
			var scrollPos = 0;
			if (scrollSelector !== undefined && scrollSelector !== null && scrollSelector !== "") {
				if ($(".site-header").height() === undefined) {
					if ($(scrollSelector).position() !== undefined) {
						scrollPos = $(scrollSelector).position().top;
					}
				} else {
					var selectorOffsetTop = $(scrollSelector).offset() !== undefined ? $(scrollSelector).offset().top : 0;
					scrollPos = selectorOffsetTop - $(".site-header").height();
				}
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
	}
}
