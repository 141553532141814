
import { DefaultService } from 'src/app/modules/layout/default.service';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ScriptLocation, ScriptService } from 'src/app/shared/service/script.service';
import { SharedService } from '../../shared.service';
import { LpAppSetting, YotpoSettings } from 'src/app/model/lpapp-setting';
import { Title, Meta } from '@angular/platform-browser';
import { SiteContentService } from '../../sitecontent.service';
import { SiteContentDetail } from '../../models/siteContent';

@Component({
	selector: 'reviews,[reviews]',
	templateUrl: './reviews.component.html',
	styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit, OnDestroy, AfterViewInit {
	yotpoScriptElement: HTMLScriptElement | null = null;

	constructor(private renderer: Renderer2, private defaultService: DefaultService, private scriptService: ScriptService, private sharedService: SharedService, private titleService: Title, private metaService: Meta, private siteContentService: SiteContentService,) { }
	LpAppSetting: LpAppSetting;
	yotpoSettings: YotpoSettings;
	siteContent: SiteContentDetail;

	ngOnInit(): void {
		this.getApplicationSettings();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.getYotpoSettings();
		this.loadYotpoScriptFile();
	}
	ngOnDestroy() {
		this.unloadYotpoScriptFile();
	}
	ngAfterViewInit(): void {
		this.updateHeadElements();
	}

	private async getYotpoSettings(): Promise<void> {
		if (!this.LpAppSetting) {
			this.LpAppSetting = await this.sharedService.getLpAppSettings();
		}
		if (this.LpAppSetting.YotpoSettings) {
			this.yotpoSettings = this.LpAppSetting.YotpoSettings;
			const { ApiKey: apiKey } = this.yotpoSettings;
			const isScriptLoaded = (url) => {
				var scripts = document.querySelectorAll('[src*="' + url + '"]');
				if (scripts && scripts.length > 0) {
					return true;
				}
				else {
					return false;
				}
				return false;
			};
			const scriptUrl = "//cdn-widgetsrepository.yotpo.com/v1/loader/" + apiKey;
			if (!isScriptLoaded(scriptUrl)) {
				setTimeout(this.loadYotpoScript, 0, scriptUrl);
			}
		}
	}

	loadYotpoScript(scriptUrl) {
		(window as any).yotpoWidgetsContainer = {};
		(function () {
			var e = document.createElement("script");
			e.type = "text/javascript";
			e.async = true;
			e.src = scriptUrl;
			var t = document.getElementsByTagName("script")[0];
			t.parentNode.insertBefore(e, t);
		})();
	}

	loadYotpoScriptFile() {
		const scriptsToLoad = [
			'yotpoScripts',
		];

		this.scriptService
			.load(
				ScriptLocation.Body,
				...scriptsToLoad
			).then((scripts) => {
			})
			.catch((error) => console.debug(error));
	}

	unloadYotpoScriptFile() {
		this.scriptService
			.unload(
				ScriptLocation.Body,
				'yotpoScripts',
			)
			.then((data) => {
				// console.debug('script unloaded ', data);
			})
			.catch((error) => console.debug(error));
	}

	getApplicationSettings() {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	updateHeadElements(): void {
		if (this.LpAppSetting.WebStoreId !== 2) {
			this.setTitle('Customer Reviews Hawes and Curtis');
			this.setMeta('description', 'View our customer product & service reviews here.');
		} else {
			this.setTitle('Kundenbewertungen Hawes and Curtis');
			this.setMeta('description', 'KundenbewertungenSehen Sie sich hier die Kundenbewertungen unserer Produkte und Dienstleistungen an.');
		}
		const storeId = this.LpAppSetting.WebStoreId;

		switch (storeId) {
			case 1:
				this.addLinkElement('canonical', 'https://www.hawesandcurtis.co.uk/reviews', null);
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.co.uk/reviews', 'en-GB');
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.co.uk/reviews', 'x-default');
				break;
			case 2:
				this.addLinkElement('canonical', 'https://www.hawesandcurtis.de/reviews', null);
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.de/reviews', 'de-DE');
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.de/reviews', 'x-default');
				break;
			case 4:
				this.addLinkElement('canonical', 'https://www.hawesandcurtis.com/reviews', null);
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.com/reviews', 'en-US');
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.com/reviews', 'x-default');
				break;
			case 5:
				this.addLinkElement('canonical', 'https://www.hawesandcurtis.com.au/reviews', null);
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.com.au/reviews', 'en-au');
				this.addLinkElement('alternate', 'https://www.hawesandcurtis.com.au/reviews', 'x-default');
				break;
		}
	}

	setTitle(title: string) {
		this.titleService.setTitle(title);
	}

	setMeta(name: string, content: string) {
		this.metaService.updateTag({ name, content });
	}

	addLinkElement(rel: string, href: string, hreflang: string) {
		const linkElement = this.renderer.createElement('link');
		this.renderer.setAttribute(linkElement, 'rel', rel);
		this.renderer.setAttribute(linkElement, 'href', href);
		if (hreflang) {
			this.renderer.setAttribute(linkElement, 'hreflang', hreflang);
		}
		this.renderer.appendChild(document.head, linkElement);
	}
}
