import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input, NgZone, OnInit, Renderer2, ViewChild, PLATFORM_ID, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import * as $ from 'jquery';
import { ProductsFromCategoriesCriteria } from 'src/app/model/content-page-row-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { InitialiseProductListingPageTracking } from '../../../../assets/shared/gtm.js';
import { LoaderService } from '../../../shared/components/loader/loader.service';
import { ScrollService } from '../../../shared/service/scroll.service';
import { DefaultService } from '../../layout/default.service';
import { FacetFilterComponent } from '../facet-filter/facet-filter.component';
import { AlgoliaProductViewModel, Product } from '../model/algolia-product-viewmodel';
import { FacetedNavigationGroup, PriceAndStockKeyValues, ProductBanner } from '../model/product-list';
import { BannerDto, SearchVM } from '../model/search-viewmodel';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { empty } from 'rxjs';
import buildFacetGroupsFromAttraqtFacets from '../../../shared/helper/build-facet-groups-from-attraqt-facets';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { FacetFilter } from 'src/app/shared/state/facet-filter/facet-filter.action';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { ProductListSourceEnum } from 'src/app/shared/enums/product-list-source.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ViewportService } from '../../../shared/viewport.service';
import { BreadcrumbItem } from 'src/app/shared/models/breadcrumb.js';
import { ViewPortEnum } from 'src/app/enums/device-type-enum';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';
import { LocalStorageKeyEnum } from 'src/app/enums/local-storage-enum';
import { PersonifySocialProofingService } from 'src/app/shared/service/personify-social-proofing.service';
import { SocialProofingTypeEnum } from 'src/app/shared/models/social-proofing.model';

export interface BannerImage {
	Url: string;
	hoverUrl: string;
	Position: number;
}

@Component({
	selector: 'product-list,[product-list]',
	templateUrl: './product-list.component.html',
	styleUrls: ['./product-list.component.scss']
})

export class ProductListComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() productsFromCategoriesCriteria: ProductsFromCategoriesCriteria;
	@Input() breadcrumbs: BreadcrumbItem[];

	@ViewChild(FacetFilterComponent) child!: FacetFilterComponent;
	@ViewChild('loadMoreElement') loadMoreElement: ElementRef;

	private calculateHeaderHeightSubject = new Subject<void>();

	searchText: string = "";
	selectedTab = 4;
	facetedNavigationGroups: FacetedNavigationGroup[];
	isDataLoaded: Promise<boolean>;
	onFilterShow = false;
	bannerPosition: number;
	bannerImageUrl: string;
	itemsPerPage = 36;
	pageSize: number = this.itemsPerPage;
	totalPages: number = 1;
	pageNumber: number = 1;
	viewAll: boolean = false;
	onFilterHideCall = false;
	sortBy: string;
	selectedSort = false;
	sortDescending: boolean;
	innerWidth;
	showSmallScreenFilter: boolean = false;
	topPosToStartShowing = 0;
	isShowStickyBar = false;
	siteContent: SiteContentDetail;
	OfferTokenCookie: string;
	searchVM: SearchVM;
	algoliaProductViewModel: AlgoliaProductViewModel;
	products: Product[] = [];
	hasBanner: boolean = false;
	lpAppSetting: LpAppSetting;
	bannerDto: BannerDto;
	totalProduts: number = 0;
	allcombineFilters: any[] = [];
	firstUpdate = false;
	myObserver = null;
	previousUrl: string = null;
	currentUrl: string = null;
	bannerObserver = null;
	bannerDtoList: BannerDto[];
	bannerImages: BannerImage[];
	scriptContent = [];
	fredhopperResponseId: string;
	productListSource: ProductListSourceEnum;
	productListSources: typeof ProductListSourceEnum;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	preloadingCount: number = 12;
	isPreloading: boolean = true;
	loadMore: boolean = false;
	queryParams: any;
	queryParamsSubscriber: Subscription;
	schemaScript: SafeHtml;
	scrollPositionParam: string;
	isAutoVerticalScrollDisabled = false;
	deviceType: string;
	headerHeight: number;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;
	likedItems:any;
	userData;
	private likedItemsSubscription: Subscription;

	constructor(
		private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private loaderService: LoaderService,
		private cd: ChangeDetectorRef,
		private router: Router,
		private defaultService: DefaultService,
		private sharedService: SharedService,
		private siteContentService: SiteContentService,
		private r: Renderer2,
		private activatedRoute: ActivatedRoute,
		public zone: NgZone,
		private scrollService: ScrollService,
		private store: Store,
		private attraqtService: AttraqtService,
		public viewportService:ViewportService,
		private localStorageService: LocalStorageService,
		private personifySocialProofingService: PersonifySocialProofingService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId: any
	) {
		this.firstUpdate = false;
		this.productListSources = ProductListSourceEnum;
	}

	ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			this.userData = JSON.parse(localStorage.getItem('userData'));
			this.getLikedProducts();
			localStorage.setItem("productListUrl", this.router.url);
			this.subscribeToState();
			this.getLpAppSetting();
			this.enableVerticalFacetFilters();
			this.bannerObserver = this.defaultService.getBannerUrlEvent$.subscribe(data => this.getFacetFilterFromUrl(data))
			this.siteContent = this.siteContentService.getSiteContentData();

			this.myObserver = this.activatedRoute.url.subscribe(url => {

				var productUrl = localStorage.getItem("productListUrl");
				this.previousUrl = localStorage.getItem("prevUrl");
				this.currentUrl = this.router.url;

				localStorage.setItem("prevUrl", this.router.url);

				if (productUrl && productUrl.includes('?')) {
					productUrl = productUrl.substring(0, productUrl.indexOf('?'));
				}

				if (this.currentUrl && this.currentUrl.includes('?')) {
					this.currentUrl = this.currentUrl.substring(0, this.currentUrl.indexOf('?'));
				}

				if (productUrl && productUrl === this.currentUrl) {
					this.initializeProductListSettings();
				} else {
					localStorage.setItem("productListUrl", "");
				}
			});
			this.queryParamsSubscriber = this.route.queryParams.subscribe(
				(params) => {
					this.queryParams = params;
					if (params?.s && !this.scrollPositionParam) {
						this.scrollPositionParam = params.s;
					}
				}
			);
			this.innerWidth = window.innerWidth;

			if (isPlatformBrowser(this.platformId)) {
				this.OfferTokenCookie = this.getOfferTokenCookie();
			}

			this.sharedService.menuItemSelected(window.location.pathname);

			this.calculateHeaderHeightSubject.pipe(debounceTime(500)).subscribe(() => this.headerHeight = this.calculateHeaderHeight());
	}
	}

	getLikedProducts() {
		this.likedItemsSubscription = this.localStorageService.watchKey(LocalStorageKeyEnum.LikedItems).subscribe(() => {
			const likedItemsStr = this.localStorageService.getItem(LocalStorageKeyEnum.LikedItems);
			this.likedItems = likedItemsStr ? JSON.parse(likedItemsStr) : []; 

			this.products.forEach(product => {
				product.IsLiked = false;
			});

			if (Array.isArray(this.likedItems)) {
				this.likedItems.forEach(likedProductId => {
					const existingProduct = this.products.find(product => product.ProductId === likedProductId);
					if (existingProduct) {
						existingProduct.IsLiked = true;
					}
				});
			}
		});
	}

	ngAfterViewInit() {
		this.deviceType = this.viewportService.getDeviceType();
		this.calculateHeaderHeightSubject.next();
		this.cd.detectChanges();
	}

	ngOnDestroy() {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem("productListUrl", "");
			localStorage.setItem("prevUrl", "")

			if (this.myObserver) {
				this.myObserver.unsubscribe();
			}

			if (this.bannerObserver) {
				this.bannerObserver.unsubscribe();
			}
			if (this.facetFilterSubscriber) {
				this.facetFilterSubscriber.unsubscribe();
			}
			if (this.queryParamsSubscriber) {
				this.queryParamsSubscriber.unsubscribe();
			}
		}
		if (this.likedItemsSubscription) {
            this.likedItemsSubscription.unsubscribe();
        }
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.deviceType = this.viewportService.getDeviceType();

		if (this.deviceType !== ViewPortEnum.Desktop && this.showSmallScreenFilter) {
			this.hideFilterCallForSmallScreen();
		}

		this.calculateHeaderHeightSubject.next();
	}

	@HostListener('window:scroll')
	checkScroll() {
		const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		if (!this.lpAppSetting?.UserInterface?.VerticalFacetFiltersEnabled || this.deviceType !== ViewPortEnum.Desktop) {
			let winTop = window.pageYOffset;
			let elTop = $('.stick-bar').offset().top - $('.site-header').height();
			let elHeight = $('.stick-bar').height() + 'px';
			let el = $('.content-filter-mob');
			let filterSectionHeight = document.getElementsByClassName('filter-section') && document.getElementsByClassName('filter-section')[0] && document.getElementsByClassName('filter-section')[0].clientHeight ? document.getElementsByClassName('filter-section')[0].clientHeight : 0;

			if (scrollPosition >= this.topPosToStartShowing) {
				el.removeClass('sticky');
				$('.stick-bar').next().css('margin-top', '0');
			} else {
				if (winTop >= elTop) {
					if (!el.hasClass('sticky')) {
						el.addClass('sticky');
						$('.stick-bar').next().css('margin-top', elHeight);
						$('.filter-section-block').css('min-height', filterSectionHeight);
					}
				} else {
					if (el.hasClass('sticky')) {
						el.removeClass('sticky');
						$('.stick-bar').next().css('margin-top', '0');
						$('.filter-section-block').css('min-height', 'auto');
					}
				}
			}

			this.topPosToStartShowing = scrollPosition;
		}

		if (this.loadMoreElement) {
			const rect = this.loadMoreElement.nativeElement.getBoundingClientRect();
			const topShown = rect.top >= 0;
			const bottomShown = rect.bottom <= window.innerHeight;

			if (topShown && bottomShown) {
				this.loadMore = true;
			}
		}
		if (this.viewAll) {
			this.router.navigate([], { queryParams: { s: scrollPosition.toString() }, replaceUrl: true, queryParamsHandling: 'merge' });
		}
	}

	getOfferTokenCookie(): string {
		let cookie = '';

		if (document.cookie && document.cookie !== '') {
			const split: Array<string> = document.cookie.split(';');

			for (let i = 0; i < split.length; i += 1) {

				const currentCookie: Array<string> = split[i].split('=');

				currentCookie[0] = currentCookie[0].replace(/^ /, '');

				if (currentCookie[0] && currentCookie[0].toLocaleLowerCase() === "offertoken") {
					cookie = decodeURIComponent(currentCookie[1]);
					break;
				}
			}
		}

		return cookie;
	}

	initializeProductListSettings() {

		var prmstr = window.location.search.substr(1);

		if (this.productsFromCategoriesCriteria) {

			this.getProductBannerImage();

			if (this.productsFromCategoriesCriteria.searchText) {
				this.searchText = this.productsFromCategoriesCriteria.searchText;
				this.r.removeClass(document.body, 'pt-homepage');
				this.r.addClass(document.body, 'pt-productlistingpage');
			}

			this.searchVM = {} as SearchVM;
			this.searchVM.PageNumber = this.pageNumber;
			this.searchVM.ViewAll = false;
			this.searchVM.CategoryAttributes = [];

			if (this.productsFromCategoriesCriteria.CategoryAttributes) {
				var catAttriibute = this.productsFromCategoriesCriteria.CategoryAttributes.split(',').map(Number);
				this.searchVM.CategoryAttributes = catAttriibute;
			}

			this.searchVM.CategoryId = this.productsFromCategoriesCriteria.CategoryId;
			this.searchVM.CombinedSizeFiltersStr = this.productsFromCategoriesCriteria.CombinedSizeFiltersStr;
			this.searchVM.ExclFiltersStr = this.productsFromCategoriesCriteria.ExclFilters;
			this.searchVM.FilterNewArrivalProducts = this.productsFromCategoriesCriteria.FilterNewArrivalProducts;
			this.searchVM.FilterSaleProducts = this.productsFromCategoriesCriteria.FilterSaleProducts;
			this.searchVM.FiltersStr = this.productsFromCategoriesCriteria.Filters;
			this.searchVM.ImageKeys = this.productsFromCategoriesCriteria.ImageKeys;
			this.searchVM.Query = this.productsFromCategoriesCriteria.searchText;
			this.searchVM.ShopByStyle = this.productsFromCategoriesCriteria.ShopByStyle;
			this.searchVM.SortBy = this.productsFromCategoriesCriteria.SortBy;
			this.searchVM.SortDescending = this.productsFromCategoriesCriteria.SortDescending;
			this.searchVM.AttraqtSessionId = this.attraqtService.getAttraqtSessionId();

			if (this.bannerDtoList && this.bannerDtoList.length > 0) {
				this.searchVM.Banners = [] as BannerDto[];
				this.searchVM.Banners = this.bannerDtoList;
			}

			if (prmstr) {
				this.searchVM.SearchParams = prmstr;
			}

			var params = this.getSearchParameters();

			if (params) {
				if (params['sortDescending']) {
					this.sortDescending = JSON.parse(params['sortDescending']);
					this.searchVM.SortDescending = JSON.parse(params['sortDescending']);
				}

				if (params['sortBy']) {
					this.sortBy = params['sortBy'];
					this.searchVM.SortBy = params['sortBy'];
				}

				if (params['page']) {
					this.pageNumber = JSON.parse(params['page']);
					this.searchVM.PageNumber = JSON.parse(params['page']);
				} else {
					this.pageNumber = 1;
					this.searchVM.PageNumber = 1;
				}

				if (params['viewAll']) {
					this.viewAll = JSON.parse(params['viewAll']);
					this.searchVM.ViewAll = JSON.parse(params['viewAll']);
				}

				if (params['q']) {
					this.searchVM.Query = params['q'];
				}
			}

			// Immediately show preloading products as soon as the page loads so that CLS is prevented when real API call is made
			this.initialisePreloadingProducts();

			this.doProductSearch();
		}
	}

	hideFilterCall() {
		this.onFilterHideCall = true;
	}

	showFilterCall() {
		this.onFilterHideCall = false;
		setTimeout(() => {
			this.child.showFilterContent();
		}, 700);
	}

	showFilterCallForSmallScreen() {
		$('.content-mask-out').addClass('conetntMaskBackground');
		this.showSmallScreenFilter = true;
		this.child.showSmallScreenFilter();
	}

	hideFilterCallForSmallScreen() {
		$('.content-mask-out').removeClass('conetntMaskBackground');
		this.showSmallScreenFilter = false;
		this.child.hideSmallScreenFilter();
	}

	sortItemsFilter(e) {
		this.selectedSort = true;
		this.setSort(e[0], e[1]);
	}

	setSort(sortby: string, sortDescending?: boolean): void {

		this.pageNumber = 1;
		this.selectedSort = !this.selectedSort;
		this.sortBy = sortby;
		this.sortDescending = sortDescending;
		if (this.searchVM) {
			this.searchVM.PageNumber = this.pageNumber;
			this.searchVM.SortBy = sortby;
			this.searchVM.SortDescending = sortDescending;
		}

		if (this.productListSource === ProductListSourceEnum.Attraqt) {
			const queryParams = { ...this.queryParams };
			if (queryParams?.hasOwnProperty('sortBy')) {
				if (!this.sortBy) {
					delete queryParams['sortBy'];
				} else {
					queryParams['sortBy'] = this.sortBy;
				}
			}
			if (!queryParams?.hasOwnProperty('sortBy') && this.sortBy) {
				queryParams['sortBy'] = this.sortBy;
			}
			if (queryParams?.hasOwnProperty('sortDescending')) {
				if (!this.sortDescending) {
					delete queryParams['sortDescending'];
				} else {
					queryParams['sortDescending'] = this.sortDescending;
				}
			}
			if (!queryParams?.hasOwnProperty('sortDescending') && this.sortDescending) {
				queryParams['sortDescending'] = this.sortDescending;
			}
			const queryString = this.resolveSearchQueryString(queryParams);
			this.searchVM.SearchParams = queryString;
			this.doProductSearch();
			this.router.navigate([], { queryParams });
		} else {
			let params = {};
			if (sortby) {
				params['sortBy'] = sortby;
				if (sortDescending) {
					params['sortDescending'] = true;
				}
			}
			this.doProductSearch();
			this.router.navigate([], { queryParams: params });
		}
	}

	sortChangedHandler([sortby, sortDescending]: any): void {
		this.pageNumber = 1;
		if (this.searchVM) {
			this.searchVM.PageNumber = this.pageNumber;
			this.searchVM.SortBy = sortby;
			this.searchVM.SortDescending = sortDescending;
		}
	}

	setPagination(pageNumber) {

		var params = this.getSearchParameters();

		if (!params) {
			params = {};
		}

		if (pageNumber > 1) {
			this.pageNumber = pageNumber;
			params['page'] = pageNumber;
		} else {
			this.pageNumber = 1;
			delete params['page'];
		}

		if (this.searchVM) {
			this.searchVM.PageNumber = this.pageNumber;
		}

		this.doProductSearch();

		this.router.navigate(
			[],
			{
				queryParams: params,
			});

			this.scrollService.windowScrollTo(0, 0);
	}

	setPage(isViewAll) {

		try {

			this.viewAll = isViewAll;
			this.pageNumber = 1;

			var params = this.getSearchParameters();

			if (this.searchVM) {
				this.searchVM.PageNumber = this.pageNumber;
				this.searchVM.ViewAll = isViewAll;
			}

			if (isViewAll) {

				if (params) {
					let pageNumber = params['page'];

					if (pageNumber) {
						delete params['page'];
					}
				}

				if (!params) {
					params = {};
				}

				params['viewAll'] = isViewAll;
				this.doProductSearch();
			}
			else {

				if (params) {
					if (params['viewAll']) {
						delete params['viewAll'];
					}
					if (params['s']) {
						delete params['s'];
					}
				}

				this.pageSize = this.itemsPerPage;
				this.doProductSearch();
			}

			this.router.navigate(
				[],
				{
					queryParams: params,

				});
		} catch (ex) {

		}
	}

	initialisePreloadingProducts() {
		var viewModel = <AlgoliaProductViewModel>{};

		viewModel.TotalProduct = this.preloadingCount;

		viewModel.FacetedNavigationGroup = new Array<FacetedNavigationGroup>(); //<FacetedNavigationGroup[]>{};

		var emptyFacetedNavigationGroup = <FacetedNavigationGroup>{};
		emptyFacetedNavigationGroup.GroupId = '';
		emptyFacetedNavigationGroup.Order = 0;
		emptyFacetedNavigationGroup.Key = "preloading";
		emptyFacetedNavigationGroup.Title = "Preloading";
		emptyFacetedNavigationGroup.Items = [];
		emptyFacetedNavigationGroup.SelectedItems = [];
		emptyFacetedNavigationGroup.IsVisible = true;

		viewModel.FacetedNavigationGroup.push(emptyFacetedNavigationGroup);
		viewModel.FacetedNavigationGroup.push(emptyFacetedNavigationGroup);
		viewModel.FacetedNavigationGroup.push(emptyFacetedNavigationGroup);

		viewModel.Products = new Array<Product>(); //<Product[]>{};

		var emptyProduct = <Product>{};
		emptyProduct.Name = "Preloading Name";
		emptyProduct.PriceAndStockKeyValues = <PriceAndStockKeyValues>{
			"RetailPrice": 100,
			"SalePrice": 100,
			"Qty": 100,
			"Icons": []
		};
		emptyProduct.IsPreloading = true;

		for (var i = 0; i < this.preloadingCount; i++) {
			viewModel.Products.push(emptyProduct);
		}

		this.renderProductsToView(viewModel);
	}

	doProductSearch() {
		this.defaultService.AjaxProductsFromCategories(this.searchVM).subscribe(data => {
			this.renderProductsToView(data);
			this.isPreloading = false;

			// If the number of products (and banners) are less than the preloadingCount then we cannot show any more products
			if (this.products.length <= this.preloadingCount) {
				this.loadMore = true;
			}

			this.getTrackingScript();
			this.loadSocialProofing();
			this.localStorageService.trigger(LocalStorageKeyEnum.LikedItems);
		});
	}

	generateSchemaListItemCollection(lstProducts: any[]): any[] {
		console.log(lstProducts)
		return lstProducts.map((item, idx) => ({
		  "@type": "ListItem",
		  "position": idx + 1,
		  "url": window.location.origin + item.FullUrl,
		  "name": item.Name,
		}));
	  }

	generateScript(data: any[]): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(`<p><script type="application/ld+json">{ "@context": "http://schema.org", "@type": "ItemList", "itemListElement": ${JSON.stringify(data)} }</script></p>`);
	}

	renderProductsToView(data: AlgoliaProductViewModel) {
		if (data) {

			const itemList = this.generateSchemaListItemCollection(data.Products);
			this.schemaScript = this.generateScript(itemList);

			this.algoliaProductViewModel = data;

			this.totalProduts = this.algoliaProductViewModel.TotalProduct;

			if (this.totalProduts && this.itemsPerPage && this.itemsPerPage != 0) {
				this.totalPages = (this.totalProduts / this.itemsPerPage);
			} else {
				this.totalPages = 0
			}

			this.pageNumber = this.algoliaProductViewModel.Page;

			if (this.algoliaProductViewModel.Source) {
				this.productListSource = this.algoliaProductViewModel.Source
			}

			if (this.algoliaProductViewModel.Products) {
				this.products = [...this.algoliaProductViewModel.Products];

				this.addBannerToProductList();
			}

			if (this.algoliaProductViewModel.FacetedNavigationGroup) {
				if (this.productListSource === ProductListSourceEnum.Attraqt) {
					this.facetedNavigationGroups = buildFacetGroupsFromAttraqtFacets(
						this.algoliaProductViewModel.FacetedNavigationGroup
					);
				}
				if (this.productListSource === ProductListSourceEnum.Algolia || this.productListSource === ProductListSourceEnum.Database) {

					this.facetedNavigationGroups = this.algoliaProductViewModel.FacetedNavigationGroup;

					if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {

						if (this.productsFromCategoriesCriteria && this.productsFromCategoriesCriteria.CombinedSizeFiltersStr) {
							this.combineFilter();
						}

						let index = 0;

						this.facetedNavigationGroups.forEach(group => {

							if (group && group.IsVisible) {
								group.Index = index;
								index++;

								var itemList = group.Items.filter(x => x.IsSelected === true);

								if (itemList) {
									group.SelectedFilter = itemList.length;
								}
							}
						})
					}
				}
			}

			if (this.viewAll) {
				if (this.products && this.products.length > 0) {
					this.pageSize = this.products.length;
				}
			} else {
				this.pageSize = this.itemsPerPage;
			}
			if (this.algoliaProductViewModel.FredhopperRID) {
				this.fredhopperResponseId = this.algoliaProductViewModel.FredhopperRID;
				this.store.dispatch(new FacetFilter.SetSourceId(this.fredhopperResponseId));
			}
		}
		const isPlaceholderProduct = !this.algoliaProductViewModel?.Products[0]?.ObjectID
		if (!this.isAutoVerticalScrollDisabled && !isPlaceholderProduct && this.viewAll && +this.scrollPositionParam > 0) {
			this.scrollService.setScrollYPosition(0);
			this.loadMore = true;
			this.isAutoVerticalScrollDisabled = true;
			setTimeout(() => {
				this.scrollService.windowScrollTo(0, parseInt(this.scrollPositionParam, 10));
			}, 1000);
		} 
		this.isDataLoaded = Promise.resolve(true);
	}

	addBannerToProductList() {

		if (this.products) {
			this.products = this.products.filter(x => {

				if (x.PriceAndStockKeyValues) {
					return true;
				}
				else {
					false;
				}
			})
		}

		if (this.bannerDtoList && this.bannerDtoList.length > 0 && this.products && this.products.length > 0) {

			this.bannerDtoList.forEach(element => {
				if (this.pageNumber === element.CustomBannerPage) {

					const temp = this.productsFromCategoriesCriteria.BannerControls.filter(v => v.RowControlId == element.BannerID)[0];

					if (temp && temp.Properties) {

						let algoliaProduct = {} as Product;
						algoliaProduct.ProductBanner = {} as ProductBanner;
						algoliaProduct.ProductBanner.HasBanner = element.HasBanner;

						temp.Properties.forEach(item => {
							if (item.ControlTypeElementKey.toLocaleLowerCase() == 'src') {
								algoliaProduct.ProductBanner.BannerUrl = item.Value
							} else if (item.ControlTypeElementKey.toLocaleLowerCase() == 'hoversrc') {
								algoliaProduct.ProductBanner.HoverBannerUrl = item.Value
							} else if (item.ControlTypeElementKey && item.ControlTypeElementKey.toLocaleLowerCase() === "destinationurl") {
								algoliaProduct.ProductBanner.DestinationUrl = item.Value;
							} else if (item.ControlTypeElementKey && item.ControlTypeElementKey.toLocaleLowerCase() === "content") {
								algoliaProduct.ProductBanner.Content = item.Value;
							}
						});

						this.products[element.CustomBannerPosition - 1]
						this.products.splice((element.CustomBannerPosition - 1), 0, algoliaProduct);
					}
				}
			});
		}
	}

	getProductBannerImage() {
		this.bannerDtoList = [] as BannerDto[];

		if (this.productsFromCategoriesCriteria) {

			if (this.productsFromCategoriesCriteria.BannerControls && this.productsFromCategoriesCriteria.BannerControls.length > 0) {

				this.productsFromCategoriesCriteria.BannerControls.forEach(control => {

					this.bannerDto = {} as BannerDto;
					this.bannerDto.HasBanner = true;
					this.bannerDto.StyleClass = "banner"
					this.bannerDto.InsertAtIndex = 0;

					let hasbanner = false;
					let bannerImage = {} as BannerImage;

					if (control.SortOrder) {
						this.bannerDto.DBSortOrder = control.SortOrder;
					}

					if (control.RowControlId) {
						this.bannerDto.BannerID = control.RowControlId;
					}

					if (control.ControlTypeName.toLocaleLowerCase() === 'banner') {

						hasbanner = true;

						if (control.Properties && control.Properties.length > 0) {

							control.Properties.forEach(prop => {

								if (prop.ControlTypeElementKey && prop.ControlTypeElementKey.toLocaleLowerCase() === 'src') {
									this.bannerImageUrl = prop.Value;
									bannerImage.Url = prop.Value;
								} else if (prop.ControlTypeElementKey && prop.ControlTypeElementKey.toLocaleLowerCase() === "hoversrc") {
									bannerImage.hoverUrl = prop.Value;
								}

								if (prop.ControlTypeElementKey && prop.ControlTypeElementKey.toLocaleLowerCase() === 'bannerposition') {
									if (+prop.Value < 1) {
										this.bannerDto.CustomBannerPosition = -1;
										this.bannerPosition = -1;
										bannerImage.Position = -1;
									} else {
										this.bannerDto.CustomBannerPosition = + prop.Value;
										bannerImage.Position = + prop.Value;
									}
								}

								if (prop.ControlTypeElementKey && prop.ControlTypeElementKey.toLocaleLowerCase() === 'bannerpage') {
									if (+prop.Value < 1) {
										this.bannerDto.CustomBannerPage = 1;
									} else {
										this.bannerDto.CustomBannerPage = + prop.Value;
									}
								}
							});
						}

						if (this.bannerDto && hasbanner) {

							if (!this.bannerImages) {
								this.bannerImages = [] as BannerImage[];
							}

							if (bannerImage) {
								this.bannerImages.push(bannerImage)
							}

							this.bannerDtoList.push(this.bannerDto);
						}
					}
				});
			}
		}
	}

	onFilterSelected(query: string) {

		if (this.pageNumber) {
			this.pageNumber = 1;
		}

		if (this.searchVM) {
			this.searchVM.PageNumber = this.pageNumber;
			if (this.productListSource === ProductListSourceEnum.Attraqt) {
				this.searchVM.SearchParams = query;
			}
			if (this.productListSource === ProductListSourceEnum.Algolia || this.productListSource === ProductListSourceEnum.Database) {
				this.searchVM.SearchParams = this.getSearchParams();
			}
		}

		this.doProductSearch();
	}

	getSearchParams(): string {

		var searchParams = "";

		// Append filter params
		if (this.facetedNavigationGroups && this.facetedNavigationGroups.length > 0) {
			this.facetedNavigationGroups.forEach(group => {

				var groupKey = group.Key;
				var itemIds = [];

				// Ignore null/undefined group keys
				if (!groupKey) {
					return;
				}

				if (groupKey === "combifilters") {
					return;
				}

				group.Items.forEach(item => {
					if (item.IsSelected) {
						itemIds.push(item.Id);
					}
				})

				if (itemIds.length > 0) {
					searchParams += (searchParams.length > 0 ? "&" : "") + groupKey + "=" + itemIds.join(',');
				}
			})
		}

		return searchParams;
	}

	selectTab(selectedTab) {
		if (selectedTab) {
			this.selectedTab = selectedTab;
		}
	}

	getSearchParameters() {
		var prmstr = window.location.search.substr(1);

		return prmstr != null && prmstr != '' ? this.transformToAssocArray(prmstr) : {};
	}

	transformToAssocArray(prmstr) {
		var params = {};

		var prmarr = prmstr.split('&');

		for (var i = 0; i < prmarr.length; i++) {
			var tmparr = prmarr[i].split('=');

			if (!params[tmparr[0]]) {
				params[tmparr[0]] = tmparr[1];
			} else {
				params[tmparr[0]] = params[tmparr[0]] + ',' + tmparr[1];
			}
		}

		return params;
	}

	getFacetFilterFromUrl(event) {

		if (event) {

			let params = {};

			if (event) {

				let urlArray = event.split("?");

				if (urlArray && urlArray.length > 1) {

					let queryParamUrl = urlArray[1];

					if (queryParamUrl) {
						if (this.searchVM) {
							this.searchVM.SearchParams = queryParamUrl;
						}

						let queryParamList = queryParamUrl.split("&");

						if (queryParamList) {
							for (var i = 0; i < queryParamList.length; i++) {

								let keyValuePair = queryParamList[i];

								if (keyValuePair) {
									let keyValuePairList = keyValuePair.split("=");

									if (keyValuePairList && keyValuePairList.length > 1) {
										params[keyValuePairList[0]] = keyValuePairList[1];
									}
								}
							}
						}
					}
				}
			}

			if (params && this.searchVM) {
				if (params['sortDescending']) {
					this.sortDescending = JSON.parse(params['sortDescending']);
					this.searchVM.SortDescending = JSON.parse(params['sortDescending']);
				}

				if (params['sortBy']) {
					this.sortBy = params['sortBy'];
					this.searchVM.SortBy = params['sortBy'];
				}

				if (params['page']) {
					this.pageNumber = JSON.parse(params['page']);
					this.searchVM.PageNumber = JSON.parse(params['page']);
				}

				if (params['viewAll']) {
					this.viewAll = JSON.parse(params['viewAll']);
					this.searchVM.ViewAll = JSON.parse(params['viewAll']);
				}

				if (params['q']) {
					this.searchVM.Query = params['q'];
				}
			}

			this.router.navigate(
				[],
				{

					queryParams: params,
				});
		}

		this.doProductSearch();
	}

	getLpAppSetting() {
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	combineFilter() {

		if (this.productsFromCategoriesCriteria.CombinedSizeFiltersStr && this.facetedNavigationGroups) {

			let combinedSizeFiltersPosition = 1;
			let comFilterStr = "";
			let combinedSizeFilterKeys;

			var combinedGroup = {} as any;

			if (this.productsFromCategoriesCriteria) {

				if (this.productsFromCategoriesCriteria.CombinedSizeFiltersPosition) {
					combinedSizeFiltersPosition = this.productsFromCategoriesCriteria.CombinedSizeFiltersPosition;
				}

				if (this.productsFromCategoriesCriteria.CombinedSizeFiltersTitle) {
					combinedGroup.Title = this.productsFromCategoriesCriteria.CombinedSizeFiltersTitle;
				}

				if (this.productsFromCategoriesCriteria.CombinedSizeFiltersStr) {
					comFilterStr = this.productsFromCategoriesCriteria.CombinedSizeFiltersStr;
					combinedSizeFilterKeys = comFilterStr.split(',');
				}
			}

			combinedGroup.CanToggle = false;
			combinedGroup.hasSelectedItems = false;
			combinedGroup.hideDesktop = false;
			combinedGroup.hideSmallDesktop = false;
			combinedGroup.hideTablet = false;
			combinedGroup.IsActive = false;
			combinedGroup.IsVisible = true;
			combinedGroup.Items = [];
			combinedGroup.SelectedItems = [];
			combinedGroup.Key = "combifilters";

			let combineFilters = [];
			this.allcombineFilters = [];

			this.facetedNavigationGroups.forEach(group => {

				// Check if group matches any inside Combined Size Filters
				var isCombinedSizeFilter = combinedSizeFilterKeys.filter(function (x) { return x == group.Key; }).length > 0;

				// Add these group's filter items into the new group
				if (isCombinedSizeFilter) {

					combineFilters.push(group);

					if (group.Items) {
						group.Items.forEach(filterItem => {
							combinedGroup.Items.push(filterItem);
						});
					}
					// Hide this group as we are using the Combined Size Filter now
					// group.show = false;
				}
			});

			this.allcombineFilters = combineFilters;

			let comFilterStrArray = this.productsFromCategoriesCriteria.CombinedSizeFiltersStr.split(",");

			this.facetedNavigationGroups.forEach(group => {
				let isExist = true;

				if (comFilterStrArray && comFilterStrArray.length > 0) {
					comFilterStrArray.forEach(key => {
						if (group.Key === key) {
							isExist = false;
						}
					})
				}

				group.IsVisible = isExist;
			})

			this.facetedNavigationGroups.splice(combinedSizeFiltersPosition - 1, 0, combinedGroup);
		}
	}

	getTrackingScript() {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.ProductList;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts();
			}, err => {

			})
		}
	}

	applyTrackingScripts() {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}

		//gtm start
		if (this.products) {
			var data = this.products.filter(x => {
				if (x.PriceAndStockKeyValues) {
					return true;
				}
				else {
					// AR 20230519 Fixed typo to return false
					//false;
					return false;
				}
			});

			if (this.lpAppSetting && this.productsFromCategoriesCriteria) {

				this.productsFromCategoriesCriteria.Query = "";

				if (this.searchText) {
					this.productsFromCategoriesCriteria.Query = this.searchText;
				}

				InitialiseProductListingPageTracking(this.lpAppSetting.CriteoSettings, data, this.productsFromCategoriesCriteria, this.facetedNavigationGroups, this.breadcrumbs);
			}
		}
		//gtm end
	}

	isBrowser() {
		return isPlatformBrowser(this.platformId);
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
				this.productListSource = facetFilterState.attraqtPLPEnabled
					? ProductListSourceEnum.Attraqt
					: ProductListSourceEnum.Algolia;
			}
		);
	}

	private resolveSearchQueryString(queryParams: any): string {
		const length = Object.keys(queryParams).length;
		const queryParam = Object.entries(queryParams).reduce(
			(acc: string, current: any, index) => {
				const [key, value] = current;
				if (index === length - 1) {
					acc += `${key}=${value}`;
				} else {
					acc += `${key}=${value}&`;
				}
				return acc;
			},
			''
		);
		return encodeURIComponent(queryParam);
	}

	verticalFiltersEnabledCheck() {
		//Vertical filters are not supposed to appear on mobile at all
		if (this.lpAppSetting?.UserInterface?.VerticalFacetFiltersEnabled
			&& this.viewportService.getDeviceType() === ViewPortEnum.Desktop) {
			return true;
		}
		else {
			return false;
		}
	}

	private enableVerticalFacetFilters() {
		// Add wrapper class in document body element to enable 'vertical facet filters' on PLP and search result
		if (this.verticalFiltersEnabledCheck()) {
			this.r.addClass(document.body, 'vertical-facet-filter-wrapper');
		}
	}

	private calculateHeaderHeight(): number | null {
		if (!this.verticalFiltersEnabledCheck() || this.deviceType !== ViewPortEnum.Desktop) {
			return null;
		}

		const headerElemet = this.document.querySelector<HTMLElement>('header .site-header');

		if (!headerElemet) {
			return null;
		}

		const { offsetHeight, offsetTop } = headerElemet;

		return offsetHeight + offsetTop + 5;
	}

	private loadSocialProofing() {
		const productCodes = this.products.filter(product => product.Code).map(product => product.Code);

		this.personifySocialProofingService.loadProductTrends(productCodes, SocialProofingTypeEnum.PLP);
			}
}