import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildScarfGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		ScarfTitle: title,
		ScarfMobileBannerImageUrl: mobileBannerImage,
		ScarfPlpLink: plpLink,
		ScarfHowToTitle: howToTitle,
		StepName1Text: stepNameOne,
		StepName2Text: stepNameTwo,
		StepName3Text: stepNameThree,
		StepName4Text: stepNameFour,
		StepName5Text: stepNameFive,
		ScarfParisianKnotName: parisianKnotName,
		ScarfParisianKnotDescription: parisianKnotDescription,
		ScarfParisianKnotVideoUrl: parisianKnotVideoUrl,
		ScarfParisianKnotDesktopImageUrl1: parisianKnotDesktopImageUrl1,
		ScarfParisianKnotMobileImageUrl1: parisianKnotMobileImageUrl1,
		ScarfParisianKnotDesktopImageUrl2: parisianKnotDesktopImageUrl2,
		ScarfParisianKnotMobileImageUrl2: parisianKnotMobileImageUrl2,
		ScarfParisianKnotDesktopImageUrl3: parisianKnotDesktopImageUrl3,
		ScarfParisianKnotMobileImageUrl3: parisianKnotMobileImageUrl3,
		ScarfParisianKnotDesktopImageUrl4: parisianKnotDesktopImageUrl4,
		ScarfParisianKnotMobileImageUrl4: parisianKnotMobileImageUrl4,
		ScarfParisianKnotDesktopImageUrl5: parisianKnotDesktopImageUrl5,
		ScarfParisianKnotMobileImageUrl5: parisianKnotMobileImageUrl5,
		ScarfOnceAroundName: onceAroundName,
		ScarfOnceAroundDescription: onceAroundDescription,
		ScarfOnceAroundVideoUrl: onceAroundVideoUrl,
		ScarfOnceAroundDesktopImageUrl1: onceAroundDesktopImageUrl1,
		ScarfOnceAroundMobileImageUrl1: onceAroundMobileImageUrl1,
		ScarfOnceAroundDesktopImageUrl2: onceAroundDesktopImageUrl2,
		ScarfOnceAroundMobileImageUrl2: onceAroundMobileImageUrl2,
		ScarfOnceAroundDesktopImageUrl3: onceAroundDesktopImageUrl3,
		ScarfOnceAroundMobileImageUrl3: onceAroundMobileImageUrl3,
		ScarfOnceAroundDesktopImageUrl4: onceAroundDesktopImageUrl4,
		ScarfOnceAroundMobileImageUrl4: onceAroundMobileImageUrl4,
		ScarfOverHandName: overHandName,
		ScarfOverHandDescription: overHandDescription,
		ScarfOverHandVideoUrl: overHandVideoUrl,
		ScarfOverHandDesktopImageUrl1: overHandDesktopImageUrl1,
		ScarfOverHandMobileImageUrl1: overHandMobileImageUrl1,
		ScarfOverHandDesktopImageUrl2: overHandDesktopImageUrl2,
		ScarfOverHandMobileImageUrl2: overHandMobileImageUrl2,
		ScarfOverHandDesktopImageUrl3: overHandDesktopImageUrl3,
		ScarfOverHandMobileImageUrl3: overHandMobileImageUrl3,
		ScarfOverHandDesktopImageUrl4: overHandDesktopImageUrl4,
		ScarfOverHandMobileImageUrl4: overHandMobileImageUrl4,
		ScarfOverHandDesktopImageUrl5: overHandDesktopImageUrl5,
		ScarfOverHandMobileImageUrl5: overHandMobileImageUrl5,
		ScarfReverseDrapeTuckName: reverseDrapeTuckName,
		ScarfReverseDrapeTuckDescription: reverseDrapeTuckDescription,
		ScarfReverseDrapeTuckVideoUrl: reverseDrapeTuckVideoUrl,
		ScarfReverseDrapeTuckDesktopImageUrl1: reverseDrapeTuckDesktopImageUrl1,
		ScarfReverseDrapeTuckMobileImageUrl1: reverseDrapeTuckMobileImageUrl1,
		ScarfReverseDrapeTuckDesktopImageUrl2: reverseDrapeTuckDesktopImageUrl2,
		ScarfReverseDrapeTuckMobileImageUrl2: reverseDrapeTuckMobileImageUrl2,
		ScarfReverseDrapeTuckDesktopImageUrl3: reverseDrapeTuckDesktopImageUrl3,
		ScarfReverseDrapeTuckMobileImageUrl3: reverseDrapeTuckMobileImageUrl3,
		ScarfReverseDrapeTuckDesktopImageUrl4: reverseDrapeTuckDesktopImageUrl4,
		ScarfReverseDrapeTuckMobileImageUrl4: reverseDrapeTuckMobileImageUrl4,
		ScarfReverseDrapeTuckDesktopImageUrl5: reverseDrapeTuckDesktopImageUrl5,
		ScarfReverseDrapeTuckMobileImageUrl5: reverseDrapeTuckMobileImageUrl5,
		ScarfTwistName: twistName,
		ScarfTwistDescription: twistDescription,
		ScarfTwistVideoUrl: twistVideoUrl,
		ScarfTwistDesktopImageUrl1: twistDesktopImageUrl1,
		ScarfTwistMobileImageUrl1: twistMobileImageUrl1,
		ScarfTwistDesktopImageUrl2: twistDesktopImageUrl2,
		ScarfTwistMobileImageUrl2: twistMobileImageUrl2,
		ScarfTwistDesktopImageUrl3: twistDesktopImageUrl3,
		ScarfTwistMobileImageUrl3: twistMobileImageUrl3,
		ScarfTwistDesktopImageUrl4: twistDesktopImageUrl4,
		ScarfTwistMobileImageUrl4: twistMobileImageUrl4,
		ScarfTwistDesktopImageUrl5: twistDesktopImageUrl5,
		ScarfTwistMobileImageUrl5: twistMobileImageUrl5,
		ScarfTwistDesktopImageUrl6: twistDesktopImageUrl6,
		ScarfTwistMobileImageUrl6: twistMobileImageUrl6,
		ScarfWearParisianKnotDescription1: parisianKnotDescription1,
		ScarfWearParisianKnotDescription2: parisianKnotDescription2,
		ScarfWearParisianKnotDescription3: parisianKnotDescription3,
		ScarfWearParisianKnotDescription4: parisianKnotDescription4,
		ScarfWearOnceAroundDescription1: onceAroundDescription1,
		ScarfWearOnceAroundDescription2: onceAroundDescription2,
		ScarfWearOnceAroundDescription3: onceAroundDescription3,
		ScarfWearOverHandDescription1: overHandDescription1,
		ScarfWearOverHandDescription2: overHandDescription2,
		ScarfWearOverHandDescription3: overHandDescription3,
		ScarfWearOverHandDescription4: overHandDescription4,
		ScarfWearReverseDrapeTuckDescription1: drapeTuckDescription1,
		ScarfWearReverseDrapeTuckDescription2: drapeTuckDescription2,
		ScarfWearReverseDrapeTuckDescription3: drapeTuckDescription3,
		ScarfWearReverseDrapeTuckDescription4: drapeTuckDescription4,
		ScarfWearTwistDescription1: twistDescription1,
		ScarfWearTwistDescription2: twistDescription2,
		ScarfWearTwistDescription3: twistDescription3,
		ScarfWearTwistDescription4: twistDescription4,
		ScarfWearTwistDescription5: twistDescription5,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: howToTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: parisianKnotName,
						plpLink: plpLink,
						videoLink: parisianKnotVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: parisianKnotDesktopImageUrl1,
								mobile: parisianKnotMobileImageUrl1,
							},
							{
								desktop: parisianKnotDesktopImageUrl2,
								mobile: parisianKnotMobileImageUrl2,
							},
							{
								desktop: parisianKnotDesktopImageUrl3,
								mobile: parisianKnotMobileImageUrl3,
							},
							{
								desktop: parisianKnotDesktopImageUrl4,
								mobile: parisianKnotMobileImageUrl4,
							},
							{
								desktop: parisianKnotDesktopImageUrl5,
								mobile: parisianKnotMobileImageUrl5,
							},
						],
						imageDetails: [
							{ name: null, description: parisianKnotDescription },
							{ name: stepNameOne, description: parisianKnotDescription1 },
							{ name: stepNameTwo, description: parisianKnotDescription2 },
							{ name: stepNameThree, description: parisianKnotDescription3 },
							{ name: stepNameFour, description: parisianKnotDescription4 },
						],
						isActive: false,
					},
					{
						name: onceAroundName,
						plpLink: plpLink,
						videoLink: onceAroundVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: onceAroundDesktopImageUrl1,
								mobile: onceAroundMobileImageUrl1,
							},
							{
								desktop: onceAroundDesktopImageUrl2,
								mobile: onceAroundMobileImageUrl2,
							},
							{
								desktop: onceAroundDesktopImageUrl3,
								mobile: onceAroundMobileImageUrl3,
							},
							{
								desktop: onceAroundDesktopImageUrl4,
								mobile: onceAroundMobileImageUrl4,
							},
						],
						imageDetails: [
							{ name: null, description: onceAroundDescription },
							{ name: stepNameOne, description: onceAroundDescription1 },
							{ name: stepNameTwo, description: onceAroundDescription2 },
							{ name: stepNameThree, description: onceAroundDescription3 },
						],
						isActive: true,
					},
					{
						name: overHandName,
						plpLink: plpLink,
						videoLink: overHandVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: overHandDesktopImageUrl1,
								mobile: overHandMobileImageUrl1,
							},
							{
								desktop: overHandDesktopImageUrl2,
								mobile: overHandMobileImageUrl2,
							},
							{
								desktop: overHandDesktopImageUrl3,
								mobile: overHandMobileImageUrl3,
							},
							{
								desktop: overHandDesktopImageUrl4,
								mobile: overHandMobileImageUrl4,
							},
							{
								desktop: overHandDesktopImageUrl5,
								mobile: overHandMobileImageUrl5,
							},
						],
						imageDetails: [
							{ name: null, description: overHandDescription },
							{ name: stepNameOne, description: overHandDescription1 },
							{ name: stepNameTwo, description: overHandDescription2 },
							{ name: stepNameThree, description: overHandDescription3 },
							{ name: stepNameFour, description: overHandDescription4 },
						],
						isActive: false,
					},
					{
						name: reverseDrapeTuckName,
						plpLink: plpLink,
						videoLink: reverseDrapeTuckVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: reverseDrapeTuckDesktopImageUrl1,
								mobile: reverseDrapeTuckMobileImageUrl1,
							},
							{
								desktop: reverseDrapeTuckDesktopImageUrl2,
								mobile: reverseDrapeTuckMobileImageUrl2,
							},
							{
								desktop: reverseDrapeTuckDesktopImageUrl3,
								mobile: reverseDrapeTuckMobileImageUrl3,
							},
							{
								desktop: reverseDrapeTuckDesktopImageUrl4,
								mobile: reverseDrapeTuckMobileImageUrl4,
							},
							{
								desktop: reverseDrapeTuckDesktopImageUrl5,
								mobile: reverseDrapeTuckMobileImageUrl5,
							},
						],
						imageDetails: [
							{ name: null, description: reverseDrapeTuckDescription },
							{ name: stepNameOne, description: drapeTuckDescription1 },
							{ name: stepNameTwo, description: drapeTuckDescription2 },
							{ name: stepNameThree, description: drapeTuckDescription3 },
							{ name: stepNameFour, description: drapeTuckDescription4 },
						],
						isActive: false,
					},
					{
						name: twistName,
						plpLink: plpLink,
						videoLink: twistVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: twistDesktopImageUrl1,
								mobile: twistMobileImageUrl1,
							},
							{
								desktop: twistDesktopImageUrl2,
								mobile: twistMobileImageUrl2,
							},
							{
								desktop: twistDesktopImageUrl3,
								mobile: twistMobileImageUrl3,
							},
							{
								desktop: twistDesktopImageUrl4,
								mobile: twistMobileImageUrl4,
							},
							{
								desktop: twistDesktopImageUrl5,
								mobile: twistMobileImageUrl5,
							},
							{
								desktop: twistDesktopImageUrl6,
								mobile: twistMobileImageUrl6,
							},
						],
						imageDetails: [
							{ name: null, description: twistDescription },
							{ name: stepNameOne, description: twistDescription1 },
							{ name: stepNameTwo, description: twistDescription2 },
							{ name: stepNameThree, description: twistDescription3 },
							{ name: stepNameFour, description: twistDescription4 },
							{ name: stepNameFive, description: twistDescription5 },
						],
						isActive: false,
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2 top3 right5',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildScarfGuideViewModel;
