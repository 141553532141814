import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { AddBasket } from 'src/app/model/basket-viewmodel';
import { BasketPageViewModel } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { SharedService } from 'src/app/shared/shared.service';
import { InitialiseBasketTracking } from '../../../../assets/shared/gtm.js';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { CheckoutSwitcherService } from '../../checkout-switcher.service';
import { NAVIGATOR_LANGUAGE } from '../../constants/attraqt';
import { AddToCartData } from '../../models/cart-model';
import { SiteContentDetail } from '../../models/siteContent';
import { CartService } from '../../service/cart.service';
import { SiteContentService } from '../../sitecontent.service';
import { MobileAddBagComponent } from '../mobile-add-bag/mobile-add-bag.component';
import { ViewportService } from '../../viewport.service';
import { flatMap } from 'lodash';
import { ViewPortEnum } from 'src/app/enums/device-type-enum';

@Component({
	selector: '[cart], cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnChanges, OnDestroy {

	cartItems: any[];
	showCartItemsInfo = false;
	totalAmount = 0;
	index = 2;
	showCartData = false;
	@Input() isCartPopUp: boolean;
	isDisplayCartItems: boolean;
	isPersonifyRecommendationsActive: boolean = false;
	checkMask: boolean = false;
	shoppingBasket: ShoppingBasket;
	basketPageViewModel: BasketPageViewModel;
	cartProduct: AddToCartData;
	moreItemsQuantity: number;
	maxVisibleItems: number = 2;
	basketLoaded: boolean;
	disableQuantityChange: boolean;
	allowMinibasketCheckout: boolean = true;
	showGenericTextInMiniBasket: boolean;
	allowMiniBasketQuantityChange: boolean;
	showCartItem: boolean = false;
	siteContent: SiteContentDetail;
	deleteBasketItem: AddBasket;
	LpAppSetting: LpAppSetting;
	checkoutUrl: string;
	basketUrl: string = "/checkout/shoppingbasket";
	matDialogRef: MatDialogRef<MobileAddBagComponent>;
	isDesktopDisplay: boolean = false;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private router: Router,
		private cartService: CartService,
		@Inject(DOCUMENT) document,
		private r: Renderer2,
		private siteContentService: SiteContentService,
		private sharedService: SharedService,
		private matDialog: MatDialog,
		private attraqtService: AttraqtService,
		private viewportService: ViewportService,
		private checkoutSwitcherService: CheckoutSwitcherService) {
	}

	async ngOnInit(): Promise<void> {
		this.subscribeToState();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.initPersonifyRecommendationsSettings();
		this.getCartProduct();
		this.secureCheckout(false);
		this.getDeviceType();
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	ngOnChanges() {
		if (this.isCartPopUp) {
			this.FadeOutLink();
		}
	}

	onBasketRefresh() {

		this.moreItemsQuantity = 0;
		let showCartPopup = true;
		let baketItems;

		if (this.basketPageViewModel && this.basketPageViewModel.ShoppingBasketPage &&
			this.basketPageViewModel.ShoppingBasketPage.Basket &&
			this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems &&
			this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems.length > 0) {
			baketItems = this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems
			this.moreItemsQuantity = this.basketPageViewModel.ShoppingBasketPage.Basket.BasketTotalItemCount;

			if (this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems.length > this.maxVisibleItems) {
				for (var i = 0; i <= this.maxVisibleItems - 1; i++) {
					this.moreItemsQuantity -= this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems[i].Quantity;
				}
			}

			let bundleProductIds = JSON.parse(localStorage.getItem("bundleProductIds"));

			if (bundleProductIds && bundleProductIds.length > 0) {
				$.each(bundleProductIds, function (idx, bundleProductId) {
					var matches = baketItems.filter(function (basketItem) {
						return basketItem.ProductId === bundleProductId;
					});

					if (matches.length == 0) {
						showCartPopup = false;
					}
				});
			}
		}

		if (showCartPopup) {
			if (this.cartService.displayCart) {
				if (this.viewportService.getDeviceType() === ViewPortEnum.Tablet) {
					this.cartService.displayCart = false;
					this.matDialogRef = this.matDialog.open(MobileAddBagComponent, {
						disableClose: true
					});
				} else {
					this.showCartItem = true;
				}
			}
		} else {
			this.showCartItem = false;
		}

		this.basketLoaded = true;
		this.disableQuantityChange = false;
	}

	getCartProduct() {
		this.cartService.getShoppingBasket().subscribe(data => {

			if (data) {
				this.basketPageViewModel = data;
				this.onBasketRefresh();

				if (data.ShoppingBasketPage && data.ShoppingBasketPage.Basket && data.ShoppingBasketPage.Basket.BasketItems) {
					if (data.ShoppingBasketPage.Basket.BasketItems.length > 2) {
						this.index = data.ShoppingBasketPage.Basket.BasketItems.length - 2;
					}

					if (data.ShoppingBasketPage.Basket.BasketItems.length > 0) {

						if (this.LpAppSetting && this.cartService.basketUpdated) {
							this.cartService.basketUpdated = false;
							this.sharedService.isShoppingCartInitialized = true;

							InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data.ShoppingBasketPage.Basket, false, true);
						}

						if (this.cartService.displayCart || this.isCartPopUp) {

							if ((this.cartService.displayCart && this.showCartItem) || this.isCartPopUp) {
								if (this.router.url != '/checkout/shoppingbasket') {
									this.isDisplayCartItems = true;

									//this.cartService.displayCart=false;
									this.r.addClass(document.body, 'mask-show');
								}
							}
						}
						else {
							this.isDisplayCartItems = false;
						}
					}
				}

				if ((data.ShoppingBasketPage && !data.ShoppingBasketPage.Basket) || (data.ShoppingBasketPage && data.ShoppingBasketPage.Basket
					&& data.ShoppingBasketPage.Basket.BasketItems && data.ShoppingBasketPage.Basket.BasketItems.length === 0)) {
					if (!this.sharedService.isShoppingCartInitialized) {
						this.sharedService.isShoppingCartInitialized = true;
					}
				}
			}

			this.fadeout(5000);
		});
	}

	FadeOutLink() {
		if (this.isDisplayCartItems) {
			setTimeout(function () {

				this.isDisplayCartItems = false;

				this.r.removeClass(document.body, 'mask-show');

			}, 1000);
		}
	}

	showCart(isShow) {
		this.checkMask = isShow;
		this.showCartItemsInfo = false;

		if (this.router.url != '/checkout/shoppingbasket') {
			this.isCartPopUp = true;
			this.isDisplayCartItems = true;

			if (this.checkMask) {
				this.r.addClass(document.body, 'mask-show');
			}
		}
	}

	fadeFromParent(removeMask) {

		this.checkMask = removeMask;
		let self = this;
		let time = 10;

		setTimeout(function () {
			if (!self.showCartItemsInfo) {
				self.fadeout(time);
				//this.forceHideCart();
			}
		}, 10, time)
	}

	fadeout(timeout) {
		this.showCartData = false;
		let self = this;

		setTimeout(function () {
			if (self.isDisplayCartItems && !self.showCartData) {
				self.isDisplayCartItems = false;
				self.isCartPopUp = false;
				if (!this.checkMask)
					self.r.removeClass(document.body, 'mask-show');
			}
		}, timeout, self);
	}

	forceHideCart() {
		this.isDisplayCartItems = false;
		this.isCartPopUp = false;
		this.r.removeClass(document.body, 'mask-show');
	}

	showCartItems() {
		this.showCartItemsInfo = true;
		this.isDisplayCartItems = true;
		this.showCartData = true;
	}

	closeCartPopUp() {
		this.isDisplayCartItems = false;
		this.isCartPopUp = false;

		if (!this.checkMask) {
			this.r.removeClass(document.body, 'mask-show');
		}
	}

	goToBasketUrl() {
		this.router.navigate([this.basketUrl]);
		this.closeCartPopUp();
	}

	secureCheckout(gotoUrl) {
		if (!localStorage.getItem('isSignedIn') && !localStorage.getItem('isGuestLogin')) {
			this.checkoutUrl = "/checkout/welcome";
		} else {
			this.checkoutUrl = this.checkoutSwitcherService.getCheckoutUrl();
		}

		if (gotoUrl) {
			this.router.navigate([this.checkoutUrl])
			this.closeCartPopUp();
		}
	}

	qtyChanged(productItem) {

		this.cartProduct = {} as AddToCartData;
		this.cartProduct.productCode = productItem.Code;
		this.cartProduct.ean = productItem.Ean;
		this.cartProduct.quantity = 0;
		this.cartProduct.productName = productItem.Name;
		this.cartProduct.currencyCode = productItem.CurrencyCode;
		this.cartProduct.retailPrice = productItem.RetailPrice;
		this.cartProduct.salePrice = productItem.SalePrice;
		//console.log("Calling cart-component qtyChanged -> " + JSON.stringify(this.addToCartData));

		this.deleteBasketItem = {} as AddBasket;
		this.deleteBasketItem.quantity = 0;

		if (productItem) {
			this.deleteBasketItem.quantity = -productItem.Quantity;
			this.deleteBasketItem.inventoryProduct = productItem.InventoryProductId;
			this.deleteBasketItem.customSizes = productItem.CustomSizes;
			productItem.Quantity -= productItem.Quantity;

			if (this.facetFilterState.attraqtPLPEnabled) {
				this.attraqtService.sendActivity(
					new AttraqtActivityBuilder(AttraqtActivityActionEnum.RemoveFromCart)
						.setTarget({ product: productItem.ProductId.toString() })
						.setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) })
						.build()
				);
			}
		}

		this.cartService.AddToBasket(this.deleteBasketItem).subscribe(data => {

			if (data && data.result) {
				this.cartService.refreshLocalBasket(data.result, true);

				this.cartService.trackCartProduct(data.result, this.cartProduct, this.LpAppSetting);
			}
		});
	}

	getDeviceType() {
		this.isDesktopDisplay = this.viewportService.getDeviceType() === ViewPortEnum.Desktop ? true : false;
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}

	private initPersonifyRecommendationsSettings(): void {
		if (this.LpAppSetting?.ProductRecommendationsSettings?.MiniBasketCarousel?.IsActive) {
			this.isPersonifyRecommendationsActive = true;
			this.maxVisibleItems = 1;
		}
	}
}
