import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storageSubject = new BehaviorSubject<string | null>(null);
  private suppressStorageEvent = false;

  constructor() {
    window.addEventListener('storage', (event: StorageEvent) => {
      if (!this.suppressStorageEvent && event.storageArea === localStorage) {
        this.storageSubject.next(event.key);
      }
    });
  }

  watchKey(key: string): Observable<string | null> {
    return this.storageSubject.asObservable().pipe(
      filter((changedKey) => {
        return changedKey === key}),
    );
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
    this.storageSubject.next(key);
  }

  setItem2(key: string, value: string): void {
    this.suppressStorageEvent = true;
    localStorage.setItem(key, value);
    setTimeout(() => this.suppressStorageEvent = false, 0); 
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
    this.storageSubject.next(key);
  }

  trigger(key: string): void {
    this.storageSubject.next(key);
  }
}
