import { IFitGuideComparePageVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';
import { FitGuideMainPageKeyEnum } from '../../enums/fit-guide-main-page-key.enum';

const buildCompareFitPageViewModel = (
	fitGuideSetting: FitGuideSetting,
	pageKey: FitGuideMainPageKeyEnum
): IFitGuideComparePageVM => {
	const {
		MenSuitFitTitle: menSuitFitTitle,
		MenSuitFitSlimName: menSuitFitSlimName,
		MenSuitFitSlimPlpLink: menSuitFitSlimPlpLink,
		MenSuitFitSlimMobileImageUrl1: menSuitFitSlimMobileImageUrl1,
		MenSuitFitClassicName: menSuitFitClassicName,
		MenSuitFitClassicPlpLink: menSuitFitClassicPlpLink,
		MenSuitFitClassicMobileImageUrl1: menSuitFitClassicMobileImageUrl1,
		MenSuitFitTailoredName: menSuitFitTailoredName,
		MenSuitFitTailoredPlpLink: menSuitFitTailoredPlpLink,
		MenSuitFitTailoredMobileImageUrl1: menSuitFitTailoredMobileImageUrl1,
		MenShirtFitTitle: menShirtFitTitle,
		MenShirtFitExtraSlimName: menShirtFitExtraSlimName,
		MenShirtFitExtraSlimPlpLink: menShirtFitExtraSlimPlpLink,
		MenShirtFitExtraSlimMobileImageUrl1: menShirtFitExtraSlimMobileImageUrl1,
		MenShirtFitFittedSlimName: menShirtFitFittedSlimName,
		MenShirtFitFittedSlimPlpLink: menShirtFitFittedSlimPlpLink,
		MenShirtFitFittedSlimMobileImageUrl1: menShirtFitFittedSlimMobileImageUrl1,
		MenShirtFitSlimName: menShirtFitSlimName,
		MenShirtFitSlimPlpLink: menShirtFitSlimPlpLink,
		MenShirtFitSlimMobileImageUrl1: menShirtFitSlimMobileImageUrl1,
		MenShirtFitClassicName: menShirtFitClassicName,
		MenShirtFitClassicPlpLink: menShirtFitClassicPlpLink,
		MenShirtFitClassicMobileImageUrl1: menShirtFitClassicMobileImageUrl1,
		WomenShirtFitTitle: womenShirtFitTitle,
		WomenShirtFitSemiFittedName: womenShirtSemiFittedName,
		WomenShirtFitSemiFittedPlpLink: womenShirtSemiFittedPlpLink,
		WomenShirtFitSemiFittedMobileImageUrl1: womenShirtSemiFittedMobileImageUrl1,
		WomenShirtFitFittedName: womenShirtFittedName,
		WomenShirtFitFittedPlpLink: womenShirtFittedPlpLink,
		WomenShirtFitFittedMobileImageUrl1: womenShirtFittedMobileImageUrl1,
		WomenShirtFitRelaxedName: womenShirtRelaxedName,
		WomenShirtFitRelaxedPlpLink: womenShirtRelaxedPlpLink,
		WomenShirtFitRelaxedMobileImageUrl1: womenShirtRelaxedMobileImageUrl1,
		WomenSuitFitTitle: womenSuitFitTitle,
		WomenSuitFitSingleBreastedName: womenSuitFitSingleBreastedName,
		WomenSuitFitSingleBreastedPlpLink: womenSuitFitSingleBreastedPlpLink,
		WomenSuitFitSingleBreastedMobileImageUrl1:
			womenSuitFitSingleBreastedMobileImageUrl1,
		WomenSuitFitDoubleBreastedName: womenSuitFitDoubleBreastedName,
		WomenSuitFitDoubleBreastedPlpLink: womenSuitFitDoubleBreastedPlpLink,
		WomenSuitFitDoubleBreastedMobileImageUrl1:
			womenSuitFitDoubleBreastedMobileImageUrl1,
		WomenSuitFitTrouserName: womenSuitTrouserName,
		WomenSuitFitTrouserPlpLink: womenSuitTrouserPlpLink,
		WomenSuitFitTrouserMobileImageUrl1: womenSuitTrouserMobileImageUrl1,
		WomenSuitFitSkirtName: womenSuitSkirtName,
		WomenSuitFitSkirtPlpLink: womenSuitSkirtPlpLink,
		WomenSuitFitSkirtMobileImageUrl1: womenSuitSkirtMobileImageUrl1,
		MenShirtFitCompareFitPageDescription: shirtFitDescription,
		MenSuitFitCompareFitPageDescription: suitFitDescription,
		WomenShirtFitCompareFitPageDescription: womenShirtFitDescription,
		MenShirtFitExtraSlimSubFitGuideSectionText: menShirtFitExtraSlimDescription,
		MenShirtFitFittedSlimSubFitGuideSectionText:
			menShirtFitFittedSlimDescription,
		MenShirtFitSlimSubFitGuideSectionText: menShirtFitSlimDescription,
		MenShirtFitClassicSubFitGuideSectionText: menShirtFitClassicDescription,
		MenSuitSlimSubFitGuideSectionText: menSuitSlimSubFitGuideText,
		MenSuitTailoredSubFitGuideSectionText: menSuitTailoredSubFitGuideText,
		MenSuitClassicSubFitGuideSectionText: menSuitClassicSubFitGuideText,
		WomenSuitFitCompareFitPageDescription: womenSuitFitDescription,
	} = fitGuideSetting;

	if (pageKey === FitGuideMainPageKeyEnum.MenSuit) {
		return {
			title: menSuitFitTitle,
			description: suitFitDescription,
			cards: [
				{
					name: menSuitFitSlimName,
					description: menSuitSlimSubFitGuideText,
					plpLink: menSuitFitSlimPlpLink,
					imageUrl: menSuitFitSlimMobileImageUrl1,
				},
				{
					name: menSuitFitClassicName,
					description: menSuitTailoredSubFitGuideText,
					plpLink: menSuitFitClassicPlpLink,
					imageUrl: menSuitFitClassicMobileImageUrl1,
				},
				{
					name: menSuitFitTailoredName,
					description: menSuitClassicSubFitGuideText,
					plpLink: menSuitFitTailoredPlpLink,
					imageUrl: menSuitFitTailoredMobileImageUrl1,
				},
			],
		};
	}
	if (pageKey === FitGuideMainPageKeyEnum.WomenShirt) {
		return {
			title: womenShirtFitTitle,
			description: womenShirtFitDescription,
			cards: [
				{
					name: womenShirtSemiFittedName,
					plpLink: womenShirtSemiFittedPlpLink,
					imageUrl: womenShirtSemiFittedMobileImageUrl1,
				},
				{
					name: womenShirtFittedName,
					plpLink: womenShirtFittedPlpLink,
					imageUrl: womenShirtFittedMobileImageUrl1,
				},
				{
					name: womenShirtRelaxedName,
					plpLink: womenShirtRelaxedPlpLink,
					imageUrl: womenShirtRelaxedMobileImageUrl1,
				},
			],
		};
	}
	if (pageKey === FitGuideMainPageKeyEnum.WomenSuit) {
		return {
			title: womenSuitFitTitle,
			description: womenSuitFitDescription,
			cards: [
				{
					name: womenSuitFitSingleBreastedName,
					plpLink: womenSuitFitSingleBreastedPlpLink,
					imageUrl: womenSuitFitSingleBreastedMobileImageUrl1,
				},
				{
					name: womenSuitFitDoubleBreastedName,
					plpLink: womenSuitFitDoubleBreastedPlpLink,
					imageUrl: womenSuitFitDoubleBreastedMobileImageUrl1,
				},
				{
					name: womenSuitTrouserName,
					plpLink: womenSuitTrouserPlpLink,
					imageUrl: womenSuitTrouserMobileImageUrl1,
				},
				{
					name: womenSuitSkirtName,
					plpLink: womenSuitSkirtPlpLink,
					imageUrl: womenSuitSkirtMobileImageUrl1,
				},
			],
		};
	}
	return {
		title: menShirtFitTitle,
		description: shirtFitDescription,
		cards: [
			{
				name: menShirtFitExtraSlimName,
				description: menShirtFitExtraSlimDescription,
				plpLink: menShirtFitExtraSlimPlpLink,
				imageUrl: menShirtFitExtraSlimMobileImageUrl1,
			},
			{
				name: menShirtFitFittedSlimName,
				description: menShirtFitFittedSlimDescription,
				plpLink: menShirtFitFittedSlimPlpLink,
				imageUrl: menShirtFitFittedSlimMobileImageUrl1,
			},
			{
				name: menShirtFitSlimName,
				description: menShirtFitSlimDescription,
				plpLink: menShirtFitSlimPlpLink,
				imageUrl: menShirtFitSlimMobileImageUrl1,
			},
			{
				name: menShirtFitClassicName,
				description: menShirtFitClassicDescription,
				plpLink: menShirtFitClassicPlpLink,
				imageUrl: menShirtFitClassicMobileImageUrl1,
			},
		],
	};
};

export default buildCompareFitPageViewModel;
