import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class StoresGuard implements CanActivate {

  appSettings: LpAppSetting;

  constructor(private sharedService: SharedService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return from(this.getApplicationSetting()).pipe(
      map(storeEnabled => {
        if (!storeEnabled) {
          this.router.navigate(['/pagenotfound']);
        }
        return storeEnabled;
      })
    );
  }

  private async getApplicationSetting(): Promise<boolean> {
    return (await this.sharedService.getLpAppSettings()).Stores.StoreEnabled;
  }
}
