import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
	DomSanitizer,
	SafeHtml,
	SafeResourceUrl,
} from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import Splide from '@splidejs/splide';
import * as $ from 'jquery';

import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ViewportService } from 'src/app/shared/viewport.service';
import { ScrollService } from 'src/app/shared/service/scroll.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SharedService } from 'src/app/shared/shared.service';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { FitGuide as FitGuideAction } from 'src/app/shared/state/fit-guide/fit-guide.action';
import { FitGuideState } from 'src/app/shared/state/fit-guide/fit-guide.state';
import { IFitGuideState } from 'src/app/shared/interfaces/fit-guide/fit-guide-state.interface';
import {
	IFitGuideMainPageSectionItemImageDetail,
	IFitGuideMainVM,
} from 'src/app/shared/models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';
import { FitGuideMainPageKeyEnum } from 'src/app/shared/enums/fit-guide-main-page-key.enum';
import buildMenShirtGuideViewModel from 'src/app/shared/helper/fit-guide/build-men-shirt-guide-view-model';
import buildMenSuitGuideViewModel from 'src/app/shared/helper/fit-guide/build-men-suit-guide-view-model';
import buildWomenShirtGuideViewModel from 'src/app/shared/helper/fit-guide/build-women-shirt-guide-view-model';
import buildWomenSuitGuideViewModel from 'src/app/shared/helper/fit-guide/build-women-suit-guide-view-model';
import buildTieGuideViewModel from 'src/app/shared/helper/fit-guide/build-tie-guide-view-model';
import buildBowTieGuideViewModel from 'src/app/shared/helper/fit-guide/build-bow-tie-guide-view-model';
import buildPocketSquareGuideViewModel from 'src/app/shared/helper/fit-guide/build-pocket-square-guide-view-model';
import buildScarfGuideViewModel from 'src/app/shared/helper/fit-guide/build-scarf-guide-view-model';
import buildShoeGuideViewModel from 'src/app/shared/helper/fit-guide/build-shoe-guide-view-model';

@Component({
	selector: 'app-fit-guide-main-page',
	templateUrl: './fit-guide-main-page.component.html',
	styleUrls: ['./fit-guide-main-page.component.scss'],
})
export class FitGuideMainPageComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	siteContent: SiteContentDetail;
	fitGuideSetting: FitGuideSetting;
	fitGuideMainPageVM: IFitGuideMainVM;
	deviceType: 'Mobile' | 'Tablet' | 'Desktop';
	pageKey: FitGuideMainPageKeyEnum;
	mountedCarouselIds: string[];
	mountedCarouselsMap: Map<string, Splide>;
	isVideoPlaying: boolean;
	playingVideoUrl: SafeResourceUrl;
	videoModal: any;
	isOutsideVideoModalClickedEventBound: boolean;
	currentMobileScreen: number;
	areCarouselsInitialized: boolean;
	currentUrl: string;

	fitGuideSubscriber: Subscription;
	private locationUnsubscribe: VoidFunction;
	@Select(FitGuideState) fitGuide$: Observable<IFitGuideState>;

	private carouselArrowTouchStartListeners: Array<() => void> = [];
	private mobileBannerCarouselArrowsVisibilityTimeoutId: any;
	private shouldShowMobileBannerArrows: boolean = false;
	private isMobileBannerCarouselFirstMoveCompleted: boolean = false;
	private isMobileBannerCarouselMoving: boolean = false;

	constructor(
		private router: Router,
		private activateRoute: ActivatedRoute,
		private location: Location,
		private sanitizer: DomSanitizer,
		private siteContentService: SiteContentService,
		private viewportService: ViewportService,
		private scrollService: ScrollService,
		private sharedService: SharedService,
		private loaderService: LoaderService,
		private store: Store
	) {
		this.mountedCarouselIds = [];
		this.mountedCarouselsMap = new Map();
		this.isVideoPlaying = false;
		this.playingVideoUrl = null;
		this.isOutsideVideoModalClickedEventBound = false;
		this.areCarouselsInitialized = false;
	}

	async ngOnInit(): Promise<void> {
		this.currentUrl = this.router.url;
		this.deviceType = this.viewportService.getDeviceType();
		this.siteContent = this.siteContentService.getSiteContentData();
		this.pageKey = this.activateRoute.snapshot.params['pageKey'];

		const appSettings = await this.sharedService.getLpAppSettings();
		this.fitGuideSetting = appSettings?.FitGuideSettings;
		this.fitGuideMainPageVM = this.resolveMatchingFitGuidePageViewModel();

		if (this.fitGuideMainPageVM && !this.areCarouselsInitialized) {
			setTimeout(() => {
				this.ngAfterViewInit();
			}, 1000);
		}

		if (this.deviceType === 'Mobile') {
			this.browserBackendButtonClickEventHandler();
		}

		this.subscribeToState();

		this.scrollService.windowScrollTo(0, 0);
	}

	async ngAfterViewInit(): Promise<void> {
		if (this.deviceType !== 'Mobile') {
			this.initializeCarousels();
		}
	}

	ngOnDestroy(): void {
		if (this.fitGuideSubscriber) {
			this.fitGuideSubscriber.unsubscribe();
		}

		if (this.carouselArrowTouchStartListeners.length) {
			this.carouselArrowTouchStartListeners.forEach((removeListener) =>
				removeListener()
			);
			this.carouselArrowTouchStartListeners = [];
		}

		if (this.locationUnsubscribe) {
			this.locationUnsubscribe();
		}
	}

	backgroundSwitchButtonClickedHandler(
		rowIndex: number,
		itemIndex: number,
		isRowCarousel: boolean
	): void {
		const sectionItemsCount =
			this.fitGuideMainPageVM.sections[rowIndex].sectionItems.length;

		for (let i = 0; i < sectionItemsCount; ++i) {
			this.fitGuideMainPageVM.sections[rowIndex].sectionItems[i].isActive =
				itemIndex === i;
		}

		if (isRowCarousel) {
			setTimeout(() => {
				this.mountCarousel(rowIndex, itemIndex);
			}, 1);
		}

		if (this.deviceType === 'Mobile' && sectionItemsCount > 2) {
			this.setActivePagination(rowIndex, itemIndex);

			if (
				this.isMobileBannerCarouselFirstMoveCompleted &&
				!this.isMobileBannerCarouselMoving
			) {
				this.shouldShowMobileBannerArrows = true;

				if (this.mobileBannerCarouselArrowsVisibilityTimeoutId) {
					clearTimeout(this.mobileBannerCarouselArrowsVisibilityTimeoutId);
				}

				this.mobileBannerCarouselArrowsVisibilityHandler(true);

				this.mobileBannerCarouselArrowsVisibilityTimeoutId = setTimeout(() => {
					this.mobileBannerCarouselArrowsVisibilityHandler(false);
				}, 5000);
			}
		}
	}

	shopNowClickedHandler(plpLink: string): void {
		this.router.navigate([plpLink]);
	}

	compareFitClickedHandler(): void {
		this.router.navigate([`${this.router.url}/compare`]);
	}

	playVideoClickedHandler(videoLink: string): void {
		this.playingVideoUrl =
			this.sanitizer.bypassSecurityTrustResourceUrl(videoLink);
		this.isVideoPlaying = true;
		this.videoModal = $('#v2-fit-guide-video-player-modal');
		this.videoModal.show();

		if (!this.isOutsideVideoModalClickedEventBound) {
			this.onOutsideVideoModalClickedHandler();
		}
	}

	closeVideoModalClickedHandler(): void {
		if (this.videoModal) {
			this.videoModal.hide();
			this.isVideoPlaying = false;
			this.playingVideoUrl = null;
			this.videoModal = null;
		}
	}

	onOutsideVideoModalClickedHandler(): void {
		$('body').bind('click', (e: any) => {
			if ($(e.target).hasClass('v2-fit-guide-modal')) {
				if (this.videoModal) {
					this.isOutsideVideoModalClickedEventBound = true;
					this.videoModal.hide();
					this.isVideoPlaying = false;
					this.playingVideoUrl = null;
					this.videoModal = null;
				}
			}
		});
	}

	screenNavButtonClickedHandler(screenIndex: number): void {
		this.store.dispatch(
			new FitGuideAction.SetActiveMobileScreenIndex(
				this.pageKey,
				screenIndex + 1
			)
		);
		this.scrollService.windowScrollTo(0, 0);
	}

	backButtonClickedHandler(): void {
		this.currentMobileScreen = 0;

		this.store.dispatch(
			new FitGuideAction.SetActiveMobileScreenIndex(
				this.pageKey,
				this.currentMobileScreen
			)
		);
		this.mobileBannerCarouselArrowsVisibilityHandler(false);
		this.shouldShowMobileBannerArrows = false;
		this.isMobileBannerCarouselFirstMoveCompleted = false;
		this.isMobileBannerCarouselMoving = false;
		this.scrollService.windowScrollTo(0, 0);
	}

	resolveAsHtml(text1: string, text2?: string): SafeHtml {
		let text = `${text1}`;

		if (text2) {
			let plainText = text2;
			if (this.deviceType === 'Mobile') {
				plainText = this.replaceLineBreakFromHtmlString(text2);
			}
			text = text + `<br>${plainText}`;
		}

		return this.sanitizer.bypassSecurityTrustHtml(text);
	}

	replaceLineBreakFromHtmlString(htmlString: string): string {
		const regex = /<br \/>/g;
		return htmlString.replace(regex, ' ');
	}

	private mobileScreenSwitchHandler(screenIndex: number): void {
		if (this.fitGuideMainPageVM.sections[screenIndex].sectionItems.length > 2) {
			const carouselId = `btn-slider${screenIndex}`;

			if (!this.mountedCarouselIds.includes(carouselId)) {
				const splideCarousel = new Splide(`#${carouselId}`, {
					type: 'slide',
					pagination: true,
					perPage: 3,
					focus: 0,
					arrows: true,
					drag: true,
					perMove: 1,
					gap: '0.125em',
					classes: {
						pagination: 'splide__pagination v2-fit-guide-mb-custom-pagination',
						arrows: 'splide__arrows',
						arrow: 'splide__arrow v2-fit-guide-mb-custom-arrows2-arrow',
						prev: 'splide__arrow--prev v2-fit-guide-mb-custom-arrows2-arrow-prev',
						next: 'splide__arrow--next v2-fit-guide-mb-custom-arrows2-arrow-next',
					},
				});

				splideCarousel.on('move', (newIndex) => {
					this.isMobileBannerCarouselMoving = true;

					if (this.shouldShowMobileBannerArrows) {
						if (this.mobileBannerCarouselArrowsVisibilityTimeoutId) {
							clearTimeout(this.mobileBannerCarouselArrowsVisibilityTimeoutId);
						}
						this.mobileBannerCarouselArrowsVisibilityHandler(true);
					}

					this.backgroundSwitchButtonClickedHandler(
						screenIndex,
						newIndex,
						this.fitGuideMainPageVM.sections[screenIndex].isSectionItemCarousel
					);
				});

				splideCarousel.on('drag', () => {
					this.shouldShowMobileBannerArrows = true;
				});

				splideCarousel.on('moved', () => {
					this.isMobileBannerCarouselMoving = false;

					this.isMobileBannerCarouselFirstMoveCompleted = true;
					this.mobileBannerCarouselArrowsVisibilityTimeoutId = setTimeout(
						() => {
							this.mobileBannerCarouselArrowsVisibilityHandler(false);
						},
						5000
					);
				});

				splideCarousel.mount();

				this.mountedCarouselIds.push(carouselId);

				const itemIndex = this.fitGuideMainPageVM.sections[
					screenIndex
				].sectionItems.findIndex(({ isActive }) => isActive);

				this.setActivePagination(screenIndex, itemIndex);

				setTimeout(() => {
					splideCarousel.go(itemIndex);
					this.mobileBannerCarouselArrowTouchStartEventHandler();
				}, 1);
			}
		}
		if (this.fitGuideMainPageVM.sections[screenIndex].isSectionItemCarousel) {
			const activeIndex = this.fitGuideMainPageVM.sections[
				screenIndex
			].sectionItems.findIndex(({ isActive }) => isActive);

			setTimeout(() => {
				this.mountCarousel(screenIndex, activeIndex);
			}, 1);
		}
	}

	private resolveMatchingFitGuidePageViewModel() {
		if (!this.siteContent || !this.fitGuideSetting || !this.pageKey) {
			return null;
		}
		switch (this.pageKey) {
			case FitGuideMainPageKeyEnum.MenShirt:
				return buildMenShirtGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.MenSuit:
				return buildMenSuitGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.WomenShirt:
				return buildWomenShirtGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.WomenSuit:
				return buildWomenSuitGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.Tie:
				return buildTieGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.BowTie:
				return buildBowTieGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.PocketSquare:
				return buildPocketSquareGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.Scarf:
				return buildScarfGuideViewModel(this.fitGuideSetting);
			case FitGuideMainPageKeyEnum.Shoe:
				return buildShoeGuideViewModel(this.fitGuideSetting);
		}
	}

	private initializeCarousels(): void {
		if (!this.fitGuideMainPageVM) {
			return;
		}

		this.areCarouselsInitialized = true;

		for (let i = 0; i < this.fitGuideMainPageVM.sections.length; ++i) {
			if (this.fitGuideMainPageVM.sections[i].isSectionItemCarousel) {
				for (
					let j = 0;
					j < this.fitGuideMainPageVM.sections[i].sectionItems.length;
					++j
				) {
					if (this.fitGuideMainPageVM.sections[i].sectionItems[j].isActive) {
						this.mountCarousel(i, j, true);
					}
				}
			}
		}
	}

	private mountCarousel(
		rowIndex: number,
		itemIndex: number,
		setHeight = false
	): void {
		const carouselId = `slider${rowIndex}${itemIndex}`;
		const isCarouselAlreadyMounted =
			this.mountedCarouselIds.includes(carouselId);
		const haveImageDetails =
			!!this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
				?.imageDetails;
		const haveTextBetweenArrows =
			this.fitGuideMainPageVM.sections[rowIndex]?.sectionItems[itemIndex]
				?.textBetweenArrows?.length > 0;
		let imageDetailsParentId = null;

		if (isCarouselAlreadyMounted) {
			const carousel = this.mountedCarouselsMap.get(carouselId);
			if (carousel) {
				carousel.go(0);
			}
		}

		if (this.deviceType !== 'Mobile') {
			imageDetailsParentId = haveImageDetails
				? `#image-details-${rowIndex}`
				: null;
			const imageDetails = haveImageDetails
				? this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
						.imageDetails
				: [];
			const arrowIndicatorSelector = haveTextBetweenArrows
				? `#text-arrow-indicator-${rowIndex}${itemIndex}`
				: null;
			const textBetweenCarouselArrows = haveTextBetweenArrows
				? this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
						.textBetweenArrows
				: [];

			if (isCarouselAlreadyMounted) {
				if (haveImageDetails || haveTextBetweenArrows) {
					this.setImageDetails(
						imageDetailsParentId,
						imageDetails[0],
						arrowIndicatorSelector,
						textBetweenCarouselArrows[0]
					);
				}
				return;
			}

			const carousel = new Splide(`#${carouselId}`, {
				type: 'loop',
				classes: {
					pagination: 'splide__pagination',
				},
			});

			if (haveImageDetails || haveTextBetweenArrows) {
				this.setImageDetails(
					imageDetailsParentId,
					imageDetails[0],
					arrowIndicatorSelector,
					textBetweenCarouselArrows[0]
				);

				carousel.on('move', (index: number) => {
					this.setImageDetails(
						imageDetailsParentId,
						imageDetails[index],
						arrowIndicatorSelector,
						textBetweenCarouselArrows[index]
					);
				});
			}

			if (setHeight) {
				carousel.on('ready', () => {
					this.loaderService.isLoading.next(true);

					setTimeout(() => {
						const height = $(
							`#carouselImagesWrapper${rowIndex}${itemIndex}`
						).height();

						if (height > 0) {
							const count =
								this.fitGuideMainPageVM.sections[rowIndex].sectionItems.length;

							for (let i = 0; i < count; ++i) {
								$(`#carouselImagesWrapper${rowIndex}${i}`).height(height);
							}
						}
						this.loaderService.isLoading.next(false);
					}, 5000);
				});
			}

			carousel.mount();
			this.mountedCarouselsMap.set(carouselId, carousel);
		} else {
			imageDetailsParentId = haveImageDetails
				? `#image-details-mb-${rowIndex}`
				: null;

			if (isCarouselAlreadyMounted) {
				if (haveImageDetails) {
					this.setImageDetails(
						imageDetailsParentId,
						this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
							.imageDetails[0]
					);
				}
				return;
			}

			const carousel = new Splide(`#${carouselId}`, {
				type: 'loop',
				pagination: false,
			});

			if (haveImageDetails) {
				this.setImageDetails(
					imageDetailsParentId,
					this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
						.imageDetails[0]
				);

				carousel.on('move', (index: number) => {
					this.setImageDetails(
						imageDetailsParentId,
						this.fitGuideMainPageVM.sections[rowIndex].sectionItems[itemIndex]
							.imageDetails[index]
					);
				});
			}

			carousel.mount();
			this.mountedCarouselsMap.set(carouselId, carousel);
		}
		this.mountedCarouselIds.push(carouselId);
	}

	private setActivePagination(rowIndex: number, itemIndex: number): void {
		const paginationItems = document.querySelectorAll(
			`#btn-slider${rowIndex} .splide__pagination .splide__pagination__page`
		);

		paginationItems.forEach((item) => item.classList.remove('is-active'));

		if (paginationItems[itemIndex]) {
			paginationItems[itemIndex].classList.add('is-active');
		}
	}

	private setImageDetails(
		selectorPrefix: string,
		imageDetail: IFitGuideMainPageSectionItemImageDetail,
		arrowIndicatorSelector?: string,
		textBetweenArrows?: string
	): void {
		if (selectorPrefix && imageDetail) {
			const { name, description } = imageDetail;

			$(`${selectorPrefix} > p:nth-child(1)`).text(name);
			$(`${selectorPrefix} > p:nth-child(2)`).html(description);
		}

		if (arrowIndicatorSelector && textBetweenArrows) {
			$(`${arrowIndicatorSelector}`).text(textBetweenArrows);
		}
	}

	private subscribeToState(): void {
		this.fitGuideSubscriber = this.fitGuide$.subscribe((fitGuideState) => {
			if (fitGuideState && fitGuideState.activeMobileScreens) {
				const { activeMobileScreens } = fitGuideState;

				if (activeMobileScreens[this.pageKey]) {
					this.currentMobileScreen = activeMobileScreens[this.pageKey];

					if (this.deviceType === 'Mobile') {
						setTimeout(() => {
							this.mobileScreenSwitchHandler(this.currentMobileScreen - 1);
						}, 100);
					}
				} else {
					this.currentMobileScreen = 0;
				}
			} else {
				this.currentMobileScreen = 0;
			}
		});
	}

	// Prevent zoom in on iOS when repeated tap on mobile banner carousel arrows when arrow is disabled
	private mobileBannerCarouselArrowTouchStartEventHandler(): void {
		const prevArrows = Array.from(
			document.querySelectorAll('.v2-fit-guide-mb-custom-arrows2-arrow-prev')
		);
		const nextArrows = Array.from(
			document.querySelectorAll('.v2-fit-guide-mb-custom-arrows2-arrow-next')
		);
		const arrows = [...prevArrows, ...nextArrows];

		arrows.forEach((arrow) => {
			const listener = (e: any) => {
				if (arrow.hasAttribute('disabled')) {
					return e.preventDefault();
				}
			};

			arrow.addEventListener('touchstart', listener);

			this.carouselArrowTouchStartListeners.push(() =>
				arrow.removeEventListener('touchstart', listener)
			);
		});
	}

	private mobileBannerCarouselArrowsVisibilityHandler(
		shouldVisible: boolean
	): void {
		const displayValue = shouldVisible ? 'flex' : 'none';

		$('.v2-fit-guide-mb-custom-arrows2-arrow-prev').css(
			'display',
			displayValue
		);
		$('.v2-fit-guide-mb-custom-arrows2-arrow-next').css(
			'display',
			displayValue
		);
	}

	private browserBackendButtonClickEventHandler() {
		this.locationUnsubscribe = this.location.onUrlChange((url: string) => {
			if (
				url === '/fit-guide' &&
				this.currentUrl.replace('/fit-guide', '').length > 0 &&
				this.currentMobileScreen > 0
			) {
				this.backButtonClickedHandler();
				setTimeout(() => {
					this.router.navigate([this.currentUrl]);
				}, 1);
			}
		});
	}
}
