import { SiteContentDetail } from '../../models/siteContent';
import { IFitGuideLandingVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildFitGuideLandingPageViewModel = (
	siteContent: SiteContentDetail,
	fitGuideSetting: FitGuideSetting
): IFitGuideLandingVM => {
	const {
		LandingTitle: title,
		LandingDescription: description,
		MenShirtTitle: menShirtTitle,
		LandingMenShirtDesktopImageUrl: menShirtDesktopImageUrl,
		LandingMenShirtMobileImageUrl: menShirtMobileImageUrl,
		MenSuitTitle: menSuitTitle,
		LandingMenSuitDesktopImageUrl: menSuitDesktopImageUrl,
		LandingMenSuitMobileImageUrl: menSuitMobileImageUrl,
		WomenShirtTitle: womenShirtTitle,
		LandingWomenShirtDesktopImageUrl: womenShirtDesktopImageUrl,
		LandingWomenShirtMobileImageUrl: womenShirtMobileImageUrl,
		LandingWomenSuitDesktopImageUrl: womenSuitDesktopImageUrl,
		LandingWomenSuitMobileImageUrl: womenSuitMobileImageUrl,
		LandingPSDesktopImageUrl: psDesktopImageUrl,
		LandingPSMobileImageUrl: psMobileImageUrl,
		TieTitle: tieTitle,
		LandingTieDesktopImageUrl: tieDesktopImageUrl,
		LandingTieMobileImageUrl: tieMobileImageUrl,
		ScarfTitle: scarfTitle,
		LandingScarfDesktopImageUrl: scarfDesktopImageUrl,
		LandingScarfMobileImageUrl: scarfMobileImageUrl,
		BowTieTitle: bowTieTitle,
		LandingBowTieDesktopImageUrl: bowTieDesktopImageUrl,
		LandingBowTieMobileImageUrl: bowTieMobileImageUrl,
		ShoeTitle: shoeTitle,
		LandingShoeDesktopImageUrl: shoeDesktopImageUrl,
		LandingShoeMobileImageUrl: shoeMobileImageUrl,
		LandingPSTitle: psTitle,
		LandingButtonTextFindYourFit: buttonTextFindYourFit,
		LandingButtonTextDiscoverMore: buttonTextDiscoverMore,
		LandingMenShirtDescription: menShirtDescription,
		LandingMenSuitDescription: menSuitDescription,
		LandingWomenShirtDescription: womenShirtDescription,
		LandingTieDescription: tieDescription,
		LandingBowTieDescription: bowTieDescription,
		LandingPSDescription: pSDescription,
		LandingScarfDescription: scarfDescription,
		LandingShoeDescription: shoeDescription,
	} = fitGuideSetting;
	return {
		title,
		description,
		sections: [
			{
				cards: [
					{
						title: menShirtTitle,
						description: menShirtDescription,
						buttonText: buttonTextFindYourFit,
						link: 'men-shirts',
						image: {
							desktop: menShirtDesktopImageUrl,
							mobile: menShirtMobileImageUrl,
						},
					},
					{
						title: menSuitTitle,
						description: menSuitDescription,
						buttonText: buttonTextFindYourFit,
						link: 'men-suits',
						image: {
							desktop: menSuitDesktopImageUrl,
							mobile: menSuitMobileImageUrl,
						},
					},
					{
						title: womenShirtTitle,
						description: womenShirtDescription,
						buttonText: buttonTextFindYourFit,
						link: 'women-shirts',
						image: {
							desktop: womenShirtDesktopImageUrl,
							mobile: womenShirtMobileImageUrl,
						},
					},
				],
			},
			{
				title: siteContent.Accessories,
				cards: [
					{
						title: tieTitle,
						description: tieDescription,
						buttonText: buttonTextDiscoverMore,
						link: 'ties',
						image: {
							desktop: tieDesktopImageUrl,
							mobile: tieMobileImageUrl,
						},
					},
					{
						title: bowTieTitle,
						description: bowTieDescription,
						buttonText: buttonTextDiscoverMore,
						link: 'bowties',
						image: {
							desktop: bowTieDesktopImageUrl,
							mobile: bowTieMobileImageUrl,
						},
					},
					{
						title: psTitle,
						description: pSDescription,
						buttonText: buttonTextDiscoverMore,
						link: 'pocket-squares',
						image: {
							desktop: psDesktopImageUrl,
							mobile: psMobileImageUrl,
						},
					},
					{
						title: scarfTitle,
						description: scarfDescription,
						buttonText: buttonTextDiscoverMore,
						link: 'scarves',
						image: {
							desktop: scarfDesktopImageUrl,
							mobile: scarfMobileImageUrl,
						},
					},
					{
						title: shoeTitle,
						description: shoeDescription,
						buttonText: buttonTextDiscoverMore,
						link: 'shoes',
						image: {
							desktop: shoeDesktopImageUrl,
							mobile: shoeMobileImageUrl,
						},
					},
				],
			},
		],
	};
};

export default buildFitGuideLandingPageViewModel;
