import { Injectable } from '@angular/core';
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { Basket, BasketItem } from 'src/app/model/basketpage-viewmodel';
import { PersonifyRecommendationType, PersonifyRequest, PersonifyResponse, PRQBasket } from '../models/product-recommendations.model';


@Injectable({
  providedIn: 'root'
})
export class PersonifyService {

  private readonly personifyCookieName: string = "PERSONIFY";

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Access-Control-Allow-Origin': '*',
  //   })
  // };

  constructor(
    //private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getRecommendations(personifyApiUrl: string, payload: PersonifyRequest): Observable<PersonifyResponse> {
    // Commented out due to CORS issue
    // return this.httpClient.post<PersonifyResponse>(personifyApiUrl, payload, this.httpOptions);

    // This method use XMLHttpRequest as a workaround to avoid CORS errors when making API calls with HttpClient
    return new Observable<PersonifyResponse>((observer) => {
      const xhr = new XMLHttpRequest();

      xhr.open("POST", personifyApiUrl, true);
      xhr.responseType = "json";
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          observer.error(`Request failed.
              \nStatus: ${xhr.status}
              \nStatus Text: ${xhr.statusText}`
          );
        }

        if (!xhr.response || typeof xhr.response !== 'object') {
          observer.error(`Empty or invalid response received.
                \nResponse: ${xhr.response}
                \nStatus Text: ${xhr.statusText}`
          );
        }

        observer.next(xhr.response as PersonifyResponse);
        observer.complete();
      };

      xhr.onerror = () => {
        observer.error(`An error occurred during the request. Status: ${xhr.status}, Status Text: ${xhr.statusText}`);
      };

      xhr.send(JSON.stringify(payload));

      return () => xhr.abort();
    });
  }

  getPersonifySessionId(): string {
    return this.getOrSetCookieWithExpiry();
  }

  getPersonifyShopperId(): string {
    //TODO:
    return "";
  }

  getRecommendationType(recommendationType: string | null | undefined): number {
    return PersonifyRecommendationType[recommendationType?.trim().toUpperCase() as keyof typeof PersonifyRecommendationType] ?? PersonifyRecommendationType.ALL;
  }

  getBasket(basket: Basket): PRQBasket[] {
    //TODO:
    return this.criteo_populateProductPriceLines(basket?.BasketItems, basket?.CurrencyCode);
  }

  private getOrSetCookieWithExpiry(): string {
    let value = this.cookieService.get(this.personifyCookieName);

    if (value) {
      return value;
    }

    value = this.guidGenerator();

    const date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); //7 day session for articles

    this.cookieService.put(this.personifyCookieName, value, { expires: date, path: '/' });

    return value;
  }

  private guidGenerator(): string {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };

    var date = new Date();
    return (date.getTime() + "-" + S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  }

  // Copied from 'hc_gtm.js' script
  private criteo_populateProductPriceLines(prods: BasketItem[], currency: string): PRQBasket[] {
    var pArray: PRQBasket[] = [];

    if (!prods) {
      return pArray;
    }

    var poundConversionRate = this.getPoundConversionRate(currency);
    var pArray: PRQBasket[] = [];

    for (var loop = 0; loop <= prods.length - 1; loop++) {
      pArray.push(
        {
          productid: prods[loop].Code,
          unitprice: (Math.round(prods[loop].SalePrice * 100) / 100) * poundConversionRate,
          quantity: prods[loop].Quantity
        });
    }

    return pArray;
  }

  // Copied from 'hc_gtm.js' script
  private getPoundConversionRate(currency: string): number {
    var poundConversionRate = 1;

    switch (currency) {
      case "GBP":
        poundConversionRate = 1.00;
        break;
      case "EUR":
        poundConversionRate = 0.85;
        break;
      case "USD":
        poundConversionRate = 0.77;
        break;
      case "AUS":
        poundConversionRate = 0.52;
        break;
      default:
    }
    return poundConversionRate;
  }
}