import { Component, Injector, NgZone, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { Label } from 'src/app/shared/models/Labels';
import { RegExLibrary } from 'src/app/shared/models/regex-library';
import { ShippingToClickAndCollect, ShippingVM } from 'src/app/shared/models/shippingoptions';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { LabelService } from 'src/app/shared/service/label.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { PaypalService } from '../../../service/paypal.service';

@Component({
	selector: 'app-paypal-quickconfirm',
	templateUrl: './paypal-quickconfirm.component.html',
	styleUrls: ['./paypal-quickconfirm.component.scss']
})
export class PaypalQuickConfirmComponent implements OnInit {
	shippingVM: ShippingVM[];
	paymentModel: any;
	sitecontent: SiteContentDetail;
	selectedShippingId: number;
	regExLibrary: RegExLibrary;
	label: Label;
	shippingToClickAndCollect: ShippingToClickAndCollect;
	address: string = '';
	email: string = '';
	title: string;
	fName: string;
	lName: string;
	paypalData = null;
	token: string;
	payerID: string;
	basketID: number;
	isGift: boolean = false;
	isDutyFree: boolean = false;
	hasCustomSizes: boolean = false;

	constructor(
		private sharedService: SharedService,
		private paypalService: PaypalService,
		private route: ActivatedRoute,
		private injector: Injector,
		private titleService: Title,
		private meta: Meta,
		public sanitizer: DomSanitizer,
		private siteContentService: SiteContentService,
		private labelService: LabelService) {

		$("body").removeClass();
		$("body").addClass("checkout ng-scope");
	}

	ngOnInit() {

		this.getSiteContent();

		this.route.queryParamMap.subscribe(queryParams => {
			this.token = queryParams.get("token");
			this.payerID = queryParams.get("PayerID");
			this.basketID = +queryParams.get("bid");

			this.paypalService.quickConfirm(this.token, this.payerID, this.basketID).subscribe(data => {

				if (data && data.Basket && data.Basket.AddressShipping) {
					this.setIsDutyFree(data.Basket.AddressShipping);
				}

				this.paypalData = data;
				this.shippingVM = data['ShippingOptions'];
				this.selectedShippingId = data['SelectedShippingId'];
				this.fName = this.paypalData.FirstName;
				this.lName = this.paypalData.LastName;

				if (this.paypalData.AddressLine1) {
					this.address += this.paypalData.AddressLine1 + '<br>';
				}

				if (this.paypalData.AddressLine2) {
					this.address += this.paypalData.AddressLine2 + '<br>';
				}

				if (this.paypalData.TownCity) {
					this.address += this.paypalData.TownCity + '<br>';
				}

				if (this.paypalData.County) {
					this.address += this.paypalData.County + '<br>';
				}

				if (this.paypalData.PostZipCode) {
					this.address += this.paypalData.PostZipCode + '<br>';
				}

				if (this.paypalData.Country) {
					this.address += this.paypalData.Country;
				}

				this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;

				this.shippingToClickAndCollect.price = 0.00;
				this.shippingToClickAndCollect.shippingText = this.sitecontent.Shipping;
				this.shippingToClickAndCollect.showdeliveryoption = false;

				if (this.paypalData?.Basket?.ShippingTotal) {
					this.shippingToClickAndCollect.price = this.paypalData.Basket.ShippingTotal;
				}
				
				const basketItems = data.Basket.BasketItems.filter(x => x.CustomSizes.length > 0);

				if (basketItems.length > 0) {
					this.hasCustomSizes = true;
				}
			})
		})

		this.getRegExLibrary();
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	getRegExLibrary() {
		this.sharedService.getRegExLibrary().subscribe(data => {
			if (data) {
				this.regExLibrary = data;
				this.getAllLabels();
			}
		});
	}

	getAllLabels() {
		this.label = this.labelService.getLabelData();

		if (!this.label) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.label = data;
				}
			});
		}
	}

	shippingChange(option) {
		this.selectedShippingId = option.Id;

		this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;

		this.shippingToClickAndCollect.price = option.Price;
		this.shippingToClickAndCollect.shippingText = this.sitecontent.Shipping;
		this.shippingToClickAndCollect.showdeliveryoption = false;
	}

	paypalPayment() {
		let data = {
			"Token": this.token,
			"PayerId": this.payerID,
			"AddressOption": "SameAsDelivery",
			"SelectedShippingId": this.selectedShippingId,
			"IsGift": this.isGift,
		}

		this.paypalService.quickCompletePayment(data).subscribe(data => {
			if (data && data.orderId > 0) {
				const routerService = this.injector.get(Router);
				const ngZone = this.injector.get(NgZone);

				ngZone.run(() => {
					routerService.navigate(['/checkout/receipt', data.orderId], { relativeTo: this.route });
				});

				return;
			}
		});
	}

	setIsDutyFree(addressShipping) {
		// Assign Duty Free if country IsEU and not UK
		if (addressShipping) {
			this.isDutyFree = addressShipping.IsCountryEU && addressShipping.CountryId != 1;
		}
	}
}
