import { Component, OnInit, Input, PLATFORM_ID, Inject } from '@angular/core';
import { MenuItem } from 'src/app/shared/models/menuItem';
import * as $ from 'jquery'
import { isPlatformBrowser } from '@angular/common';
import { ViewportService } from 'src/app/shared/viewport.service';
import { WebBrowser } from 'src/app/enums/web-browser-type-enum';

@Component({
	selector: 'header-menu-list,[header-menu-list]',
	templateUrl: './header-menu-list.component.html',
	styleUrls: ['./header-menu-list.component.scss']
})
export class HeaderMenuListComponent implements OnInit {

	@Input() menuItems: MenuItem[];
	@Input() parentMenu: MenuItem;

	firstMenuItem: MenuItem;
	classTotal: number;
	isrecordsList = false;
	isCentered = false;
	renderFirstItemAsDiv: boolean;
	renderFirstItemAsDiv1: boolean;
	hasChildren: boolean;
	numberInWords = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen'];
	classTotalInWord: string;
	divStart = '<div class="navigate-wrapper">';
	divEnd = '</div>';
	isLoading = false;
	menuitemId: number;
	value;

	constructor(@Inject(PLATFORM_ID) private platformId: any,private viewportService:ViewportService) { }

	ngOnInit() {
		this.setMenuValues();
	}

	ngDoCheck() {
		if (isPlatformBrowser(this.platformId)) {
			if ($(this.value).hasClass("hideHover")) {
				setTimeout(() => {
					$(this.value).removeClass("hideHover");
				}, 3000)
			}
		}
	}

	startsWith(str: string[], word: string): boolean {
		if (str && str.length > 0) {
			for (let j = 0; j < str.length; j++) {

				return str[j].lastIndexOf(word, 0) === 0;
			}
		}
	}

	setMenuValues(): void {
		if (this.menuItems) {

			this.menuItems = this.menuItems.map(item => {
				if (item.Content !== null) {
				  const urlMatch = item.Content.match(/href="([^"]+)"/);
				  
				  if (urlMatch && urlMatch[1]) {
					item.ContentUrl = urlMatch[1];
				  }
				}
				return item;
			  });

			this.firstMenuItem = this.menuItems[0];
			this.classTotal = this.menuItems.length;
			const recordsListFirst = this.menuItems.filter(
				item => item.Level === 1);
			if (recordsListFirst) {
				let recordsListSeconds = recordsListFirst.filter(item => item.ClassNameAsCollection !== null);
				if (recordsListSeconds && recordsListSeconds.length > 0) {

					recordsListSeconds = recordsListFirst.filter(item => this.startsWith(item.ClassNameAsCollection, 'banner-'));
					if (recordsListSeconds && recordsListSeconds.length > 0) {
						this.isrecordsList = true;
					}

				}
			}

			if (this.firstMenuItem.Level === 1 && this.classTotal > 0 && this.isrecordsList) {
				this.classTotal = this.classTotal - 1;
			}

			if (this.firstMenuItem.Parent && this.parentMenu) {
				const isCenterClass = this.parentMenu.ClassNameAsCollection.filter(x => x.indexOf('center') > -1);
				if (isCenterClass && isCenterClass.length > 0) {
					this.isCentered = true;
				}
			}

			if (this.firstMenuItem.Level === 1 && this.firstMenuItem.ImageUrl && !this.isCentered) {
				this.renderFirstItemAsDiv = true;
				this.menuitemId = this.firstMenuItem.MenuItemId;
				this.renderFirstItemAsDiv1 = true;
			}

			if (this.renderFirstItemAsDiv) {
				this.classTotal = this.classTotal - 1;
			}
			this.hasChildren = this.firstMenuItem.HasChildren;

			if (this.classTotal > 0) {
				this.classTotalInWord = this.numberInWords[this.classTotal - 1];
			}

			// tslint:disable-next-line: prefer-for-of
			for (let i = 0; i < this.menuItems.length; i++) {

				if (this.menuItems[i].MenuItemId === this.menuitemId && this.renderFirstItemAsDiv) {

					this.menuItems[i].isFirstItem = true;
				} else {
					this.menuItems[i].isFirstItem = false;
				}
				if (this.menuItems[i] && this.menuItems[i].ClassNameAsCollection) {
					const isHiddenResult = this.menuItems[i].ClassNameAsCollection.filter(x => x === 'hidden');
					if ((isHiddenResult && isHiddenResult.length > 0) || this.renderFirstItemAsDiv1) {
						this.renderFirstItemAsDiv1 = false;
						this.menuItems[i].isHiden = true;
					} else {
						this.menuItems[i].isHiden = false;
					}
				}
			}

			this.isLoading = true;
		}
	}

	removeHover(e) {
		if (e.path && e.path.length > 0 && e.path[1].children && e.path[1].children.length > 0) {
			this.value = e.path[1].children[1];
			$(e.path[1].children[1]).addClass("hideHover");
		}
		localStorage.removeItem('viewAll');
		localStorage.removeItem('paginationNumber');
		localStorage.removeItem('filteredList');
		localStorage.removeItem('filterFacestsData');
		localStorage.removeItem('sortBy');
		localStorage.removeItem('sortDescending');
	}

	resetSafariMenu() {
		if (this.viewportService.detectBrowser().includes(WebBrowser.Safari)) {
			const subnavs: any = document.querySelectorAll('.subnav');
			subnavs.forEach(subnav => {
				subnav.style.height = '';
			});
		}
	}
}
