import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FitGuideLandingPageComponent } from './landing-page/fit-guide-landing-page.component';
import { FitGuideMainPageComponent } from './main-page/fit-guide-main-page.component';
import { FitGuideCompareFitPageComponent } from './compare-fit-page/fit-guide-compare-fit-page.component';

@NgModule({
	declarations: [
		FitGuideLandingPageComponent,
		FitGuideMainPageComponent,
		FitGuideCompareFitPageComponent,
	],
	imports: [CommonModule, RouterModule],
})
export class FitGuideModule {}
