import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { AttributeTypeEnum } from 'src/app/enums/attribute-types-enum';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { ScriptLocation, ScriptService } from 'src/app/shared/service/script.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ViewportService } from 'src/app/shared/viewport.service';
import { ProductImageVM } from '../../../model/product-image-viewmodel';
import { Product } from '../models/product-detail';
import { ViewPortEnum } from 'src/app/enums/device-type-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from 'src/app/shared/shared.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { PersonifySocialProofingService } from 'src/app/shared/service/personify-social-proofing.service';
import { SocialProofingTypeEnum } from 'src/app/shared/models/social-proofing.model';

@Component({
	selector: 'image-slider',
	templateUrl: './image-slider.component.html',
	styleUrls: ['./image-slider.component.scss'],
})
@Inject('root')
export class ImageSliderComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() imagesData: ProductImageVM[];
	@Input() product: Product;
	@Input() WebStoreId: number;

	readonly NAVIGATION_STATUS_KEY: string = 'hnc_img_slider_navigation_status';
	private destroy$ = new Subject<void>();

	valueOfImage = 0;
	changeImageBefore = true;
	changeImageBeforeAfter = true;
	stopBeforeChange = true;
	stopBeforeChangeThumb = true;
	zoomEnabledCheck: boolean = true;
	innerWidth: number;
	offerTokenCookie: string = '';
	sitecontent: SiteContentDetail;
	lpAppSetting: LpAppSetting;

	magicZoomOptions = {};
	magicZoomMobileOptions = {};
	magicScrollOptions = {};
	magicScrollMobileOptions = {};

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
		private scriptService: ScriptService,
		private siteContentService: SiteContentService,
		private viewportService: ViewportService,
		private sharedService: SharedService,
		private router: Router,
		private personifySocialProofingService: PersonifySocialProofingService
	) { }

	ngOnInit() {
		this.getLpAppSetting();
		this.loadSocialProofing();
		this.innerWidth = window.innerWidth;
		this.offerTokenCookie = this.getOfferTokenCookie();

		this.sitecontent = this.siteContentService.getSiteContentData();

		this.magicZoomOptions = {
			lazyZoom: true,
			zoomOn: 'click',
			zoomPosition: 'inner',
			cssClass: 'mz-show-arrows',
			variableZoom: true,
			hint: 'always',
			transitionEffect: false,
			expandZoomMode: 'zoom',
			textClickZoomHint: this.sitecontent.EnableZoom,
			textExpandHint: this.sitecontent.ClickToZoom
		};

		this.magicZoomMobileOptions = {
			lazyZoom: true,
			zoomMode: 'zoom',
			transitionEffect: false,
			textClickZoomHint: this.sitecontent.EnableZoom,
			textExpandHint: this.sitecontent.ClickToZoom
		};

		this.magicScrollOptions = {
			lazyLoad: true,
			arrows: 'inside',
			orientation: 'vertical',
			step: 1,
			items: 5,
			loop: 'on',
			width: '100%'
		};

		this.magicScrollMobileOptions = {
			lazyLoad: true,
			orientation: 'horizontal',
			step: 1,
			items: 3,
			width: '100%'
		};

		this.setNavigationStatus();
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.loadMagicZoom();
		}
	}

	ngOnDestroy() {
		this.unloadMagicZoom();
		this.destroy$.next();
		this.destroy$.complete();
	}

	@HostListener('window:beforeunload', ['$event'])
	handlePageUnload(event: Event): void {
		//clear sessionStorage item on page refresh or unload
		sessionStorage.removeItem(this.NAVIGATION_STATUS_KEY);
	}

	getLpAppSetting() {
		this.lpAppSetting = this.sharedService.getLpAppSettingsData();
	}

	checkAttributes(video) {
		return video.AttributeValues.find(attribute => attribute.Name === AttributeTypeEnum.ShowonPDP)
	}

	settingToString(setting): string {

		// Need to convert object to MagicZoom formatted setting string
		var str = JSON.stringify(setting);
		str = str.replace("{", "");
		str = str.replace("}", "");
		str = str.replace(new RegExp(",", "g"), "; ");
		str = str.replace(new RegExp("\"", "g"), "");

		return str;
	}

	getOfferTokenCookie(): string {
		let cookie = '';

		if (document.cookie && document.cookie !== '') {

			const split: Array<string> = document.cookie.split(';');

			for (let i = 0; i < split.length; i += 1) {
				const currentCookie: Array<string> = split[i].split('=');
				currentCookie[0] = currentCookie[0].replace(/^ /, '');

				if (currentCookie[0] && currentCookie[0].toLocaleLowerCase() === 'offertoken') {
					cookie = decodeURIComponent(currentCookie[1]);
					break;
				}
			}
		}

		return cookie;
	}

	getSalepercentage(retailPrice, salePrice) {
		if (retailPrice && salePrice) {
			return Math.round((retailPrice - salePrice) / retailPrice * 100);
		}
	}

	loadMagicZoom() {
		const scriptsToLoad = [
			'magic-zoom',
			'magic-thumb',
			'magic-scroll',
		];

		{
			var IsPDPVideo = false;

			if (this.product.Videos && this.product.Videos.length > 0) {
				IsPDPVideo = this.product.Videos.some(video => this.checkAttributes(video));
			}

			if (IsPDPVideo) {
				scriptsToLoad.push('magic-zoom-video-handler');
			}
		}

		this.scriptService
			.load(
				ScriptLocation.Body,
				...scriptsToLoad
			).then((scripts) => {
				if (this.imagesData.length > 5 && this.viewportService.getDeviceType() === ViewPortEnum.Desktop) {
					setTimeout(() => {
						var elements = document.getElementsByClassName('mcs-button');
						if (elements.length > 0) {
							for (var i = 0; i < elements.length; i++) {
								elements[i].classList.remove("mcs-hidden", "mcs-disabled");
							}
						}
					}, 1000);
				}
				this.refreshMagicScroll(scripts);
			})
			.catch((error) => console.debug(error));
	}

	unloadMagicZoom() {
		this.scriptService
			.unload(
				ScriptLocation.Body,
				'magic-zoom',
				'magic-thumb',
				'magic-scroll',
				'magic-zoom-video-handler'
				//'magic-zoom-settings'
			)
			.then((data) => {
				//console.debug('script unloaded ', data);
			})
			.catch((error) => console.debug(error));
	}

	private loadSocialProofing() {
		const productCodes = [this.product.Code];
		this.personifySocialProofingService.loadProductTrends(productCodes, SocialProofingTypeEnum.PDP);
	}

	private refreshMagicScroll(scripts: any[]): void {
		if (!this.isNavigated()) {
			return;
		}

		if (!this.isMagicScrollLoaded(scripts)) {
			return;
		}

		try {
			setTimeout(() => window['MagicScroll']?.refresh('product-thumbnails'), 500);
		} catch (error) {
			console.log('An error occurred while refreshing MagicScroll', error);
		}
	}

	private isMagicScrollLoaded(scripts: any[]): boolean {
		return scripts.find(x => x.script === 'magic-scroll' && x.loaded);
	}

	private setNavigationStatus(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				takeUntil(this.destroy$)
			).subscribe((event) => {
				if (this.router.navigated) {
					sessionStorage.setItem(this.NAVIGATION_STATUS_KEY, 'true');
				}
			});
	}

	private isNavigated(): boolean {
		return sessionStorage.getItem(this.NAVIGATION_STATUS_KEY) ? true : false;
	}
}