import { NgModule } from '@angular/core';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { ProductListComponent } from './product-list/product-list.component';
import { LazyImageComponent } from './lazy-image/lazy-image.component';
import { ProductIconComponent } from './product-icon/product-icon.component';
import { ProductIconHCComponent } from './product-icon-h-c/product-icon-h-c.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { ReadMoreContentComponent } from './read-more-content/read-more-content.component';
import { FacetFilterComponent } from './facet-filter/facet-filter.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchProductsComponent } from './search-products/search-products.component';
import { AttraqtFacetFilterComponent } from './attraqt-facet-filter/attraqt-facet-filter.component';
import { LikedProductComponent } from './liked-product/liked-product.component';

@NgModule({
    declarations: [
        ProductListComponent,
        LazyImageComponent,
        ProductIconComponent,
        ProductIconHCComponent,
        ProductViewComponent,
        QuickViewComponent,
        HeaderBannerComponent,
        ReadMoreContentComponent,
        FacetFilterComponent,
        SearchProductsComponent,
        AttraqtFacetFilterComponent,
        LikedProductComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [ProductListComponent, ProductViewComponent, ProductIconComponent]
})
export class ProductListModule { }
