import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from '@angular/material/dialog';
import { SubscribeFormComponent } from './components/footer/subscribe-form/subscribe-form.component';
import { DefaultFooterComponent } from './components/footer/default-footer/default-footer.component';
import { FooterMenuListComponent } from './components/footer/footer-menu-list/footer-menu-list.component';
import { SocialFooterComponent } from './components/footer/social-footer/social-footer.component';
import { AcceptedPaymentMethodsComponent } from './components/footer/accepted-payment-methods/accepted-payment-methods.component';
import { DefaultHeaderComponent } from './components/header/default-header/default-header.component';
import { HeaderMenuListComponent } from './components/header/header-menu-list/header-menu-list.component';
import { HeaderMenuSublistComponent } from './components/header/header-menu-sublist/header-menu-sublist.component';
import { CartService } from './service/cart.service';
import { CartComponent } from './components/cart/cart.component';
import { GeoLocationComponent } from './components/geo-location/geo-location.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule ,ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterUniquePipe } from './pipes/unique-filter';
import { UniqueRecordPipe } from './pipes/unique-record';
import { SizeGuideComponent } from './components/size-guide/size-guide.component';
import { StoresComponent } from './components/stores/stores.component';
import { StoreDetailsComponent } from './components/store-details/store-details.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CheckoutDeliveryComponent } from './components/checkout-delivery/checkout-delivery.component';
import { CheckoutHeaderComponent } from './components/checkout-header/checkout-header.component';
import { CheckoutFooterComponent } from './components/checkout-footer/checkout-footer.component';
import { CheckoutBasketSummaryComponent } from './components/checkout-basket-summary/checkout-basket-summary.component';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
import { FindPostalCodeComponent } from './components/find-postal-code/find-postal-code.component';
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { GooglePayButtonModule } from "@google-pay/button-angular";
import { PayPalComponent } from './components/payments/pay-pal/pay-pal.component';
import {MatTabsModule} from '@angular/material/tabs';
import { SearchItemsComponent } from './components/header/search-items/search-items.component';
import { MobileNavBarComponent } from './components/header/mobile-nav-bar/mobile-nav-bar.component';
import { MapsModalComponent } from './components/maps-modal/maps-modal.component';
import { HeritageComponent } from './components/heritage/heritage.component';
import { ReviewsModalComponent } from './components/reviews-modal/reviews-modal.component';
import { AccordianV1Directive } from './directives/accordian-v1.directive';
import { AddMarginDirective } from "./directives/add-margin.directive";
import { AnimateDirective } from './directives/animate.directive';
import { AskAQuestionDirective } from './directives/ask-aquestion.directive';
import { ContentToggleDirective } from './directives/content-toggle.directive';
import { DummyDirective } from './directives/dummy.directive';
import { FixedHeaderDirective } from './directives/fixed-header.directive';
import { FlagStrapDirective } from './directives/flag-strap.directive';
import { FullHeightDirective } from './directives/full-height.directive';
import { FullPageDirective } from './directives/full-page.directive';
import { GalleryItemDirective } from './directives/gallery-item.directive';
import { HoverImageDirective } from './directives/hover-image.directive';
import { IconBarModalDirective } from './directives/icon-bar-modal.directive';
import { ImageMapDirective } from './directives/image-map.directive';
import { ImageOrientationDirective } from './directives/image-orientation.directive';
import { InitModelDirective } from './directives/init-model.directive';
import { PinterestPinDirective } from './directives/pinterest-pin.directive';
import { ProductionDirective } from './directives/production.directive';
import { DummyComponent } from './directives/dummy/dummy.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { NamephoneEntryComponent } from './components/namephone-entry/namephone-entry.component';
import { ProgressComponent } from './components/progress/progress.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { StoreDetailComponent } from './components/store-detail/store-detail.component';
import { ClickAndCollectComponent } from './components/click-and-collect/click-and-collect.component';
import { AdyenDropInComponent } from './components/adyen-drop-in/adyen-drop-in.component';
import { DataOpenInModalDirective } from './directives/data-open-in-modal.directive';
import { TrimPipe } from './pipes/trim.pipe';
import { ReceiptSummaryComponent } from './components/receipt-summary/receipt-summary.component';
import { AmazonpayLoginDirective } from './directives/amazonpay-login.directive';
import { AmazonCheckoutComponent } from './components/amazonpay/amazon-checkout.component';
import { AmazonpayAddressDirective } from './directives/amazonpay-addressbook.directive';
import { AmazonpayWalletDirective } from './directives/amazonpay-wallet.directive';
import { ScrolltoDirective } from './directives/scrollto.directive';
import { SafedataDirective } from './directives/safedata.directive';
import { AmazonpayLogoutDirective } from './directives/amazonpay-logout.directive';
import { PaypalQuickConfirmComponent } from './components/paypal/paypal-quickconfirm/paypal-quickconfirm.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { GlobalCookieLawComponent } from './components/global-cookie-law/global-cookie-law.component';
import { IconBarModalService } from './service/iconbarmodel.service';
import { PaypalIcCompleteComponent } from './components/paypal/paypal-ic-complete/paypal-ic-complete.component';
import { RegisterThanksComponent } from './components/register-thanks/register-thanks.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MatchproductmodelComponent } from './components/matchproductmodel/matchproductmodel.component';
import { PromoLineBarComponent } from './components/promo-line-bar/promo-line-bar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { StoreSinglePageComponent } from './components/store-single-page/store-single-page.component';
import { StoreListComponent } from './components/store-list/store-list.component';
import { MapModalComponent } from './components/map-modal/map-modal.component';
import { StoreFilterPipe } from './pipes/store-filter.pipe';
import { StoreFilterImagePipe } from './pipes/store-image-filter.pipe';
import { StoreOpeningTimeFilterPipe } from './pipes/store-timings.pipe';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { PaymentCancelledComponent } from './components/payment-cancelled/payment-cancelled.component';
import { UserFriendlyPipe } from './pipes/userfriendly.pipe';
import { AmazonFailedComponent } from './components/amazon-failed/amazon-failed.component';
import { MobileAddBagComponent } from './components/mobile-add-bag/mobile-add-bag.component';
import { SizeGuideNewComponent } from './components/style-guide-new/style-guide-new.component';
import { ScrollableTableLineComponent } from './components/scrollable-table-line/scrollable-table-line.component';
import { SizeGuideImageComponent } from './components/size-guide-image/style-guide-image.component';
import { ScriptService } from './service/script.service';
import { CheckoutPOQComponent } from './components/checkout-poq/checkout-poq.component';
import { GoogleMapsModule } from '@angular/google-maps'
import { ApiTestComponent } from './components/_api-test/api-test.component';
import { SizeselectorHandcComponent } from '../modules/product-details/sizeselector-handc/sizeselector-handc.component';
import { GlobaleCheckoutComponent } from './components/globale-checkout/globale-checkout.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { FreeDeliveryProgressBarComponent } from './components/free-delivery-progress-bar/free-delivery-progress-bar.component';
import { ProductRecommendationsComponent } from './components/product-recommendations/product-recommendations.component';
import { SocialProofComponent } from './components/social-proof/social-proof.component';


@NgModule({
	declarations: [
		ApiTestComponent,
        DummyComponent,
        SubscribeFormComponent,
        DefaultFooterComponent,
        FooterMenuListComponent,
        SocialFooterComponent,
        AcceptedPaymentMethodsComponent,
        DefaultHeaderComponent,
        HeaderMenuListComponent,
        HeaderMenuSublistComponent,
        SizeGuideComponent,
        CartComponent,
        GeoLocationComponent,
        FilterUniquePipe,
        UniqueRecordPipe, SizeGuideComponent, StoresComponent, StoreDetailsComponent,
		SubscribeComponent, CheckoutComponent, CheckoutPOQComponent, CheckoutDeliveryComponent, CheckoutHeaderComponent,
        CheckoutFooterComponent, CheckoutBasketSummaryComponent, AddressDetailsComponent,
        FindPostalCodeComponent, CheckoutPaymentComponent, PayPalComponent,
        SearchItemsComponent,
        MobileNavBarComponent, MapsModalComponent, HeritageComponent, ReviewsModalComponent,
        AccordianV1Directive,
        AnimateDirective, AskAQuestionDirective, ContentToggleDirective, DummyDirective, FixedHeaderDirective,
        FlagStrapDirective, FullHeightDirective, FullPageDirective, GalleryItemDirective, HoverImageDirective,
        IconBarModalDirective, ImageMapDirective, ImageOrientationDirective, InitModelDirective, PinterestPinDirective,
        ProductionDirective,
        AddMarginDirective,
        SafeHtmlPipe,
        PageNotFoundComponent,
        ReceiptComponent,
        NamephoneEntryComponent,
        ProgressComponent,
        StoreDetailComponent,
        ClickAndCollectComponent, AdyenDropInComponent, DataOpenInModalDirective,
        TrimPipe, ReceiptSummaryComponent,
        AmazonpayLoginDirective,
        AmazonCheckoutComponent,
        AmazonpayAddressDirective,
        AmazonpayWalletDirective,
        ScrolltoDirective,
        SafedataDirective, AmazonpayLogoutDirective, PaypalQuickConfirmComponent,
        GlobalCookieLawComponent,
        PaypalIcCompleteComponent,
        RegisterThanksComponent,
        ContactUsComponent,
        MatchproductmodelComponent, PromoLineBarComponent, BreadcrumbsComponent,
        StoreSinglePageComponent,
        StoreListComponent,
        MapModalComponent, StoreFilterPipe, StoreFilterImagePipe, StoreOpeningTimeFilterPipe,
        PaymentFailedComponent, PaymentCancelledComponent, UserFriendlyPipe, AmazonFailedComponent, MobileAddBagComponent, SizeGuideNewComponent, ScrollableTableLineComponent,
		SizeGuideImageComponent,
		SizeselectorHandcComponent,
		GlobaleCheckoutComponent,
        ReviewsComponent,
        MobileNavBarComponent,
        FreeDeliveryProgressBarComponent,
        ProductRecommendationsComponent,
        SocialProofComponent
    ],
    imports: [
		CommonModule,
		NgxPayPalModule,
		GooglePayButtonModule,
        SlickCarouselModule,
        FormsModule, ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        NgxPaginationModule,
        RouterModule,
		MatTabsModule,
		NgxPageScrollCoreModule,
		AutocompleteLibModule,
		NgxCaptchaModule,
		GoogleMapsModule,
        MatIconModule
    ],
    exports: [
        SubscribeFormComponent,
        DefaultFooterComponent,
        FooterMenuListComponent,
        SocialFooterComponent,
        AcceptedPaymentMethodsComponent,
        DefaultHeaderComponent,
        HeaderMenuListComponent,
        HeaderMenuSublistComponent,
        CommonModule,
        FormsModule, ReactiveFormsModule,
        SlickCarouselModule,
        NgxPaginationModule,
        FilterUniquePipe,
        UniqueRecordPipe,
		RouterModule,
		AccordianV1Directive, SafeHtmlPipe, DataOpenInModalDirective, TrimPipe,
        ScrolltoDirective, SafedataDirective,
        AmazonpayLogoutDirective,
        AmazonpayLoginDirective,
        AmazonCheckoutComponent,
        AmazonpayAddressDirective,
        AmazonpayWalletDirective,
        PromoLineBarComponent, BreadcrumbsComponent, UserFriendlyPipe, FullHeightDirective,
        SizeGuideNewComponent,
        ScrollableTableLineComponent,
		SizeGuideImageComponent,
		SizeselectorHandcComponent,
        FreeDeliveryProgressBarComponent,
        ProductRecommendationsComponent,
        SocialProofComponent
    ], providers: [CartService, IconBarModalService, ScriptService]
})
export class SharedModule { }
