import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { SiteContentDetail } from '../../models/siteContent';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { InitialiseReceiptPageTracking } from '../../../../assets/shared/gtm.js';
import { AuthService } from '../../../modules/auth/auth.service';
import { forkJoin, map, Observable, Subscription } from 'rxjs';

@Component({
	selector: 'app-globale-checkout',
	templateUrl: './globale-checkout.component.html',
	styleUrls: ['./globale-checkout.component.scss']
})
export class GlobaleCheckoutComponent implements OnInit {
	siteContent: SiteContentDetail;
	LpAppSetting: LpAppSetting;
	isGuest: boolean = false;
	isGerman: boolean = false;
	formLoaded: Promise<boolean>;

	scriptContent = [];

	constructor(
		private sharedService: SharedService,
		private router: Router,
		private titleService: Title,
		private meta: Meta,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService,
		private authService: AuthService,
		private ngZone: NgZone,

		@Inject(PLATFORM_ID)
		private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	ngOnInit() {
		this.getLpAppSetting();
		this.getSiteContent();
		this.meta.updateTag({ name: 'description', content: '' });
		this.scrollService.windowScrollTo(0, 0);
		this.isGuest = localStorage.getItem("isGuestLogin") ? true : false;
		this.getDeliveryData();

		// Create a global JS reference to GlobaleCheckoutComponent Angular Component which can be called by external GE Javascript
		window["GlobaleCheckoutComponentReference"] = {
			component: this,
			zone: this.ngZone,
			loadTrackingScript: (pageTypeDimensionEnum) => this.loadTrackingScript(pageTypeDimensionEnum),
		};
	}

	initialiseGleGemClientAnalytics() {

		// https://docs.global-e.com/enterprise/en/client-analytics.html
		//$(window).on("load", function () {
		var glegem = window["glegem"] || function () {
			console.log("glegem LOADING");

			(window["glegem"].q = window["glegem"].q || []).push(arguments);

			console.log("glegem LOADED");
		};

		// Keep this data for usage in GleGem
		window["LpAppSetting"] = this.LpAppSetting;

		glegem("OnCheckoutStepLoaded", function (data) {

			var callLogCheckout = function (data, eventType) {
				console.log("glegem OnCheckoutStepLoaded " + eventType, data);

				fetch(`/Globale/LogCheckout?eventType=${eventType}&globaleOrderId=${data.OrderId ? data.OrderId : ''}`);
			}

			this.LpAppSetting = window["LpAppSetting"];

			if (!this.LpAppSetting) {
				console.log("glegem ERROR (no LpAppSetting)");
			}

			switch (data.StepId) {
				case data.Steps.LOADED:
					callLogCheckout(data, "LOADED");

					// Call GlobaleCheckoutComponent loadTrackingScript() method
					window["GlobaleCheckoutComponentReference"].zone.run(() => {
						window["GlobaleCheckoutComponentReference"].loadTrackingScript(PageTypeDimensionEnum.CheckoutPayment);
					});

					break;
				case data.Steps.CONFIRMATION:
					callLogCheckout(data, "CONFIRMATION");

					if (data.IsSuccess) {
						var deliveryData = JSON.parse(localStorage.getItem("deliveryData"));
						var isGuest = localStorage.getItem("isGuestLogin") ? true : false;

						// Convert GE Order to HC Receipt Model
						var receipt: any = {};

						receipt.Order = {};
						receipt.Order.OrderId = data.OrderId;
						receipt.Order.Created = new Date();
						receipt.CurrencyCode = data.details.MerchantCurrencyCode;
						receipt.CurrencyPrefix = deliveryData.Basket.CurrencyPrefix;
						receipt.Order.Total = data.details.OrderPrices.CustomerTransactionInMerchantCurrency.CustomerTotalPriceInMerchantCurrency;
						receipt.Order.ShippingTotal = data.details.OrderPrices.CustomerTransactionInMerchantCurrency.CustomerShippingPriceInMerchantCurrency;
						receipt.Order.Tax = data.details.OrderPrices.MerchantTransaction.TotalVAT;
						receipt.Order.ProductTax = receipt.Order.Tax;

						receipt.Order.SubTotal = data.details.OrderPrices.CustomerTransactionInMerchantCurrency.CustomerTotalProductsPriceInMerchantCurrency;
						receipt.Order.GrandTotal = receipt.Order.SubTotal;
						receipt.Order.DiscountTotal = data.details.OrderPrices.CustomerTransactionInMerchantCurrency.CustomerTotalProductsPriceInMerchantCurrency - data.details.OrderPrices.CustomerTransactionInMerchantCurrency.CustomerTotalDiscountedProductsPriceInMerchantCurrency;

						if (data.details.Discounts) {
							var promoCodeDiscount = data.details.Discounts.find(x => x.CouponCode);
							receipt.Order.PromoCode = promoCodeDiscount?.CouponCode;
						}

						receipt.WebStoreDefaultUrl = new URL(data.details.SiteURL).origin;

						receipt.Order.Customer = {};
						receipt.Order.Customer.CustomerId = data.details.CustomerDetails.MerchantUserId;
						receipt.Order.Customer.EmailAddress = deliveryData.Email; // data.details.CustomerDetails.BillingAddress?.BillingEmail OR deliveryData.Email (receipt.Order.Customer.EmailAddress)
						receipt.Order.Customer.Existing = deliveryData.Orders ? true : false; // (receipt.Order.Customer.Existing)

						receipt.Order.AddressShipping = {};
						receipt.Order.AddressShipping.FullName = data.details.CustomerDetails.ShippingAddress?.ShippingFirstName + " " + data.details.CustomerDetails.ShippingAddress?.ShippingLastName; // (receipt.Order.AddressShipping.FullName)
						receipt.Order.AddressShipping.Title = data.details.CustomerDetails.ShippingAddress?.ShippingSalutation; // (receipt.Order.AddressShipping.Title)
						receipt.Order.AddressShipping.FirstName = data.details.CustomerDetails.ShippingAddress?.ShippingFirstName; // (receipt.Order.AddressShipping.FirstName)
						receipt.Order.AddressShipping.LastName = data.details.CustomerDetails.ShippingAddress?.ShippingLastName; // (receipt.Order.AddressShipping.LastName)
						receipt.Order.AddressShipping.PostCode = data.details.CustomerDetails.ShippingAddress?.ShippingZipCode; // (receipt.Order.AddressShipping.PostCode)

						receipt.IsGuest = isGuest; // (receipt.IsGuest)

						receipt.Order.Shipping = {};
						receipt.Order.Shipping.ShippingTypeId = data.details.ShippingMethodName + "|" + data.details.ShippingMethodType; // (receipt.Order.Shipping.ShippingTypeId)

						receipt.Order.OrderItems = [];
						data.details.ProductInformation.forEach(function (element) {

							var basketItem = deliveryData.Basket.BasketItems.filter(x => x.Ean == element.SKU)[0];

							var orderItem: any = {};
							orderItem.Code = element.ProductGroupCode;
							orderItem.Name = element.ProductName;
							orderItem.LineSoldPrice = element.ProductPrices.CustomerTransactionInMerchantCurrency.CustomerSalePriceInMerchantCurrency * element.Quantity;
							orderItem.Quantity = element.Quantity;
							orderItem.ImagePath = basketItem?.ImagePath; // (element.ImagePath)
							orderItem.ProductFullUrl = receipt.WebStoreDefaultUrl + element.ProductURL;
							orderItem.ProductCategoryName = ""; // Empty Missing Data (element.ProductCategoryName)
							orderItem.PrimaryCategoryPath = ""; // Empty Missing Data (element.PrimaryCategoryPath)
							orderItem.Ean = element.SKU;

							receipt.Order.OrderItems.push(orderItem);
						});

						// Call GlobaleCheckoutComponent loadTrackingScript() method
						window["GlobaleCheckoutComponentReference"].zone.run(() => {
							window["GlobaleCheckoutComponentReference"].loadTrackingScript(PageTypeDimensionEnum.CheckoutReceipt);
						});

						// Populate gtm.js:InitialiseReceiptPageTracking => hc_gtm.js:
						InitialiseReceiptPageTracking(this.LpAppSetting.GeneralSettings, this.LpAppSetting.UserSettings, receipt);

						callLogCheckout(data, "CONFIRMATION COMPLETE (Populate gtm.js:InitialiseReceiptPageTracking => hc_gtm.js)");
					}

					break;
			}
		});
		//});
	}

	getSiteContent() {
		this.siteContent = this.siteContentService.getSiteContentData();

		if (!this.siteContent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.siteContent = data;

				this.titleService.setTitle(this.siteContent.Checkout);
			})
		} else {
			this.titleService.setTitle(this.siteContent.Checkout);
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.setGermanConfig();

		this.initialiseGleGemClientAnalytics();
	}

	setGermanConfig() {
		this.isGerman = this.LpAppSetting.WebStoreId === 2 ? true : false;

		// Add attribute to root html
		if (this.isGerman) {
			var htmlElement = document.querySelector("html");
			$(htmlElement).attr("data-ge-lang", "de");
		}
	}

	// Taken from CheckoutDeliveryComponent
	getDeliveryData() {

		let data = {
			isGuest: true,
		}

		if (!this.isGuest) {

			data.isGuest = false;
		}

		forkJoin({
			deliveryData: this.sharedService.getDeliveryDetails(data),
			webOrders: this.authService.getWebOrders()
		})
			.pipe(
				map(response => {
					const deliveryData = response.deliveryData;
					const webOrders = response.webOrders;
					return { deliveryData: deliveryData, webOrders: webOrders };
				})
			)
			.subscribe((res) => {
				// Since Global-E sometimes hang, we need to force reload
				if (res && res.deliveryData && res.deliveryData.result) {
					if (res.deliveryData.returnUrl && res.deliveryData.returnUrl === "shopping") {
						this.router.navigate(['/checkout/shoppingbasket']).then(() => {
							window.location.reload();
						});

						return;
					}

					if (res.deliveryData.returnUrl && res.deliveryData.returnUrl === "welcome") {
						this.router.navigate(['/checkout/welcome']).then(() => {
							window.location.reload();
						});

						return;
					}
				}

				if (res && res.deliveryData && res.deliveryData.Basket && res.deliveryData.Basket.BasketTotalItemCount == 0) {
					this.router.navigate(['/checkout/shoppingbasket']).then(() => {
						window.location.reload();
					});

					return;
				}

				var deliveryData = res.deliveryData;
				deliveryData.Orders = res.webOrders.Orders;

				// Keep this data for tracking
				localStorage.setItem("deliveryData", JSON.stringify(deliveryData));

				this.formLoaded = Promise.resolve(true);
			});
	}

	loadTrackingScript(pageTypeDimensionEnum: PageTypeDimensionEnum) {
		let trackingVm = {} as TrackingVM;
		trackingVm.PageTypeDimension = pageTypeDimensionEnum;

		this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
			this.scriptContent = this.sharedService.ParseScript(data);

			if (this.scriptContent && this.scriptContent.length > 0) {
				setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
			}
		});
	}
}
