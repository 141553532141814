import { Injectable } from '@angular/core';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { CookieService } from 'ngx-cookie';

import shouldIncludeWithCredentials from './helper/should-include-with-credentials';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
	constructor(
		@Inject(PLATFORM_ID)
		private platformId: string,
		private cookieService: CookieService
	) { }

	// Keep track of last sessionId being used so we don't flood the console
	private sessionId: string = '';

	getTimestamp() {
		return `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`;
	}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let newSessionId = this.cookieService.get('HCSESSION');
		let showLog = false;
		let startTime: Date = new Date();

		if (newSessionId != this.sessionId) {
			this.sessionId = newSessionId;
			showLog = true;
		}

		if (isPlatformBrowser(this.platformId)) {
			if (showLog) {
				console.log('Browser Session Cookie:', {
					sessionId: this.sessionId,
					request: request,
				});
			}
			if (shouldIncludeWithCredentials(request.url)) {
				request = request.clone({
					withCredentials: true,
				});
			}
		} else {
			if (showLog) {
				console.log('Server Session Cookie:', {
					sessionId: this.sessionId,
					request: request,
				});
			}
		}

		return next.handle(request).pipe(
			tap((response: HttpResponse<any>): void => {

				// Outputs the API calls request and time to console
				var requestTime = (new Date().valueOf() - startTime.valueOf());

				if (!isPlatformBrowser(this.platformId) && !showLog) {
					console.log(`${this.getTimestamp()} - ${requestTime}ms (${request.method})`, request.url);
				}
			}),
		);
	}
}
