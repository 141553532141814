export enum DeviceTypeEnum {
	Desktop = 1,
	Mobile = 2,
	Tablet = 3
}

export enum ViewPortEnum {
	Desktop = 'Desktop',
	Mobile = 'Mobile',
	Tablet = 'Tablet'
}
