import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildShoeGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		StepName1Text: stepName1Text,
		StepName2Text: stepName2Text,
		StepName3Text: stepName3Text,
		StepName4Text: stepName4Text,
		StepName5Text: stepName5Text,
		StepName6Text: stepName6Text,
		ShoeTitle: title,
		ShoeMobileBannerImageUrl: mobileBannerImage,
		ShoePolishTitle: polishTitle,
		ShoePolishStep1DesktopImageUrl: polishStep1DesktopImageUrl,
		ShoePolishStep1MobileImageUrl: polishStep1MobileImageUrl,
		ShoePolishStep2DesktopImageUrl: polishStep2DesktopImageUrl,
		ShoePolishStep2MobileImageUrl: polishStep2MobileImageUrl,
		ShoePolishStep3DesktopImageUrl: polishStep3DesktopImageUrl,
		ShoePolishStep3MobileImageUrl: polishStep3MobileImageUrl,
		ShoePolishStep4DesktopImageUrl: polishStep4DesktopImageUrl,
		ShoePolishStep4MobileImageUrl: polishStep4MobileImageUrl,
		ShoePolishStep5DesktopImageUrl: polishStep5DesktopImageUrl,
		ShoePolishStep5MobileImageUrl: polishStep5MobileImageUrl,
		ShoePolishStep6DesktopImageUrl: polishStep6DesktopImageUrl,
		ShoePolishStep6MobileImageUrl: polishStep6MobileImageUrl,
		ShoePolishDescription1: shoePolishDescription1,
		ShoePolishDescription2: shoePolishDescription2,
		ShoePolishDescription3: shoePolishDescription3,
		ShoePolishDescription4: shoePolishDescription4,
		ShoePolishDescription5: shoePolishDescription5,
		ShoePolishDescription6: shoePolishDescription6,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: polishTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						isActive: true,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: polishStep1DesktopImageUrl,
								mobile: polishStep1MobileImageUrl,
							},
							{
								desktop: polishStep2DesktopImageUrl,
								mobile: polishStep2MobileImageUrl,
							},
							{
								desktop: polishStep3DesktopImageUrl,
								mobile: polishStep3MobileImageUrl,
							},
							{
								desktop: polishStep4DesktopImageUrl,
								mobile: polishStep4MobileImageUrl,
							},
							{
								desktop: polishStep5DesktopImageUrl,
								mobile: polishStep5MobileImageUrl,
							},
							{
								desktop: polishStep6DesktopImageUrl,
								mobile: polishStep6MobileImageUrl,
							},
						],
						imageDetails: [
							{ name: stepName1Text, description: shoePolishDescription1 },
							{ name: stepName2Text, description: shoePolishDescription2 },
							{ name: stepName3Text, description: shoePolishDescription3 },
							{ name: stepName4Text, description: shoePolishDescription4 },
							{ name: stepName5Text, description: shoePolishDescription5 },
							{ name: stepName6Text, description: shoePolishDescription6 },
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildShoeGuideViewModel;
