import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { SiteContentDetail } from './models/siteContent';
const CACHE_SIZE = 1;

@Injectable({
	providedIn: 'root'
})
export class SiteContentService {

	siteContent: SiteContentDetail;

	private siteContent$: any;

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) {
	}

	requestSiteContent() {
		return this.httpClient.get<SiteContentDetail>(this.environmentService.environment.siteUrl + 'sitecontentdetails', this.httpOptions).pipe(
			map(response => { this.siteContent = response; return response })
		);
	}

	getSiteContent(): Observable<SiteContentDetail> {
		if (!this.siteContent$) {
			this.siteContent$ = this.requestSiteContent().pipe(
				shareReplay({ bufferSize: 1, refCount: true })
			);
		}
		return this.siteContent$;
	}

	setSiteContent() {
		this.getSiteContent().subscribe(data => {
			this.siteContent = data;
		})
	}

	getSiteContentData(): SiteContentDetail {
		return this.siteContent;
	}
}
