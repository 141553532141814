import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { ViewportService } from 'src/app/shared/viewport.service';
import { ScrollService } from 'src/app/shared/service/scroll.service';
import { SharedService } from 'src/app/shared/shared.service';
import { FitGuide as FitGuideAction } from 'src/app/shared/state/fit-guide/fit-guide.action';
import { IFitGuideLandingVM } from 'src/app/shared/models/fit-guide';
import buildFitGuideLandingPageViewModel from 'src/app/shared/helper/fit-guide/build-fit-guide-landing-page-view-model';

@Component({
	selector: 'app-fit-guide-landing-page',
	templateUrl: './fit-guide-landing-page.component.html',
	styleUrls: ['./fit-guide-landing-page.component.scss'],
})
export class FitGuideLandingPageComponent implements OnInit {
	fitGuideLandingPageVM: IFitGuideLandingVM;
	deviceType: 'Mobile' | 'Tablet' | 'Desktop';

	constructor(
		private router: Router,
		private siteContentService: SiteContentService,
		private viewportService: ViewportService,
		private scrollService: ScrollService,
		private sharedService: SharedService,
		private store: Store
	) {}

	async ngOnInit(): Promise<void> {
		this.deviceType = this.viewportService.getDeviceType();
		const siteContentData = this.siteContentService.getSiteContentData();
		const appSettings = await this.sharedService.getLpAppSettings();
		if (appSettings?.FitGuideSettings && siteContentData) {
			this.fitGuideLandingPageVM = buildFitGuideLandingPageViewModel(
				siteContentData,
				appSettings.FitGuideSettings
			);
		}

		this.store.dispatch(new FitGuideAction.ResetStateToDefault());

		this.scrollService.windowScrollTo(0, 0);
	}

	navigateToRoute(routeLink: string): void {
		this.router.navigate([`${this.router.url}/${routeLink}`]);
	}
}
