import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultModule } from './modules/layout/default.module';
import { BrowserStateInterceptor } from './shared/browser-state-interceptor';
import { LoaderInterceptor } from './shared/components/loader/loader-interceptor.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoaderService } from './shared/components/loader/loader.service';
import { CookieInterceptor } from './shared/cookie-interceptor-service';
import { MyErrorHandler } from './shared/error-handler';
import { ScriptService } from './shared/service/script.service';
import { ScrollService } from './shared/service/scroll.service';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { FacetFilterState } from './shared/state/facet-filter/facet-filter.state';
import { FitGuideState } from './shared/state/fit-guide/fit-guide.state';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {

		// Optional Step: Do some stuff with the url if needed.
		let urlExist = url;
		if (urlExist.toLocaleLowerCase().indexOf("/webservice/refreshcontent") > -1) {

			return super.parse(urlExist.toLocaleLowerCase());
		}

		// If you lower it in the optional step
		// you don't need to use "toLowerCase"
		// when you pass it down to the next function
		return super.parse(url);
	}

	serialize(tree: UrlTree): any {

		const dus = new DefaultUrlSerializer();
		let path = dus.serialize(tree);

		// use your regex to replace as per your requirement.
		path = path.replace(/%3F/g, '?');
		path = path.replace(/%3D/g, '=');
		path = path.replace(/%23/g, '#');
		path = path.replace(/%25/g, "%");
		return path;
	}
}

//const initAppFn = (envService: EnvironmentService) => {
//	return () => envService.loadEnvironmentConfig("/app-config.json");
//};

@NgModule({
	declarations: [
		AppComponent,
		LoaderComponent

	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		DefaultModule,
		AppRoutingModule,
		DataTablesModule,
		CookieModule.withOptions(),
		NgxsModule.forRoot([FacetFilterState, FitGuideState], { developmentMode: false }),
		NgxsStoragePluginModule.forRoot({ key: ['facetFilter', 'fitGuide']})
	],
	exports: [
		LoaderComponent
	],
	providers: [Title,
		LoaderService,
		ScrollService,
		ScriptService,
		//EnvironmentService,
		//{
		//	provide: APP_INITIALIZER,
		//	useFactory: initAppFn,
		//	multi: true,
		//	deps: [EnvironmentService],
		//},
		{ provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BrowserStateInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer
		},
		{ provide: ErrorHandler, useClass: MyErrorHandler },
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
