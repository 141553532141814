export enum PageTypeDimensionEnum {
    None = 0,
    Home = 1,
    //CategoryLanding = 2,
    ProductList = 3,
    ProductDetails = 4,
    ShoppingBasket = 5,
    AccountSignIn = 6,
    //AccountRegister = 7,
    //AccountEditPersonalDetails = 8,
    //Error = 9,
    //Error404 = 10,
    //SearchResults = 11,
    //SearchUnsuccessful = 12,
    //StoreLocator = 13,
    //CheckoutWelcome = 14,
    CheckoutDelivery = 15,
    //GuestCheckoutDelivery = 16,
    //CheckoutCollectFromStore = 17,
    //GuestCheckoutCollectFromStore = 18,
    CheckoutPayment = 19,
    //GuestCheckoutPayment = 20,
    CheckoutReceipt = 21,
    //GuestCheckoutReceipt = 22,
    //AccountMyAccount = 23,
    //AccountEditAddressBook = 24,
    //AccountViewOrders = 25,
    //UnassignedWebPage = 26,
    //WebPage = 27,
    //KlarnaPayment = 28,
    //PayPalQuickConfirm = 29
    LandingPage = 30,
    UserRegistered = 31,
    UserSubscribed = 32,
    MyAccount = 33,
}
