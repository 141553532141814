import { AfterViewInit, Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PromoLineBarEnum } from 'src/app/enums/promo-line-bar-enum';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { UserSettings } from 'src/app/shared/models/user-settings';
import { SharedService } from 'src/app/shared/shared.service';
import { CheckoutSwitcherService } from '../../checkout-switcher.service';
import { ScrollService } from '../../service/scroll.service';
import { SiteContentService } from '../../sitecontent.service';
import { LpAppSetting } from 'src/app/model/lpapp-setting';

@Component({
	selector: 'app-checkout-header',
	templateUrl: './checkout-header.component.html',
	styleUrls: ['./checkout-header.component.scss']
})
export class CheckoutHeaderComponent implements OnInit, AfterViewInit {
	userSettings: UserSettings;
	sitecontent: SiteContentDetail;
	promoLineBarCheckoutHeaderHtml: string;
	promoLineBarCheckoutHeaderBgHexColour: string;
	promoLineBarCheckoutHeaderTextHexColour: string;
	lpAppSettings: LpAppSetting;
	isGlobalEEnabled$: Observable<boolean>
	@ViewChild('promotext') promotext: ElementRef<HTMLInputElement>;

	constructor(
		private router: Router,
		private sharedService: SharedService,
		private route: ActivatedRoute,
		private scrollService: ScrollService,
		private sanitizer: DomSanitizer,
		private siteContentService: SiteContentService,
		private checkoutSwitcherService: CheckoutSwitcherService) {
	}

	ngOnInit() {
		this.getSiteContent();
		this.getUserSettings();
		this.isGlobalEEnabled();
		this.renderPromoLineBar();
	}

	ngAfterViewInit() {
		
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();
		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	private async getUserSettings(): Promise<void> {
		this.lpAppSettings = await this.sharedService.getLpAppSettings();
		if (this.lpAppSettings?.UserSettings) {
			this.userSettings = this.lpAppSettings.UserSettings;
		}
	}

	private async renderPromoLineBar(): Promise<void> {
		this.lpAppSettings = this.sharedService.getLpAppSettingsData();
		if (!this.lpAppSettings) {
			this.lpAppSettings = await this.sharedService.getLpAppSettings();
		}

		this.promoLineBarCheckoutHeaderHtml = this.lpAppSettings.UserSettings.PromoLineBarHeaderCheckout;
		this.promoLineBarCheckoutHeaderBgHexColour = this.lpAppSettings.UserSettings.PromoLineBarHeaderCheckout_BgHexColour;
		this.promoLineBarCheckoutHeaderTextHexColour = this.lpAppSettings.UserSettings.PromoLineBarHeaderCheckout_TextHexColour;

		let param = window.location.search.substr(1);

		this.sharedService.RenderPromoLineBar(param, PromoLineBarEnum.HeaderCheckout).subscribe(response => {
			if (response && response.MarketingTag) {
				this.promoLineBarCheckoutHeaderHtml = response.MarketingTag.Content.PromoLineBarHeaderCheckout ? response.MarketingTag.Content.PromoLineBarHeaderCheckout : this.promoLineBarCheckoutHeaderHtml;
				this.promoLineBarCheckoutHeaderBgHexColour = response.MarketingTag.Content.PromoLineBarHeaderCheckout_BgHexColour ? response.MarketingTag.Content.PromoLineBarHeaderCheckout_BgHexColour : this.promoLineBarCheckoutHeaderBgHexColour;
				this.promoLineBarCheckoutHeaderTextHexColour = response.MarketingTag.Content.PromoLineBarHeaderCheckout_TextHexColour ? response.MarketingTag.Content.PromoLineBarHeaderCheckout_TextHexColour : this.promoLineBarCheckoutHeaderTextHexColour;
			}
		});
	}

	getPromoTextColour(bgColor, promoTxtColour) {
		if (promoTxtColour) {
			var htmlElement = (document.querySelector('.promo-line') as HTMLElement);

			// Null check
			if (htmlElement != null) {
				htmlElement.style.setProperty('--promo-text-color', promoTxtColour);

				let styles = {
					'background-color': bgColor,
					'--promo-text-color': promoTxtColour
				};

				let style: string = `.promo-line {--promo-text-color :${promoTxtColour ? promoTxtColour : ''}; }`;

				this.createStyle(style);
				return styles;
			}
		}

		return '';
	}

	createStyle(style: string): void {
		const styleElement = document.createElement('style');
		styleElement.appendChild(document.createTextNode(style));

		if (this.promotext && this.promotext.nativeElement) {
			this.promotext.nativeElement.appendChild(styleElement);
		}
	}

	logoClicked() {
		this.scrollService.windowScrollTo(0, 0);
	}

	isGlobalEEnabled() {
		this.isGlobalEEnabled$ = this.checkoutSwitcherService.isGlobalEEnabled();
	}

	getContent(content: string) {
		if (content) {
			return this.sanitizer.sanitize(SecurityContext.HTML, content);
		}

		return content;
	}
}
