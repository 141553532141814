import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';
import { of, tap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LikedProductService {

	httpOptions = {
		headers: new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
		})
	};

	constructor(private httpClient: HttpClient,
		private environmentService: EnvironmentService) { }


	private cachedProductData: { [key: string]: any } = {};

	GetProductDetailsNotLoggedIn(productIds: number[]) {

		const cachedData = this.getCachedDataForIds(productIds);

		if (cachedData.length === productIds.length) {
			return of({ desc: cachedData });
		} else {
			return this.httpClient.post<any>(this.environmentService.environment.accountUrl + "LikedItemsData", productIds, this.httpOptions).pipe(
				tap(response => {
					this.cacheProductData(productIds, response);
				})
			);
		}
	}

	private getCachedDataForIds(productIds: number[]): any[] {
		return productIds.map(id => this.cachedProductData[id]).filter(data => data !== undefined);
	}

	private cacheProductData(productIds: number[], response: any): void {
		productIds.forEach((id, index) => {
			this.cachedProductData[id] = response.desc[index];
		});
	}

	getLikedProductforUser(productIds: number[]) {
		return this.httpClient.post<any>(this.environmentService.environment.accountUrl + "GetLikedProducts", productIds, this.httpOptions
		);
	}

	AddtoLikedProductperUser(productId) {
		return this.httpClient.get<any>(this.environmentService.environment.basketUrl + "addtowishlist/?productid=" + productId + "&fromUrl=''", this.httpOptions
		);
	}

	RemovefromLikedProductperUser(productId) {
		return this.httpClient.get<any>(this.environmentService.environment.basketUrl + "RemoveFromWishlist/?productid=" + productId + "&fromUrl=''", this.httpOptions
		);
	}

}
