import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildMenShirtGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		StepName1Text: stepName1Text,
		StepName2Text: stepName2Text,
		StepName3Text: stepName3Text,
		StepName4Text: stepName4Text,
		StepName5Text: stepName5Text,
		TextBetweenCarouselArrowsFront: txtFront,
		TextBetweenCarouselArrowsLeft: txtLeft,
		TextBetweenCarouselArrowsRight: txtRight,
		TextBetweenCarouselArrowsBack: txtBack,
		MeasuredInInchesText: measuredInInchesText,
		MenShirtTitle: title,
		MenShirtMobileBannerImageUrl: mobileBannerImage,
		MenShirtFitTitle: fitTitle,
		MenShirtFitCompareFitPageText: compareFitPageText,
		MenShirtFitCompareFitPageDescription: compareFitPageDescription,
		MenShirtFitExtraSlimName: fitExtraSlimName,
		MenShirtFitExtraSlimSubFitGuideSectionText: extraSlimSubFitGuideText,
		MenShirtFitExtraSlimDescription1: fitExtraSlimDescription1,
		MenShirtFitExtraSlimDescription2: fitExtraSlimDescription2,
		MenShirtFitExtraSlimDescription3: fitExtraSlimDescription3,
		MenShirtFitExtraSlimPlpLink: fitExtraSlimPlpLink,
		MenShirtFitExtraSlimVideoUrl: fitExtraSlimVideoUrl,
		MenShirtFitExtraSlimDesktopImageUrl1: fitExtraSlimDesktopImageUrl1,
		MenShirtFitExtraSlimMobileImageUrl1: fitExtraSlimMobileImageUrl1,
		MenShirtFitExtraSlimDesktopImageUrl2: fitExtraSlimDesktopImageUrl2,
		MenShirtFitExtraSlimMobileImageUrl2: fitExtraSlimMobileImageUrl2,
		MenShirtFitExtraSlimDesktopImageUrl3: fitExtraSlimDesktopImageUrl3,
		MenShirtFitExtraSlimMobileImageUrl3: fitExtraSlimMobileImageUrl3,
		MenShirtFitExtraSlimDesktopImageUrl4: fitExtraSlimDesktopImageUrl4,
		MenShirtFitExtraSlimMobileImageUrl4: fitExtraSlimMobileImageUrl4,
		MenShirtFitFittedSlimName: fitFittedSlimName,
		MenShirtFitFittedSlimSubFitGuideSectionText: fittedSlimSubFitGuideText,
		MenShirtFitFittedSlimDescription1: fitFittedSlimDescription1,
		MenShirtFitFittedSlimDescription2: fitFittedSlimDescription2,
		MenShirtFitFittedSlimDescription3: fitFittedSlimDescription3,
		MenShirtFitFittedSlimPlpLink: fitFittedSlimPlpLink,
		MenShirtFitFittedSlimVideoUrl: fitFittedSlimVideoUrl,
		MenShirtFitFittedSlimDesktopImageUrl1: fitFittedSlimDesktopImageUrl1,
		MenShirtFitFittedSlimMobileImageUrl1: fitFittedSlimMobileImageUrl1,
		MenShirtFitFittedSlimDesktopImageUrl2: fitFittedSlimDesktopImageUrl2,
		MenShirtFitFittedSlimMobileImageUrl2: fitFittedSlimMobileImageUrl2,
		MenShirtFitFittedSlimDesktopImageUrl3: fitFittedSlimDesktopImageUrl3,
		MenShirtFitFittedSlimMobileImageUrl3: fitFittedSlimMobileImageUrl3,
		MenShirtFitFittedSlimDesktopImageUrl4: fitFittedSlimDesktopImageUrl4,
		MenShirtFitFittedSlimMobileImageUrl4: fitFittedSlimMobileImageUrl4,
		MenShirtFitSlimName: fitSlimName,
		MenShirtFitSlimSubFitGuideSectionText: slimSubFitGuideText,
		MenShirtFitSlimDescription1: fitSlimDescription1,
		MenShirtFitSlimDescription2: fitSlimDescription2,
		MenShirtFitSlimDescription3: fitSlimDescription3,
		MenShirtFitSlimPlpLink: fitSlimPlpLink,
		MenShirtFitSlimVideoUrl: fitSlimVideoUrl,
		MenShirtFitSlimDesktopImageUrl1: fitSlimDesktopImageUrl1,
		MenShirtFitSlimMobileImageUrl1: fitSlimMobileImageUrl1,
		MenShirtFitSlimDesktopImageUrl2: fitSlimDesktopImageUrl2,
		MenShirtFitSlimMobileImageUrl2: fitSlimMobileImageUrl2,
		MenShirtFitSlimDesktopImageUrl3: fitSlimDesktopImageUrl3,
		MenShirtFitSlimMobileImageUrl3: fitSlimMobileImageUrl3,
		MenShirtFitSlimDesktopImageUrl4: fitSlimDesktopImageUrl4,
		MenShirtFitSlimMobileImageUrl4: fitSlimMobileImageUrl4,
		MenShirtFitClassicName: fitClassicName,
		MenShirtFitClassicSubFitGuideSectionText: classicSubFitGuideText,
		MenShirtFitClassicDescription1: fitClassicDescription1,
		MenShirtFitClassicDescription2: fitClassicDescription2,
		MenShirtFitClassicDescription3: fitClassicDescription3,
		MenShirtFitClassicPlpLink: fitClassicPlpLink,
		MenShirtFitClassicVideoUrl: fitClassicVideoUrl,
		MenShirtFitClassicDesktopImageUrl1: fitClassicDesktopImageUrl1,
		MenShirtFitClassicMobileImageUrl1: fitClassicMobileImageUrl1,
		MenShirtFitClassicDesktopImageUrl2: fitClassicDesktopImageUrl2,
		MenShirtFitClassicMobileImageUrl2: fitClassicMobileImageUrl2,
		MenShirtFitClassicDesktopImageUrl3: fitClassicDesktopImageUrl3,
		MenShirtFitClassicMobileImageUrl3: fitClassicMobileImageUrl3,
		MenShirtFitClassicDesktopImageUrl4: fitClassicDesktopImageUrl4,
		MenShirtFitClassicMobileImageUrl4: fitClassicMobileImageUrl4,
		MenShirtMeasurementTitle: measurementTitle,
		MenShirtMeasurementCollarName: measurementCollarName,
		MenShirtMeasurementCollarPlpLink: measurementCollarPlpLink,
		MenShirtMeasurementCollarVideoUrl: measurementCollarVideoUrl,
		MenShirtMeasurementCollarStepDescription1: measurementCollarDescription1,
		MenShirtMeasurementCollarStepDescription2: measurementCollarDescription2,
		MenShirtMeasurementCollarStepDescription3: measurementCollarDescription3,
		MenShirtMeasurementCollarDesktopImageUrl: measurementCollarDesktopImageUrl,
		MenShirtMeasurementCollarMobileImageUrl: measurementCollarMobileImageUrl,
		MenShirtMeasurementChestCircumferenceName: measurementChestName,
		MenShirtMeasurementChestCircumferencePlpLink: measurementChestPlpLink,
		MenShirtMeasurementChestCircumferenceVideoUrl: measurementChestVideoUrl,
		MenShirtMeasurementChestCircumferenceStepDescription1:
			measurementChestStepDescription1,
		MenShirtMeasurementChestCircumferenceStepDescription2:
			measurementChestStepDescription2,
		MenShirtMeasurementChestCircumferenceStepDescription3:
			measurementChestStepDescription3,
		MenShirtMeasurementChestCircumferenceDesktopImageUrl:
			measurementChestDesktopImageUrl,
		MenShirtMeasurementChestCircumferenceMobileImageUrl:
			measurementChestMobileImageUrl,
		MenShirtMeasurementWaistName: measurementWaistName,
		MenShirtMeasurementWaistPlpLink: measurementWaistPlpLink,
		MenShirtMeasurementWaistVideoUrl: measurementWaistVideoUrl,
		MenShirtMeasurementWaistStepDescription1: measurementWaistStepDescription1,
		MenShirtMeasurementWaistStepDescription2: measurementWaistStepDescription2,
		MenShirtMeasurementWaistStepDescription3: measurementWaistStepDescription3,
		MenShirtMeasurementWaistDesktopImageUrl: measurementWaistDesktopImageUrl,
		MenShirtMeasurementWaistMobileImageUrl: measurementWaistMobileImageUrl,
		MenShirtMeasurementShoulderWidthName: measurementShoulderWidthName,
		MenShirtMeasurementShoulderWidthPlpLink: measurementShoulderWidthPlpLink,
		MenShirtMeasurementShoulderWidthVideoUrl: measurementShoulderWidthVideoUrl,
		MenShirtMeasurementShoulderWidthStepDescription1:
			measurementShoulderWidthStepDescription1,
		MenShirtMeasurementShoulderWidthStepDescription2:
			measurementShoulderWidthStepDescription2,
		MenShirtMeasurementShoulderWidthStepDescription3:
			measurementShoulderWidthStepDescription3,
		MenShirtMeasurementShoulderWidthDesktopImageUrl:
			measurementShoulderWidthDesktopImageUrl,
		MenShirtMeasurementShoulderWidthMobileImageUrl:
			measurementShoulderWidthMobileImageUrl,
		MenShirtMeasurementBackLengthName: measurementBackLengthName,
		MenShirtMeasurementBackLengthPlpLink: measurementBackLengthPlpLink,
		MenShirtMeasurementBackLengthVideoUrl: measurementBackLengthVideoUrl,
		MenShirtMeasurementBackLengthStepDescription1:
			measurementBackLengthStepDescription1,
		MenShirtMeasurementBackLengthStepDescription2:
			measurementBackLengthStepDescription2,
		MenShirtMeasurementBackLengthStepDescription3:
			measurementBackLengthStepDescription3,
		MenShirtMeasurementBackLengthDesktopImageUrl:
			measurementBackLengthDesktopImageUrl,
		MenShirtMeasurementBackLengthMobileImageUrl:
			measurementBackLengthMobileImageUrl,
		MenShirtMeasurementSleeveLengthName: measurementSleeveLengthName,
		MenShirtMeasurementSleeveLengthPlpLink: measurementSleeveLengthPlpLink,
		MenShirtMeasurementSleeveLengthVideoUrl: measurementSleeveLengthVideoUrl,
		MenShirtMeasurementSleeveLengthStepDescription1:
			measurementSleeveLengthStepDescription1,
		MenShirtMeasurementSleeveLengthStepDescription2:
			measurementSleeveLengthStepDescription2,
		MenShirtMeasurementSleeveLengthStepDescription3:
			measurementSleeveLengthStepDescription3,
		MenShirtMeasurementSleeveLengthStepDescription4:
			measurementSleeveLengthStepDescription4,
		MenShirtMeasurementSleeveLengthStepDescription5:
			measurementSleeveLengthStepDescription5,
		MenShirtMeasurementSleeveLengthDesktopImageUrl:
			measurementSleeveLengthDesktopImageUrl,
		MenShirtMeasurementSleeveLengthMobileImageUrl:
			measurementSleeveLengthMobileImageUrl,
		MenShirtCuffTitle: cuffTitle,
		MenShirtCuffSingleName: cuffSingleName,
		MenShirtCuffSingleDescription: cuffSingleDescription,
		MenShirtCuffSinglePlpLink: cuffSinglePlpLink,
		MenShirtCuffSingleDesktopImageUrl: cuffSingleDesktopImageUrl,
		MenShirtCuffSingleMobileImageUrl: cuffSingleMobileImageUrl,
		MenShirtCuffDoubleName: cuffDoubleName,
		MenShirtCuffDoubleDescription: cuffDoubleDescription,
		MenShirtCuffDoublePlpLink: cuffDoublePlpLink,
		MenShirtCuffDoubleDesktopImageUrl: cuffDoubleDesktopImageUrl,
		MenShirtCuffDoubleMobileImageUrl: cuffDoubleMobileImageUrl,
		MenShirtCollarTitle: collarTitle,
		MenShirtCollarFullCutawayName: collarFullName,
		MenShirtCollarFullCutawayDescription: collarFullDescription,
		MenShirtCollarFullCutawayPlpLink: collarFullPlpLink,
		MenShirtCollarFullCutawayDesktopImageUrl: collarFullDesktopImageUrl,
		MenShirtCollarFullCutawayMobileImageUrl: collarFullMobileImageUrl,
		MenShirtCollarSemiCutawayName: collarSemiName,
		MenShirtCollarSemiCutawayDescription: collarSemiDescription,
		MenShirtCollarSemiCutawayPlpLink: collarSemiPlpLink,
		MenShirtCollarSemiCutawayDesktopImageUrl: collarSemiDesktopImageUrl,
		MenShirtCollarSemiCutawayMobileImageUrl: collarSemiMobileImageUrl,
		MenShirtCollarHighName: collarHighName,
		MenShirtCollarHighDescription: collarHighDescription,
		MenShirtCollarHighPlpLink: collarHighPlpLink,
		MenShirtCollarHighDesktopImageUrl: collarHighDesktopImageUrl,
		MenShirtCollarHighMobileImageUrl: collarHighMobileImageUrl,
		MenShirtCollarMidName: collarMidName,
		MenShirtCollarMidDescription: collarMidDescription,
		MenShirtCollarMidPlpLink: collarMidPlpLink,
		MenShirtCollarMidDesktopImageUrl: collarMidDesktopImageUrl,
		MenShirtCollarMidMobileImageUrl: collarMidMobileImageUrl,
		MenShirtCollarButtonDownName: collarButtonDownName,
		MenShirtCollarButtonDownDescription: collarButtonDownDescription,
		MenShirtCollarButtonDownPlpLink: collarButtonDownPlpLink,
		MenShirtCollarButtonDownDesktopImageUrl: collarButtonDownDesktopImageUrl,
		MenShirtCollarButtonDownMobileImageUrl: collarButtonDownMobileImageUrl,
		MenShirtWeaveTitle: weaveTitle,
		MenShirtWeavePoplinName: poplinName,
		MenShirtWeavePoplinDescription: poplinDescription,
		MenShirtWeavePoplinPlpLink: poplinPlpLink,
		MenShirtWeavePoplinDesktopImageUrl: poplinDesktopImageUrl,
		MenShirtWeavePoplinMobileImageUrl: poplinMobileImageUrl,
		MenShirtWeaveHerringboneName: herringboneName,
		MenShirtWeaveHerringboneDescription: herringboneDescription,
		MenShirtWeaveHerringbonePlpLink: herringbonePlpLink,
		MenShirtWeaveHerringboneDesktopImageUrl: herringboneDesktopImageUrl,
		MenShirtWeaveHerringboneMobileImageUrl: herringboneMobileImageUrl,
		MenShirtWeaveTwillName: twillName,
		MenShirtWeaveTwillDescription: twillDescription,
		MenShirtWeaveTwillPlpLink: twillPlpLink,
		MenShirtWeaveTwillDesktopImageUrl: twillDesktopImageUrl,
		MenShirtWeaveTwillMobileImageUrl: twillMobileImageUrl,
		MenShirtWeaveDobbyName: dobbyName,
		MenShirtWeaveDobbyDescription: dobbyDescription,
		MenShirtWeaveDobbyPlpLink: dobbyPlpLink,
		MenShirtWeaveDobbyDesktopImageUrl: dobbyDesktopImageUrl,
		MenShirtWeaveDobbyMobileImageUrl: dobbyMobileImageUrl,
		MenShirtWeavePiqueName: piqueName,
		MenShirtWeavePiqueDescription: piqueDescription,
		MenShirtWeavePiquePlpLink: piquePlpLink,
		MenShirtWeavePiqueDesktopImageUrl: piqueDesktopImageUrl,
		MenShirtWeavePiqueMobileImageUrl: piqueMobileImageUrl,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: fitTitle,
				subFitGuidePageText: compareFitPageText,
				subFitGuidePageDescription: compareFitPageDescription,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: fitExtraSlimName,
						subFitGuideSectionText: extraSlimSubFitGuideText,
						description1: fitExtraSlimDescription1,
						description2: fitExtraSlimDescription2,
						description3: fitExtraSlimDescription3,
						description4: measuredInInchesText,
						plpLink: fitExtraSlimPlpLink,
						videoLink: fitExtraSlimVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: false,
						images: [
							{
								desktop: fitExtraSlimDesktopImageUrl1,
								mobile: fitExtraSlimMobileImageUrl1,
							},
							{
								desktop: fitExtraSlimDesktopImageUrl2,
								mobile: fitExtraSlimMobileImageUrl2,
							},
							{
								desktop: fitExtraSlimDesktopImageUrl3,
								mobile: fitExtraSlimMobileImageUrl3,
							},
							{
								desktop: fitExtraSlimDesktopImageUrl4,
								mobile: fitExtraSlimMobileImageUrl4,
							},
						],
					},
					{
						name: fitFittedSlimName,
						subFitGuideSectionText: fittedSlimSubFitGuideText,
						description1: fitFittedSlimDescription1,
						description2: fitFittedSlimDescription2,
						description3: fitFittedSlimDescription3,
						description4: measuredInInchesText,
						plpLink: fitFittedSlimPlpLink,
						videoLink: fitFittedSlimVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: true,
						images: [
							{
								desktop: fitFittedSlimDesktopImageUrl1,
								mobile: fitFittedSlimMobileImageUrl1,
							},
							{
								desktop: fitFittedSlimDesktopImageUrl2,
								mobile: fitFittedSlimMobileImageUrl2,
							},
							{
								desktop: fitFittedSlimDesktopImageUrl3,
								mobile: fitFittedSlimMobileImageUrl3,
							},
							{
								desktop: fitFittedSlimDesktopImageUrl4,
								mobile: fitFittedSlimMobileImageUrl4,
							},
						],
					},
					{
						name: fitSlimName,
						subFitGuideSectionText: slimSubFitGuideText,
						description1: fitSlimDescription1,
						description2: fitSlimDescription2,
						description3: fitSlimDescription3,
						description4: measuredInInchesText,
						plpLink: fitSlimPlpLink,
						videoLink: fitSlimVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: false,
						images: [
							{
								desktop: fitSlimDesktopImageUrl1,
								mobile: fitSlimMobileImageUrl1,
							},
							{
								desktop: fitSlimDesktopImageUrl2,
								mobile: fitSlimMobileImageUrl2,
							},
							{
								desktop: fitSlimDesktopImageUrl3,
								mobile: fitSlimMobileImageUrl3,
							},
							{
								desktop: fitSlimDesktopImageUrl4,
								mobile: fitSlimMobileImageUrl4,
							},
						],
					},
					{
						name: fitClassicName,
						subFitGuideSectionText: classicSubFitGuideText,
						description1: fitClassicDescription1,
						description2: fitClassicDescription2,
						description3: fitClassicDescription3,
						description4: measuredInInchesText,
						plpLink: fitClassicPlpLink,
						videoLink: fitClassicVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: false,
						images: [
							{
								desktop: fitClassicDesktopImageUrl1,
								mobile: fitClassicMobileImageUrl1,
							},
							{
								desktop: fitClassicDesktopImageUrl2,
								mobile: fitClassicMobileImageUrl2,
							},
							{
								desktop: fitClassicDesktopImageUrl3,
								mobile: fitClassicMobileImageUrl3,
							},
							{
								desktop: fitClassicDesktopImageUrl4,
								mobile: fitClassicMobileImageUrl4,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: measurementTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: measurementCollarName,
						plpLink: measurementCollarPlpLink,
						videoLink: measurementCollarVideoUrl,
						isActive: false,
						images: [
							{
								desktop: measurementCollarDesktopImageUrl,
								mobile: measurementCollarMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementCollarDescription1,
							},
							{
								name: stepName2Text,
								description: measurementCollarDescription2,
							},
							{
								name: stepName3Text,
								description: measurementCollarDescription3,
							},
						],
					},
					{
						name: measurementChestName,
						plpLink: measurementChestPlpLink,
						videoLink: measurementChestVideoUrl,
						isActive: false,
						images: [
							{
								desktop: measurementChestDesktopImageUrl,
								mobile: measurementChestMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementChestStepDescription1,
							},
							{
								name: stepName2Text,
								description: measurementChestStepDescription2,
							},
							{
								name: stepName3Text,
								description: measurementChestStepDescription3,
							},
						],
					},
					{
						name: measurementWaistName,
						plpLink: measurementWaistPlpLink,
						videoLink: measurementWaistVideoUrl,
						isActive: false,
						images: [
							{
								desktop: measurementWaistDesktopImageUrl,
								mobile: measurementWaistMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementWaistStepDescription1,
							},
							{
								name: stepName2Text,
								description: measurementWaistStepDescription2,
							},
							{
								name: stepName3Text,
								description: measurementWaistStepDescription3,
							},
						],
					},
					{
						name: measurementShoulderWidthName,
						plpLink: measurementShoulderWidthPlpLink,
						videoLink: measurementShoulderWidthVideoUrl,
						isActive: false,
						images: [
							{
								desktop: measurementShoulderWidthDesktopImageUrl,
								mobile: measurementShoulderWidthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementShoulderWidthStepDescription1,
							},
							{
								name: stepName2Text,
								description: measurementShoulderWidthStepDescription2,
							},
							{
								name: stepName3Text,
								description: measurementShoulderWidthStepDescription3,
							},
						],
					},
					{
						name: measurementBackLengthName,
						plpLink: measurementBackLengthPlpLink,
						videoLink: measurementBackLengthVideoUrl,
						isActive: false,
						images: [
							{
								desktop: measurementBackLengthDesktopImageUrl,
								mobile: measurementBackLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementBackLengthStepDescription1,
							},
							{
								name: stepName2Text,
								description: measurementBackLengthStepDescription2,
							},
							{
								name: stepName3Text,
								description: measurementBackLengthStepDescription3,
							},
						],
					},
					{
						name: measurementSleeveLengthName,
						plpLink: measurementSleeveLengthPlpLink,
						videoLink: measurementSleeveLengthVideoUrl,
						isActive: true,
						images: [
							{
								desktop: measurementSleeveLengthDesktopImageUrl,
								mobile: measurementSleeveLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measurementSleeveLengthStepDescription1,
							},
							{
								name: stepName2Text,
								description: measurementSleeveLengthStepDescription2,
							},
							{
								name: stepName3Text,
								description: measurementSleeveLengthStepDescription3,
							},
							{
								name: stepName4Text,
								description: measurementSleeveLengthStepDescription4,
							},
							{
								name: stepName5Text,
								description: measurementSleeveLengthStepDescription5,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start vw-97',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: cuffTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: cuffSingleName,
						description1: cuffSingleDescription,
						plpLink: cuffSinglePlpLink,
						isActive: false,
						images: [
							{
								desktop: cuffSingleDesktopImageUrl,
								mobile: cuffSingleMobileImageUrl,
							},
						],
					},
					{
						name: cuffDoubleName,
						description1: cuffDoubleDescription,
						plpLink: cuffDoublePlpLink,
						isActive: true,
						images: [
							{
								desktop: cuffDoubleDesktopImageUrl,
								mobile: cuffDoubleMobileImageUrl,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: collarTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: collarFullName,
						description1: collarFullDescription,
						plpLink: collarFullPlpLink,
						isActive: false,
						images: [
							{
								desktop: collarFullDesktopImageUrl,
								mobile: collarFullMobileImageUrl,
							},
						],
					},
					{
						name: collarSemiName,
						description1: collarSemiDescription,
						plpLink: collarSemiPlpLink,
						isActive: true,
						images: [
							{
								desktop: collarSemiDesktopImageUrl,
								mobile: collarSemiMobileImageUrl,
							},
						],
					},
					{
						name: collarMidName,
						description1: collarMidDescription,
						plpLink: collarMidPlpLink,
						isActive: false,
						images: [
							{
								desktop: collarMidDesktopImageUrl,
								mobile: collarMidMobileImageUrl,
							},
						],
					},
					{
						name: collarHighName,
						description1: collarHighDescription,
						plpLink: collarHighPlpLink,
						isActive: false,
						images: [
							{
								desktop: collarHighDesktopImageUrl,
								mobile: collarHighMobileImageUrl,
							},
						],
					},
					{
						name: collarButtonDownName,
						description1: collarButtonDownDescription,
						plpLink: collarButtonDownPlpLink,
						isActive: false,
						images: [
							{
								desktop: collarButtonDownDesktopImageUrl,
								mobile: collarButtonDownMobileImageUrl,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: weaveTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: poplinName,
						description1: poplinDescription,
						plpLink: poplinPlpLink,
						isActive: false,
						images: [
							{
								desktop: poplinDesktopImageUrl,
								mobile: poplinMobileImageUrl,
							},
						],
					},
					{
						name: herringboneName,
						description1: herringboneDescription,
						plpLink: herringbonePlpLink,
						isActive: false,
						images: [
							{
								desktop: herringboneDesktopImageUrl,
								mobile: herringboneMobileImageUrl,
							},
						],
					},
					{
						name: twillName,
						description1: twillDescription,
						plpLink: twillPlpLink,
						isActive: false,
						images: [
							{
								desktop: twillDesktopImageUrl,
								mobile: twillMobileImageUrl,
							},
						],
					},
					{
						name: dobbyName,
						description1: dobbyDescription,
						plpLink: dobbyPlpLink,
						isActive: false,
						images: [
							{
								desktop: dobbyDesktopImageUrl,
								mobile: dobbyMobileImageUrl,
							},
						],
					},
					{
						name: piqueName,
						description1: piqueDescription,
						plpLink: piquePlpLink,
						isActive: true,
						images: [
							{
								desktop: piqueDesktopImageUrl,
								mobile: piqueMobileImageUrl,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildMenShirtGuideViewModel;
