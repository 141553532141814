import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { DefaultService } from '../../../modules/layout/default.service';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { CheckoutSwitcherService } from '../../checkout-switcher.service';
import { SiteContentDetail } from '../../models/siteContent';
import { AttraqtService } from '../../service/attraqt.service';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
	guestTabActive: boolean = true;
	signInFormCheckout: UntypedFormGroup
	guestFormCheckout: UntypedFormGroup
	incorrectEmailAndPassword: boolean = false;
	emailExists: boolean = false;
	emailExistsMessage = '';
	siteContent: SiteContentDetail;
	LpAppSetting: LpAppSetting;
	scriptContent = [];
	facetFilterState: IFacetFilterState;
	facetFilterSubscriber: Subscription;

	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		@Inject(DOCUMENT) document,
		private r: Renderer2,
		private fb: UntypedFormBuilder,
		private router: Router,
		private authService: AuthService,
		private sharedService: SharedService,
		private defaultService: DefaultService,
		private titleService: Title,
		private meta: Meta,
		private scrollService: ScrollService,
		private siteContentService: SiteContentService,
		private attraqtService: AttraqtService,
		private checkoutSwitcherService: CheckoutSwitcherService,

		@Inject(PLATFORM_ID)
		private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	ngOnInit() {
		this.getLpAppSetting();
		this.getSiteContent();
		this.intiateForm();
		this.intiateFormGuest();
		this.titleService.setTitle(this.siteContent.Welcome);
		this.meta.updateTag({ name: 'description', content: '' });
		this.scrollService.windowScrollTo(0, 0);
		this.getTrackingScript();
		this.subscribeToState();
	}

	ngOnDestroy(): void {
		if (this.facetFilterSubscriber) {
			this.facetFilterSubscriber.unsubscribe();
		}
	}

	getSiteContent() {
		this.siteContent = this.siteContentService.getSiteContentData();

		if (!this.siteContent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.siteContent = data;
			})
		}
	}

	changeTabActive(userTab) {
		if (userTab == "guest") {
			this.guestTabActive = true;
		} else if (userTab == "signIn") {
			this.guestTabActive = false;
		} else {
			this.guestTabActive = true;
		}
	}

	intiateForm() {
		this.signInFormCheckout = this.fb.group({
			EmailAddress: ['', [Validators.required, Validators.email]],
			Password: ["", [Validators.required, Validators.minLength(5)]],
		})
	}

	intiateFormGuest() {
		this.guestFormCheckout = this.fb.group({
			EmailAddress: ['', [Validators.required, Validators.email]],
		})
	}

	removeErrorMessage() {
		this.incorrectEmailAndPassword = false;
		this.emailExists = false;
	}

	submitSignInForm() {
		if (this.signInFormCheckout.status == "INVALID") {
			this.defaultService.validateAllFormFields(this.signInFormCheckout);
			return;
		}

		this.authService.welcomePageLogin(this.signInFormCheckout.value).subscribe(res => {

			if (res.result == "PasswordError") {
				this.incorrectEmailAndPassword = true;
			} else {

				if (res.desc) {
					localStorage.setItem("isSignedIn", "true");
					this.sharedService.changeSigninInfo("Hello from Second Component");

					if (res.desc?.CustomerId && this.facetFilterState.attraqtPLPEnabled) {
						this.attraqtService.setLoggedUser(res.desc.CustomerId);
					}
				}

				this.router.navigateByUrl('details', { skipLocationChange: true }).then(() => {
					this.gotoCheckoutPage(false);
				});

			}
		}), err => {
			this.incorrectEmailAndPassword = true;
		}
	}

	clickForgotPassword() {
		localStorage.setItem('forgotForm', 'true');
		localStorage.removeItem('createForm');

		this.router.navigate(['account']).then(() => {
			window.location.reload();
		});
	}

	submitGuestForm() {

		if (this.guestFormCheckout.status == "INVALID") {
			this.defaultService.validateAllFormFields(this.guestFormCheckout);
			return;
		}

		this.authService.guestCheckoutLogin(this.guestFormCheckout.value).subscribe(res => {

			if (res.result == "PasswordError") {
				this.emailExists = true;
				this.emailExistsMessage = res.desc;
			} else if (res.result == "Success") {
				localStorage.setItem('isGuestLogin', 'true');

				this.gotoCheckoutPage(true);
			}
		}, err => {
		});
	}

	gotoCheckoutPage(replaceUrl) {
		this.checkoutSwitcherService.gotoCheckoutPage(replaceUrl);
	}

	getGeneratedMessage(toReplace, text) {
		return text.replace('{0}', toReplace);
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
	}

	getTrackingScript() {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.CheckoutDelivery;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts();
			}, err => {
			});
		}
	}

	applyTrackingScripts() {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
