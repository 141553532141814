import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ViewportService } from 'src/app/shared/viewport.service';
import { ScrollService } from 'src/app/shared/service/scroll.service';
import { SharedService } from 'src/app/shared/shared.service';
import { FitGuideMainPageKeyEnum } from 'src/app/shared/enums/fit-guide-main-page-key.enum';
import buildCompareFitPageViewModel from 'src/app/shared/helper/fit-guide/build-compare-fit-page-view-model';
import { IFitGuideComparePageVM } from 'src/app/shared/models/fit-guide';

@Component({
	selector: 'app-fit-guide-compare-fit-page',
	templateUrl: './fit-guide-compare-fit-page.component.html',
	styleUrls: ['./fit-guide-compare-fit-page.component.scss'],
})
export class FitGuideCompareFitPageComponent implements OnInit {
	deviceType: 'Mobile' | 'Tablet' | 'Desktop';
	pageKey: FitGuideMainPageKeyEnum;
	compareFitPageVM: IFitGuideComparePageVM;

	constructor(
		private router: Router,
		private activateRoute: ActivatedRoute,
		private viewportService: ViewportService,
		private scrollService: ScrollService,
		private sharedService: SharedService
	) {}

	async ngOnInit(): Promise<void> {
		this.deviceType = this.viewportService.getDeviceType();
		this.pageKey = this.activateRoute.snapshot.params['pageKey'];
		const appSettings = await this.sharedService.getLpAppSettings();
		if (appSettings?.FitGuideSettings && this.pageKey) {
			this.compareFitPageVM = buildCompareFitPageViewModel(
				appSettings.FitGuideSettings,
				this.pageKey
			);
		}
		this.scrollService.windowScrollTo(0, 0);
	}

	backToMainPage(): void {
		this.router.navigate([`/fit-guide/${this.pageKey}`]);
	}
}
