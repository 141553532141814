import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildTieGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		TieTitle: title,
		TieMobileBannerImageUrl: mobileBannerImage,
		TiePlpLink: plpLink,
		StepName1Text: stepNameOne,
		StepName2Text: stepNameTwo,
		StepName3Text: stepNameThree,
		StepName4Text: stepNameFour,
		StepName5Text: stepNameFive,
		StepName6Text: stepNameSix,
		StepName7Text: stepNameSeven,
		StepName8Text: stepNameEight,
		StepName9Text: stepNameNine,
		StepName10Text: stepNameTen,
		TieKnotTitle: knotTitle,
		TieKnotDoubleWindsorName: doubleWindsorName,
		TieKnotDoubleWindsorDescription: doubleWindsorDescription,
		TieDWHowToTieDescription1: doubleWindsorHowTo1,
		TieDWHowToTieDescription2: doubleWindsorHowTo2,
		TieDWHowToTieDescription3: doubleWindsorHowTo3,
		TieDWHowToTieDescription4: doubleWindsorHowTo4,
		TieDWHowToTieDescription5: doubleWindsorHowTo5,
		TieDWHowToTieDescription6: doubleWindsorHowTo6,
		TieDWHowToTieDescription7: doubleWindsorHowTo7,
		TieDWHowToTieDescription8: doubleWindsorHowTo8,
		TieDWHowToTieDescription9: doubleWindsorHowTo9,
		TieDWHowToTieDescription10: doubleWindsorHowTo10,
		TieKnotDoubleWindsorVideoUrl: doubleWindsorVideoUrl,
		TieKnotDoubleWindsorDesktopImageUrl1: doubleWindsorDesktopImageUrl1,
		TieKnotDoubleWindsorMobileImageUrl1: doubleWindsorMobileImageUrl1,
		TieKnotDoubleWindsorDesktopImageUrl2: doubleWindsorDesktopImageUrl2,
		TieKnotDoubleWindsorMobileImageUrl2: doubleWindsorMobileImageUrl2,
		TieKnotDoubleWindsorDesktopImageUrl3: doubleWindsorDesktopImageUrl3,
		TieKnotDoubleWindsorMobileImageUrl3: doubleWindsorMobileImageUrl3,
		TieKnotDoubleWindsorDesktopImageUrl4: doubleWindsorDesktopImageUrl4,
		TieKnotDoubleWindsorMobileImageUrl4: doubleWindsorMobileImageUrl4,
		TieKnotDoubleWindsorDesktopImageUrl5: doubleWindsorDesktopImageUrl5,
		TieKnotDoubleWindsorMobileImageUrl5: doubleWindsorMobileImageUrl5,
		TieKnotDoubleWindsorDesktopImageUrl6: doubleWindsorDesktopImageUrl6,
		TieKnotDoubleWindsorMobileImageUrl6: doubleWindsorMobileImageUrl6,
		TieKnotDoubleWindsorDesktopImageUrl7: doubleWindsorDesktopImageUrl7,
		TieKnotDoubleWindsorMobileImageUrl7: doubleWindsorMobileImageUrl7,
		TieKnotDoubleWindsorDesktopImageUrl8: doubleWindsorDesktopImageUrl8,
		TieKnotDoubleWindsorMobileImageUrl8: doubleWindsorMobileImageUrl8,
		TieKnotDoubleWindsorDesktopImageUrl9: doubleWindsorDesktopImageUrl9,
		TieKnotDoubleWindsorMobileImageUrl9: doubleWindsorMobileImageUrl9,
		TieKnotDoubleWindsorDesktopImageUrl11: doubleWindsorDesktopImageUrl11,
		TieKnotDoubleWindsorMobileImageUrl11: doubleWindsorMobileImageUrl11,
		TieKnotDoubleWindsorDesktopImageUrl12: doubleWindsorDesktopImageUrl12,
		TieKnotDoubleWindsorMobileImageUrl12: doubleWindsorMobileImageUrl12,
		TieKnotHalfWindsorName: halfWindsorName,
		TieKnotHalfWindsorDescription: halfWindsorDescription,
		TieHWHowToTieDescription1: halfWindsorHowTo1,
		TieHWHowToTieDescription2: halfWindsorHowTo2,
		TieHWHowToTieDescription3: halfWindsorHowTo3,
		TieHWHowToTieDescription4: halfWindsorHowTo4,
		TieHWHowToTieDescription5: halfWindsorHowTo5,
		TieHWHowToTieDescription6: halfWindsorHowTo6,
		TieHWHowToTieDescription7: halfWindsorHowTo7,
		TieHWHowToTieDescription8: halfWindsorHowTo8,
		TieKnotHalfWindsorVideoUrl: halfWindsorVideoUrl,
		TieKnotHalfWindsorDesktopImageUrl1: halfWindsorDesktopImageUrl1,
		TieKnotHalfWindsorMobileImageUrl1: halfWindsorMobileImageUrl1,
		TieKnotHalfWindsorDesktopImageUrl2: halfWindsorDesktopImageUrl2,
		TieKnotHalfWindsorMobileImageUrl2: halfWindsorMobileImageUrl2,
		TieKnotHalfWindsorDesktopImageUrl3: halfWindsorDesktopImageUrl3,
		TieKnotHalfWindsorMobileImageUrl3: halfWindsorMobileImageUrl3,
		TieKnotHalfWindsorDesktopImageUrl4: halfWindsorDesktopImageUrl4,
		TieKnotHalfWindsorMobileImageUrl4: halfWindsorMobileImageUrl4,
		TieKnotHalfWindsorDesktopImageUrl5: halfWindsorDesktopImageUrl5,
		TieKnotHalfWindsorMobileImageUrl5: halfWindsorMobileImageUrl5,
		TieKnotHalfWindsorDesktopImageUrl6: halfWindsorDesktopImageUrl6,
		TieKnotHalfWindsorMobileImageUrl6: halfWindsorMobileImageUrl6,
		TieKnotHalfWindsorDesktopImageUrl7: halfWindsorDesktopImageUrl7,
		TieKnotHalfWindsorMobileImageUrl7: halfWindsorMobileImageUrl7,
		TieKnotHalfWindsorDesktopImageUrl8: halfWindsorDesktopImageUrl8,
		TieKnotHalfWindsorMobileImageUrl8: halfWindsorMobileImageUrl8,
		TieKnotHalfWindsorDesktopImageUrl9: halfWindsorDesktopImageUrl9,
		TieKnotHalfWindsorMobileImageUrl9: halfWindsorMobileImageUrl9,
		TieKnotFourInHandName: fourInHandName,
		TieKnotFourInHandDescription: fourInHandDescription,
		TieFHHowToTieDescription1: fourInHandHowTo1,
		TieFHHowToTieDescription2: fourInHandHowTo2,
		TieFHHowToTieDescription3: fourInHandHowTo3,
		TieFHHowToTieDescription4: fourInHandHowTo4,
		TieFHHowToTieDescription5: fourInHandHowTo5,
		TieFHHowToTieDescription6: fourInHandHowTo6,
		TieKnotFourInHandVideoUrl: fourInHandVideoUrl,
		TieKnotFourInHandDesktopImageUrl1: fourInHandDesktopImageUrl1,
		TieKnotFourInHandMobileImageUrl1: fourInHandMobileImageUrl1,
		TieKnotFourInHandDesktopImageUrl2: fourInHandDesktopImageUrl2,
		TieKnotFourInHandMobileImageUrl2: fourInHandMobileImageUrl2,
		TieKnotFourInHandDesktopImageUrl3: fourInHandDesktopImageUrl3,
		TieKnotFourInHandMobileImageUrl3: fourInHandMobileImageUrl3,
		TieKnotFourInHandDesktopImageUrl5: fourInHandDesktopImageUrl5,
		TieKnotFourInHandMobileImageUrl5: fourInHandMobileImageUrl5,
		TieKnotFourInHandDesktopImageUrl6: fourInHandDesktopImageUrl6,
		TieKnotFourInHandMobileImageUrl6: fourInHandMobileImageUrl6,
		TieKnotFourInHandDesktopImageUrl7: fourInHandDesktopImageUrl7,
		TieKnotFourInHandMobileImageUrl7: fourInHandMobileImageUrl7,
		TieKnotFourInHandDesktopImageUrl10: fourInHandDesktopImageUrl10,
		TieKnotFourInHandMobileImageUrl10: fourInHandMobileImageUrl10,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: knotTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: doubleWindsorName,
						plpLink: plpLink,
						videoLink: doubleWindsorVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: doubleWindsorDesktopImageUrl1,
								mobile: doubleWindsorMobileImageUrl1,
							},
							{
								desktop: doubleWindsorDesktopImageUrl2,
								mobile: doubleWindsorMobileImageUrl2,
							},
							{
								desktop: doubleWindsorDesktopImageUrl3,
								mobile: doubleWindsorMobileImageUrl3,
							},
							{
								desktop: doubleWindsorDesktopImageUrl4,
								mobile: doubleWindsorMobileImageUrl4,
							},
							{
								desktop: doubleWindsorDesktopImageUrl5,
								mobile: doubleWindsorMobileImageUrl5,
							},
							{
								desktop: doubleWindsorDesktopImageUrl6,
								mobile: doubleWindsorMobileImageUrl6,
							},
							{
								desktop: doubleWindsorDesktopImageUrl7,
								mobile: doubleWindsorMobileImageUrl7,
							},
							{
								desktop: doubleWindsorDesktopImageUrl8,
								mobile: doubleWindsorMobileImageUrl8,
							},
							{
								desktop: doubleWindsorDesktopImageUrl9,
								mobile: doubleWindsorMobileImageUrl9,
							},
							{
								desktop: doubleWindsorDesktopImageUrl11,
								mobile: doubleWindsorMobileImageUrl11,
							},
							{
								desktop: doubleWindsorDesktopImageUrl12,
								mobile: doubleWindsorMobileImageUrl12,
							},
						],
						imageDetails: [
							{ name: null, description: doubleWindsorDescription },
							{ name: stepNameOne, description: doubleWindsorHowTo1 },
							{ name: stepNameTwo, description: doubleWindsorHowTo2 },
							{ name: stepNameThree, description: doubleWindsorHowTo3 },
							{ name: stepNameFour, description: doubleWindsorHowTo4 },
							{ name: stepNameFive, description: doubleWindsorHowTo5 },
							{ name: stepNameSix, description: doubleWindsorHowTo6 },
							{ name: stepNameSeven, description: doubleWindsorHowTo7 },
							{ name: stepNameEight, description: doubleWindsorHowTo8 },
							{ name: stepNameNine, description: doubleWindsorHowTo9 },
							{ name: stepNameTen, description: doubleWindsorHowTo10 },
						],
						isActive: false,
					},
					{
						name: halfWindsorName,
						plpLink: plpLink,
						videoLink: halfWindsorVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: halfWindsorDesktopImageUrl1,
								mobile: halfWindsorMobileImageUrl1,
							},
							{
								desktop: halfWindsorDesktopImageUrl2,
								mobile: halfWindsorMobileImageUrl2,
							},
							{
								desktop: halfWindsorDesktopImageUrl3,
								mobile: halfWindsorMobileImageUrl3,
							},
							{
								desktop: halfWindsorDesktopImageUrl4,
								mobile: halfWindsorMobileImageUrl4,
							},
							{
								desktop: halfWindsorDesktopImageUrl5,
								mobile: halfWindsorMobileImageUrl5,
							},
							{
								desktop: halfWindsorDesktopImageUrl6,
								mobile: halfWindsorMobileImageUrl6,
							},
							{
								desktop: halfWindsorDesktopImageUrl7,
								mobile: halfWindsorMobileImageUrl7,
							},
							{
								desktop: halfWindsorDesktopImageUrl8,
								mobile: halfWindsorMobileImageUrl8,
							},
							{
								desktop: halfWindsorDesktopImageUrl9,
								mobile: halfWindsorMobileImageUrl9,
							},
						],
						imageDetails: [
							{ name: null, description: halfWindsorDescription },
							{ name: stepNameOne, description: halfWindsorHowTo1 },
							{ name: stepNameTwo, description: halfWindsorHowTo2 },
							{ name: stepNameThree, description: halfWindsorHowTo3 },
							{ name: stepNameFour, description: halfWindsorHowTo4 },
							{ name: stepNameFive, description: halfWindsorHowTo5 },
							{ name: stepNameSix, description: halfWindsorHowTo6 },
							{ name: stepNameSeven, description: halfWindsorHowTo7 },
							{ name: stepNameEight, description: halfWindsorHowTo8 },
						],
						isActive: true,
					},
					{
						name: fourInHandName,
						plpLink: plpLink,
						videoLink: fourInHandVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: fourInHandDesktopImageUrl1,
								mobile: fourInHandMobileImageUrl1,
							},
							{
								desktop: fourInHandDesktopImageUrl2,
								mobile: fourInHandMobileImageUrl2,
							},
							{
								desktop: fourInHandDesktopImageUrl3,
								mobile: fourInHandMobileImageUrl3,
							},
							{
								desktop: fourInHandDesktopImageUrl5,
								mobile: fourInHandMobileImageUrl5,
							},
							{
								desktop: fourInHandDesktopImageUrl6,
								mobile: fourInHandMobileImageUrl6,
							},
							{
								desktop: fourInHandDesktopImageUrl7,
								mobile: fourInHandMobileImageUrl7,
							},
							{
								desktop: fourInHandDesktopImageUrl10,
								mobile: fourInHandMobileImageUrl10,
							},
						],
						imageDetails: [
							{ name: null, description: fourInHandDescription },
							{ name: stepNameOne, description: fourInHandHowTo1 },
							{ name: stepNameTwo, description: fourInHandHowTo2 },
							{ name: stepNameThree, description: fourInHandHowTo3 },
							{ name: stepNameFour, description: fourInHandHowTo4 },
							{ name: stepNameFive, description: fourInHandHowTo5 },
							{ name: stepNameSix, description: fourInHandHowTo6 },
						],
						isActive: false,
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2 top3 right3',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildTieGuideViewModel;
