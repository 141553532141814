import { Component, Input, OnInit } from '@angular/core';
import { SiteContentDetail } from '../../models/siteContent';
import { SiteContentService } from '../../sitecontent.service';
import { FreeDeliveryProgressBar, LpAppSetting } from 'src/app/model/lpapp-setting';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'free-delivery-progress-bar,[free-delivery-progress-bar]',
  templateUrl: './free-delivery-progress-bar.component.html',
  styleUrls: ['./free-delivery-progress-bar.component.scss']
})
export class FreeDeliveryProgressBarComponent implements OnInit {
  @Input() currentTotal:number;
  @Input() shippingTotal:number;
  absolutePath=window.location.pathname;
  LpAppSetting: LpAppSetting;
  freeDeliveryProgressBarActive: boolean = false;
  freeDeliveryThreshold: number = 175;
  freeDeliveryProgressBarQualifiedMessage="";
  FreeDeliveryProgressBarKeepShoppingMessage="";

  // Remaining amount to add to qualify for free delivery
  amountForFreeDelivery = 0.0;
  
  stepPercentage = 0;
  qualifiedForFreeDelivery : boolean = true;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.getLpAppSetting();
  }

  ngOnChanges() {
    this.calculateAmountToAdd();
  };

  private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.lpAppSettings;

    this.freeDeliveryProgressBarActive=this.LpAppSetting?.FreeDeliveryProgressBar?.Active;
    this.freeDeliveryThreshold = this.LpAppSetting?.FreeDeliveryProgressBar?.Threshold;
    this.freeDeliveryProgressBarQualifiedMessage=this.LpAppSetting?.FreeDeliveryProgressBar?.QualifiedMessage;
    this.FreeDeliveryProgressBarKeepShoppingMessage=this.LpAppSetting?.FreeDeliveryProgressBar?.KeepShoppingMessage;
	}

  calculateAmountToAdd()
  {
    let total = this.currentTotal-this.shippingTotal;

    let amountToPay = this.freeDeliveryThreshold - total;
    this.amountForFreeDelivery = amountToPay <= 0 ? 0 : amountToPay;

    this.qualifiedForFreeDelivery = this.amountForFreeDelivery === 0 ? true : false;

    this.stepPercentage = this.calculateThresholdPercentage(total, this.freeDeliveryThreshold);

  }

  // calculate percentage of threshold
  calculateThresholdPercentage(currentTotal, threshold) 
  {
    let percentage = (currentTotal / threshold) * 100;
    // stepPercentage is used to set which quarter the progression step should be set to
    let stepPercentage = 0;
    if (percentage <= 25) stepPercentage = 25;
    else if (percentage <= 50) stepPercentage = 50;
    else if (percentage < 100) stepPercentage = 75;
    else if (percentage >= 100) stepPercentage = 100;
    return stepPercentage;
  };

  formatString(template, ...args) 
  {
    return template.replace(/{([0-9]+)}/g, function (match, index) {
      return typeof args[index] === 'undefined' ? match : args[index];
    });
  }
}
