import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { SiteContentDetail } from '../../models/siteContent';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';

@Component({
	selector: 'register-thanks',
	templateUrl: './register-thanks.component.html',
	styleUrls: ['./register-thanks.component.scss']
})

export class RegisterThanksComponent implements OnInit {
	sitecontent: SiteContentDetail;
	WebStoreId: number;
	scriptContent = [];

	constructor(private sharedService: SharedService, private route: ActivatedRoute, private router: Router,
		private siteContentService: SiteContentService) {
		$("body").removeClass();
		$("body").addClass("checkout ng-scope");
	}

	ngOnInit() {
		this.getSiteContent();
		this.route.params.subscribe(params => {
			this.WebStoreId = params['webstoreid'];
			this.getTrackingScript();
		});
	}

	getSiteContent() {

		this.sitecontent = this.siteContentService.getSiteContentData();
		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {

				this.sitecontent = data;
			})
		}
	}

	getTrackingScript() {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.UserRegistered;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts();
			});
		}
	}

	applyTrackingScripts() {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}
	}

	ngAfterViewInit() {

	}
}
