export interface SocialProofingVM {
    ProductCode: string;
    Title: string;
    Message: string;
    IconPath: string;
}

export enum SocialProofingTypeEnum {
    PLP,
    PDP
}

export interface SocialProofingTrendsData {
    Type: SocialProofingTypeEnum,
    ViewModels: SocialProofingVM[]
}