import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { PersonifyService } from '../../service/personify.service';
import { ViewportService } from '../../viewport.service';
import { SiteContentDetail } from '../../models/siteContent';
import { CarouselSettings, LpAppSetting, ProductRecommendationsSettings } from 'src/app/model/lpapp-setting';
import { catchError, EMPTY, filter, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { CartComponent } from '../cart/cart.component';
import { HomePageComponent } from 'src/app/modules/Home/home-page/home-page.component';
import { ShoppingBasketComponent } from 'src/app/modules/shopping-basket/shopping-basket/shopping-basket.component';
import { ProductDetailRequest, ProductDetailResponse, PDRQCriteria, PDRSImage, ProductRecsTypeEnum, PersonifyRequest, PRQBasket, PRSRecommendation, ProductRecommendationsVM, PRVMImage } from '../../models/product-recommendations.model';
import { Product } from 'src/app/modules/product-details/models/product-detail';
import { Basket } from 'src/app/model/basketpage-viewmodel';


@Component({
  selector: 'product-recommendations, [product-recommendations]',
  templateUrl: './product-recommendations.component.html'
})
export class ProductRecommendationsComponent implements OnInit, OnChanges {
  @Input() product: Product;
  @Input() basket: Basket;

  private lpAppSetting: LpAppSetting;
  private productRecSettings: ProductRecommendationsSettings;
  private carouselSettings: CarouselSettings;

  siteContent: SiteContentDetail;
  parentType: ProductRecsTypeEnum | undefined;
  recsTypeEnum = ProductRecsTypeEnum;
  classType: string = null;
  slideConfig: any = {};

  productRecsVM$: Observable<ProductRecommendationsVM[]>;

  constructor(
    private sharedService: SharedService,
    private siteContentService: SiteContentService,
    private personifyService: PersonifyService,
    private viewportService: ViewportService,
    @Optional() @Inject(CartComponent) private cartComponent: CartComponent,
    @Optional() @Inject(HomePageComponent) private homePageComponent: HomePageComponent,
    @Optional() @Inject(ShoppingBasketComponent) private shoppingBasketComponent: ShoppingBasketComponent,

  ) {
    if (this.cartComponent) {
      this.parentType = ProductRecsTypeEnum.MiniBasket;
    }
    else if (this.homePageComponent) {
      this.parentType = ProductRecsTypeEnum.PDP;
    }
    else if (this.shoppingBasketComponent) {
      this.parentType = ProductRecsTypeEnum.ShoppingBasket;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.inint();
    this.getRecommendations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Refresh mini basket carousel on changes
    if (this.isMinibasketChanged(changes)) {
      this.getRecommendations();
    }
  }

  formatPrice(price: number, currencyPrefix: string): string {
    if (price % 1 == 0) {
      return currencyPrefix + price;
    } else {
      return currencyPrefix + price.toFixed(2);
    }
  }

  private async inint(): Promise<void> {
    this.lpAppSetting = await this.sharedService.getLpAppSettings();
    this.siteContent = await firstValueFrom(this.siteContentService.getSiteContent());

    if (!this.lpAppSetting?.ProductRecommendationsSettings) {
      return;
    }

    this.productRecSettings = this.lpAppSetting.ProductRecommendationsSettings;

    const {
      MiniBasketCarousel,
      PDPCarousel,
      ShoppingBasketCarousel
    } = this.productRecSettings;

    const isAllInactive = [MiniBasketCarousel, PDPCarousel, ShoppingBasketCarousel].every(x => x.IsActive === false);

    if (isAllInactive) {
      return;
    }

    const prevArrowButton = '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i></i></button>';
    const nextArrowButton = '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i></i></button>';

    switch (this.parentType) {
      case ProductRecsTypeEnum.MiniBasket:
        this.classType = "rec-carousel-mini-basket";
        this.carouselSettings = this.productRecSettings.MiniBasketCarousel;
        this.slideConfig = {
          autoplay: false,
          pauseOnHover: true,
          prevArrow: prevArrowButton,
          nextArrow: nextArrowButton,
          dots: false,
          arrows: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
        break;

      case ProductRecsTypeEnum.PDP:
        this.classType = "rec-carousel-pdp";
        this.carouselSettings = this.productRecSettings.PDPCarousel;
        this.slideConfig = {
          autoplay: false,
          pauseOnHover: true,
          prevArrow: prevArrowButton,
          nextArrow: nextArrowButton,
          dots: false,
          arrows: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        }
        break;

      case ProductRecsTypeEnum.ShoppingBasket:
        this.classType = "rec-carousel-shopping-basket";
        this.carouselSettings = this.productRecSettings.ShoppingBasketCarousel;
        this.slideConfig = {
          autoplay: false,
          pauseOnHover: true,
          prevArrow: prevArrowButton,
          nextArrow: nextArrowButton,
          dots: false,
          arrows: true,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }
        break;

      default:
        return;
    }
  }

  private getRecommendations(): void {
    if (!this.carouselSettings || !this.productRecSettings || this.carouselSettings?.IsActive === false) {
      return;
    }

    const personifyApiUrl = `${this.productRecSettings.PersonifyApiEndpointBaseUrl}${this.productRecSettings.PersonifyApiEndpoint}`;
    const personifyPayload = this.buildPersonifyRecommendationRequestPayload();
    if (!personifyPayload) {
      return;
    }

    this.productRecsVM$ = this.personifyService.getRecommendations(personifyApiUrl, personifyPayload).pipe(
      map(personifyResponse => this.buildProductDetailRequestPayload(personifyResponse?.recommendations)),
      switchMap(productDetailRQ => productDetailRQ != null ? this.sharedService.getProductDetails(productDetailRQ) : EMPTY),
      map(productDetailRS => this.buildProductRecommendationsVM(productDetailRS)),
      filter(productRecsVM => productRecsVM != null),
      map(productRecsVM => productRecsVM.slice(0, this.carouselSettings.ItemCount)),
      catchError(error => {
        console.log('An error occurred while processing Product Recommendations. Error:\n', error);
        return EMPTY;
      })
    );
  }

  private buildPersonifyRecommendationRequestPayload(): PersonifyRequest {
    let productcontext: string = "";
    let basket: PRQBasket[] = [];

    switch (this.parentType) {
      case ProductRecsTypeEnum.MiniBasket:
        basket = this.personifyService.getBasket(this.basket);
        break;

      case ProductRecsTypeEnum.PDP:
        productcontext = this.product.Code;
        break;

      case ProductRecsTypeEnum.ShoppingBasket:
        basket = this.personifyService.getBasket(this.basket);
        break;

      default:
        return null;
    }

    const { Algorithm, PageSize, RecommendationType } = this.carouselSettings.PersonifyApiSettings;

    return {
      productcontext: productcontext,
      basket: basket,
      sessionid: this.personifyService.getPersonifySessionId(),
      shopperid: this.personifyService.getPersonifyShopperId(),
      algorithm: Algorithm,
      pagesize: PageSize,
      rectype: this.personifyService.getRecommendationType(RecommendationType),
      currency: this.productRecSettings.CurrencySymbol,
      device: this.viewportService.getDeviceType()
    };
  }

  private buildProductDetailRequestPayload(personifyRecommendations: PRSRecommendation[]): ProductDetailRequest | null {
    if (!personifyRecommendations || personifyRecommendations.length === 0) {
      return null;
    }

    const criteria: PDRQCriteria[] = personifyRecommendations.map(x => ({
      Sku: x.productcode,
      ThirdPartyId: '0'
    }));

    const imageKey = this.parentType === ProductRecsTypeEnum.MiniBasket ? "n2-thumb" : "n2-medium";

    return {
      Reference: "personify",
      ImageKeys: imageKey,
      ProductsCriteria: criteria
    };
  }

  private buildProductRecommendationsVM(response: ProductDetailResponse): ProductRecommendationsVM[] {
    let imageCount = 2;
    if (this.parentType === ProductRecsTypeEnum.MiniBasket) {
      imageCount = 1
    }

    return response?.Desc?.Products.map(p => ({
      ProductId: p.ProductId,
      ProductName: p.Name,
      ProductUrl: p.ProductUrl,
      Images: this.getProductImages(p.Images, imageCount),
      IsOnSale: p.IsOnSale,
      SalePrice: p.SalePrice,
      RetailPrice: p.RetailPrice,
      CurrencyPrefix: p.CurrencyPrefix
    }));
  }

  private getProductImages(images: PDRSImage[], imageCount: number): PRVMImage[] {
    return images.sort((a, b) => a.SortOrder - b.SortOrder).slice(0, imageCount)
      .map((img, index) => ({
        Id: img.ImageId,
        Url: img.ImageSizes[0].CdnFullUrl,
        ClassName: index == 0 ? "primary-image" : "secondary-image",
        AltText: img.AltText
      }));
  }

  private isMinibasketChanged(changes: SimpleChanges): boolean {
    const basket = changes['basket'];

    if (!basket) {
      return false;
    }

    if (basket.firstChange === true) {
      return false;
    }

    return basket.currentValue?.BasketTotalItemCount !== basket.previousValue?.BasketTotalItemCount;
  }
}