import { Injectable } from "@angular/core";
import { Action, State, StateContext, StateToken } from "@ngxs/store";

import { FitGuide } from "./fit-guide.action";
import { IFitGuideState } from "../../interfaces/fit-guide/fit-guide-state.interface";
import { FitGuideMainPageKeyEnum } from "../../enums/fit-guide-main-page-key.enum";

const FIT_GUIDE_STATE_TOKEN = new StateToken<IFitGuideState>("fitGuide");

const activeMobileScreens = {
	[FitGuideMainPageKeyEnum.MenShirt]: 0,
	[FitGuideMainPageKeyEnum.MenSuit]: 0,
	[FitGuideMainPageKeyEnum.WomenShirt]: 0,
	[FitGuideMainPageKeyEnum.WomenSuit]: 0,
	[FitGuideMainPageKeyEnum.Tie]: 0,
	[FitGuideMainPageKeyEnum.BowTie]: 0,
	[FitGuideMainPageKeyEnum.PocketSquare]: 0,
	[FitGuideMainPageKeyEnum.Scarf]: 0,
	[FitGuideMainPageKeyEnum.Shoe]: 0,
};

@State({
	name: FIT_GUIDE_STATE_TOKEN,
	defaults: {
		activeMobileScreens,
	},
})
@Injectable()
export class FitGuideState {
	@Action(FitGuide.SetActiveMobileScreenIndex)
	private setActiveMobileScreenIndex(
		ctx: StateContext<IFitGuideState>,
		action: FitGuide.SetActiveMobileScreenIndex
	) {
		const { activeMobileScreens, ...rest } = ctx.getState();

		activeMobileScreens[action.pageKey] = action.screenIndex;

		ctx.setState({
			...rest,
			activeMobileScreens,
		});
	}

	@Action(FitGuide.ResetStateToDefault)
	private resetStateToDefault(
		ctx: StateContext<IFitGuideState>,
		action: FitGuide.ResetStateToDefault
	) {
		const state = ctx.getState();

		ctx.setState({
			...state,
			activeMobileScreens,
		});
	}
}
