import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
//import { mobilemenuTriggerToggle } from '../../../../../assets/shared/helper_scripts.js'
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { NAVIGATOR_LANGUAGE } from 'src/app/shared/constants/attraqt';
import { FacetFilterState } from '../../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { Menu, MobileMenuItem } from 'src/app/shared/models/menuItem';
import { ViewportService } from 'src/app/shared/viewport.service';

@Component({
  selector: 'mobile-nav-bar',
  templateUrl: './mobile-nav-bar.component.html',
  styleUrls: ['./mobile-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileNavBarComponent implements OnInit, OnDestroy {
  @Output() closeMenu = new EventEmitter<string>();
  @Input() menuItems: MobileMenuItem[] = [];

  private filteredMenuItems: MobileMenuItem[] = [];
  private expandedMenuItemIds: Set<number> = new Set();
  private menuMap: Map<number, number[]> = new Map();

  private facetFilterSubscriber: Subscription;
  private facetFilterState: IFacetFilterState;
  menuHeight: number = 100;
  mainMenu: Menu = {
    members: 0,
    height: 0,
  };
  @Select(FacetFilterState) private facetFilter$: Observable<IFacetFilterState>;

  visibleItems: MobileMenuItem[] = [];
  isMenuOpen$ = new BehaviorSubject<boolean>(false);
  isMenSelected: boolean = true;
  isWomenSelected: boolean = false;
  sitecontent: SiteContentDetail;
  lastOpenMenuItem: MobileMenuItem | null;

  constructor(private router: Router, private attraqtService: AttraqtService, private siteContentService: SiteContentService, private viewportService: ViewportService,private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.sitecontent = this.siteContentService.getSiteContentData();
    this.subscribeToState();

    this.filterCategories();
    this.menuMap = this.createMenuMap(this.menuItems);
  }

  ngOnDestroy() {
    this.facetFilterSubscriber.unsubscribe();
  }

  menButtonClicked(): void {
    this.isMenSelected = true;
    this.isWomenSelected = false;
    this.filterCategories('m');
  }

  womenButtonClicked(): void {
    this.isMenSelected = false;
    this.isWomenSelected = true;
    this.filterCategories('f');
  }
  toggleMobileMenu(): void {
    const isMenuOpen = !this.isMenuOpen$.value;
    this.isMenuOpen$.next(isMenuOpen);
  
    if (!isMenuOpen) {
      return;
    } else {
      this.calculateMenuHeight().then((percentage) => {
        this.menuHeight = this.mainMenu.height = percentage;
        this.mainMenu.members = this.visibleItems.length;
        this.cdr.detectChanges();
      });
    }
  
    this.updateVisibleItems(this.lastOpenMenuItem);
  }

  handleItemClick(item: MobileMenuItem): void {
    this.scrollToTop();

    if (!item.HasChildren) {
      return this.navigate(item);
    }

    if (this.expandedMenuItemIds.has(item.MenuItemId)) {
      this.expandedMenuItemIds.delete(item.MenuItemId);
    } else {
      this.addExpandedMenuItemId(item.MenuItemId);
    }

    this.lastOpenMenuItem = item;

    this.updateVisibleItems(item);
    //mobilemenuTriggerToggle(menuItem.Children[0].$id);
  }

  scrollToTop(){
    function smoothScrollTo(element, target) {
      const startPosition = element.scrollTop;
      const distance = target - startPosition;
      const duration = 500;
      let startTime = null;
    
      function scrollStep(timestamp) {
        if (!startTime) startTime = timestamp;
        const timeElapsed = timestamp - startTime;
        const progress = Math.min(timeElapsed / duration, 1); 
        element.scrollTop = startPosition + distance * easeInOutQuad(progress);
    
        if (progress < 1) {
          requestAnimationFrame(scrollStep);
        }
      }
    
      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }
    
      requestAnimationFrame(scrollStep);
    }
    
    const menu = document.querySelector('#mob-navigation');
    if (menu) {
      smoothScrollTo(menu, 0);
    }
    
  }

  setMenuClasses(item: MobileMenuItem): any {
    const isExpanded = this.expandedMenuItemIds.has(item.MenuItemId);
    return {
      'active': isExpanded,
      'selected': isExpanded
    }
  }

  private filterCategories(category: 'm' | 'f' = 'm'): void {
    category = category === 'm' ? 'f' : 'm';

    this.filteredMenuItems = this.menuItems.filter(item => !item.ClassNameAsCollection?.includes(category));

    this.lastOpenMenuItem = null;
    this.expandedMenuItemIds.clear();
    this.updateVisibleItems();
  }

  private updateVisibleItems(item?: MobileMenuItem): void {
    if (!item || this.isRootMenuClosed(item)) {
      this.visibleItems = this.buildVisibleItems(this.filteredMenuItems);
      return;
    }

    const rootMenuItemId = this.getRootMenuIdBySubMenuItemId(this.menuMap, item.MenuItemId);
    this.visibleItems = this.buildVisibleItems(this.filteredMenuItems.filter(i => i.MenuItemId === rootMenuItemId));
  }

  private isRootMenuClosed(item: MobileMenuItem): boolean {
    return item && item.IsRoot && !this.expandedMenuItemIds.has(item.MenuItemId);
  }

  private buildVisibleItems(items: MobileMenuItem[]): MobileMenuItem[] {
    let visibleItems: MobileMenuItem[] = [];

    items.forEach(item => {
      visibleItems.push(item);
      if (item.HasChildren && this.expandedMenuItemIds.has(item.MenuItemId)) {
        visibleItems = visibleItems.concat(this.buildVisibleItems(this.setSubMenu(item.Children)));
      }
    });
    this.calculateSubMenuHeight(visibleItems)
    return visibleItems;
  }

  private setSubMenu(item: MobileMenuItem[]): MobileMenuItem[] {
    return item.map(item => {
      if (item.ClassNameAsCollection.some(className => ['plus-icon', 'sub-menu'].includes(className))) {
        return item;
      }

      item.ClassNameAsCollection.push('sub-menu');
      item.ClassName = item.ClassNameAsCollection.join(' ');

      return item
    });
  }

  private getRootMenuIdBySubMenuItemId(menuMap: Map<number, number[]>, subMenuIdToFind: number): number | null {
    for (const [key, values] of menuMap) {
      if (values.includes(subMenuIdToFind)) {
        return key;
      }
    }
    return null;
  }

  private createMenuMap(menuItems: MobileMenuItem[]): Map<number, number[]> {
    const menuMap: Map<number, number[]> = new Map();

    menuItems.forEach(menuItem => {
      if (menuItem.IsRoot && menuItem.HasChildren) {
        menuMap.set(menuItem.MenuItemId, [menuItem.MenuItemId]);

        const collectedIds = menuMap.get(menuItem.MenuItemId);

        this.collectChildIds(menuItem.Children, collectedIds);
      }
    });

    return menuMap;
  }

  private collectChildIds(children: MobileMenuItem[], collectedIds: number[]): void {
    children.forEach(child => {
      collectedIds.push(child.MenuItemId);
      if (child.HasChildren) {
        this.collectChildIds(child.Children, collectedIds);
      }
    });
  }

  private addExpandedMenuItemId(menuItemId: number): void {
    const rootMenuItemId = this.getRootMenuIdBySubMenuItemId(this.menuMap, menuItemId);

    if (!this.isRootMenu(menuItemId)) {
      const expandedIdsToRemove = this.menuMap.get(rootMenuItemId)?.filter(id => !this.isRootMenu(id) && this.expandedMenuItemIds.has(id));
      expandedIdsToRemove?.forEach(id => this.expandedMenuItemIds.delete(id));

      this.expandedMenuItemIds.add(menuItemId);
      return;
    }

    if (this.isSubMenuExpanded(rootMenuItemId)) {
      this.expandedMenuItemIds.add(menuItemId);
      return;
    }

    const fristChildMenuItemId = this.getFristChildMenuId(rootMenuItemId);
    if (fristChildMenuItemId) {
      this.expandedMenuItemIds.add(fristChildMenuItemId);
    }

    this.expandedMenuItemIds.add(menuItemId);
  }

  private isRootMenu(rootMenuItemId: number): boolean {
    return this.menuMap.has(rootMenuItemId);
  }

  private isSubMenuExpanded(rootMenuItemId: number): boolean {
    return this.menuMap.get(rootMenuItemId)?.some(id => this.expandedMenuItemIds.has(id));
  }

  private getFristChildMenuId(rootMenuItemId: number): number | null {
    const fristChild = this.filteredMenuItems.find(i => i.MenuItemId === rootMenuItemId)?.Children?.at(0);
    if (!fristChild || fristChild && !fristChild.HasChildren) {
      return null;
    }

    return fristChild.MenuItemId;
  }

  private navigate(item: MobileMenuItem): void {
    if (item?.Url?.startsWith('https://') || item?.Url?.startsWith('http://')) {
      window.location.href = item.Url;
    } else {
      if (this.facetFilterState.attraqtPLPEnabled) {
        const { protocol, host } = window.location;
        this.attraqtService.sendActivity(
          new AttraqtActivityBuilder(AttraqtActivityActionEnum.View)
            .setTarget({ pathToPage: `${protocol}//${host}${item.Url}` })
            .setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) || 'en_GB' })
            .build()
        );
      }
      this.router.navigate([item.Url]);
    }

    this.isMenuOpen$.next(false);
    this.closeMenu.emit();
  }

  private subscribeToState(): void {
    this.facetFilterSubscriber = this.facetFilter$.subscribe(
      (facetFilterState) => {
        this.facetFilterState = facetFilterState;
      }
    );
  }

  calculateMenuHeight(): Promise<number> {
    return new Promise((resolve) => {
      const checkIntervalId = setInterval(() => {
        const navigationContainer = document.querySelector("#mob-navigation");

        if (navigationContainer) {
          const navigationRect = navigationContainer.getBoundingClientRect();
          const siteHeaderHeight = document.querySelector("div.site-header").clientHeight;
          const bottomPosition = navigationRect.bottom;
          const viewportHeight = this.viewportService.getViewportHeight();
          const heightPercentage = viewportHeight < 500 ?
            ((bottomPosition + siteHeaderHeight) / viewportHeight) * 120 :
            viewportHeight < 600 ?
              ((bottomPosition + siteHeaderHeight) / viewportHeight) * 115 :
              ((bottomPosition + siteHeaderHeight) / viewportHeight) * 100;

          clearInterval(checkIntervalId);
          resolve(heightPercentage);
        }
      }, 100);
    });
  }

  calculateSubMenuHeight(visibleItems) {
    if (this.visibleItems.length) {
      this.menuHeight = (this.mainMenu.height / this.mainMenu.members) * visibleItems.length;
    }
    this.removeCountryFlag();
  }

  removeCountryFlag() {
    const element = document.querySelector("#ge_ss0_1");
    if (element) {
      element.remove();
    }
  }

}
