import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { WebBrowser } from '../enums/web-browser-type-enum';
import { ViewPortEnum } from '../enums/device-type-enum';

@Injectable({
	providedIn: 'root'
})
export class ViewportService {

	private readonly deviceTypeCookie: string = "hcDeviceType";
	private browserName: string | null = null;

	constructor(
		private cookieService: CookieService) {

	    this.detectBrowser();
		this.cookieService.put(this.deviceTypeCookie, this.getDeviceType());
	}

	getDeviceType() {
		var width = window.innerWidth;

		if (width <= 639) {
			return ViewPortEnum.Mobile;

		}
		if (width <= 1099) {
			return ViewPortEnum.Tablet;
		}

		return ViewPortEnum.Desktop;
	}

	getViewportHeight() {
		return window.innerHeight;
	}

	detectBrowser() {
		if (this.browserName) {
			return this.browserName; 
		}

		const userAgent = navigator.userAgent;

		if (userAgent.indexOf(WebBrowser.Chrome) > -1) {
			this.browserName = WebBrowser.Chrome;
		} else if (userAgent.indexOf(WebBrowser.Firefox) > -1) {
			this.browserName = WebBrowser.Firefox;
		} else if (userAgent.indexOf(WebBrowser.Safari) > -1) {
			this.browserName = WebBrowser.Safari;
		} else if (userAgent.indexOf(WebBrowser.Edge) > -1) {
			this.browserName = WebBrowser.Edge;
		} else {
			this.browserName = 'Unknown';
		}

		return this.browserName; 
	}
}
