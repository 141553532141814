import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildWomenShirtGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		StepName1Text: stepName1Text,
		StepName2Text: stepName2Text,
		StepName3Text: stepName3Text,
		StepName4Text: stepName4Text,
		StepName5Text: stepName5Text,
		TextBetweenCarouselArrowsFront: txtFront,
		TextBetweenCarouselArrowsLeft: txtLeft,
		TextBetweenCarouselArrowsRight: txtRight,
		TextBetweenCarouselArrowsBack: txtBack,
		WomenShirtTitle: title,
		WomenShirtMobileBannerImageUrl: mobileBannerImage,
		WomenShirtFitTitle: fitTitle,
		MenShirtFitCompareFitPageText: compareFitPageText,
		WomenShirtFitCompareFitPageDescription: compareFitPageDescription,
		WomenShirtFitSemiFittedName: semiFittedName,
		WomenShirtFitSemiFittedDescription1: semiFittedDescription1,
		WomenShirtFitSemiFittedDescription2: semiFittedDescription2,
		WomenShirtFitSemiFittedPlpLink: semiFittedPlpLink,
		WomenShirtFitSemiFittedVideoUrl: semiFittedVideoUrl,
		WomenShirtFitSemiFittedDesktopImageUrl1: semiFittedDesktopImageUrl1,
		WomenShirtFitSemiFittedMobileImageUrl1: semiFittedMobileImageUrl1,
		WomenShirtFitSemiFittedDesktopImageUrl2: semiFittedDesktopImageUrl2,
		WomenShirtFitSemiFittedMobileImageUrl2: semiFittedMobileImageUrl2,
		WomenShirtFitSemiFittedDesktopImageUrl3: semiFittedDesktopImageUrl3,
		WomenShirtFitSemiFittedMobileImageUrl3: semiFittedMobileImageUrl3,
		WomenShirtFitSemiFittedDesktopImageUrl4: semiFittedDesktopImageUrl4,
		WomenShirtFitSemiFittedMobileImageUrl4: semiFittedMobileImageUrl4,
		WomenShirtFitFittedName: fittedName,
		WomenShirtFitFittedDescription1: fittedDescription1,
		WomenShirtFitFittedDescription2: fittedDescription2,
		WomenShirtFitFittedPlpLink: fittedPlpLink,
		WomenShirtFitFittedVideoUrl: fittedVideoUrl,
		WomenShirtFitFittedDesktopImageUrl1: fittedDesktopImageUrl1,
		WomenShirtFitFittedMobileImageUrl1: fittedMobileImageUrl1,
		WomenShirtFitFittedDesktopImageUrl2: fittedDesktopImageUrl2,
		WomenShirtFitFittedMobileImageUrl2: fittedMobileImageUrl2,
		WomenShirtFitFittedDesktopImageUrl3: fittedDesktopImageUrl3,
		WomenShirtFitFittedMobileImageUrl3: fittedMobileImageUrl3,
		WomenShirtFitFittedDesktopImageUrl4: fittedDesktopImageUrl4,
		WomenShirtFitFittedMobileImageUrl4: fittedMobileImageUrl4,
		WomenShirtFitRelaxedName: relaxedName,
		WomenShirtFitRelaxedDescription1: relaxedDescription1,
		WomenShirtFitRelaxedDescription2: relaxedDescription2,
		WomenShirtFitRelaxedPlpLink: relaxedPlpLink,
		WomenShirtFitRelaxedVideoUrl: relaxedVideoUrl,
		WomenShirtFitRelaxedDesktopImageUrl1: relaxedDesktopImageUrl1,
		WomenShirtFitRelaxedMobileImageUrl1: relaxedMobileImageUrl1,
		WomenShirtFitRelaxedDesktopImageUrl3: relaxedDesktopImageUrl3,
		WomenShirtFitRelaxedMobileImageUrl3: relaxedMobileImageUrl3,
		WomenShirtMeasurementTitle: measurementTitle,
		WomenShirtMeasurementPlpLink: measurementPlpLink,
		WomenShirtMeasurementChestName: chestName,
		WomenShirtMeasurementChestVideoUrl: chestVideoUrl,
		WomenShirtMeasurementChestStepDescription1: chestDescription1,
		WomenShirtMeasurementChestStepDescription2: chestDescription2,
		WomenShirtMeasurementChestStepDescription3: chestDescription3,
		WomenShirtMeasurementChestDesktopImageUrl: chestDesktopImageUrl,
		WomenShirtMeasurementChestMobileImageUrl: chestMobileImageUrl,
		WomenShirtMeasurementWaistName: waistName,
		WomenShirtMeasurementWaistVideoUrl: waistVideoUrl,
		WomenShirtMeasurementWaistStepDescription1: waistDescription1,
		WomenShirtMeasurementWaistStepDescription2: waistDescription2,
		WomenShirtMeasurementWaistStepDescription3: waistDescription3,
		WomenShirtMeasurementWaistDesktopImageUrl: waistDesktopImageUrl,
		WomenShirtMeasurementWaistMobileImageUrl: waistMobileImageUrl,
		WomenShirtMeasurementShoulderWidthName: shoulderWidthName,
		WomenShirtMeasurementShoulderWidthVideoUrl: shoulderWidthVideoUrl,
		WomenShirtMeasurementShoulderWidthStepDescription1:
			shoulderWidthDescription1,
		WomenShirtMeasurementShoulderWidthStepDescription2:
			shoulderWidthDescription2,
		WomenShirtMeasurementShoulderWidthStepDescription3:
			shoulderWidthDescription3,
		WomenShirtMeasurementShoulderWidthDesktopImageUrl:
			shoulderWidthDesktopImageUrl,
		WomenShirtMeasurementShoulderWidthMobileImageUrl:
			shoulderWidthMobileImageUrl,
		WomenShirtMeasurementBackLengthName: backLengthName,
		WomenShirtMeasurementBackLengthVideoUrl: backLengthVideoUrl,
		WomenShirtMeasurementBackLengthStepDescription1: backLengthDescription1,
		WomenShirtMeasurementBackLengthStepDescription2: backLengthDescription2,
		WomenShirtMeasurementBackLengthStepDescription3: backLengthDescription3,
		WomenShirtMeasurementBackLengthDesktopImageUrl: backLengthDesktopImageUrl,
		WomenShirtMeasurementBackLengthMobileImageUrl: backLengthMobileImageUrl,
		WomenShirtMeasurementSleeveLengthName: sleeveLengthName,
		WomenShirtMeasurementSleeveLengthVideoUrl: sleeveLengthVideoUrl,
		WomenShirtMeasurementSleeveLengthStepDescription1: sleeveLengthDescription1,
		WomenShirtMeasurementSleeveLengthStepDescription2: sleeveLengthDescription2,
		WomenShirtMeasurementSleeveLengthStepDescription3: sleeveLengthDescription3,
		WomenShirtMeasurementSleeveLengthStepDescription4: sleeveLengthDescription4,
		WomenShirtMeasurementSleeveLengthStepDescription5: sleeveLengthDescription5,
		WomenShirtMeasurementSleeveLengthDesktopImageUrl:
			sleeveLengthDesktopImageUrl,
		WomenShirtMeasurementSleeveLengthMobileImageUrl: sleeveLengthMobileImageUrl,
		MenShirtCuffTitle: cuffTitle,
		MenShirtCuffSingleName: cuffSingleName,
		MenShirtCuffSingleDescription: cuffSingleDescription,
		WomenShirtCuffSinglePlpLink: cuffSinglePlpLink,
		MenShirtCuffDoubleName: cuffDoubleName,
		MenShirtCuffDoubleDescription: cuffDoubleDescription,
		WomenShirtCuffDoublePlpLink: cuffDoublePlpLink,
		WomenShirtCollarTitle: collarTitle,
		WomenShirtCollarRegularName: collarRegularName,
		WomenShirtCollarRegularPlpLink: collarRegularPlpLink,
		WomenShirtCollarRegularDesktopImageUrl: collarRegularDesktopImageUrl,
		WomenShirtCollarRegularMobileImageUrl: collarRegularMobileImageUrl,
		WomenShirtCollarSemiCutawayName: collarSemiCutawayName,
		WomenShirtCollarSemiCutawayPlpLink: collarSemiCutawayPlpLink,
		WomenShirtCollarSemiCutawayDesktopImageUrl:
			collarSemiCutawayDesktopImageUrl,
		WomenShirtCollarSemiCutawayMobileImageUrl: collarSemiCutawayMobileImageUrl,
		WomenShirtCollarVintageName: collarVintageName,
		WomenShirtCollarVintagePlpLink: collarVintagePlpLink,
		WomenShirtCollarVintageDesktopImageUrl: collarVintageDesktopImageUrl,
		WomenShirtCollarVintageMobileImageUrl: collarVintageMobileImageUrl,
		WomenShirtCollarPussyBowName: collarPussyBowName,
		WomenShirtCollarPussyBowPlpLink: collarPussyBowPlpLink,
		WomenShirtCollarPussyBowDesktopImageUrl: collarPussyBowDesktopImageUrl,
		WomenShirtCollarPussyBowMobileImageUrl: collarPussyBowMobileImageUrl,
		WomenShirtCollarRegularDescription: collarRegularDescription,
		WomenShirtCollarSemiCutawayDescription: collarSemiCutawayDescription,
		WomenShirtCollarVintageDescription: collarVintageDescription,
		WomenShirtCollarPussyBowDescription: collarPussyBowDescription,
		WomenShirtCuffSingleDesktopImageUrl: cuffSingleDesktopImageUrl,
		WomenShirtCuffSingleMobileImageUrl: cuffSingleMobileImageUrl,
		WomenShirtCuffDoubleDesktopImageUrl: cuffDoubleDesktopImageUrl,
		WomenShirtCuffDoubleMobileImageUrl: cuffDoubleMobileImageUrl,
	} = fitGuideSetting;

	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: fitTitle,
				subFitGuidePageText: compareFitPageText,
				subFitGuidePageDescription: compareFitPageDescription,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: semiFittedName,
						description1: semiFittedDescription1,
						description2: semiFittedDescription2,
						plpLink: semiFittedPlpLink,
						videoLink: semiFittedVideoUrl,
						isActive: false,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: semiFittedDesktopImageUrl1,
								mobile: semiFittedMobileImageUrl1,
							},
							{
								desktop: semiFittedDesktopImageUrl2,
								mobile: semiFittedMobileImageUrl2,
							},
							{
								desktop: semiFittedDesktopImageUrl3,
								mobile: semiFittedMobileImageUrl3,
							},
							{
								desktop: semiFittedDesktopImageUrl4,
								mobile: semiFittedMobileImageUrl4,
							},
						],
					},
					{
						name: fittedName,
						description1: fittedDescription1,
						description2: fittedDescription2,
						plpLink: fittedPlpLink,
						videoLink: fittedVideoUrl,
						isActive: true,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: fittedDesktopImageUrl1,
								mobile: fittedMobileImageUrl1,
							},
							{
								desktop: fittedDesktopImageUrl2,
								mobile: fittedMobileImageUrl2,
							},
							{
								desktop: fittedDesktopImageUrl3,
								mobile: fittedMobileImageUrl3,
							},
							{
								desktop: fittedDesktopImageUrl4,
								mobile: fittedMobileImageUrl4,
							},
						],
					},
					{
						name: relaxedName,
						description1: relaxedDescription1,
						description2: relaxedDescription2,
						plpLink: relaxedPlpLink,
						videoLink: relaxedVideoUrl,
						isActive: false,
						textBetweenArrows: [txtFront, txtBack],
						images: [
							{
								desktop: relaxedDesktopImageUrl1,
								mobile: relaxedMobileImageUrl1,
							},
							{
								desktop: relaxedDesktopImageUrl3,
								mobile: relaxedMobileImageUrl3,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right1 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: measurementTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: chestName,
						plpLink: measurementPlpLink,
						videoLink: chestVideoUrl,
						images: [
							{
								desktop: chestDesktopImageUrl,
								mobile: chestMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: chestDescription1,
							},
							{
								name: stepName2Text,
								description: chestDescription2,
							},
							{
								name: stepName3Text,
								description: chestDescription3,
							},
						],
						isActive: false,
					},
					{
						name: waistName,
						plpLink: measurementPlpLink,
						videoLink: waistVideoUrl,
						images: [
							{
								desktop: waistDesktopImageUrl,
								mobile: waistMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: waistDescription1,
							},
							{
								name: stepName2Text,
								description: waistDescription2,
							},
							{
								name: stepName3Text,
								description: waistDescription3,
							},
						],
						isActive: false,
					},
					{
						name: shoulderWidthName,
						plpLink: measurementPlpLink,
						videoLink: shoulderWidthVideoUrl,
						images: [
							{
								desktop: shoulderWidthDesktopImageUrl,
								mobile: shoulderWidthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: shoulderWidthDescription1,
							},
							{
								name: stepName2Text,
								description: shoulderWidthDescription2,
							},
							{
								name: stepName3Text,
								description: shoulderWidthDescription3,
							},
						],
						isActive: false,
					},
					{
						name: backLengthName,
						plpLink: measurementPlpLink,
						videoLink: backLengthVideoUrl,
						images: [
							{
								desktop: backLengthDesktopImageUrl,
								mobile: backLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: backLengthDescription1,
							},
							{
								name: stepName2Text,
								description: backLengthDescription2,
							},
							{
								name: stepName3Text,
								description: backLengthDescription3,
							},
						],
						isActive: false,
					},
					{
						name: sleeveLengthName,
						plpLink: measurementPlpLink,
						videoLink: sleeveLengthVideoUrl,
						images: [
							{
								desktop: sleeveLengthDesktopImageUrl,
								mobile: sleeveLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: sleeveLengthDescription1,
							},
							{
								name: stepName2Text,
								description: sleeveLengthDescription2,
							},
							{
								name: stepName3Text,
								description: sleeveLengthDescription3,
							},
							{
								name: stepName4Text,
								description: sleeveLengthDescription4,
							},
							{
								name: stepName5Text,
								description: sleeveLengthDescription5,
							},
						],
						isActive: true,
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: cuffTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: cuffSingleName,
						description1: cuffSingleDescription,
						plpLink: cuffSinglePlpLink,
						isActive: false,
						images: [
							{
								desktop: cuffSingleDesktopImageUrl,
								mobile: cuffSingleMobileImageUrl,
							},
						],
					},
					{
						name: cuffDoubleName,
						description1: cuffDoubleDescription,
						plpLink: cuffDoublePlpLink,
						isActive: true,
						images: [
							{
								desktop: cuffDoubleDesktopImageUrl,
								mobile: cuffDoubleMobileImageUrl,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: collarTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: collarRegularName,
						plpLink: collarRegularPlpLink,
						description1: collarRegularDescription,
						images: [
							{
								desktop: collarRegularDesktopImageUrl,
								mobile: collarRegularMobileImageUrl,
							},
						],
						isActive: false,
					},
					{
						name: collarSemiCutawayName,
						plpLink: collarSemiCutawayPlpLink,
						description1: collarSemiCutawayDescription,
						images: [
							{
								desktop: collarSemiCutawayDesktopImageUrl,
								mobile: collarSemiCutawayMobileImageUrl,
							},
						],
						isActive: true,
					},
					{
						name: collarVintageName,
						plpLink: collarVintagePlpLink,
						description1: collarVintageDescription,
						images: [
							{
								desktop: collarVintageDesktopImageUrl,
								mobile: collarVintageMobileImageUrl,
							},
						],
						isActive: false,
					},
					{
						name: collarPussyBowName,
						plpLink: collarPussyBowPlpLink,
						description1: collarPussyBowDescription,
						images: [
							{
								desktop: collarPussyBowDesktopImageUrl,
								mobile: collarPussyBowMobileImageUrl,
							},
						],
						isActive: false,
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildWomenShirtGuideViewModel;
