import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, NgZone, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { List } from 'src/app/model/country-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { HomeService } from 'src/app/modules/Home/home.service';
import { AddScriptStyleLinks, AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { CheckoutSwitcherService } from '../../checkout-switcher.service';
import { CountriesSelectLists, DeliveryDetail, StateList, TitleList } from '../../models/checkout-delivery.model';
import { Label } from '../../models/Labels';
import { PaymentVM } from '../../models/payment-vm';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingOptions, ShippingToClickAndCollect, ShippingVM, UpdateBasketShipping } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { CartService } from '../../service/cart.service';
import { LabelService } from '../../service/label.service';
import { ScrollService } from '../../service/scroll.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { LoaderService } from '../loader/loader.service';

@Component({
	selector: 'app-checkout-delivery',
	templateUrl: './checkout-delivery.component.html',
	styleUrls: ['./checkout-delivery.component.scss']
})
export class CheckoutDeliveryComponent implements OnInit, AfterViewInit {

	frmDelivery: UntypedFormGroup;
	frmccFindStore: UntypedFormGroup;
	sitecontent: SiteContentDetail;
	regExLibrary: RegExLibrary;
	label: Label;
	isGuest: boolean = false;
	submitted: boolean = false;
	isDropDownActive: boolean = false;
	shippingVM: ShippingVM[];
	shippingModel: ShippingOptions;
	deliveryFormLoaded: Promise<boolean>;
	IsGift: boolean = false;
	postcode: string;
	activeTab: string = "Tab1";
	findPostCodeForm: UntypedFormGroup;
	submitfindPostCode: boolean = false;
	deliveryDetail: DeliveryDetail;
	selectedStore: any;
	selectedShippingId: number = 0;
	updateBasketShipping: UpdateBasketShipping;
	shippingToClickAndCollect: ShippingToClickAndCollect;
	paymentVM: PaymentVM;
	isClickAndCollect: boolean = false;
	isAddressExist: boolean = false;
	isDutyFree: boolean = false;
	addCustomCurl: boolean = false;
	scriptContent = [];
	deliveryAddressDetails = {
		title: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		phoneCountryId: '',
		countryId: 0,
		country: '',
		addressOne: '',
		addressTwo: '',
		city: '',
		county: '',
		postCode: '',
		email: '',
		townCity: '',
	};
	countryList: CountriesSelectLists[] = [];
	titleList: TitleList[] = [];
	stateList: StateList[] = [];
	countriesList: List[] = [];
	useThisAddressClicked: boolean = false;
	LpAppSetting: LpAppSetting;
	currentCulture: string;
	isUpdated: boolean = false;
	hasCustomSizes: boolean = false;

	constructor(private sharedService: SharedService,
		private fb: UntypedFormBuilder,
		private r: Renderer2,
		private router: Router,
		private homeService: HomeService,
		private titleService: Title,
		private meta: Meta,
		private cdr: ChangeDetectorRef,
		private siteContentService: SiteContentService,
		private route: ActivatedRoute,
		private renderer: Renderer2,
		private scrollService: ScrollService,
		private labelService: LabelService,
		private cartService: CartService,
		private checkoutSwitcherService: CheckoutSwitcherService,
		private loaderService: LoaderService,

		public sanitizer: DomSanitizer,
		public zone: NgZone,

		@Inject(DOCUMENT)
		private document,

		@Inject(PLATFORM_ID)
		private platformId: any) {
		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			// Goto Global-E checkout if operated by them
			if (this.checkoutSwitcherService.isOperatedByGlobalE()) {
				this.checkoutSwitcherService.gotoCheckoutPage(null);
			}

			this.getSiteContent();
			this.getLpAppSetting();
			this.isGuest = localStorage.getItem('isGuestLogin') ? true : false;
			this.scrollService.windowScrollTo(0, 0);
			this.getRegExLibrary();

			this.titleService.setTitle('');
			this.meta.updateTag({ name: 'description', content: '' });

			localStorage.removeItem("activeTab");
			localStorage.removeItem("ccpostcode");

			this.cartService.GetBasket().subscribe(data => {
				const basketItems = data.BasketItems.filter(x => x.CustomSizes.length > 0);

				if (basketItems.length > 0) {
					this.hasCustomSizes = true;
				}
			})
		}
	}

	ngAfterViewInit() {
		if (this.addCustomCurl) {
			this.addCustomCurl = false;

			if (this.LpAppSetting && this.LpAppSetting.CurrentCulture) {
				this.renderer.setAttribute(document.querySelector('html'), 'lang', this.LpAppSetting.CurrentCulture);
				this.currentCulture = this.LpAppSetting.CurrentCulture;
			}
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();

		if (!this.LpAppSetting) {
			this.LpAppSetting = await this.sharedService.getLpAppSettings();
			setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
		}

		this.addCustomCurl = true;
		this.ngAfterViewInit();
	}

	getSiteContent() {

		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	getRegExLibrary() {
		this.sharedService.getRegExLibrary().subscribe(data => {
			if (data) {
				this.regExLibrary = data;
				this.getAllLabels();
				this.getDeliveryData();
			}
		});
	}

	getDeliveryData() {

		let data = {
			isGuest: true,
		}

		this.shippingModel = {} as ShippingOptions;

		if (!this.isGuest) {

			data.isGuest = false;
		}

		this.sharedService.getDeliveryDetails(data).subscribe(res => {

			if (res && res.result) {
				if (res.returnUrl && res.returnUrl === "shopping") {
					this.router.navigate(['/checkout/shoppingbasket']);
					return;
				}

				if (res.returnUrl && res.returnUrl === "welcome") {
					this.router.navigate(['/checkout/welcome']);
					return;
				}
			}

			if (res && res.Basket && res.Basket.BasketTotalItemCount == 0) {
				this.router.navigate(['/checkout/shoppingbasket']);
				return;
			}

			if (res && res.Basket && res.Basket.AddressShipping) {
				this.setIsDutyFree(res.Basket.AddressShipping);
			}

			this.deliveryDetail = res;
			this.getTrackingScript();
			this.deliveryFormInitilizeWithData(res);
			this.shippingModel.countryId = res.CountryId;
			this.shippingModel.isClickAndCollect = false;
			this.shippingModel.postcode = res.PostZipCode;
			this.getShippingOptions(this.shippingModel);
			this.countryList = res.CountriesSelectList.List;
			this.stateList = res.StateList;
			this.countriesList = res.CountriesSelectList.List;
			this.deliveryAddressDetails.title = res.Title;
			this.deliveryAddressDetails.firstName = res.FirstName;
			this.deliveryAddressDetails.lastName = res.LastName;
			this.deliveryAddressDetails.email = res.Email;
			this.titleList = res.TitleList;
			this.deliveryAddressDetails.phoneNumber = res.PhoneNumber;
			this.deliveryAddressDetails.phoneCountryId = res.PhoneCountryId;
			this.deliveryAddressDetails.countryId = res.CountryId;
			this.deliveryAddressDetails.country = res.Country;
			this.deliveryAddressDetails.addressOne = res.AddressLine1;

			if (res.AddressLine1) {
				this.isAddressExist = true;
				this.useThisAddressClicked = true;
			}

			this.deliveryAddressDetails.addressTwo = res.AddressLine2;
			this.deliveryAddressDetails.city = res.TownCity;
			this.deliveryAddressDetails.postCode = res.PostZipCode;
			this.deliveryAddressDetails.county = res.County;

			this.deliveryFormLoaded = Promise.resolve(true);
		});
	}

	countyChangeEvent(event) {
		this.getShippingOptions(event);
	}

	getShippingOptions(shippingModel: ShippingOptions) {

		this.sharedService.getShippingOptions(shippingModel).subscribe(data => {
			if (data) {
				this.shippingVM = data;

				if (this.shippingVM) {
					for (let i = 0; i < this.shippingVM.length; i++) {

						if (this.shippingVM[i].Selected == true) {
							// this.selectedShippingId = this.shippingVM[i].Id;
							this.updateShippingOption(this.shippingVM[i].Id, this.deliveryDetail.CountryId);
							this.updateDeliveryMethod(false, this.sitecontent.Shipping, this.shippingVM[i].Id);
							break;
						}

						if (i == this.shippingVM.length - 1) {
							// this.selectedShippingId = this.shippingVM[0].Id;
							this.updateShippingOption(this.shippingVM[0].Id, this.deliveryDetail.CountryId);
							this.updateDeliveryMethod(false, this.sitecontent.Shipping, this.shippingVM[0].Id);
						}
					}
				}
			} else {
				this.shippingVM = [];
			}
		});
	}

	updateShipping(shipping) {
		if (shipping && this.deliveryDetail) {
			this.updateShippingOption(shipping.Id, this.deliveryDetail.CountryId);
		}
	}

	getAllLabels() {
		this.label = this.labelService.getLabelData();

		if (!this.label) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.label = data;
				}
			});
		}
	}

	getCountryId(countryId, deliveryAddressDetailsCountryId) {
		if (countryId === deliveryAddressDetailsCountryId) {
			return true;
		}

		return false;
	}

	continueToPayment() {

		this.submitted = true;

		if (!this.frmDelivery.valid) {
			return;
		}

		let obj = {
			...this.frmDelivery.value,
		}

		localStorage.setItem("selectedAddress", null)
		localStorage.setItem('deliveryTabDetails', JSON.stringify(obj));

		if (this.selectedShippingId) {
			this.frmDelivery.controls['selectedShippingId'].setValue(this.selectedShippingId);
		}

		this.sharedService.deliveryDetailSave(this.frmDelivery.value).subscribe(result => {
			this.router.navigate(['checkout/payment']);
		});
	}

	deliveryFormInitilizeWithData(data) {
		let title = "Mr";

		if (this.LpAppSetting && this.LpAppSetting.WebStoreId === 2) {
			title = "Herr";
		}

		if (data.Title && data.TitleList && data.TitleList.length > 0) {
			let titleId = +data.Title;
			if (typeof titleId === "number") {
				let titles = data.TitleList.filter(x => x.TitleId === titleId);
				if (titles && titles.length > 0) {
					title = titles[0].Name;
				}
			} else {
				title = data.Title;
			}
		}

		this.frmDelivery = this.fb.group({
			firstName: [data.FirstName ? data.FirstName : '', [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
			lastName: [data.LastName, [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
			title: [title, Validators.required],
			phoneNumber: [data.PhoneNumber, [
				Validators.required,
				Validators.minLength(5), Validators.maxLength(20),
				Validators.pattern(this.regExLibrary.Phone)
			]],
			phoneCountryId: [data.PhoneCountryId],
			country: [data.Country],
			countryCode: ['GB'],
			addAsAGift: [false],
			addressLine1: [data.AddressLine1, Validators.required],
			addressLine2: [data.AddressLine2],
			townCity: [data.TownCity, Validators.required],
			stateCode: [data.StateCode],
			countryId: [data.CountryId],
			postZipCode: [data.PostZipCode, Validators.required],
			county: [data.County],
			selectedShippingId: [this.selectedShippingId],
			deliveryTypeSelected: [data.DeliveryTypeSelected],
		})
	}

	changeToClick(tabvalue) {
		localStorage.setItem("activeTab", tabvalue)
		this.activeTab = tabvalue;
	}

	initiatePostalForm() {
		this.frmccFindStore = this.fb.group({
			postCodeFind: ['', [Validators.required, Validators.maxLength(10)]],
		});
	}

	submitfindPostCodeForm() {

		this.submitfindPostCode = true;

		if (this.frmccFindStore.invalid) {
			return;
		}

		this.homeService.getPostcodeDetails(this.findPostCodeForm.value).subscribe(res => {

		});
	}

	selectStore(store) {
		this.selectedStore = store;
	}

	useThisAddressClickedEvent($event) {
		this.useThisAddressClicked = $event;
	}

	updateDeliveryMethod(isClickAndCollect, shippingText, shippingId) {
		this.isClickAndCollect = isClickAndCollect;

		var price = 0.00;

		if (shippingId) {
			var record = this.shippingVM.filter(function (x) { return x.Id === shippingId; });
			if (record && record.length > 0) {
				price = record[0].Price;
			}
		} else {
			price = (this.selectedStore && this.selectedStore.length > 0) ? this.selectedStore.Price : 0.00;
		}

		this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;
		this.shippingToClickAndCollect.price = price;
		this.shippingToClickAndCollect.shippingText = shippingText;
		this.shippingToClickAndCollect.showdeliveryoption = !isClickAndCollect;
	}

	refreshShoppingBasket(shippingOption: ShippingToClickAndCollect) {

		this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;

		if (shippingOption) {
			this.shippingToClickAndCollect = shippingOption;
		}
	}

	updateShippingOption(shippingOptionsId, CountryId) {
		this.isUpdated = false;

		setTimeout(() => {
			// this.loaderService.isLoading.next(false);
			this.loaderService.isLoading.next(true);
		})

		if (CountryId) {
			localStorage.setItem('countryId', CountryId);
		}

		this.updateBasketShipping = {} as UpdateBasketShipping;
		this.updateBasketShipping.ShippingId = shippingOptionsId;
		this.selectedShippingId = shippingOptionsId;
		this.updateBasketShipping.CountryId = CountryId;

		if (shippingOptionsId) {
			this.frmDelivery.controls['selectedShippingId'].setValue(shippingOptionsId);
		}

		this.homeService.updateBasketShippingOption(this.updateBasketShipping).subscribe(res => {
			this.isUpdated = true;
			if (this.isUpdated) {
				this.loaderService.isLoading.next(false);
			}
		});
	}

	shippingIdChanged($event) {
		this.selectedShippingId = $event;

		if ($event && this.deliveryDetail) {
			this.updateShippingOption($event, this.deliveryDetail.CountryId);
		}

		this.updateDeliveryMethod(false, this.sitecontent.Shipping, $event);
	}

	setIsDutyFree(addressShipping) {
		// Assign Duty Free if country IsEU and not UK
		if (addressShipping) {
			this.isDutyFree = addressShipping.IsCountryEU && addressShipping.CountryId != 1;
		}
	}

	getTrackingScript() {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.CheckoutDelivery;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts();
			}, err => {
			});
		}
	}

	applyTrackingScripts() {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}
	}

	//initilizedeliveryForm() {
	//	this.frmDelivery = this.fb.group({
	//		firstName: ['', [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
	//		lastName: ['', [Validators.required, Validators.pattern(this.regExLibrary.FirstLastName)]],
	//		title: ['', Validators.required],
	//		phoneNumber: ['', [
	//			Validators.required,
	//			Validators.minLength(5), Validators.maxLength(20),
	//			Validators.pattern(this.regExLibrary.Phone)
	//		]],
	//		phoneCountryId: [''],
	//		country: [''],
	//		countryCode: ['GB'],
	//		addAsAGift: [false],
	//		addressLine1: [''],
	//		addressLine2: [''],
	//		townCity: [''],
	//		stateCode: [''],
	//		county: [''],
	//		countryId: [],
	//		postZipCode: [''],
	//		selectedShippingId: [this.selectedShippingId],
	//	})
	//}

	//countryChanged() {
	//}
}
