export enum PersonifyRecommendationType {
    PRODUCT = 0,
    CONTENT = 1,
    ALL = 2,
    CATEGORY = 3
}

export enum ProductRecsTypeEnum {
    MiniBasket = 0,
    PDP = 1,
    ShoppingBasket = 2
}

export interface ProductRecommendationsVM {
    ProductId: number;
    ProductName: string;
    ProductUrl: string;
    Images: PRVMImage[];
    IsOnSale: boolean;
    SalePrice: number;
    RetailPrice: number;
    CurrencyPrefix: string;
}

export interface PRVMImage {
    Id: number;
    Url: string;
    ClassName: string;
    AltText: string;
}

export interface PersonifyRequest {
    sessionid: string;
    productcontext?: string;
    basket?: PRQBasket[];
    shopperid?: string;
    algorithm?: string;
    pagesize?: number;
    rectype?: number;
    currency?: string;
    device?: string;
    country?: string;
    language?: string;
    products?: string[];
}

export interface PersonifyResponse {
    request: PersonifyRequest;
    mission_attributes: any[];
    propensity: number;
    missions: PRSMission[];
    flags: PRSFlag[];
    mission_tags: any[];
    recommendations: PRSRecommendation[];
    socialmessages: PRSMessage[];
    productdata: PRSProductdata[]
}

export interface PRQBasket {
    productid: string;
    quantity: number;
    unitprice: number;
}

export interface PRSMission {
    val: number;
    name: string;
    id: number;
}

export interface PRSFlag {
    code: string;
    data: any;
}

export interface PRSRecommendation {
    image: string;
    productcode: string;
    rectype: string;
    varietymap: PRSVarietymap[];
    image_alt: string;
    link: string;
    rating: number;
    description: string;
    rrp: number;
    reviewcount: number;
    price: number;
    name: string;
    category: string;
    brand: string;
}

export interface PRSVarietymap {
    variety_name: string;
    variety_link: string;
    variety_id: string;
    variety_sizes: string;
    variety_image: string;
    variety_skus: string;
}

export interface PRSMessage {
    itemtype?: string
    productcode?: string
    quantity: number
    score: number
    splitid: string
    type: string
}

export interface PRSProductdata {
    productcode: string
    size_coverage_coverage: number
    purchased: PRSPurchased[]
    added: PRSAdded[]
    viewed: PRSViewed[]
    messages: PRSMessage[]
    type: string
    stock: PRSStock[]
    variant_coverage_coverage: number
    total_stock: number
}

interface PurchaseRecord {
    quantity: number;
    splitid: string;
    variant?: string;
}

export interface PRSPurchased extends PurchaseRecord { }
export interface PRSAdded extends PurchaseRecord { }
export interface PRSViewed extends PurchaseRecord { }
export interface PRSStock extends PurchaseRecord { }

export interface ProductDetailRequest {
    Reference: string;
    ImageKeys: string;
    ProductsCriteria: PDRQCriteria[];
}

export interface PDRQCriteria {
    Sku: string;
    ThirdPartyId: string;
}

export interface ProductDetailResponse {
    Result: string;
    Desc: PDRSDesc;
}

export interface PDRSDesc {
    UseEnhancedAnalytics: boolean;
    Reference: string;
    Products: PDRSProduct[];
    Sizes: any;
}

export interface PDRSProduct {
    ProductId: number;
    RecommendationId: string;
    Index: number;
    Code: string;
    Name: string;
    Description: string;
    CommodityCode: any;
    ShortName: any;
    RetailPrice: number;
    SalePrice: number;
    BundlePrice: number;
    CurrencyPrefix: string;
    Images: PDRSImage[];
    Videos: any[];
    ImageKeys: string;
    SeoUrl: string;
    ProductUrl: string;
    Promotion: any;
    PromoTxtColour: any;
    InWishlist: boolean;
    ColourId: number;
    Colour: string;
    IsOnSale: boolean;
    IsNewArrival: boolean;
    IsVirtual: boolean;
    Qty: number;
    Settings: any;
    SpecialSettings: any;
    GoogleProductCategory: string;
    ProductCategory: any;
    CustomPriceText: string;
    ShowCustomPriceText: boolean;
    ShowBundlePrice: boolean;
    PromoBadgeText: any;
    PromoBadgeTextColour: any;
    Icons: PDRSIcon[];
    BadgeText: any;
    BadgeTextColour: any;
    BadgeCssClass: any;
    ShowBadge: boolean;
    InventoryProducts: any;
    SelectedInventoryProductId: any;
    IsOneSize: boolean;
    SizesSelectList: any;
    WebStoreId: number;
    LanguageId: number;
    CurrencyId: number;
    CustomerId: any;
    CustomerTempId: any;
    BasketId: number;
    DisplayMessage: boolean;
}

export interface PDRSImage {
    ImageId: number;
    ImageGroupKey: string;
    ProductId: number;
    SortOrder: number;
    ImageSizes: PDRSImageSize[];
    AttributeValues: any[];
    AltText: string;
}

export interface PDRSImageSize {
    Filename: string;
    SeoText: string;
    Key: string;
    CdnUrl: string;
    CdnFullUrl: string;
    Width: string;
    Height: string;
}

export interface PDRSIcon {
    Id: number;
    Name: string;
    CssClass: any;
    Colour: string;
    Filename: any;
    Text: string;
    IsActive: boolean;
}