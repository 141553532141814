import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { AddBasket, PromoCodeVM } from 'src/app/model/basket-viewmodel';
import { BasketItem, BasketPageViewModel } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { RelatedProductVM } from 'src/app/model/related-product-viewmodel';
import { ShoppingBasket } from 'src/app/model/shopping-basket';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { Label } from 'src/app/shared/models/Labels';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { AdyenService } from 'src/app/shared/service/adyen.service';
import { CartService } from 'src/app/shared/service/cart.service';
import { LabelService } from 'src/app/shared/service/label.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { InitialiseBasketTracking } from '../../../../assets/shared/gtm.js';
import { AddTrackingScriptToDOM, AddScriptStyleLinks } from '../../../../assets/shared/tracking';
import { PaypalService } from '../../../shared/service/paypal.service';
import { ScrollService } from '../../../shared/service/scroll.service';
import { DefaultService } from '../../layout/default.service';
import { AddToCartData } from '../../../shared/models/cart-model.js';
import { AttraqtService } from 'src/app/shared/service/attraqt.service';
import { AttraqtActivityBuilder } from 'src/app/shared/builder/attraqt/activity-builder';
import { AttraqtActivityActionEnum } from 'src/app/shared/enums/attraqt-activity-action.enum';
import { NAVIGATOR_LANGUAGE } from '../../../shared/constants/attraqt';
import { FacetFilterState } from '../../../shared/state/facet-filter/facet-filter.state';
import { IFacetFilterState } from '../../../shared/interfaces/attraqt/facet-filter-state.interface';
import { CheckoutSwitcherService } from '../../../shared/checkout-switcher.service';

@Component({
	selector: 'app-shopping-basket',
	templateUrl: './shopping-basket.component.html',
	styleUrls: ['./shopping-basket.component.scss'],
})
export class ShoppingBasketComponent implements OnInit, AfterViewInit, OnDestroy {

	cartItems: any[];
	totalAmount: number = 0;
	promoCodeForm: UntypedFormGroup
	showPromoCodeMessage: boolean = false;
	messagesForPromoCode: string = '';
	shoppingBasket: ShoppingBasket;
	basketPageViewModel: BasketPageViewModel;
	LpAppSetting: LpAppSetting;
	showBundleDetails: boolean = true;
	enabled: boolean = true;
	deleteBasketItem: AddBasket;
	addBasket: AddBasket;
	cartProduct: AddToCartData;
	promocodeVM: PromoCodeVM;
	amazonClientId: string;
	amazonSellerId: string;
	amazonIsSandbox: string = "1";
	paypalMerchantId: string = "";
	paypalEnvironment: string = "";
	countryCode: string = "";
	scriptContent = [];
	siteContent: SiteContentDetail;
	labelData: Label;
	classlist = "fixed-header mask-hide ng-scope ";
	updated: boolean = false;
	relatedProducts: RelatedProductVM = null;
	facetFilterSubscriber: Subscription;
	facetFilterState: IFacetFilterState;
	@Select(FacetFilterState) facetFilter$: Observable<IFacetFilterState>;

	constructor(
		private cartService: CartService,
		private fb: UntypedFormBuilder,
		private router: Router,
		private defaultService: DefaultService,
		@Inject(DOCUMENT) document,
		private r: Renderer2,
		private titleService: Title,
		private meta: Meta,
		private paypalService: PaypalService,
		private sharedService: SharedService,
		private adyenService: AdyenService,
		private siteContentService: SiteContentService,
		private scrollService: ScrollService,
		private labelService: LabelService,
		private attraqtService: AttraqtService,
		private checkoutSwitcherService: CheckoutSwitcherService,

		@Inject(PLATFORM_ID)
		private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass(this.classlist);

			router.events
				.subscribe((event: NavigationStart) => {
					if (event.navigationTrigger === 'popstate') {

						this.scrollService.windowScrollTo(0, 0);
					}
				});
		}
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this.subscribeToState();
			this.cartService.basketUpdated = true;
			this.getLpAppSetting();
			this.siteContent = this.siteContentService.getSiteContentData();
			this.labelData = this.labelService.getLabelData();

			if (this.LpAppSetting) {
				if (this.LpAppSetting.UserSettings.PromoLineBarHeader == "") {
					this.classlist += "no-promo-text ";
					$("body").removeClass();
					$("body").addClass(this.classlist);
				}
			}

			this.getPaymentModel();
			this.getShoppingCartData();

			localStorage.removeItem('filteredList');
			localStorage.removeItem('filterFacestsData');

			this.scrollService.windowScrollTo(0, 0);
			this.titleService.setTitle(this.siteContent.ShoppingBasket);
			this.meta.updateTag({ name: 'description', content: '' });
			this.sharedService.menuItemSelected(window.location.pathname);
		}
	}

	ngAfterViewInit() {
		if (this.basketPageViewModel &&
			this.basketPageViewModel.ShoppingBasketPage &&
			this.basketPageViewModel.ShoppingBasketPage.Basket &&
			this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems &&
			this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems.length > 0) {
		}

		if (this.LpAppSetting) {
			setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
		}
	}

	ngOnDestroy() {
		this.facetFilterSubscriber.unsubscribe();
	}

	removePromoCode() {
		this.promocodeVM = {} as PromoCodeVM;

		this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {
			if (res) {
				if (res.Result && +res.Result === 0 || res.Result == 0) {

					if (this.basketPageViewModel && this.basketPageViewModel.ShoppingBasketPage && this.basketPageViewModel.ShoppingBasketPage.Basket) {

						this.basketPageViewModel.ShoppingBasketPage.Basket.PromoCode = null;
						this.showPromoCodeMessage = false;
						this.messagesForPromoCode = res.Message;

						this.cartService.GetBasket().subscribe(data => {
							if (data) {
								this.basketPageViewModel.ShoppingBasketPage.Basket = data;

								//gmt start
								if (data && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
									InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data, false, false)
								}
								//gmt end
							}
						})
					}
				}
			}
		})
	}

	onQuantityChanged(quantity, cartItem) {

		this.cartProduct = {} as AddToCartData;
		this.cartProduct.productCode = cartItem.Code;
		this.cartProduct.ean = cartItem.Ean;
		this.cartProduct.quantity = cartItem.Quantity;
		this.cartProduct.productName = cartItem.Name;
		this.cartProduct.currencyCode = cartItem.CurrencyCode;
		this.cartProduct.retailPrice = cartItem.RetailPrice;
		this.cartProduct.salePrice = cartItem.SalePrice;
		//console.log("Calling shopping-basket onQuantityChanged -> " + JSON.stringify(this.addToCartData));

		if (quantity && cartItem) {

			this.addBasket = {} as AddBasket;

			if (cartItem.newQty) {
				if (cartItem.newQty < +cartItem.Quantity) {
					this.addBasket.quantity = +cartItem.Quantity - cartItem.newQty;
				} else {
					this.addBasket.quantity = +cartItem.Quantity - cartItem.newQty;
				}
			}

			if (cartItem) {
				this.addBasket.inventoryProduct = cartItem.InventoryProductId;
				this.addBasket.customSizes = cartItem.CustomSizes;
			}

			let userSignedIn = localStorage.getItem("isSignedIn");

			this.cartService.AddToBasket(this.addBasket).subscribe(data => {
				if (data && data.result) {
					this.cartService.refreshLocalBasket(data.result, true);

					this.cartService.trackCartProduct(data.result, this.cartProduct, this.LpAppSetting);
				}
			});
		}
	}

	getUserInterfaceValues(item) {

		// item.Quantity -- Selected Quantity
		// item.MaxQuantity -- Maximum Quantity of item

		item.newQty = item.Quantity;
		item.qtyOptions = [];

		var maxValue = 0;

		// Fix to prevent browser javascript from locking up trying to render 100+ option html elements
		if (item.Quantity >= 10) {
			maxValue = 100;
		} else {
			maxValue = Math.min(item.MaxQuantity, 10)
		}

		// Ensure maxValue is never larger than available
		if (maxValue > item.MaxQuantity) {
			maxValue = item.MaxQuantity;
		}

		for (var loop = 1; loop <= maxValue; loop++) {
			item.qtyOptions.push(loop);
		}

		return item.qtyOptions;
	}

	removeProductFromCart(productItem: BasketItem) {

		this.deleteBasketItem = {} as AddBasket;
		this.deleteBasketItem.quantity = 0;
		let userSignedIn = localStorage.getItem("isSignedIn");

		if (productItem) {
			this.deleteBasketItem.quantity = -productItem.Quantity;
			this.deleteBasketItem.inventoryProduct = productItem.InventoryProductId;
			this.deleteBasketItem.customSizes = productItem.CustomSizes;
			if (this.facetFilterState.attraqtPLPEnabled) {
				this.attraqtService.sendActivity(
					new AttraqtActivityBuilder(AttraqtActivityActionEnum.RemoveFromCart)
						.setTarget({ product: productItem.ProductId.toString() })
						.setMetadata({ locale: localStorage.getItem(NAVIGATOR_LANGUAGE) })
						.build()
				);
			}
		}

		this.cartProduct = {} as AddToCartData;
		this.cartProduct.productCode = productItem.Code;
		this.cartProduct.ean = productItem.Ean;
		this.cartProduct.quantity = 0;
		this.cartProduct.productName = productItem.Name;
		this.cartProduct.currencyCode = productItem.CurrencyCode;
		this.cartProduct.retailPrice = productItem.RetailPrice;
		this.cartProduct.salePrice = productItem.SalePrice;
		//console.log("Calling shopping-basket removeProductFromCart -> " + JSON.stringify(this.addToCartData));

		this.cartService.AddToBasket(this.deleteBasketItem).subscribe(data => {
			if (data && data.result) {
				this.cartService.refreshLocalBasket(data.result, true);

				this.cartService.trackCartProduct(data.result, this.cartProduct, this.LpAppSetting);
			}
		});
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = await this.sharedService.getLpAppSettings();
		this.addScriptToDom();
	}

	addScriptToDom() {
		if (this.LpAppSetting) {
			this.countryCode = this.LpAppSetting.CurrentCulture;
			this.ngAfterViewInit();
		}
	}

	getShineColor(color) {
		let shineColor = { "--color-qty": color };
		return shineColor;
	}

	slideConfig = {
		"slidesToShow": 4,
		"slidesToScroll": 1,
		"nextArrow": "<button class='slick-prev slick-arrow' aria-label='Previous' type='button' aria-disabled='false' style='display: block;left: 0%;top:38%'><i></i><span></span></button>",
		"prevArrow": " <button class='slick-next slick-arrow' aria-label='Next' type='button' aria-disabled='false' style='display: block;right: 2.5%;;top:38%'><i></i><span></span></button>",
		"infinite": true
	}

	getShoppingCartData() {
		this.updated = false;
		this.shoppingBasket = {} as ShoppingBasket;

		this.cartService.getShoppingCart(this.shoppingBasket).subscribe(data => {

			if (data && data.desc) {
				this.basketPageViewModel = data.desc;

				if (this.basketPageViewModel.ShoppingBasketPage) {
					this.amazonClientId = this.basketPageViewModel.ShoppingBasketPage.AmazonClientId;
					this.amazonSellerId = this.basketPageViewModel.ShoppingBasketPage.AmazonSellerId;
					this.amazonIsSandbox = this.basketPageViewModel.ShoppingBasketPage.AmazonIsSandbox ? "1" : "0";
					this.paypalMerchantId = this.basketPageViewModel.ShoppingBasketPage.PayPalMerchantId;
					this.paypalEnvironment = this.basketPageViewModel.ShoppingBasketPage.IsSandboxEnvironment ? 'sandbox' : 'production';

					//get related products -RecommendedForYou
					if (this.basketPageViewModel.ShoppingBasketPage.RecommendedForYou && this.basketPageViewModel.ShoppingBasketPage.RecommendedForYou.length) {
						this.relatedProducts = {
							CarouselSlides: 4,
							CarouselDirective: 'carousel',
							IsHistoryView: false,
							Title: this.siteContent.YouMayAlsoLike,
							ProductSmallVMs: this.basketPageViewModel.ShoppingBasketPage.RecommendedForYou
						}
					}
				}

				this.promoCodeForm = this.fb.group({
					promoCode: [this.basketPageViewModel.ShoppingBasketPage.Basket?.PromoCode]
				})

				this.getTrackingScript(data);
				this.cartService.refreshLocalBasket(data.desc, false);
			}
		});
	}

	showPromoStripBanner() {
		if (this.basketPageViewModel && this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems) {
			return this.basketPageViewModel.ShoppingBasketPage.Basket.BasketItems.some(function (item) { return item.ShowBanner; });
		}

		return false;
	}

	getTotalPrice(data) {
		if (data) {
			data.forEach(element => {
				this.totalAmount += element.salePrice;
			});
		}
	}

	applyPromoCode() {
		if (!this.promoCodeForm.value.promoCode) {
			return;
		}
		this.promoCodeForm.value.promoCode = this.promoCodeForm.value.promoCode.trim();
		this.promocodeVM = {} as PromoCodeVM;
		this.promocodeVM.PromoCode = this.promoCodeForm.value.promoCode;

		this.cartService.checkPromoCode(this.promocodeVM).subscribe(res => {
			if (res) {
				if (res.Result && +res.Result === 2) {

					if (this.basketPageViewModel && this.basketPageViewModel.ShoppingBasketPage && this.basketPageViewModel.ShoppingBasketPage.Basket) {
						this.basketPageViewModel.ShoppingBasketPage.Basket.PromoCode = this.promoCodeForm.value.promoCode;
						this.cartService.GetBasket().subscribe(data => {

							if (data) {
								this.basketPageViewModel.ShoppingBasketPage.Basket = data;

								//gmt start
								if (data && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
									InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, data, false, false)
								}
								//gmt end
							}
						})
					}

					this.showPromoCodeMessage = false;
					this.messagesForPromoCode = '';
				} else {
					this.showPromoCodeMessage = true;
					this.messagesForPromoCode = res.Message;
				}
			}
		})
	}

	trimPromoCode() {
		const promoCodeControl = this.promoCodeForm.get('promoCode');
		if (promoCodeControl) {
			promoCodeControl.patchValue(promoCodeControl.value.trim(), { emitEvent: false });
		}
	}

	secureCheckout() {
		if (!localStorage.getItem('isSignedIn') && !localStorage.getItem('isGuestLogin')) {
			this.router.navigate(['/checkout/welcome'])
		} else {
			this.checkoutSwitcherService.gotoCheckoutPage(null);
		}
	}

	paypalSubmit() {
		var origin = window.location.origin; // Passing origin of browser so that callback to correct url

		this.paypalService.quickInitialise(origin).subscribe(data => {
			if (data.redirectUrl !== null) {
				window.location.href = data;
			}
			else {
				window.location.href = '/checkout/payment';
			}
		})
	}

	getPaymentModel() { }

	getTrackingScript(basketData) {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.ShoppingBasket;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts(basketData);
			})
		}
	}

	applyTrackingScripts(basketData) {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}

		//gmt start
		if (basketData && this.LpAppSetting && this.LpAppSetting.CriteoSettings) {
			InitialiseBasketTracking(this.LpAppSetting.CriteoSettings, basketData, false, false)
		}
		//gmt end
	}

	private subscribeToState(): void {
		this.facetFilterSubscriber = this.facetFilter$.subscribe(
			(facetFilterState) => {
				this.facetFilterState = facetFilterState;
			}
		);
	}
}
