import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { PageTypeDimensionEnum } from 'src/app/enums/page-type-dimension-enum';
import { Basket } from 'src/app/model/basket';
import { List } from 'src/app/model/country-viewmodel';
import { LpAppSetting } from 'src/app/model/lpapp-setting';
import { TrackingVM } from 'src/app/model/tracking-viewmodel';
import { AddTrackingScriptToDOM } from '../../../../assets/shared/tracking';
import { PaymentMethodEnum } from '../../enums/courierEnum';
import { StateList, TitleList } from '../../models/checkout-delivery.model';
import { AddressEntryModel, AddressOption, AdyenInitResponse, NamePhone, PaymentMethod, PaymentModel, UserDetails } from '../../models/checkout-payment.model';
import { Label } from '../../models/Labels';
import { AdyenAddressChangePayment, PaymentInitialize } from '../../models/payment-vm';
import { RegExLibrary } from '../../models/regex-library';
import { ShippingOptions, ShippingToClickAndCollect, ShippingVM } from '../../models/shippingoptions';
import { SiteContentDetail } from '../../models/siteContent';
import { WebsiteContentDetail } from '../../models/websiteContent';
import { AdyenService } from '../../service/adyen.service';
import { CheckoutService } from '../../service/checkout.service';
import { LabelService } from '../../service/label.service';
import { ScrollService } from '../../service/scroll.service';
import { WebsiteService } from '../../service/website.service';
import { SharedService } from '../../shared.service';
import { SiteContentService } from '../../sitecontent.service';
import { AddScriptStyleLinks } from '../../../../assets/shared/tracking';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CheckoutSwitcherService } from '../../checkout-switcher.service';

@Component({
	selector: 'app-checkout-payment',
	templateUrl: './checkout-payment.component.html',
	styleUrls: ['./checkout-payment.component.scss']
})
export class CheckoutPaymentComponent implements OnInit {
	cartform: UntypedFormGroup;
	activeStep: string = 'stepTwo';
	sitecontent: SiteContentDetail;
	regExLibrary: RegExLibrary;
	labelData: Label;
	isGuest: boolean = false;
	shippingModel: ShippingOptions;
	shippingVM: ShippingVM[];
	titleList: TitleList[];
	paymentModel: PaymentModel;
	fullName: string;
	storeList: any[];
	deliveryMethod: string;
	submitted: boolean = false;
	selectedPaymentName: string;
	selectedShippingId: number;

	isLoading: boolean = false;
	selectedAddressOption: string = null;
	selectedAddressOptionId: number = 1;
	selectedAddressBookAddressId = null;
	isGift: boolean = false;
	agreeToGermanLegalRequirements: boolean = false;
	requiresLoading: boolean = false;
	countryId: number = 1;
	stateList: StateList[] = [];
	countriesList: List[] = [];

	themeName: string = null;
	phoneNumber: string = '';
	email: string = '';
	paymentMethods: PaymentMethod[] = [];
	pmtGatewaycookie: string = '';
	paymentFormLoaded: Promise<boolean>;
	scriptContent = [];

	constants = {
		sameAsDelivery: "SameAsDelivery",
		newAddress: "NewAddress",
		Klarna: PaymentMethodEnum.Klarna,
		KlarnaV3: PaymentMethodEnum.KlarnaV3,
	};

	phoneCountry: string = ""

	showBillingAddressOptions: boolean = false;
	aydenPaymentMethodsResponse: AdyenInitResponse;
	selectedPaymentMethod: PaymentMethod = null;
	addressOptions: AddressOption[] = [];
	basket: Basket;
	toggleNamePhoneData: boolean = false;
	pmtMethod: PaymentMethod;

	//TODO: this needs to be false by default and will be true when payment has been authorised
	//isPaymentAuthorised: boolean = true;
	showDeliveryOption: boolean = false;
	addressEntryModel: AddressEntryModel;
	address: string;
	namePhone: NamePhone;
	paymentInitialize: PaymentInitialize;
	adyenPaymentInitialize: AdyenAddressChangePayment;
	adyenPaymentAction: boolean = false;

	shippingToClickAndCollect: ShippingToClickAndCollect;
	amazonClientId: string;
	amazonSellerId: string;
	amazonIsSandbox: string = "1";
	paypalMerchantId: string = "";
	paypalEnvironment: string = "";
	LpAppSetting: LpAppSetting;
	countryCode: string = "";
	isDutyFree: boolean = false;
	agreeToGermanLegalRequirementsClass: boolean = false;
	websiteContentDetail: WebsiteContentDetail;

	constructor(
		private sharedService: SharedService,
		private fb: UntypedFormBuilder,
		@Inject(DOCUMENT)
		private document,
		private r: Renderer2,
		private router: Router,
		private route: ActivatedRoute,
		private adyenService: AdyenService,
		private checkoutService: CheckoutService,
		private titleService: Title,
		private meta: Meta,
		private siteContentService: SiteContentService,
		private labelService: LabelService,
		private scrollService: ScrollService,
		private websiteService: WebsiteService,
		private reCaptchaV3Service: ReCaptchaV3Service,
		private checkoutSwitcherService: CheckoutSwitcherService,

		@Inject(PLATFORM_ID)
		private platformId: any) {

		if (isPlatformBrowser(this.platformId)) {
			$("body").removeClass();
			$("body").addClass("checkout ng-scope");
		}
	}

	title: string;
	fName: string;
	lName: string;

	userDetails: UserDetails;
	deliveryAddressDetails = {
		title: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		phoneCountryId: '',
		countryId: 0,
		country: '',
		addressOne: '',
		addressTwo: '',
		city: '',
		county: '',
		postCode: '',
		email: '',
		townCity: '',
	};

	selectStoreClicked(e) {

	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {

			// Goto Global-E checkout if operated by them
			if (this.checkoutSwitcherService.isOperatedByGlobalE()) {
				this.checkoutSwitcherService.gotoCheckoutPage(null);
			}

			this.getRegExLibrary();
			this.getSiteContent();
			this.getAllLabels();
			this.websiteContentDetail = this.websiteService.getWebsiteData();
			this.getLpAppSetting();
			this.getPaymentModel();
			this.scrollService.windowScrollTo(0, 0);

			if (localStorage.getItem('deliveryTabDetails')) {
				this.email = '';
				this.userDetails = JSON.parse(localStorage.getItem('deliveryTabDetails'));

				this.fName = this.userDetails.firstName;
				this.lName = this.userDetails.lastName;
				this.phoneNumber = this.userDetails.phoneNumber;
				this.title = this.userDetails.title;
				let addressTwo = ''

				if (this.userDetails.addressTwo) {
					addressTwo = this.userDetails.addressLine2 + ',';
				}

				this.address = this.userDetails.addressLine1 + ' ,' + addressTwo + this.userDetails.postZipCode + ' ,' + this.userDetails.townCity + ', ' + this.userDetails.country;
			}

			this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;
			this.shippingToClickAndCollect.showdeliveryoption = false;
			this.titleService.setTitle('');
			this.meta.updateTag({ name: 'description', content: '' });
		}
	}

	getAllLabels() {
		this.labelData = this.labelService.getLabelData();

		if (!this.labelData) {
			this.labelService.getLabels().subscribe(data => {
				if (data) {
					this.labelData = data;
				}
			});
		}
	}

	private async getLpAppSetting(): Promise<void> {
		this.LpAppSetting = this.sharedService.getLpAppSettingsData();

		if (!this.LpAppSetting) {
			this.LpAppSetting = await this.sharedService.getLpAppSettings();
			setTimeout(AddScriptStyleLinks, 0, this.LpAppSetting);
		}
	}

	getSiteContent() {
		this.sitecontent = this.siteContentService.getSiteContentData();

		if (!this.sitecontent) {
			this.siteContentService.getSiteContent().subscribe(data => {
				this.sitecontent = data;
			})
		}
	}

	getPaymentModel() {
		this.checkoutService.getPaymentMethods().subscribe(data => {

			if (data) {
				if (data.result) {
					if (data.returnUrl && data.returnUrl === "shopping") {
						if (window && window.amazon && window.amazon.hasOwnProperty('Login') && window.amazon['login']) {
							window.amazon['login'].logout();
						}

						this.router.navigate(['/checkout/shoppingbasket']);

						return;
					}
					if (data.returnUrl && data.returnUrl === "welcome") {
						this.router.navigate(['/checkout/welcome']);

						return;
					}
				}

				if (data && data.Basket) {
					const {
						BasketTotalItemCount: totalBasketItemCount,
						AddressShipping: shippingAddress,
					} = data.Basket;
					if (totalBasketItemCount === 0) {
						this.router.navigate(["/checkout/shoppingbasket"]);
						return;
					}
					if (shippingAddress) {
						this.setIsDutyFree(shippingAddress);
					}
				}

				this.paymentModel = data;
				this.getTrackingScript();
				this.amazonClientId = data.AmazonClientid;
				this.amazonSellerId = data.AmazonSellerid;
				this.amazonIsSandbox = data.AmazonIsSandbox ? "1" : "0";
				this.paypalMerchantId = data.PayPalMerchantId;
				this.paypalEnvironment = data.IsSandboxEnvironment ? 'sandbox' : 'production';
				this.deliveryFormInitilizeWithData(data);

				if (data.Basket.AddressShipping) {
					this.paymentModel.CountryId = data.Basket.AddressShipping.CountryId;
					this.paymentModel.AddressLine1 = data.Basket.AddressShipping.StreetAddress1;
					this.paymentModel.AddressLine2 = data.Basket.AddressShipping.StreetAddress2;
					this.paymentModel.TownCity = data.Basket.AddressShipping.City;
					this.paymentModel.County = data.Basket.AddressShipping.County;
					this.paymentModel.StateCode = data.Basket.AddressShipping.State;
					this.paymentModel.Country = data.Basket.AddressShipping.CountryId;
					this.paymentModel.PostZipCode = data.Basket.AddressShipping.PostCode;
				}

				if (data && data.Titles && data.Titles.List) {
					this.titleList = data.Titles.List;
				}

				this.stateList = this.paymentModel.StateList;
				this.countriesList = this.paymentModel.CountriesSelectList.List;
				this.shippingModel = {} as ShippingOptions;
				this.shippingModel.countryId = this.paymentModel.CountryId;
				this.shippingModel.isClickAndCollect = data.IsClickAndCollect;
				this.shippingModel.postcode = this.paymentModel.PostZipCode;
				this.getShippingOptions(this.shippingModel);

				this.initialise();

				this.paymentFormLoaded = Promise.resolve(true);
			}
		})
	}

	bindAddress() {
		if (localStorage.getItem("selectedAddress")) {

			let userDetail = JSON.parse(localStorage.getItem("selectedAddress"))

			if (userDetail) {
				if (userDetail.Address) {

					let addressTwo = ''
					this.address = '';

					if (userDetail.StoreName) {
						this.address += userDetail.StoreName + ", "
					}

					if (userDetail.Address.StreetAddress1) {
						this.address += userDetail.Address.StreetAddress1 + ", "
					}

					if (userDetail.Address.StreetAddress2) {
						this.address += userDetail.Address.StreetAddress2 + ", "
					}

					if (userDetail.Address.PostCode) {
						this.address += userDetail.Address.PostCode + ", "
					}

					if (userDetail.Address.City) {
						this.address += userDetail.Address.City + ", "
					}

					if (userDetail.Address.Country) {
						this.address += userDetail.Address.Country + ", "
					}
				}

				this.shippingToClickAndCollect = {} as ShippingToClickAndCollect;
				this.shippingToClickAndCollect.price = userDetail.ShippingPrice;
				this.shippingToClickAndCollect.shippingText = this.sitecontent.Collection;
				this.shippingToClickAndCollect.showdeliveryoption = false;
			}
		}
	}

	getShippingOptions(shippingModel: ShippingOptions) {
		this.sharedService.getShippingOptions(shippingModel).subscribe(data => {
			if (data) {
				this.shippingVM = data;

				if (this.shippingVM && this.shippingVM.length > 0) {
					for (let i = 0; i < this.shippingVM.length; i++) {
						if (this.shippingVM[i].Selected == true) {
							this.selectedShippingId = this.shippingVM[i].Id;
							this.shippingToClickAndCollect.price = this.shippingVM[i].Price;
							break;
						}
						if (i == this.shippingVM.length - 1) {
							this.shippingVM[0].Selected = true;
							this.selectedShippingId = this.shippingVM[0].Id;
							this.shippingToClickAndCollect.price = this.shippingVM[0].Price;
						}
					}
				}
			}
		});
	}

	getRegExLibrary() {
		this.sharedService.getRegExLibrary().subscribe(data => {
			if (data) {
				this.regExLibrary = data;
			}
		});
	}

	checkIfFieldHasValue(paymentModel) {

		let fullname = '';
		if (paymentModel && paymentModel.Basket && paymentModel.Basket.AddressShipping) {
			if (paymentModel.Basket.AddressShipping.Title) {
				fullname = paymentModel.Basket.AddressShipping.Title + ' ';
			}

			if (paymentModel.Basket.AddressShipping.FirstName) {
				fullname += paymentModel.Basket.AddressShipping.FirstName + ' ';
			}

			if (paymentModel.Basket.AddressShipping.LastName) {
				fullname += paymentModel.Basket.AddressShipping.LastName;
			}
		}

		if (fullname) {
			this.fullName = fullname;
			return true;
		}

		return false;
	}

	storeAddressAddress(input) {

		if (!input) {
			return '';
		}

		var sb = '';

		if (input.StreetAddress1) { sb += input.StreetAddress1 + ', '; }

		if (input.StreetAddress2) { sb += input.StreetAddress2 + ', '; }

		if (input.City) { sb += input.City + ', '; }

		if (input.County) { sb += input.County + ', '; }

		if (input.StateCode) { sb += input.StateCode + ', '; }

		if (input.PostCode) { sb += input.PostCode + ', '; }

		if (input.Country) { sb += input.Country; }

		if (input.CountryName && input.CountryId !== 1) { sb += ', ' + input.CountryName; }

		if (input.addressLine1) { sb += input.addressLine1 + ', '; }

		if (input.addressLine2) { sb += input.addressLine2 + ', '; }

		if (input.townCity) { sb += input.townCity + ', '; }

		if (input.county) { sb += input.county + ', '; }

		if (input.stateCode) { sb += input.stateCode + ', '; }

		if (input.postCode) { sb += input.postCode + ', '; }

		if (input.postcode) { sb += input.postcode + ', '; }

		if (input.country && input.country.Name) { sb += input.country.Name; }

		return sb;
	}

	getSelectedCountry(countries, countryId) {
		var ctr = countries.filter(function (x) { return x.CountryId == countryId; });

		if (ctr && ctr.length > 0) {
			return ctr[0];
		}
	}

	getSelectedCountryISD(countries, countryId) {
		if (!countryId) {
			countryId = 1;
		}

		var ctr = countries.filter(function (x) { return x.CountryId == countryId; });

		if (ctr && ctr.length > 0) {
			return ctr[0].ISD;
		}
	}

	getStoreList(Stores) {
		if (Stores) {
			Stores.forEach(val => {

				if (!this.storeList) {
					this.storeList = [];
				}

				this.storeList.push({
					Active: false,
					City: val.Address.City,
					Country: val.Address.Country,
					Distance: null,
					FullAddress: val.Address.StreetAddress1 + ' ' + val.Address.StreetAddress2,
					FullUrl: val.SeoUrl,
					Latitude: val.Latitude,
					Longitude: val.Longitude,
					MoreInfoText: "More info",
					Name: val.StoreName,
					OpeningTimes: null,
					PhoneNumber: val.Address.Phone,
					StoreId: val.StoreReference,
					collectplus: val.ShippingPrice != 0.00 ? 'collectplus' : ''
				});
			})
		}
	}

	getShippingDetail(payment) {
		if (payment && payment.Basket && payment.Basket.ShippingOptionId) {
			if (this.shippingVM && this.shippingVM.length > 0) {

				let shipping = this.shippingVM.filter(x => x.Id == payment.Basket.ShippingOptionId);

				if (shipping) {

					var sb = '';

					if (shipping[0].Name) {
						sb += shipping[0].Name + '\n\r';
					}

					if (shipping[0].Price) {
						sb += shipping[0].CurrencyPrefix + shipping[0].Price + '\n\r';
					}

					return sb;
				}
			}
		}
	}

	initialise() {
		this.themeName = "hawesandcurtis2";
		this.basket = this.paymentModel.Basket;

		if (this.paymentModel && this.paymentModel.Stores) {

			this.paymentModel.Stores.forEach(val => {

				if (!this.storeList) {
					this.storeList = [];
				}

				this.storeList.push({
					Active: false,
					City: val.Address.City,
					Country: val.Address.Country,
					Distance: null,
					FullAddress: val.Address.StreetAddress1 + ' ' + val.Address.StreetAddress2,
					FullUrl: val.SeoUrl,
					Latitude: val.Latitude,
					Longitude: val.Longitude,
					MoreInfoText: "More info",
					Name: val.StoreName,
					OpeningTimes: null,
					PhoneNumber: val.Address.Phone,
					StoreId: val.StoreReference,
					collectplus: val.ShippingPrice != 0.00 ? 'collectplus' : ''
				});
			})
		}

		this.paymentMethods = this.configurePaymentMethods(this.paymentModel.PaymentMethods);

		this.forceSelectDefaultPaymentMethod();

		// If the AddressShipping is null then go back to Delivery page so customer can enter details
		if (this.paymentModel.Basket.AddressShipping) {
			this.formatAddress(this.paymentModel, this.paymentModel.Basket.AddressShipping);
		} else {
			this.router.navigate(['/checkout/delivery']);
		}

		if (!this.paymentModel.IsClickAndCollect) {
			this.deliveryMethod = this.formatDelivery(this.paymentModel.Basket.Shipping);
		} else {
			if (this.paymentModel.PhoneCountryId) {
				this.phoneCountry = this.getSelectedCountry(this.paymentModel.CountriesSelectList.List, this.paymentModel.PhoneCountryId).ISD;
			}
		}

		this.addressEntryModel = {
			CountryId: this.paymentModel.CountryId,
			CountriesSelectList: this.paymentModel.CountriesSelectList,
			StateList: this.paymentModel.StateList,
			IsGuest: this.paymentModel.IsGuest,
			selectedAddress: {
				Country: this.paymentModel.Country
			},
			PostCodeAnywhereSecurityKey: this.paymentModel.PostCodeAnywhereSecurityKey,
		};

		this.paymentModel.AddressesBookEntries = [];
		this.paymentModel.PaymentMethods = [];
		this.countryId = this.paymentModel.CountryId;

		if (this.paymentModel.SagePayEnableTokenisation === false || this.paymentModel.SagePayPaymentTokens.length === 0) {
			this.paymentMethods = $.grep(this.paymentMethods, function (v) {
				return v.Id !== PaymentMethodEnum.SagePayServerToken;
			});
		}

		this.addressOptions.push({ id: 1, name: 'SameAsDelivery', value: 'Same as Delivery' });
		this.addressOptions.push({ id: 2, name: 'NewAddress', value: 'Add new address' });

		if (this.paymentModel.Basket.AddressShipping && this.paymentModel.Basket.AddressShipping.StateCode) {
			let shippingAddress = this.paymentModel.StateList.filter(x => { return x.Value == this.paymentModel.Basket.AddressShipping.StateCode; })
			if (shippingAddress && shippingAddress.length) {
				this.paymentModel.Basket.AddressShipping.State = shippingAddress[0].Text;
			}
		}

		//input to name-Phone directive
		this.namePhone = {
			title: this.getSelectedTitle(this.paymentModel.Titles.List, this.paymentModel.Title),
			firstName: this.paymentModel.FirstName,
			lastName: this.paymentModel.LastName,
			phoneCountry: this.getSelectedCountry(this.paymentModel.CountriesSelectList.List, this.paymentModel.CountryId),
			phoneNumber: this.paymentModel.PhoneNumber,
			phoneCountries: this.paymentModel.CountriesSelectList.List,
			titles: this.paymentModel.Titles.List
		};
	}

	constantService() {
		var service = {
			paymentMethodEnum: {
				Amazon: 1,
				Barclays: 2,
				EBay: 3,
				GlobalCollect: 4,      // No longer supported
				GoogleCheckout: 5,     // No longer supported
				Paypal: 6,
				Card: 7,               // No longer supported
				YesPay: 8,             // Kiosk
				SagePayServer: 9,
				Sofort: 10,
				SagePayServerToken: 11,
				Klarna: 12,
				PrePaid: 13,
				Pentagon: 14,
				Mirakl: 15,
				Borderfree: 16,
				SagePayPI: 17,
				OneIota: 18,
				AmazonMWS: 19,
				KlarnaV3: 20,
				Adyen: 21
			},
			courierEnum: {
				DPD: 1,
				RoyalMailUK: 2,
				RoyalMailUSA: 3,
				RoyalMailAUS: 4,
				RoyalMailInternational: 5,
				OCS: 6,
				HawesAndCurtis: 7,
				WNDirect: 8,
				CollectPlus: 9,
				Doddle: 10,
				RoyalMailApi: 11,
				Borderfree: 12,
				HawesAndCurtisGermany: 13
			}
		};

		return service;
	}

	configurePaymentMethods(paymentMethods) {
		var found = $.grep(paymentMethods, function (v: any) {
			return v.Id === PaymentMethodEnum.SagePayServer || v.Id === PaymentMethodEnum.Adyen;   //sagepay & adyen
		});

		if (found && found.length >= 1) {
			paymentMethods = $.grep(paymentMethods, function (v: any) { return v.Id !== PaymentMethodEnum.SagePayServer; });    //remove SagePay
		} else {
			this.paymentMethods = paymentMethods;
		}

		paymentMethods = $.grep(paymentMethods, function (v: any) {
			return v.Id !== PaymentMethodEnum.SagePayServerToken
				&& v.Id !== PaymentMethodEnum.SagePayPI;
		});

		var cnt = 0;

		paymentMethods.forEach(function (x) {
			if (x.Id === PaymentMethodEnum.Adyen) {
				x.SortOrder = 0;
			} else {
				x.SortOrder = ++cnt;
			}
		});

		paymentMethods = paymentMethods.sort(function (a, b) {
			return parseFloat(a.SortOrder) - parseFloat(b.SortOrder);
		});

		return paymentMethods;
	}

	formatAddress(paymentModel, address) {
		paymentModel.AddressLine1 = address.StreetAddress1;
		paymentModel.AddressLine2 = address.StreetAddress2;
		paymentModel.Country = address.Country;
		paymentModel.CountryId = address.CountryId;
		paymentModel.County = address.County;
		paymentModel.FirstName = address.FirstName;
		paymentModel.LastName = address.LastName;
		paymentModel.PhoneNumber = address.Phone;
		paymentModel.PostZipCode = address.PostCode;
		paymentModel.Title = address.Title;
		paymentModel.StateCode = address.State;
		paymentModel.TownCity = address.City;

		if (address.Phone) {
			this.phoneNumber = address.Phone + '\n\r';
		}

		if (address.PhoneCountryId) {
			this.phoneCountry = this.getSelectedCountry(paymentModel.CountriesSelectList.List, address.PhoneCountryId).ISD;
		}

		if (address.FullName) {
			this.fullName = address.FullName;
		}
	}

	formatDelivery(shipping) {
		if (!shipping) {
			return;
		}

		var sb = '';

		if (shipping.Name) {
			sb += shipping.Name + '\n\r';
		}

		if (shipping.Price) {
			sb += shipping.CurrencyPrefix + shipping.Price + '\n\r';
		}

		return sb;
	}

	getSelectedTitle(titles, titleName) {

		if (!titleName) {
			return null;
		}

		var ctr = $.grep(titles, function (x: any) {
			return x.Name.toLowerCase() === titleName.toLowerCase();
		});

		if (ctr !== null && ctr.length > 0) {
			return ctr[0];
		}

		return null;
	}

	deliveryFormInitilizeWithData(data) {
		let title = "Mr";

		if (data.Title && data.TitleList && data.TitleList.length > 0) {
			let titleId = +data.Title;

			if (typeof titleId === "number") {
				let titles = data.TitleList.filter(x => x.TitleId === titleId);
				if (titles && titles.length > 0) {
					title = titles[0].Name;
				}
			} else {
				title = data.Title;
			}
		}

		let firstName = '';
		let lastName = '';

		if (data) {
			firstName = data.FirstName;
			lastName = data.LastName;
		}

		if (data && data.ShippingAddress) {
			if (!firstName) {
				firstName = data.ShippingAddress.FirstName;
			}

			if (!lastName) {
				lastName = data.ShippingAddress.LastName;
			}

			if (data.ShippingAddress.Title) {
				title = data.ShippingAddress.Title;
			}
		}

		this.cartform = this.fb.group({
			firstName: [firstName, [Validators.required, Validators.pattern(this.regExLibrary ? this.regExLibrary.FirstLastName : '')]],
			lastName: [lastName, [Validators.required, Validators.pattern(this.regExLibrary ? this.regExLibrary.FirstLastName : '')]],
			title: [title, Validators.required],

			phoneCountryId: [data.PhoneCountryId],
			country: [data.Country],
			countryCode: ['GB'],
			addAsAGift: [false],
			addressLine1: [data.AddressLine1, Validators.required],
			addressLine2: [data.AddressLine2],
			townCity: [data.TownCity, Validators.required],
			stateCode: [data.StateCode],
			countryId: [data.CountryId],
			postZipCode: [data.PostZipCode, Validators.required],
			county: [data.County],
		})
	}

	selectAddressType(val) {
		this.selectedAddressOptionId = val;
		if (val === 1) {
			this.initialiseAyden();
		} else {
			this.selectedAddressOption = this.constants.newAddress;
			this.aydenPaymentMethodsResponse = null;
		}
	}

	selectPaymentMethod(pmt) {
		$('#defaultbutton').hide();
		$('#paypalbutton').hide();
		$('#amazonbutton').hide();
		$('#klarnabutton').hide();
		$('#klarnaV3button').hide();
		this.aydenPaymentMethodsResponse = null;

		this.selectedAddressOptionId = 1;
		this.selectedPaymentMethod = pmt;
		this.selectedPaymentName = pmt.Name;

		this.showBillingAddressOptions = false;

		if (pmt.Id === PaymentMethodEnum.Adyen) {

			this.pmtMethod = pmt;
			this.showBillingAddressOptions = true;

			if (this.paymentModel.IsClickAndCollect == true) {
				this.selectedAddressOption = this.constants.newAddress;
				this.paymentModel.AddressOption = this.constants.newAddress;
			} else {
				this.selectedAddressOption = this.constants.sameAsDelivery;
				this.paymentModel.AddressOption = this.constants.sameAsDelivery;
			}

			//Need to show the adyen drop-in as SameAsDelivery will always be selected by default
			if (this.selectedAddressOptionId == 1 && this.paymentModel.IsClickAndCollect === false) {
				this.selectAddressType(1);
			}

		} else if (pmt.Id === PaymentMethodEnum.Klarna) {
			$('#klarnabutton').show();
		} else if (pmt.Id === PaymentMethodEnum.Paypal) {
			$('#paypalbutton').show();
		} else if (pmt.Id === PaymentMethodEnum.Amazon) {
			this.amazonLogin();
			$('#amazonbutton').show();
		} else if (pmt.Id === PaymentMethodEnum.KlarnaV3) {
			$('#klarnaV3button').show();
		}

		return;
	}

	addressChanged(event) {
		this.initialiseAyden();
	}

	paymentAction(event) {
		this.adyenPaymentAction = true;
	}

	initialiseAyden() {
		if (true) {
			this.paymentModel.PaymentTypeSelected = PaymentMethodEnum.Adyen;
			this.paymentModel.IsGift = this.isGift;
			this.paymentModel.AgreeToGermanLegalRequirements = this.agreeToGermanLegalRequirements;

			if (this.selectedAddressOption === 'FromAddressBook') {
				this.paymentModel.AddressBookAddressId = this.selectedAddressBookAddressId;
			}

			this.paymentModel.AddressOption = this.selectedAddressOption;

			var request = JSON.parse(JSON.stringify(this.paymentModel));
			request.CountriesSelectList = [];
			request.AllCountriesSelectList = [];
			request.StateList = [];
			request.Titles = [];
			request.TitleList = [];
			request.Title = this.paymentModel.Title;

			if (request.ClickAndCollectSelectedStore != null) {
				request.ClickAndCollectSelectedStore.StoreDeliveries = [];
				request.ClickAndCollectSelectedStore.StoreOpeningHours = [];
			}

			if (request.ShippingAddress != null) {
				request.ShippingAddress.StateList = [];
			}

			request.Stores = [];

			this.paymentInitialize = {} as PaymentInitialize;
			this.paymentInitialize.CardTypeSelected = "card";
			this.paymentInitialize.IsClickAndCollect = this.paymentModel.IsClickAndCollect;
			this.paymentInitialize.IsGuest = this.paymentModel.IsGuest;
			this.paymentInitialize.IsGift = this.paymentModel.IsGift;
			this.paymentInitialize.HasAddressesBookEntries = this.paymentModel.HasAddressesBookEntries;
			this.paymentInitialize.PaymentTypeSelected = PaymentMethodEnum.Adyen;

			if (this.paymentModel && this.paymentModel.ShippingAddress && this.paymentModel.ShippingAddress.AddressDetailId) {
				this.paymentInitialize.AddressDetailId = this.paymentModel.ShippingAddress.AddressDetailId;
			} else {
				this.paymentInitialize.AddressDetailId = this.paymentModel.AddressDetailId;
			}

			this.paymentInitialize.PhoneNumber = this.paymentModel.PhoneNumber;
			this.paymentInitialize.PostCodeAnywhereSecurityKey = this.paymentModel.PostCodeAnywhereSecurityKey;
			this.paymentInitialize.PostZipCode = this.paymentModel.PostZipCode;
			this.paymentInitialize.PrePaidEnabled = this.paymentModel.PrePaidEnabled;
			this.paymentInitialize.SagePayEnableTokenisation = this.paymentModel.SagePayEnableTokenisation;
			this.paymentInitialize.SagePayPaymentTokenId = this.paymentModel.SagePayPaymentTokenId;
			this.paymentInitialize.SagePayPaymentTokens = this.paymentModel.SagePayPaymentTokens;
			this.paymentInitialize.SaveSagePayPaymentToken = this.paymentModel.SaveSagePayPaymentToken;
			this.paymentInitialize.AddressOption = this.selectedAddressOption;
			this.paymentInitialize.County = this.cartform.value.county;

			if (this.selectedAddressOption === 'NewAddress') {
				this.adyenPaymentInitialize = {} as AdyenAddressChangePayment;
				this.adyenPaymentInitialize.CardTypeSelected = "card";
				this.adyenPaymentInitialize.IsClickAndCollect = this.paymentModel.IsClickAndCollect;
				this.adyenPaymentInitialize.IsGuest = this.paymentModel.IsGuest;
				this.adyenPaymentInitialize.IsGift = this.paymentModel.IsGift;
				this.adyenPaymentInitialize.HasAddressesBookEntries = this.paymentModel.HasAddressesBookEntries;
				this.adyenPaymentInitialize.PaymentTypeSelected = PaymentMethodEnum.Adyen;

				if (this.paymentModel && this.paymentModel.ShippingAddress && this.paymentModel.ShippingAddress.AddressDetailId) {
					this.adyenPaymentInitialize.AddressDetailId = this.paymentModel.ShippingAddress.AddressDetailId;
				} else {
					this.adyenPaymentInitialize.AddressDetailId = this.paymentModel.AddressDetailId;
				}

				this.adyenPaymentInitialize.AddressBookAddressId = null;
				this.adyenPaymentInitialize.AddressLine1 = this.cartform.value.addressLine1;
				this.adyenPaymentInitialize.AddressLine2 = this.cartform.value.addressLine2;
				this.adyenPaymentInitialize.AddressOption = 'NewAddress';
				this.adyenPaymentInitialize.AmazonClientid = this.amazonClientId;
				this.adyenPaymentInitialize.AmazonIsSandbox = this.amazonIsSandbox == '1' ? true : false;
				this.adyenPaymentInitialize.AmazonSellerid = this.amazonSellerId;
				this.adyenPaymentInitialize.Basket = null;
				this.adyenPaymentInitialize.CardMonthExpirySelected = 9;
				this.adyenPaymentInitialize.CardMonthStartSelected = 0;
				this.adyenPaymentInitialize.CardName = null;
				this.adyenPaymentInitialize.CardNumber = 11111111111111;
				this.adyenPaymentInitialize.CardYearExpirySelected = 0;
				this.adyenPaymentInitialize.CardYearStartSelected = 0;
				this.adyenPaymentInitialize.ClickAndCollectSelectedStore = null;
				this.adyenPaymentInitialize.Cvv = null;
				this.adyenPaymentInitialize.DeliveryTypeSelected = 1;
				this.adyenPaymentInitialize.FirstName = this.cartform.value.firstName;
				this.adyenPaymentInitialize.GuestEmail = this.paymentModel.GuestEmail;
				this.adyenPaymentInitialize.Email = this.paymentModel.Email;
				this.adyenPaymentInitialize.LastName = this.cartform.value.lastName;
				this.adyenPaymentInitialize.Country = this.cartform.value.country;
				this.adyenPaymentInitialize.TownCity = this.cartform.value.townCity;
				this.adyenPaymentInitialize.County = this.cartform.value.county;
				this.adyenPaymentInitialize.PostZipCode = this.cartform.value.postZipCode;
			}

			if (this.selectedAddressOption === 'NewAddress') {
				this.paymentInit(this.adyenPaymentInitialize);
			}
			else {
				this.paymentInit(this.paymentInitialize);
			}
		}
	}

	paymentInit(init) {
		if (isPlatformBrowser(this.platformId)) {

			this.reCaptchaV3Service.execute(this.LpAppSetting.Recaptcha_V3_SiteKey, "checkout/payment/Adyen_Start", (recaptchaToken) => {

				init.RecaptchaToken = recaptchaToken;

				this.checkoutService.getPaymentInitialize(init).subscribe(res => {
					if (res && res.result) {
						this.aydenPaymentMethodsResponse = { ...res.result };
					}
				})

			}, {
				useGlobalDomain: false
			});
		}
	}

	paypalSubmit() {

		let paymentDetails = {} as any;

		paymentDetails.CardTypeSelected = "card";
		paymentDetails.IsClickAndCollect = this.paymentModel.IsClickAndCollect;
		paymentDetails.IsGuest = this.paymentModel.IsGuest;
		paymentDetails.IsGift = this.paymentModel.IsGift;
		paymentDetails.HasAddressesBookEntries = this.paymentModel.HasAddressesBookEntries;
		paymentDetails.PaymentTypeSelected = PaymentMethodEnum.Paypal;
		paymentDetails.AddressDetailId = this.paymentModel.AddressDetailId;
		paymentDetails.PhoneNumber = this.paymentModel.PhoneNumber;
		paymentDetails.PostCodeAnywhereSecurityKey = this.paymentModel.ShippingAddress.PostCodeAnywhereSecurityKey;
		paymentDetails.PostZipCode = this.paymentModel.ShippingAddress.PostZipCode;
		paymentDetails.PrePaidEnabled = this.paymentModel.PrePaidEnabled;
		paymentDetails.SagePayEnableTokenisation = this.paymentModel.SagePayEnableTokenisation;
		paymentDetails.SagePayPaymentTokenId = this.paymentModel.SagePayPaymentTokenId;
		paymentDetails.SagePayPaymentTokens = this.paymentModel.SagePayPaymentTokens;
		paymentDetails.SaveSagePayPaymentToken = this.paymentModel.SaveSagePayPaymentToken;
		paymentDetails.Origin = window.location.origin; // Passing origin of browser so that callback to correct url

		this.reCaptchaV3Service.execute(this.LpAppSetting.Recaptcha_V3_SiteKey, "checkout/payment/Paypal_Start", (recaptchaToken) => {

			paymentDetails.RecaptchaToken = recaptchaToken;

			this.checkoutService.paypalSubmit(paymentDetails).subscribe(data => {
				if (data.redirectUrl !== null) {
					window.location.href = data.redirectUrl;
				}
				else {
					window.location.href = '/checkout/payment';
				}
			});
		}, {
			useGlobalDomain: false
		});
	}

	validateGermanPayment(isAggreToGerman) {
		if (isAggreToGerman) {
			this.agreeToGermanLegalRequirements = isAggreToGerman;
		}

		// If German site then must have agreeToGermanLegalRequirements set to true before making opening a payment method
		if (this.paymentModel.WebStoreId == 2 && !this.agreeToGermanLegalRequirements) {
			this.agreeToGermanLegalRequirementsClass = true;
		}
		else {
			this.agreeToGermanLegalRequirementsClass = false;
		}
	}

	setIsDutyFree(addressShipping) {
		// Assign Duty Free if country IsEU and not UK
		if (addressShipping) {
			this.isDutyFree = addressShipping.IsCountryEU && addressShipping.CountryId != 1;
		}
	}

	amazonLogin() {
		if ($('.amazon-custom-button').onclick == null) {
			let ele = $('.amazon-custom-button');
			ele.on('click', () => {

				var loginOptions = {
					scope: "profile payments:widget payments:shipping_address payments:billing_address",
					popup: "true"
				};

				window.amazon['Login'].authorize(loginOptions, "/amazon/checkout");
			})
		}
	}

	getTrackingScript() {
		if (isPlatformBrowser) {
			let trackingVm = {} as TrackingVM;
			trackingVm.PageTypeDimension = PageTypeDimensionEnum.CheckoutPayment;

			this.sharedService.GetTrackingScripts(trackingVm).subscribe(data => {
				this.scriptContent = this.sharedService.ParseScript(data);
				this.applyTrackingScripts();
			}, err => {
			});
		}
	}

	applyTrackingScripts() {
		if (this.scriptContent && this.scriptContent.length > 0) {
			setTimeout(AddTrackingScriptToDOM, 0, this.scriptContent);
		}
	}

	forceSelectDefaultPaymentMethod(): void {
		if (!this.paymentMethods) {
			return;
		}

		//Id 21 -> Adyen
		let defaultMethod = this.paymentMethods.find(x => x.Id == 21);
		if (!defaultMethod) {
			return;
		}

		this.selectPaymentMethod(defaultMethod);
	}

	promoCodeChanged($event) {

		// Refresh Adyen instance
		this.aydenPaymentMethodsResponse = null;

		this.initialiseAyden();
	}
}
