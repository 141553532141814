import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildWomenSuitGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		StepName1Text: stepName1Text,
		StepName2Text: stepName2Text,
		StepName3Text: stepName3Text,
		StepName4Text: stepName4Text,
		StepName5Text: stepName5Text,
		TextBetweenCarouselArrowsFront: txtFront,
		TextBetweenCarouselArrowsLeft: txtLeft,
		TextBetweenCarouselArrowsRight: txtRight,
		TextBetweenCarouselArrowsBack: txtBack,
		MenShirtFitCompareFitPageText: compareFitPageText,
		WomenSuitFitCompareFitPageDescription: compareFitPageDescription,
		WomenSuitTitle: suitTitle,
		WomenSuitMobileBannerImageUrl: suitMobileBannerImageUrl,
		WomenSuitFitTitle: suitFitTitle,
		WomenSuitFitSingleBreastedName: singleBreastedName,
		WomenSuitFitSingleBreastedDescription1: singleBreastedDescription1,
		WomenSuitFitSingleBreastedPlpLink: singleBreastedPlpLink,
		WomenSuitFitSingleBreastedVideoUrl: singleBreastedVideoUrl,
		WomenSuitFitSingleBreastedDesktopImageUrl1: singleBreastedDesktopImageUrl1,
		WomenSuitFitSingleBreastedMobileImageUrl1: singleBreastedMobileImageUrl1,
		WomenSuitFitSingleBreastedDesktopImageUrl2: singleBreastedDesktopImageUrl2,
		WomenSuitFitSingleBreastedMobileImageUrl2: singleBreastedMobileImageUrl2,
		WomenSuitFitSingleBreastedDesktopImageUrl3: singleBreastedDesktopImageUrl3,
		WomenSuitFitSingleBreastedMobileImageUrl3: singleBreastedMobileImageUrl3,
		WomenSuitFitSingleBreastedDesktopImageUrl4: singleBreastedDesktopImageUrl4,
		WomenSuitFitSingleBreastedMobileImageUrl4: singleBreastedMobileImageUrl4,
		WomenSuitFitDoubleBreastedName: doubleBreastedName,
		WomenSuitFitDoubleBreastedDescription1: doubleBreastedDescription1,
		WomenSuitFitDoubleBreastedPlpLink: doubleBreastedPlpLink,
		WomenSuitFitDoubleBreastedVideoUrl: doubleBreastedVideoUrl,
		WomenSuitFitDoubleBreastedDesktopImageUrl1: doubleBreastedDesktopImageUrl1,
		WomenSuitFitDoubleBreastedMobileImageUrl1: doubleBreastedMobileImageUrl1,
		WomenSuitFitDoubleBreastedDesktopImageUrl2: doubleBreastedDesktopImageUrl2,
		WomenSuitFitDoubleBreastedMobileImageUrl2: doubleBreastedMobileImageUrl2,
		WomenSuitFitDoubleBreastedDesktopImageUrl3: doubleBreastedDesktopImageUrl3,
		WomenSuitFitDoubleBreastedMobileImageUrl3: doubleBreastedMobileImageUrl3,
		WomenSuitFitDoubleBreastedDesktopImageUrl4: doubleBreastedDesktopImageUrl4,
		WomenSuitFitDoubleBreastedMobileImageUrl4: doubleBreastedMobileImageUrl4,
		WomenSuitFitTrouserName: trouserName,
		WomenSuitFitTrouserDescription1: trouserDescription1,
		WomenSuitFitTrouserPlpLink: trouserPlpLink,
		WomenSuitFitTrouserVideoUrl: trouserVideoUrl,
		WomenSuitFitTrouserDesktopImageUrl1: trouserDesktopImageUrl1,
		WomenSuitFitTrouserMobileImageUrl1: trouserMobileImageUrl1,
		WomenSuitFitTrouserDesktopImageUrl2: trouserDesktopImageUrl2,
		WomenSuitFitTrouserMobileImageUrl2: trouserMobileImageUrl2,
		WomenSuitFitTrouserDesktopImageUrl3: trouserDesktopImageUrl3,
		WomenSuitFitTrouserMobileImageUrl3: trouserMobileImageUrl3,
		WomenSuitFitTrouserDesktopImageUrl4: trouserDesktopImageUrl4,
		WomenSuitFitTrouserMobileImageUrl4: trouserMobileImageUrl4,
		WomenSuitFitSkirtName: skirtName,
		WomenSuitFitSkirtDescription1: skirtDescription1,
		WomenSuitFitSkirtPlpLink: skirtPlpLink,
		WomenSuitFitSkirtVideoUrl: skirtVideoUrl,
		WomenSuitFitSkirtDesktopImageUrl1: skirtDesktopImageUrl1,
		WomenSuitFitSkirtMobileImageUrl1: skirtMobileImageUrl1,
		WomenSuitFitSkirtDesktopImageUrl2: skirtDesktopImageUrl2,
		WomenSuitFitSkirtMobileImageUrl2: skirtMobileImageUrl2,
		WomenSuitFitSkirtDesktopImageUrl3: skirtDesktopImageUrl3,
		WomenSuitFitSkirtMobileImageUrl3: skirtMobileImageUrl3,
		WomenSuitFitSkirtDesktopImageUrl4: skirtDesktopImageUrl4,
		WomenSuitFitSkirtMobileImageUrl4: skirtMobileImageUrl4,
		WomenShirtMeasurementTitle: measurementTitle,
		WomenShirtMeasurementPlpLink: measurementPlpLink,
		WomenShirtMeasurementChestName: chestName,
		WomenShirtMeasurementChestVideoUrl: chestVideoUrl,
		WomenShirtMeasurementChestStepDescription1: chestDescription1,
		WomenShirtMeasurementChestStepDescription2: chestDescription2,
		WomenShirtMeasurementChestStepDescription3: chestDescription3,
		WomenShirtMeasurementChestDesktopImageUrl: chestDesktopImageUrl,
		WomenShirtMeasurementChestMobileImageUrl: chestMobileImageUrl,
		WomenShirtMeasurementWaistName: waistName,
		WomenShirtMeasurementWaistVideoUrl: waistVideoUrl,
		WomenShirtMeasurementWaistStepDescription1: waistDescription1,
		WomenShirtMeasurementWaistStepDescription2: waistDescription2,
		WomenShirtMeasurementWaistStepDescription3: waistDescription3,
		WomenShirtMeasurementWaistDesktopImageUrl: waistDesktopImageUrl,
		WomenShirtMeasurementWaistMobileImageUrl: waistMobileImageUrl,
		WomenShirtMeasurementShoulderWidthName: shoulderWidthName,
		WomenShirtMeasurementShoulderWidthVideoUrl: shoulderWidthVideoUrl,
		WomenShirtMeasurementShoulderWidthStepDescription1:
			shoulderWidthDescription1,
		WomenShirtMeasurementShoulderWidthStepDescription2:
			shoulderWidthDescription2,
		WomenShirtMeasurementShoulderWidthStepDescription3:
			shoulderWidthDescription3,
		WomenShirtMeasurementShoulderWidthDesktopImageUrl:
			shoulderWidthDesktopImageUrl,
		WomenShirtMeasurementShoulderWidthMobileImageUrl:
			shoulderWidthMobileImageUrl,
		WomenShirtMeasurementBackLengthName: backLengthName,
		WomenShirtMeasurementBackLengthVideoUrl: backLengthVideoUrl,
		WomenShirtMeasurementBackLengthStepDescription1: backLengthDescription1,
		WomenShirtMeasurementBackLengthStepDescription2: backLengthDescription2,
		WomenShirtMeasurementBackLengthStepDescription3: backLengthDescription3,
		WomenShirtMeasurementBackLengthDesktopImageUrl: backLengthDesktopImageUrl,
		WomenShirtMeasurementBackLengthMobileImageUrl: backLengthMobileImageUrl,
		WomenShirtMeasurementSleeveLengthName: sleeveLengthName,
		WomenShirtMeasurementSleeveLengthVideoUrl: sleeveLengthVideoUrl,
		WomenShirtMeasurementSleeveLengthStepDescription1: sleeveLengthDescription1,
		WomenShirtMeasurementSleeveLengthStepDescription2: sleeveLengthDescription2,
		WomenShirtMeasurementSleeveLengthStepDescription3: sleeveLengthDescription3,
		WomenShirtMeasurementSleeveLengthStepDescription4: sleeveLengthDescription4,
		WomenShirtMeasurementSleeveLengthStepDescription5: sleeveLengthDescription5,
		WomenShirtMeasurementSleeveLengthDesktopImageUrl:
			sleeveLengthDesktopImageUrl,
		WomenShirtMeasurementSleeveLengthMobileImageUrl: sleeveLengthMobileImageUrl,
		WomenSuitMeasurementHipCircumferenceName: hipCircumferenceName,
		WomenSuitMeasurementHipCircumferenceVideoUrl: hipCircumferenceVideoUrl,
		WomenSuitMeasurementHipCircumferenceStepDescription1:
			hipCircumferenceDescription1,
		WomenSuitMeasurementHipCircumferenceStepDescription2:
			hipCircumferenceDescription2,
		WomenSuitMeasurementHipCircumferenceStepDescription3:
			hipCircumferenceDescription3,
		WomenSuitMeasurementHipCircumferenceDesktopImageUrl:
			hipCircumferenceDesktopImageUrl,
		WomenSuitMeasurementHipCircumferenceMobileImageUrl:
			hipCircumferenceMobileImageUrl,
		WomenSuitMeasurementTrouserInsideLegName: insideLegName,
		WomenSuitMeasurementTrouserInsideLegVideoUrl: insideLegVideoUrl,
		WomenSuitMeasurementTrouserInsideLegStepDescription1: insideLegDescription1,
		WomenSuitMeasurementTrouserInsideLegStepDescription2: insideLegDescription2,
		WomenSuitMeasurementTrouserInsideLegStepDescription3: insideLegDescription3,
		WomenSuitMeasurementTrouserInsideLegDesktopImageUrl:
			insideLegDesktopImageUrl,
		WomenSuitMeasurementTrouserInsideLegMobileImageUrl: insideLegMobileImageUrl,
	} = fitGuideSetting;
	return {
		title: suitTitle,
		mobileBannerImage: suitMobileBannerImageUrl,
		sections: [
			{
				title: suitFitTitle,
				subFitGuidePageText: compareFitPageText,
				subFitGuidePageDescription: compareFitPageDescription,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: singleBreastedName,
						description1: singleBreastedDescription1,
						plpLink: singleBreastedPlpLink,
						videoLink: singleBreastedVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: singleBreastedDesktopImageUrl1,
								mobile: singleBreastedMobileImageUrl1,
							},
							{
								desktop: singleBreastedDesktopImageUrl2,
								mobile: singleBreastedMobileImageUrl2,
							},
							{
								desktop: singleBreastedDesktopImageUrl3,
								mobile: singleBreastedMobileImageUrl3,
							},
							{
								desktop: singleBreastedDesktopImageUrl4,
								mobile: singleBreastedMobileImageUrl4,
							},
						],
						isActive: false,
					},
					{
						name: doubleBreastedName,
						description1: doubleBreastedDescription1,
						plpLink: doubleBreastedPlpLink,
						videoLink: doubleBreastedVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: doubleBreastedDesktopImageUrl1,
								mobile: doubleBreastedMobileImageUrl1,
							},
							{
								desktop: doubleBreastedDesktopImageUrl2,
								mobile: doubleBreastedMobileImageUrl2,
							},
							{
								desktop: doubleBreastedDesktopImageUrl3,
								mobile: doubleBreastedMobileImageUrl3,
							},
							{
								desktop: doubleBreastedDesktopImageUrl4,
								mobile: doubleBreastedMobileImageUrl4,
							},
						],
						isActive: true,
					},
					{
						name: trouserName,
						description1: trouserDescription1,
						plpLink: trouserPlpLink,
						videoLink: trouserVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: trouserDesktopImageUrl1,
								mobile: trouserMobileImageUrl1,
							},
							{
								desktop: trouserDesktopImageUrl2,
								mobile: trouserMobileImageUrl2,
							},
							{
								desktop: trouserDesktopImageUrl3,
								mobile: trouserMobileImageUrl3,
							},
							{
								desktop: trouserDesktopImageUrl4,
								mobile: trouserMobileImageUrl4,
							},
						],
						isActive: false,
					},
					{
						name: skirtName,
						description1: skirtDescription1,
						plpLink: skirtPlpLink,
						videoLink: skirtVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: skirtDesktopImageUrl1,
								mobile: skirtMobileImageUrl1,
							},
							{
								desktop: skirtDesktopImageUrl2,
								mobile: skirtMobileImageUrl2,
							},
							{
								desktop: skirtDesktopImageUrl3,
								mobile: skirtMobileImageUrl3,
							},
							{
								desktop: skirtDesktopImageUrl4,
								mobile: skirtMobileImageUrl4,
							},
						],
						isActive: false,
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right2 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: measurementTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: chestName,
						plpLink: measurementPlpLink,
						videoLink: chestVideoUrl,
						images: [
							{
								desktop: chestDesktopImageUrl,
								mobile: chestMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: chestDescription1,
							},
							{
								name: stepName2Text,
								description: chestDescription2,
							},
							{
								name: stepName3Text,
								description: chestDescription3,
							},
						],
						isActive: false,
					},
					{
						name: waistName,
						plpLink: measurementPlpLink,
						videoLink: waistVideoUrl,
						images: [
							{
								desktop: waistDesktopImageUrl,
								mobile: waistMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: waistDescription1,
							},
							{
								name: stepName2Text,
								description: waistDescription2,
							},
							{
								name: stepName3Text,
								description: waistDescription3,
							},
						],
						isActive: false,
					},
					{
						name: shoulderWidthName,
						plpLink: measurementPlpLink,
						videoLink: shoulderWidthVideoUrl,
						images: [
							{
								desktop: shoulderWidthDesktopImageUrl,
								mobile: shoulderWidthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: shoulderWidthDescription1,
							},
							{
								name: stepName2Text,
								description: shoulderWidthDescription2,
							},
							{
								name: stepName3Text,
								description: shoulderWidthDescription3,
							},
						],
						isActive: false,
					},
					{
						name: backLengthName,
						plpLink: measurementPlpLink,
						videoLink: backLengthVideoUrl,
						images: [
							{
								desktop: backLengthDesktopImageUrl,
								mobile: backLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: backLengthDescription1,
							},
							{
								name: stepName2Text,
								description: backLengthDescription2,
							},
							{
								name: stepName3Text,
								description: backLengthDescription3,
							},
						],
						isActive: false,
					},
					{
						name: sleeveLengthName,
						plpLink: measurementPlpLink,
						videoLink: sleeveLengthVideoUrl,
						images: [
							{
								desktop: sleeveLengthDesktopImageUrl,
								mobile: sleeveLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: sleeveLengthDescription1,
							},
							{
								name: stepName2Text,
								description: sleeveLengthDescription2,
							},
							{
								name: stepName3Text,
								description: sleeveLengthDescription3,
							},
							{
								name: stepName4Text,
								description: sleeveLengthDescription4,
							},
							{
								name: stepName5Text,
								description: sleeveLengthDescription5,
							},
						],
						isActive: true,
					},
					{
						name: hipCircumferenceName,
						plpLink: measurementPlpLink,
						videoLink: hipCircumferenceVideoUrl,
						images: [
							{
								desktop: hipCircumferenceDesktopImageUrl,
								mobile: hipCircumferenceMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: hipCircumferenceDescription1,
							},
							{
								name: stepName2Text,
								description: hipCircumferenceDescription2,
							},
							{
								name: stepName3Text,
								description: hipCircumferenceDescription3,
							},
						],
						isActive: false,
					},
					{
						name: insideLegName,
						plpLink: measurementPlpLink,
						videoLink: insideLegVideoUrl,
						images: [
							{
								desktop: insideLegDesktopImageUrl,
								mobile: insideLegMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: insideLegDescription1,
							},
							{
								name: stepName2Text,
								description: insideLegDescription2,
							},
							{
								name: stepName3Text,
								description: insideLegDescription3,
							},
						],
						isActive: false,
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildWomenSuitGuideViewModel;
