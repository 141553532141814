import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildPocketSquareGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		PSTitle: title,
		PSMobileBannerImageUrl: mobileBannerImage,
		StepName1Text: stepNameOne,
		StepName2Text: stepNameTwo,
		StepName3Text: stepNameThree,
		StepName4Text: stepNameFour,
		StepName5Text: stepNameFive,
		StepName6Text: stepNameSix,
		PSPlpLink: plpLink,
		PSFoldingTitle: foldingTitle,
		PSFoldingFlatName: foldingFlatName,
		PSFoldingFlatDescription: foldingFlatDescription,
		PSFoldingFlatVideoUrl: foldingFlatVideoUrl,
		PSFoldingFlatDesktopImageUrl1: foldingFlatDesktopImageUrl1,
		PSFoldingFlatMobileImageUrl1: foldingFlatMobileImageUrl1,
		PSFoldingFlatDesktopImageUrl2: foldingFlatDesktopImageUrl2,
		PSFoldingFlatMobileImageUrl2: foldingFlatMobileImageUrl2,
		PSFoldingFlatDesktopImageUrl3: foldingFlatDesktopImageUrl3,
		PSFoldingFlatMobileImageUrl3: foldingFlatMobileImageUrl3,
		PSFoldingFlatDesktopImageUrl4: foldingFlatDesktopImageUrl4,
		PSFoldingFlatMobileImageUrl4: foldingFlatMobileImageUrl4,
		PSFoldingFlatDesktopImageUrl5: foldingFlatDesktopImageUrl5,
		PSFoldingFlatMobileImageUrl5: foldingFlatMobileImageUrl5,
		PSFoldingFlatDesktopImageUrl6: foldingFlatDesktopImageUrl6,
		PSFoldingFlatMobileImageUrl6: foldingFlatMobileImageUrl6,
		PSFoldingWingPuffName: foldingWingPuffName,
		PSFoldingWingPuffDescription: foldingWingPuffDescription,
		PSFoldingWingPuffVideoUrl: foldingWingPuffVideoUrl,
		PSFoldingWingPuffDesktopImageUrl1: foldingWingPuffDesktopImageUrl1,
		PSFoldingWingPuffMobileImageUrl1: foldingWingPuffMobileImageUrl1,
		PSFoldingWingPuffDesktopImageUrl2: foldingWingPuffDesktopImageUrl2,
		PSFoldingWingPuffMobileImageUrl2: foldingWingPuffMobileImageUrl2,
		PSFoldingWingPuffDesktopImageUrl3: foldingWingPuffDesktopImageUrl3,
		PSFoldingWingPuffMobileImageUrl3: foldingWingPuffMobileImageUrl3,
		PSFoldingWingPuffDesktopImageUrl4: foldingWingPuffDesktopImageUrl4,
		PSFoldingWingPuffMobileImageUrl4: foldingWingPuffMobileImageUrl4,
		PSFoldingWingPuffDesktopImageUrl5: foldingWingPuffDesktopImageUrl5,
		PSFoldingWingPuffMobileImageUrl5: foldingWingPuffMobileImageUrl5,
		PSFoldingWingPuffDesktopImageUrl6: foldingWingPuffDesktopImageUrl6,
		PSFoldingWingPuffMobileImageUrl6: foldingWingPuffMobileImageUrl6,
		PSFoldingWingPuffDesktopImageUrl7: foldingWingPuffDesktopImageUrl7,
		PSFoldingWingPuffMobileImageUrl7: foldingWingPuffMobileImageUrl7,
		PSFoldingPuffName: foldingPuffName,
		PSFoldingPuffDescription: foldingPuffDescription,
		PSFoldingPuffVideoUrl: foldingPuffVideoUrl,
		PSFoldingPuffDesktopImageUrl1: foldingPuffDesktopImageUrl1,
		PSFoldingPuffMobileImageUrl1: foldingPuffMobileImageUrl1,
		PSFoldingPuffDesktopImageUrl2: foldingPuffDesktopImageUrl2,
		PSFoldingPuffMobileImageUrl2: foldingPuffMobileImageUrl2,
		PSFoldingPuffDesktopImageUrl3: foldingPuffDesktopImageUrl3,
		PSFoldingPuffMobileImageUrl3: foldingPuffMobileImageUrl3,
		PSFoldingPuffDesktopImageUrl4: foldingPuffDesktopImageUrl4,
		PSFoldingPuffMobileImageUrl4: foldingPuffMobileImageUrl4,
		PSFoldingPuffDesktopImageUrl5: foldingPuffDesktopImageUrl5,
		PSFoldingPuffMobileImageUrl5: foldingPuffMobileImageUrl5,
		PSFoldingWeaveName: foldingWeaveName,
		PSFoldingWeaveDescription: foldingWeaveDescription,
		PSFoldingWeaveVideoUrl: foldingWeaveVideoUrl,
		PSFoldingWeaveDesktopImageUrl1: foldingWeaveDesktopImageUrl1,
		PSFoldingWeaveMobileImageUrl1: foldingWeaveMobileImageUrl1,
		PSFoldingWeaveDesktopImageUrl2: foldingWeaveDesktopImageUrl2,
		PSFoldingWeaveMobileImageUrl2: foldingWeaveMobileImageUrl2,
		PSFoldingWeaveDesktopImageUrl3: foldingWeaveDesktopImageUrl3,
		PSFoldingWeaveMobileImageUrl3: foldingWeaveMobileImageUrl3,
		PSFoldingWeaveDesktopImageUrl4: foldingWeaveDesktopImageUrl4,
		PSFoldingWeaveMobileImageUrl4: foldingWeaveMobileImageUrl4,
		PSFoldingWeaveDesktopImageUrl5: foldingWeaveDesktopImageUrl5,
		PSFoldingWeaveMobileImageUrl5: foldingWeaveMobileImageUrl5,
		PSFoldingWeaveDesktopImageUrl6: foldingWeaveDesktopImageUrl6,
		PSFoldingWeaveMobileImageUrl6: foldingWeaveMobileImageUrl6,
		PSFoldingWeaveDesktopImageUrl7: foldingWeaveDesktopImageUrl7,
		PSFoldingWeaveMobileImageUrl7: foldingWeaveMobileImageUrl7,
		PSFoldingFourPointName: foldingFourPointName,
		PSFoldingFourPointDescription: foldingFourPointDescription,
		PSFoldingFourPointVideoUrl: foldingFourPointVideoUrl,
		PSFoldingFourPointDesktopImageUrl1: foldingFourPointDesktopImageUrl1,
		PSFoldingFourPointMobileImageUrl1: foldingFourPointMobileImageUrl1,
		PSFoldingFourPointDesktopImageUrl2: foldingFourPointDesktopImageUrl2,
		PSFoldingFourPointMobileImageUrl2: foldingFourPointMobileImageUrl2,
		PSFoldingFourPointDesktopImageUrl3: foldingFourPointDesktopImageUrl3,
		PSFoldingFourPointMobileImageUrl3: foldingFourPointMobileImageUrl3,
		PSFoldingFourPointDesktopImageUrl4: foldingFourPointDesktopImageUrl4,
		PSFoldingFourPointMobileImageUrl4: foldingFourPointMobileImageUrl4,
		PSFoldingFourPointDesktopImageUrl5: foldingFourPointDesktopImageUrl5,
		PSFoldingFourPointMobileImageUrl5: foldingFourPointMobileImageUrl5,
		PSFoldingFourPointDesktopImageUrl6: foldingFourPointDesktopImageUrl6,
		PSFoldingFourPointMobileImageUrl6: foldingFourPointMobileImageUrl6,
		PSFlatHowToFoldDescription1: flatHowToFold1,
		PSFlatHowToFoldDescription2: flatHowToFold2,
		PSFlatHowToFoldDescription3: flatHowToFold3,
		PSFlatHowToFoldDescription4: flatHowToFold4,
		PSFlatHowToFoldDescription5: flatHowToFold5,
		PSWingPuffHowToFoldDescription1: wingPuffHowToFold1,
		PSWingPuffHowToFoldDescription2: wingPuffHowToFold2,
		PSWingPuffHowToFoldDescription3: wingPuffHowToFold3,
		PSWingPuffHowToFoldDescription4: wingPuffHowToFold4,
		PSWingPuffHowToFoldDescription5: wingPuffHowToFold5,
		PSWingPuffHowToFoldDescription6: wingPuffHowToFold6,
		PSPuffHowToFoldDescription1: puffHowToFold1,
		PSPuffHowToFoldDescription2: puffHowToFold2,
		PSPuffHowToFoldDescription3: puffHowToFold3,
		PSPuffHowToFoldDescription4: puffHowToFold4,
		PSWaveHowToFoldDescription1: waveHowToFold1,
		PSWaveHowToFoldDescription2: waveHowToFold2,
		PSWaveHowToFoldDescription3: waveHowToFold3,
		PSWaveHowToFoldDescription4: waveHowToFold4,
		PSWaveHowToFoldDescription5: waveHowToFold5,
		PSWaveHowToFoldDescription6: waveHowToFold6,
		PSFourPointHowToFoldDescription1: fourPointHowToFold1,
		PSFourPointHowToFoldDescription2: fourPointHowToFold2,
		PSFourPointHowToFoldDescription3: fourPointHowToFold3,
		PSFourPointHowToFoldDescription4: fourPointHowToFold4,
		PSFourPointHowToFoldDescription5: fourPointHowToFold5,
	} = fitGuideSetting;
	return {
		title,
		mobileBannerImage,
		sections: [
			{
				title: foldingTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: foldingFlatName,
						plpLink: plpLink,
						videoLink: foldingFlatVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: foldingFlatDesktopImageUrl1,
								mobile: foldingFlatMobileImageUrl1,
							},
							{
								desktop: foldingFlatDesktopImageUrl2,
								mobile: foldingFlatMobileImageUrl2,
							},
							{
								desktop: foldingFlatDesktopImageUrl3,
								mobile: foldingFlatMobileImageUrl3,
							},
							{
								desktop: foldingFlatDesktopImageUrl4,
								mobile: foldingFlatMobileImageUrl4,
							},
							{
								desktop: foldingFlatDesktopImageUrl5,
								mobile: foldingFlatMobileImageUrl5,
							},
							{
								desktop: foldingFlatDesktopImageUrl6,
								mobile: foldingFlatMobileImageUrl6,
							},
						],
						imageDetails: [
							{ name: null, description: foldingFlatDescription },
							{ name: stepNameOne, description: flatHowToFold1 },
							{ name: stepNameTwo, description: flatHowToFold2 },
							{ name: stepNameThree, description: flatHowToFold3 },
							{ name: stepNameFour, description: flatHowToFold4 },
							{ name: stepNameFive, description: flatHowToFold5 },
						],
						isActive: false,
					},
					{
						name: foldingWingPuffName,
						plpLink: plpLink,
						videoLink: foldingWingPuffVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: foldingWingPuffDesktopImageUrl1,
								mobile: foldingWingPuffMobileImageUrl1,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl2,
								mobile: foldingWingPuffMobileImageUrl2,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl3,
								mobile: foldingWingPuffMobileImageUrl3,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl4,
								mobile: foldingWingPuffMobileImageUrl4,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl5,
								mobile: foldingWingPuffMobileImageUrl5,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl6,
								mobile: foldingWingPuffMobileImageUrl6,
							},
							{
								desktop: foldingWingPuffDesktopImageUrl7,
								mobile: foldingWingPuffMobileImageUrl7,
							},
						],
						imageDetails: [
							{ name: null, description: foldingWingPuffDescription },
							{ name: stepNameOne, description: wingPuffHowToFold1 },
							{ name: stepNameTwo, description: wingPuffHowToFold2 },
							{ name: stepNameThree, description: wingPuffHowToFold3 },
							{ name: stepNameFour, description: wingPuffHowToFold4 },
							{ name: stepNameFive, description: wingPuffHowToFold5 },
							{ name: stepNameSix, description: wingPuffHowToFold6 },
						],
						isActive: true,
					},
					{
						name: foldingPuffName,
						plpLink: plpLink,
						videoLink: foldingPuffVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: foldingPuffDesktopImageUrl1,
								mobile: foldingPuffMobileImageUrl1,
							},
							{
								desktop: foldingPuffDesktopImageUrl2,
								mobile: foldingPuffMobileImageUrl2,
							},
							{
								desktop: foldingPuffDesktopImageUrl3,
								mobile: foldingPuffMobileImageUrl3,
							},
							{
								desktop: foldingPuffDesktopImageUrl4,
								mobile: foldingPuffMobileImageUrl4,
							},
							{
								desktop: foldingPuffDesktopImageUrl5,
								mobile: foldingPuffMobileImageUrl5,
							},
						],
						imageDetails: [
							{ name: null, description: foldingPuffDescription },
							{ name: stepNameOne, description: puffHowToFold1 },
							{ name: stepNameTwo, description: puffHowToFold2 },
							{ name: stepNameThree, description: puffHowToFold3 },
							{ name: stepNameFour, description: puffHowToFold4 },
						],
						isActive: false,
					},
					{
						name: foldingWeaveName,
						plpLink: plpLink,
						videoLink: foldingWeaveVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: foldingWeaveDesktopImageUrl1,
								mobile: foldingWeaveMobileImageUrl1,
							},
							{
								desktop: foldingWeaveDesktopImageUrl2,
								mobile: foldingWeaveMobileImageUrl2,
							},
							{
								desktop: foldingWeaveDesktopImageUrl3,
								mobile: foldingWeaveMobileImageUrl3,
							},
							{
								desktop: foldingWeaveDesktopImageUrl4,
								mobile: foldingWeaveMobileImageUrl4,
							},
							{
								desktop: foldingWeaveDesktopImageUrl5,
								mobile: foldingWeaveMobileImageUrl5,
							},
							{
								desktop: foldingWeaveDesktopImageUrl6,
								mobile: foldingWeaveMobileImageUrl6,
							},
							{
								desktop: foldingWeaveDesktopImageUrl7,
								mobile: foldingWeaveMobileImageUrl7,
							},
						],
						imageDetails: [
							{ name: null, description: foldingWeaveDescription },
							{ name: stepNameOne, description: waveHowToFold1 },
							{ name: stepNameTwo, description: waveHowToFold2 },
							{ name: stepNameThree, description: waveHowToFold3 },
							{ name: stepNameFour, description: waveHowToFold4 },
							{ name: stepNameFive, description: waveHowToFold5 },
							{ name: stepNameSix, description: waveHowToFold6 },
						],
						isActive: false,
					},
					{
						name: foldingFourPointName,
						plpLink: plpLink,
						videoLink: foldingFourPointVideoUrl,
						textBetweenArrows: [
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
							'Steps',
						],
						images: [
							{
								desktop: foldingFourPointDesktopImageUrl1,
								mobile: foldingFourPointMobileImageUrl1,
							},
							{
								desktop: foldingFourPointDesktopImageUrl2,
								mobile: foldingFourPointMobileImageUrl2,
							},
							{
								desktop: foldingFourPointDesktopImageUrl3,
								mobile: foldingFourPointMobileImageUrl3,
							},
							{
								desktop: foldingFourPointDesktopImageUrl4,
								mobile: foldingFourPointMobileImageUrl4,
							},
							{
								desktop: foldingFourPointDesktopImageUrl5,
								mobile: foldingFourPointMobileImageUrl5,
							},
							{
								desktop: foldingFourPointDesktopImageUrl6,
								mobile: foldingFourPointMobileImageUrl6,
							},
						],
						imageDetails: [
							{ name: null, description: foldingFourPointDescription },
							{ name: stepNameOne, description: fourPointHowToFold1 },
							{ name: stepNameTwo, description: fourPointHowToFold2 },
							{ name: stepNameThree, description: fourPointHowToFold3 },
							{ name: stepNameFour, description: fourPointHowToFold4 },
							{ name: stepNameFive, description: fourPointHowToFold5 },
						],
						isActive: false,
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls height2 top3 right4',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildPocketSquareGuideViewModel;
