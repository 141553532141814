import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { PersonifySocialProofingService } from '../../service/personify-social-proofing.service';
import { Observable } from 'rxjs';
import { SocialProofingTypeEnum, SocialProofingVM } from '../../models/social-proofing.model';
import { ProductViewComponent } from 'src/app/modules/product-list/product-view/product-view.component';
import { ImageSliderComponent } from 'src/app/modules/product-details/image-slider/image-slider.component';


@Component({
    selector: 'social-proof, [social-proof]',
    templateUrl: './social-proof.component.html',
    styleUrls: ['./social-proof.component.scss']
})
export class SocialProofComponent implements OnInit {
    @Input() productCode: string;

    private parentType: SocialProofingTypeEnum | undefined;
    socialProofingVM$: Observable<SocialProofingVM>;

    constructor(
        private personifySocialProofingService: PersonifySocialProofingService,
        @Optional() @Inject(ProductViewComponent) private productViewComponent: ProductViewComponent,
        @Optional() @Inject(ImageSliderComponent) private imageSliderComponent: ImageSliderComponent,
    ) {
        if (this.productViewComponent) {
            this.parentType = SocialProofingTypeEnum.PLP;
        }
        else if (this.imageSliderComponent) {
            this.parentType = SocialProofingTypeEnum.PDP;
        }
    }

    ngOnInit(): void {
        this.socialProofingVM$ = this.personifySocialProofingService.getViewModelByProductCode(this.productCode, this.parentType);
    }
}