import { FitGuideMainPageKeyEnum } from '../../enums/fit-guide-main-page-key.enum';

export namespace FitGuide {
	export class SetActiveMobileScreenIndex {
		static readonly type = '[FitGuide] SetActiveMobileScreenIndex';
		constructor(
			public pageKey: FitGuideMainPageKeyEnum,
			public screenIndex: number
		) {}
	}

	export class ResetStateToDefault {
		static readonly type = '[FitGuide] ResetStateToDefault';
		constructor() {}
	}
}
