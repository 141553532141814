import { Injectable } from '@angular/core';
import { SharedService } from '../shared.service';
import { PersonifyService } from './personify.service';
import { SocialProofSettings } from 'src/app/model/lpapp-setting';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { PersonifyRequest, PRSMessage } from '../models/product-recommendations.model';
import { SocialProofingTrendsData, SocialProofingTypeEnum, SocialProofingVM } from '../models/social-proofing.model';

@Injectable({
  providedIn: 'root'
})
export class PersonifySocialProofingService {
  private trendsDataArray: SocialProofingTrendsData[] = [];
  private messages: any;
  private icons: any;
  private socialProofSettings: SocialProofSettings;
  private socialProofingVM$: BehaviorSubject<SocialProofingTrendsData[]> = new BehaviorSubject<SocialProofingTrendsData[]>([]);

  constructor(
    private sharedService: SharedService,
    private personifyService: PersonifyService
  ) {
    this.inint();
  }

  loadProductTrends(productCodes: string[], type: SocialProofingTypeEnum): void {
    const index = this.trendsDataArray.findIndex(x => x.Type === type);

    if (index != -1) {
      this.trendsDataArray.splice(index, 1);
    }

    this.getProductTrends(productCodes, type);
    return;
  }

  getViewModelByProductCode(productCode: string, type: SocialProofingTypeEnum): Observable<SocialProofingVM> {
    return this.socialProofingVM$.pipe(
      map(trendsData => this.getViewModel(productCode, type, trendsData)),
      filter(viewModel => viewModel != null),
      catchError(error => {
        console.log('An error occurred while processing social proofing view model.\nError:\n', error);
        return EMPTY;
      })
    );
  }

  private inint(): void {
    this.socialProofSettings = this.sharedService.lpAppSettings?.SocialProofSettings;

    if (!this.socialProofSettings) {
      return;
    }

    this.messages = {
      views: {
        "right now": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_RightNow,
        },
        "15 minutes": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_15_Minutes,
        },
        "half hour": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Half_Hour,
        },
        "hour": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Hour,
        },
        "today": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Today,
        },
        "week": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.Sold_Week,
        }
      },
      adds: {
        "right now": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_RightNow,
        },
        "15 minutes": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_15_Minutes,
        },
        "half hour": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Half_Hour,
        },
        "hour": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Hour,
        },
        "today": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.View_Today,
        },
        "week": {
          title: this.socialProofSettings.View_Title,
          message: this.socialProofSettings.Sold_Week,
        }
      },
      purchases: {
        "right now": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_RightNow
        },
        "15 minutes": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_15_Minutes
        },
        "half hour": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_Half_Hour,
        },
        "hour": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_Hour,
        },
        "today": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_Today,
        },
        "week": {
          title: this.socialProofSettings.Sold_Title,
          message: this.socialProofSettings.Sold_Week
        }
      }
    };

    this.icons = {
      views: this.socialProofSettings.FlamingIcon,
      adds: this.socialProofSettings.FlamingIcon,
      purchases: this.socialProofSettings.StarIcon
    };
  }

  private getProductTrends(productCodes: string[], type: SocialProofingTypeEnum): void {
    if (!this.socialProofSettings || this.isActive(type) == false) {
      return;
    }

    const personifyApiUrl = `${this.socialProofSettings.PersonifyApiUrl}/getproducttrends`;
    const productTrendsPayload = this.buildPersonifyProductTrendsRequestPayload(productCodes);
    if (!productTrendsPayload) {
      return;
    }

    this.personifyService.getRecommendations(personifyApiUrl, productTrendsPayload).pipe(
      tap(response => console.log('SocialProofing', SocialProofingTypeEnum[type], 'Response JSON: ->', response)),
      map(response => this.buildProductRecommendationsVM(response?.socialmessages)),
      filter(models => models.length > 0),
      catchError((error) => {
        console.log('An error occurred while processing personify product trends response.\nError:\n', error);
        return EMPTY;
      })
    ).subscribe(models => {
      this.trendsDataArray.push({
        Type: type,
        ViewModels: models
      })
      this.socialProofingVM$.next(this.trendsDataArray);
    });
  }

  private isActive(type: SocialProofingTypeEnum): boolean {
    let isActive = false;

    switch (type) {
      case SocialProofingTypeEnum.PLP:
        isActive = this.socialProofSettings?.PLP_Active;
        break;

      case SocialProofingTypeEnum.PDP:
        isActive = this.socialProofSettings?.PDP_Active;
        break;

      default:
        break;
    }

    return isActive;
  }

  private buildPersonifyProductTrendsRequestPayload(productCodes: string[]): PersonifyRequest {
    return {
      sessionid: this.personifyService.getPersonifySessionId(),
      shopperid: this.personifyService.getPersonifyShopperId(),
      products: productCodes
    }
  }

  private buildProductRecommendationsVM(socialmessages: PRSMessage[]): SocialProofingVM[] {
    return socialmessages.map(msg => ({
      ProductCode: msg?.productcode,
      Message: `${msg.quantity} ${this.messages[msg.type][msg.splitid].message}`,
      Title: this.messages[msg.type][msg.splitid].title,
      IconPath: this.icons[msg.type]
    }));
  }

  private getViewModel(productCode: string, type: SocialProofingTypeEnum, trendsDataArray: SocialProofingTrendsData[]): SocialProofingVM {
    return trendsDataArray
      .find(data => data.Type === type)?.ViewModels
      ?.find(x => x.ProductCode.toLocaleUpperCase() === productCode.toLocaleUpperCase());
  }
}