exports.AddDynanicScriptAndStyleToDOM = function AddDynanicScriptAndStyleToDOM(webpagevm) {
	try {

		if (webpagevm) {

			try {
				if (webpagevm && webpagevm.HideFromSEO) {
					var meta;
					meta = document.getElementById("robotsId");
					if (!meta) {
						meta = document.createElement('meta');
					}

					meta.name = "robots";
					meta.content = "noindex,nofollow";
					meta.setAttribute("ID", "robotsId")

					document.head.appendChild(meta);


				}
			}
			catch (ex) {

			}
			//NoIndexNoFollow start
			try {
				if (webpagevm.NoIndexNoFollow) {
					var meta;
					meta = document.getElementById("robotsValueId");
					if (!meta) {
						meta = document.createElement('meta');
					}

					meta.name = "robots";
					meta.content = "noindex,follow";
					meta.setAttribute("ID", "robotsValueId")

					document.head.appendChild(meta);


				}
			}
			catch (ex) {

			}

			//NoIndexNoFollow end
		}


	}
	catch (ex) {

	}

}
exports.AddTrackingScriptToDOM = function AddTrackingScriptToDOM(scriptContent) {
	try {

		if (scriptContent && scriptContent.length > 0) {

			var scriptExist = true;
			var scripElementNumber = 0;

			while (scriptExist) {

				var trackingScriptId = "trackingScriptId" + scripElementNumber;
				var scriptElement = document.getElementById(trackingScriptId);
				if (scriptElement) {
					scriptElement.remove();
				} else {
					scriptExist = false;
				}
				scripElementNumber++;
			}
			for (var i = 0; i < scriptContent.length; i++) {
				var script;
				var trackingScriptId = "trackingScriptId" + i;
				script = document.getElementById(trackingScriptId);
				if (script) {
					script.remove();
				}

				script = document.createElement(scriptContent[i].name);
				var content;
				if (i == 0) {
					content = `
        if(localStorage.getItem("SignedOut")==="true"){
          window.tagManagerVars={};
          localStorage.removeItem("SignedOut");
        }
        `+ scriptContent[i].content;
				} else {
					content = scriptContent[i].content;
				}

				var inlineCode = document.createTextNode(`${content}`);

				script.type = 'text/javascript';
				script.setAttribute("ID", trackingScriptId);

				script.appendChild(inlineCode);
				if (scriptContent[i].content.indexOf("<script") > -1) {
					var divtag = document.getElementById(trackingScriptId);
					if (divtag) {
						divtag.remove();
					}
					divtag = document.createElement("div");

					divtag.setAttribute("ID", trackingScriptId);
					divtag.innerHTML = scriptContent[i].content;
					document.body.appendChild(divtag);
				} else {

					document.body.appendChild(script);
				}

			}

		}

		var scriptsList = document.getElementsByTagName("script");
		if (scriptsList && scriptsList.length > 0) {
			for (var i = 0; i < scriptsList.length; i++) {
				if (scriptsList[i].src && scriptsList[i].src.indexOf("www.googletagmanager.com") > -1 && i != 0) {
					scriptsList[i].remove();
				}
			}
		}


	}
	catch (ex) {

	}

}

exports.AddScriptStyleLinks = function AddScriptStyleLinks(LpAppSetting) {

	try {
		if (LpAppSetting) {

			//FaviconPath start
			if (LpAppSetting.UserInterface.FaviconPath) {
				var link;
				var favId = "FaviconPathId"
				link = document.getElementById(favId);
				if (!link) {
					link = document.createElement('link');
				}
				link.type = 'image/png';
				link.setAttribute("rel", "shortcut icon");
				link.setAttribute("ID", favId)
				link.setAttribute("href", LpAppSetting.UserInterface.FaviconPath);
				document.head.appendChild(link);

			}
			//FaviconPath end

			//plug in start
			if (LpAppSetting.Plugin) {
				var meta;
				var pluginId = "pluginId"
				meta = document.getElementById(pluginId);
				if (!meta) {
					meta = document.createElement('meta');
				}
				var content = "app-id=" + LpAppSetting.AppId + ", app-argument=https://apps.apple.com/gb/app/hawes-and-curtis/id815542600";

				meta.setAttribute("name", "apple-itunes-app");
				meta.setAttribute("content", content);
				meta.setAttribute("ID", pluginId)

				document.head.appendChild(meta);
			}
			//plugin end

			//theme css start
			if (LpAppSetting.UserInterface.ThemeCss && LpAppSetting.UserInterface.length > 0) {
				for (var css = 0; css < LpAppSetting.UserInterface.ThemeCss.length; css++) {
					var link;
					var themecssId = "themecssId" + css;
					link = document.getElementById(themecssId);
					if (!link) {
						link = document.createElement('link');
					}
					link.type = 'text/css';
					link.setAttribute("rel", "stylesheet");
					link.setAttribute("ID", themecssId)
					link.setAttribute("href", LpAppSetting.UserInterface.ThemeCss[css]);
					document.head.appendChild(link);

				}
			}
		}
	} catch (ex) {
		console.log("AddScriptStyleLinks error: " + ex.message);
	}
}

exports.AddPostcodeKey = function AddPostcodeKey(LpAppSetting) {
	try {
		let key = LpAppSetting.PostCodeAnywhereSecurityKey;
		var url = "//services.postcodeanywhere.co.uk/css/captureplus-2.30.min.css?key=" + key;
		var link;//=document.createElement('style');
		link = document.getElementById("postcodestyleId");
		if (!link) {
			link = document.createElement('style');
		}
		link.type = 'text/css';
		link.setAttribute("rel", "stylesheet");
		link.setAttribute("ID", "postcodestyleId")
		link.setAttribute("href", url);
		//script.innerHTML =scriptContent;
		document.head.appendChild(link);
	}
	catch (ex) {
		console.log("AddPostcodeKey error: " + ex.message);
	}
}



