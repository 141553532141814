import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Injector, Input, NgZone, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { BasketItem } from 'src/app/model/basketpage-viewmodel';
import { LpAppSetting } from '../../../model/lpapp-setting';
import { AdyenErrorModel } from '../../models/error.model';
import { SiteContentDetail } from '../../models/siteContent';
import { AdyenService } from '../../service/adyen.service';
import { SharedService } from '../../shared.service';
import { LoaderService } from '../loader/loader.service';
import AdyenCheckout from '@adyen/adyen-web';

@Component({
	selector: 'adyen-drop-in,[adyen-drop-in]',
	templateUrl: './adyen-drop-in.component.html',
	styleUrls: ['./adyen-drop-in.component.scss']
})
export class AdyenDropInComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('hook', { static: true }) hook: ElementRef;
	@Input() email: string;
	@Input() paymentMethodsResponse: any;
	@Input() isGift: boolean;
	@Input() paymentOrderId: any;
	@Input() sitecontent: SiteContentDetail;
	@Input() basketItems: BasketItem[];
	@Input() LpAppSetting: LpAppSetting;

	@Output() paymentAction = new EventEmitter<any>();

	paymentResponse: any;
	storeDropinDetails: boolean = false;
	checkout: any;
	paymentPending: boolean = false;
	shippingPostCode: string;
	reloadPage: boolean = false;

	error: AdyenErrorModel = {
		resultCode: '',
		refusalReason: '',
		refusalReasonCode: ''
	}

	constructor(
		private injector: Injector,
		private adyenService: AdyenService,
		private loaderService: LoaderService,
		private route: ActivatedRoute,
		private reCaptchaV3Service: ReCaptchaV3Service,
		private sharedService: SharedService,

		@Inject(PLATFORM_ID)
		private platformId: any) {
	}

	async ngOnInit() {

		if (isPlatformBrowser(this.platformId)) {
			let locale = this.paymentMethodsResponse.Locale;
			let environment = this.paymentMethodsResponse.Environment.toLowerCase();
			let originKey = this.paymentMethodsResponse.OriginKey;
			let amountValue = this.paymentMethodsResponse.AmountValue;
			let amountCurrency = this.paymentMethodsResponse.AmountCurrency;
			let isGuest = this.paymentMethodsResponse.IsGuest;
			let enableStoreDetails = this.paymentMethodsResponse.EnableStoreDetails;
			let clientKey = this.paymentMethodsResponse.ClientKey;
			this.shippingPostCode = this.paymentMethodsResponse.ShippingPostCode;

			this.paymentMethodsResponse = JSON.parse(this.paymentMethodsResponse.JsonResponse);
			if (this.paymentMethodsResponse && this.paymentMethodsResponse.paymentMethods && this.paymentMethodsResponse.paymentMethods.length) {
				for (let i = 0; i < this.paymentMethodsResponse.paymentMethods.length; i++) {
					if (this.paymentMethodsResponse.paymentMethods[i].type == 'scheme') {
						this.paymentMethodsResponse.paymentMethods[i].name = this.sitecontent.CreditDebitCard;
						this.paymentMethodsResponse.paymentMethods[i].hasHolderName = true;
						if (enableStoreDetails) {
							this.paymentMethodsResponse.paymentMethods[i].enableStoreDetails = !isGuest;
						}
					}
				}
			}

			const configuration = {
				// https://docs.adyen.com/checkout/components-web/localization-components
				locale: locale,
				environment: environment,
				// https://docs.adyen.com/user-management/how-to-get-an-origin-key (for https://hawesandcurtis2.local)
				clientKey: clientKey,
				paymentMethodsResponse: this.paymentMethodsResponse,
				analytics: {
					enabled: true // Set to false to not send analytics data to Adyen.
				},
				amount: { value: amountValue, currency: amountCurrency },
				openFirstPaymentMethod: true,
				setStatusAutomatically: false,
				paymentMethodsConfiguration: {
					card: {
						holderNameRequired: true,
						clickToPayConfiguration: {
							merchantDisplayName: this.sitecontent.BrandCompanyName,
							shopperEmail: this.email,
						}
					}
				},
				onSubmit: this.handleOnSubmit,
			};

			this.checkout = await AdyenCheckout(configuration);

			// Check whether Adyen dropin loads correctly
			if (!this.checkout) {

				// Inform webservice that Adyen checkout has not loaded
				this.adyenService.logCheckout(false).subscribe(
					data => { this.loaderService.isLoading.next(false); },
					err => { this.loaderService.isLoading.next(false); }
				);

				location.reload();
				return;
			}

			if (enableStoreDetails) {
				this.storeDropinDetails = !isGuest;
			}

			let dropin = this.checkout
				.create("dropin", {
					// Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
					paymentMethodsConfiguration: {
						card: {
							hasHolderName: true,
							holderNameRequired: true,
							enableStoreDetails: this.storeDropinDetails,
							showStoredPaymentMethods: this.storeDropinDetails,
							clickToPayConfiguration: {
								merchantDisplayName: this.sitecontent.BrandCompanyName,
								shopperEmail: this.email,
							}
						}
					},
					onReady: () => {
						// Inform webservice that Adyen payment methods are ready
						this.adyenService.logCheckout(true).subscribe(
							data => { this.loaderService.isLoading.next(false); },
							err => { this.loaderService.isLoading.next(false); }
						);
					},
					onError: function (error) {
						// What should we do here?
					},
					onSelect: (component) => {
						// Inform webservice of selected Adyen payment method
						if (component && component.props && component.props.type) {
							this.adyenService.logSelectedPayment(component.props.type).subscribe(
								data => { this.loaderService.isLoading.next(false); },
								err => { this.loaderService.isLoading.next(false); }
							);
						}
					},
					onAdditionalDetails: this.handleOnAdditionalDetails,
				})
				.mount("#dropin-container");
		}
	}

	ngAfterViewInit() {
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(function () {
				$('.adyen-checkout__payment-method__image').each(function () {
					$(this).addClass('adyen-checkout__image--loaded')
				});
			}, 1000)
		}
	}

	ngOnDestroy() {
		// Unmount aka destroy the Adyen Drop-in component
		if (this.checkout && this.checkout.components && this.checkout.components[0]) {
			this.checkout.components[0].unmount();
		}
	}

	handleOnSubmit = (state, dropin) => {
		if (isPlatformBrowser(this.platformId) && !this.paymentPending) {

			this.paymentPending = true;

			let paymentMethodDetails = {} as any;

			// Reset any errors
			this.error = {} as any;

			// Adyen Payment Type
			paymentMethodDetails.Type = state.data.paymentMethod.type;
			paymentMethodDetails.CheckoutAttemptId = state.data.paymentMethod.checkoutAttemptId;

			// Card
			paymentMethodDetails.Brand = state.data.paymentMethod.brand;
			paymentMethodDetails.HolderName = state.data.paymentMethod.holderName;
			paymentMethodDetails.EncryptedCardNumber = state.data.paymentMethod.encryptedCardNumber;
			paymentMethodDetails.EncryptedExpiryMonth = state.data.paymentMethod.encryptedExpiryMonth;
			paymentMethodDetails.EncryptedExpiryYear = state.data.paymentMethod.encryptedExpiryYear;
			paymentMethodDetails.EncryptedSecurityCode = state.data.paymentMethod.encryptedSecurityCode;

			// Google Pay
			paymentMethodDetails.GooglePayCardNetwork = state.data.paymentMethod.googlePayCardNetwork;
			paymentMethodDetails.GooglePayToken = state.data.paymentMethod.googlePayToken;

			// Apple Pay
			paymentMethodDetails.ApplePayToken = state.data.paymentMethod.applePayToken;

			// Other details
			paymentMethodDetails.StorePaymentMethod = state.data.storePaymentMethod;
			paymentMethodDetails.StoredPaymentMethodId = state.data.paymentMethod.storedPaymentMethodId
			paymentMethodDetails.BrowserInfoJson = JSON.stringify(state.data.browserInfo);

			paymentMethodDetails.Origin = window.location.origin; // Passing origin of dropin so that 3DS Secure can be callback to correct url
			paymentMethodDetails.ShippingPostCode = this.shippingPostCode; // Passing original stored shippingPostCode to ensure delivery address has not been changed

			this.reCaptchaV3Service.execute(this.LpAppSetting.Recaptcha_V3_SiteKey, "checkout/payment/Adyen_ProcessPayment", (recaptchaToken) => {

				// Assign the recaptchaToken to request
				paymentMethodDetails.RecaptchaToken = recaptchaToken;

				this.adyenService.processPayment(paymentMethodDetails, this.isGift, this.paymentOrderId).subscribe(data => {
					this.processPaymentResult(dropin, data);
				},
					err => {
						console.error("Checkout /Adyen/ProcessPayment Error");
						this.paymentPending = false;
					});

			}, {
				useGlobalDomain: false
			});
		}
	}

	handleOnAdditionalDetails = (state, dropin) => {
		if (isPlatformBrowser(this.platformId) && !this.paymentPending) {

			this.paymentPending = true;

			this.adyenService.paymentProcessed(this.paymentOrderId, JSON.stringify(state.data)).subscribe(data => {

				// Should be returning back a new URL to redirect to
				if (data.redirectUrl !== null) {
					window.location.href = data.redirectUrl;
				}

				//this.processPaymentResult(dropin, data);
			},
				err => {
					console.error("Checkout /Adyen/PaymentProcessed Error");
					this.paymentPending = false;
				});
		}
	}

	processPaymentResult(dropin: any, data: any) {
		if (data) {
			this.paymentResponse = JSON.parse(data.result.JsonResponse);

			// Override OrderId if neccessary
			this.paymentOrderId = data.result.OrderId;

			// If error
			if (this.paymentResponse.resultCode == "Refused"
				|| this.paymentResponse.resultCode == "Error"
				|| this.paymentResponse.resultCode == "Cancelled") {
				this.error.resultCode = data.result.ResultCode;
				this.error.refusalReason = data.result.RefusalReason;
				this.error.refusalReasonCode = data.result.RefusalReasonCode;
			}

			if (data.result.ReloadPage) {
				this.reloadPage = true;
				return;
			}

			// If action required
			if (this.paymentResponse.action) {
				dropin.handleAction(this.paymentResponse.action);

				this.paymentAction.emit(true);

				this.paymentPending = false;
				return;
			}

			// Successful paid or pending transaction
			if (this.paymentResponse.resultCode == "Authorised"
				|| this.paymentResponse.resultCode == "Received") {
				const routerService = this.injector.get(Router);
				const ngZone = this.injector.get(NgZone);

				ngZone.run(() => {
					routerService.navigate(['../receipt', data.result.OrderId], { relativeTo: this.route });
				});
			}
		}

		this.paymentPending = false;
	}

	onReloadPage() {
		location.reload();
		return;
	}
}
