exports.InitialiseProductListingPageTracking = function InitialiseProductListingPageTracking(criteoSettings, products, searchViewModel, filterGroups, breadcrumbs) {
	try {
		setTimeout(function () {
			var filters = [];

			$.each(filterGroups, function (idx, group) {
				$.each(group.Items, function (idx1, item) {
					if (item.IsSelected) {
						filters.push(group.Title + "=" + item.Name);
					}
				});
			});

			var pageDimension1 = "Category List";
			if (searchViewModel.Title) {
				pageDimension1 += ":" + searchViewModel.Title;
			}

			var joinedFilters = "";
			if (filters.length > 0) {
				joinedFilters += "|";
				joinedFilters += filters.join(":");
				joinedFilters = joinedFilters.replace("\"", "");
			}

			var pageDimension3 = products.length;
			if (searchViewModel.Title) {
				pageDimension3 += "|" + searchViewModel.Title;
			}
			pageDimension3 += joinedFilters;

			var data = {};
			data.dimension1 = pageDimension1;
			data.dimension3 = pageDimension3;
			data.ecommerce = {};
			data.ecommerce.impressions = [];

			$.each(products, function (idx, x) {
				// Skip if product banner
				if (x.HasBanner) return;

				var ecommerceProd = {};
				ecommerceProd.id = x.Code;
				ecommerceProd.list = "Category Page";
				ecommerceProd.category = searchViewModel.FullUrl;
				ecommerceProd.name = x.Name;
				ecommerceProd.price = Math.round(x.PriceAndStockKeyValues.SalePrice);
				ecommerceProd.position = idx;
				ecommerceProd.googleProductCategory = x.GoogleProductCategory;
				ecommerceProd.productCategory = x.ProductCategory;

				data.ecommerce.impressions.push(ecommerceProd);
			});

			hc_gtm._initialiseProductListingPageTracking(criteoSettings, data, searchViewModel, breadcrumbs);
		}, 500);
	}
	catch (ex) {
		console.log("InitialiseProductListingPageTracking error: " + ex.message);
	}
}

exports.InitialiseProductDetailPageTracking = function InitialiseProductDetailPageTracking(product, breadcrumbs) {
	try {
		setTimeout(function () {
			var gender = '';
			if (this.detail && this.detail.Product && this.detail.Product.ProductAttributes) {
				if (this.detail.Product.ProductAttributes.length > 0) {
					this.detail.Product.ProductAttributes.forEach(item => {
						if (item.Key === "gender") {
							if (item.AttributeValues && item.AttributeValues.length > 0) {
								gender = item.AttributeValues[0].Name;
								if (gender) {
									gender = gender.toLocaleLowerCase();
								}
							}
						}
					})
				}
			}
			hc_gtm._initialiseProductDetailPageTracking(gender, product, breadcrumbs);
		}, 500);
	}
	catch (ex) {
		console.log("InitialiseProductDetailPageTracking error: " + ex.message);
	}
}

exports.InitialiseBasketTracking = function InitialiseBasketTracking(criteoSettings, basket, legacy, addedToBag) {
	try {
		function convertToJSONDate(strDate) {
			var dt = new Date(strDate);
			var year = dt.getFullYear().toString();
			if (year.length == 1) {
				year = "000" + year;
			} else if (year.length === 2) {
				year = "00" + year;
			}
			else if (year.length === 3) {
				year = "0" + year;
			}
			var month = (dt.getMonth() + 1).toString();
			if (month.length === 1) {
				month = "0" + month;
			}
			var date = dt.getDate().toString();
			if (date.length === 1) {
				date = "0" + date;
			}
			var newDate = year + "-" + month + "-" + date;
			return '/Date(' + Date.parse(newDate) + ')/';
		}

		setTimeout(function () {
			if (basket) {
				basket.Created = convertToJSONDate(basket.Created);
				basket.Updated = convertToJSONDate(basket.Updated);
			}

			hc_gtm._initialiseBasketTracking(criteoSettings, basket, legacy, addedToBag);
		}, 500);
	}
	catch (ex) {
		console.log("InitialiseBasketTracking error: " + ex.message);
	}
}

exports.InitialiseReceiptPageTracking = function InitialiseReceiptPageTracking(generalSettings, userSettings, receipt) {
	try {
		setTimeout(function () {
			var orderTotalExPostageExVat = receipt.Order.Total - receipt.Order.ShippingTotal - receipt.Order.ProductTax;

			hc_gtm._initialiseReceiptPageTracking(generalSettings, userSettings, receipt, orderTotalExPostageExVat.toString());
		}, 1000);
	}
	catch (ex) {
		console.log("InitialiseReceiptPageTracking error: " + ex.message);
	}
}

exports.InitialiseAddToCartTracking = function InitialiseAddToCartTracking(data) {
	try {
		setTimeout(function () {
			hc_gtm._initialiseAddToCartTracking(data);
		}, 500);
	}
	catch (ex) {
		console.log("InitialiseAddToCartTracking error: " + ex.message);
	}
};

exports.InitialiseRemoveFromCartTracking = function InitialiseRemoveFromCartTracking(data) {
	try {
		setTimeout(function () {
			hc_gtm._initialiseRemoveFromCartTracking(data);
		}, 500);
	}
	catch (ex) {
		console.log("InitialiseRemoveFromCartTracking error: " + ex.message);
	}
};
