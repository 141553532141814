export enum FitGuideMainPageKeyEnum {
	MenShirt = 'men-shirts',
	MenSuit = 'men-suits',
	WomenShirt = 'women-shirts',
	WomenSuit = 'women-suits',
	Tie = 'ties',
	BowTie = 'bowties',
	PocketSquare = 'pocket-squares',
	Scarf = 'scarves',
	Shoe = 'shoes',
}
