import { IFitGuideMainVM } from '../../models/fit-guide';
import { FitGuideSetting } from 'src/app/model/fit-guide-setting';

const buildMenSuitGuideViewModel = (
	fitGuideSetting: FitGuideSetting
): IFitGuideMainVM => {
	const {
		StepName1Text: stepName1Text,
		StepName2Text: stepName2Text,
		StepName3Text: stepName3Text,
		StepName4Text: stepName4Text,
		StepName5Text: stepName5Text,
		TextBetweenCarouselArrowsFront: txtFront,
		TextBetweenCarouselArrowsLeft: txtLeft,
		TextBetweenCarouselArrowsRight: txtRight,
		TextBetweenCarouselArrowsBack: txtBack,
		MenSuitTitle: title,
		MenSuitMobileBannerImageUrl: mobileBannerImageUrl,
		MenSuitStyleTitle: styleTitle,
		MenSuitStyle2PieceName: twoPieceName,
		MenSuitStyle2PieceDescription1: twoPieceDescription1,
		MenSuitStyle2PiecePlpLink: twoPiecePlpLink,
		MenSuitStyle2PieceVideoUrl: twoPieceVideoUrl,
		MenSuitStyle2PieceDesktopImageUrl: twoPieceDesktopImageUrl,
		MenSuitStyle2PieceMobileImageUrl: twoPieceMobileImageUrl,
		MenSuitStyle3PieceName: threePieceName,
		MenSuitStyle3PieceDescription1: threePieceDescription1,
		MenSuitStyle3PiecePlpLink: threePiecePlpLink,
		MenSuitStyle3PieceVideoUrl: threePieceVideoUrl,
		MenSuitStyle3PieceDesktopImageUrl: threePieceDesktopImageUrl,
		MenSuitStyle3PieceMobileImageUrl: threePieceMobileImageUrl,
		MenSuitStyleDBName: dbName,
		MenSuitStyleDBDescription1: dbDescription1,
		MenSuitStyleDBPlpLink: dbPlpLink,
		MenSuitStyleDBVideoUrl: dbVideoUrl,
		MenSuitStyleDBDesktopImageUrl: dbDesktopImageUrl,
		MenSuitStyleDBMobileImageUrl: dbMobileImageUrl,
		MenSuitStyleMorningName: morningName,
		MenSuitStyleMorningDescription1: morningDescription1,
		MenSuitStyleMorningPlpLink: morningPlpLink,
		MenSuitStyleMorningVideoUrl: morningVideoUrl,
		MenSuitStyleMorningDesktopImageUrl: morningDesktopImageUrl,
		MenSuitStyleMorningMobileImageUrl: morningMobileImageUrl,
		MenSuitStyleDinnerName: dinnerName,
		MenSuitStyleDinnerDescription1: dinnerDescription1,
		MenSuitStyleDinnerPlpLink: dinnerPlpLink,
		MenSuitStyleDinnerVideoUrl: dinnerVideoUrl,
		MenSuitStyleDinnerDesktopImageUrl: dinnerDesktopImageUrl,
		MenSuitStyleDinnerMobileImageUrl: dinnerMobileImageUrl,
		MenSuitFitTitle: fitTitle,
		MenSuitFitCompareFitPageText: fitComparePageText,
		MenSuitFitSlimName: slimName,
		MenSuitFitSlimDescription1: slimDescription1,
		MenSuitFitSlimPlpLink: slimPlpLink,
		MenSuitFitSlimVideoUrl: slimVideoUrl,
		MenSuitFitSlimDesktopImageUrl1: slimDesktopImageUrl1,
		MenSuitFitSlimMobileImageUrl1: slimMobileImageUrl1,
		MenSuitFitSlimDesktopImageUrl2: slimDesktopImageUrl2,
		MenSuitFitSlimMobileImageUrl2: slimMobileImageUrl2,
		MenSuitFitSlimDesktopImageUrl3: slimDesktopImageUrl3,
		MenSuitFitSlimMobileImageUrl3: slimMobileImageUrl3,
		MenSuitFitSlimDesktopImageUrl4: slimDesktopImageUrl4,
		MenSuitFitSlimMobileImageUrl4: slimMobileImageUrl4,
		MenSuitFitTailoredName: tailoredName,
		MenSuitFitTailoredDescription1: tailoredDescription1,
		MenSuitFitTailoredPlpLink: tailoredPlpLink,
		MenSuitFitTailoredVideoUrl: tailoredVideoUrl,
		MenSuitFitTailoredDesktopImageUrl1: tailoredDesktopImageUrl1,
		MenSuitFitTailoredMobileImageUrl1: tailoredMobileImageUrl1,
		MenSuitFitTailoredDesktopImageUrl2: tailoredDesktopImageUrl2,
		MenSuitFitTailoredMobileImageUrl2: tailoredMobileImageUrl2,
		MenSuitFitTailoredDesktopImageUrl3: tailoredDesktopImageUrl3,
		MenSuitFitTailoredMobileImageUrl3: tailoredMobileImageUrl3,
		MenSuitFitTailoredDesktopImageUrl4: tailoredDesktopImageUrl4,
		MenSuitFitTailoredMobileImageUrl4: tailoredMobileImageUrl4,
		MenSuitFitClassicName: classicName,
		MenSuitFitClassicDescription1: classicDescription1,
		MenSuitFitClassicPlpLink: classicPlpLink,
		MenSuitFitClassicVideoUrl: classicVideoUrl,
		MenSuitFitClassicDesktopImageUrl1: classicDesktopImageUrl1,
		MenSuitFitClassicMobileImageUrl1: classicMobileImageUrl1,
		MenSuitFitClassicDesktopImageUrl2: classicDesktopImageUrl2,
		MenSuitFitClassicMobileImageUrl2: classicMobileImageUrl2,
		MenSuitFitClassicDesktopImageUrl3: classicDesktopImageUrl3,
		MenSuitFitClassicMobileImageUrl3: classicMobileImageUrl3,
		MenSuitFitClassicDesktopImageUrl4: classicDesktopImageUrl4,
		MenSuitFitClassicMobileImageUrl4: classicMobileImageUrl4,
		MenSuitFitCompareFitPageDescription: compareFitPageDescription,
		MenSuitSlimSubFitGuideSectionText: slimSubFitGuideText,
		MenSuitTailoredSubFitGuideSectionText: tailoredSubFitGuideText,
		MenSuitClassicSubFitGuideSectionText: classicSubFitGuideText,
		MenSuitJLTitle: jLTitle,
		MenSuitJLPlpLink: jLPlpLink,
		MenSuitJLShortName: shortName,
		MenSuitJLShortDescription1: shortDescription1,
		MenSuitJLShortDesktopImageUrl1: shortDesktopImageUrl1,
		MenSuitJLShortMobileImageUrl1: shortMobileImageUrl1,
		MenSuitJLRegularName: regularName,
		MenSuitJLRegularDescription1: regularDescription1,
		MenSuitJLRegularDesktopImageUrl1: regularDesktopImageUrl1,
		MenSuitJLRegularMobileImageUrl1: regularMobileImageUrl1,
		MenSuitJLLongName: longName,
		MenSuitJLLongDescription1: longDescription1,
		MenSuitJLLongDesktopImageUrl1: longDesktopImageUrl1,
		MenSuitJLLongMobileImageUrl1: longMobileImageUrl1,
		MenSuitJLMeasuringName: measuringName,
		MenSuitJLMeasuringStepDescription1: measuringStepDescription1,
		MenSuitJLMeasuringStepDescription2: measuringStepDescription2,
		MenSuitJLMeasuringStepDescription3: measuringStepDescription3,
		MenShirtMeasurementBackLengthDesktopImageUrl:
			measurementBackLengthDesktopImageUrl,
		MenShirtMeasurementBackLengthMobileImageUrl:
			measurementBackLengthMobileImageUrl,
		MenShirtMeasurementBackLengthVideoUrl: measurementBackLengthVideoUrl,
		MenSuitJMTitle: jMTitle,
		MenSuitJMPlpLink: jMPlpLink,
		MenSuitJMChestWidthName: chestWidthName,
		MenSuitJMChestWidthVideoUrl: chestWidthVideoUrl,
		MenSuitJMChestWidthStepDescription1: chestWidthDescription1,
		MenSuitJMChestWidthStepDescription2: chestWidthDescription2,
		MenSuitJMChestWidthStepDescription3: chestWidthDescription3,
		MenSuitJMChestWidthDesktopImageUrl: chestWidthDesktopImageUrl,
		MenSuitJMChestWidthMobileImageUrl: chestWidthMobileImageUrl,
		MenSuitJMWaistName: waistName,
		MenSuitJMWaistVideoUrl: waistVideoUrl,
		MenSuitJMWaistStepDescription1: waistDescription1,
		MenSuitJMWaistStepDescription2: waistDescription2,
		MenSuitJMWaistStepDescription3: waistDescription3,
		MenSuitJMWaistDesktopImageUrl: waistDesktopImageUrl,
		MenSuitJMWaistMobileImageUrl: waistMobileImageUrl,
		MenSuitJMShoulderWidthName: shoulderWidthName,
		MenSuitJMShoulderWidthVideoUrl: shoulderWidthVideoUrl,
		MenSuitJMShoulderWidthStepDescription1: shoulderWidthDescription1,
		MenSuitJMShoulderWidthStepDescription2: shoulderWidthDescription2,
		MenSuitJMShoulderWidthStepDescription3: shoulderWidthDescription3,
		MenSuitJMShoulderWidthDesktopImageUrl: shoulderWidthDesktopImageUrl,
		MenSuitJMShoulderWidthMobileImageUrl: shoulderWidthMobileImageUrl,
		MenSuitJMSleeveLengthName: sleeveLengthName,
		MenSuitJMSleeveLengthVideoUrl: sleeveLengthVideoUrl,
		MenSuitJMSleeveLengthStepDescription1: sleeveLengthDescription1,
		MenSuitJMSleeveLengthStepDescription2: sleeveLengthDescription2,
		MenSuitJMSleeveLengthStepDescription3: sleeveLengthDescription3,
		MenSuitJMSleeveLengthStepDescription4: sleeveLengthDescription4,
		MenSuitJMSleeveLengthStepDescription5: sleeveLengthDescription5,
		MenSuitJMSleeveLengthDesktopImageUrl: sleeveLengthDesktopImageUrl,
		MenSuitJMSleeveLengthMobileImageUrl: sleeveLengthMobileImageUrl,
		MenSuitWaistcoatTitle: waistcoatTitle,
		MenSuitWaistcoatDescription: waistcoatDescription,
		MenSuitWaistcoatPlpLink: waistcoatPlpLink,
		MenSuitWaistcoatDesktopImageUrl1: waistcoatDesktopImageUrl1,
		MenSuitWaistcoatMobileImageUrl1: waistcoatMobileImageUrl1,
		MenSuitWaistcoatDesktopImageUrl2: waistcoatDesktopImageUrl2,
		MenSuitWaistcoatMobileImageUrl2: waistcoatMobileImageUrl2,
		MenSuitTrouserTitle: trouserTitle,
		MenSuitTrouserSlimName: trouserSlimName,
		MenSuitTrouserSlimDescription: trouserSlimDescription,
		MenSuitTrouserSlimPlpLink: trouserSlimPlpLink,
		MenSuitTrouserSlimDesktopImageUrl1: trouserSlimDesktopImageUrl1,
		MenSuitTrouserSlimMobileImageUrl1: trouserSlimMobileImageUrl1,
		MenSuitTrouserSlimDesktopImageUrl2: trouserSlimDesktopImageUrl2,
		MenSuitTrouserSlimMobileImageUrl2: trouserSlimMobileImageUrl2,
		MenSuitTrouserSlimDesktopImageUrl3: trouserSlimDesktopImageUrl3,
		MenSuitTrouserSlimMobileImageUrl3: trouserSlimMobileImageUrl3,
		MenSuitTrouserSlimDesktopImageUrl4: trouserSlimDesktopImageUrl4,
		MenSuitTrouserSlimMobileImageUrl4: trouserSlimMobileImageUrl4,
		MenSuitTrouserClassicName: trouserClassicName,
		MenSuitTrouserClassicDescription: trouserClassicDescription,
		MenSuitTrouserClassicPlpLink: trouserClassicPlpLink,
		MenSuitTrouserClassicDesktopImageUrl1: trouserClassicDesktopImageUrl1,
		MenSuitTrouserClassicMobileImageUrl1: trouserClassicMobileImageUrl1,
		MenSuitTrouserClassicDesktopImageUrl2: trouserClassicDesktopImageUrl2,
		MenSuitTrouserClassicMobileImageUrl2: trouserClassicMobileImageUrl2,
		MenSuitTrouserClassicDesktopImageUrl3: trouserClassicDesktopImageUrl3,
		MenSuitTrouserClassicMobileImageUrl3: trouserClassicMobileImageUrl3,
		MenSuitTrouserClassicDesktopImageUrl4: trouserClassicDesktopImageUrl4,
		MenSuitTrouserClassicMobileImageUrl4: trouserClassicMobileImageUrl4,
		MenSuitTrouserTailoredName: trouserTailoredName,
		MenSuitTrouserTailoredDescription: trouserTailoredDescription,
		MenSuitTrouserTailoredPlpLink: trouserTailoredPlpLink,
		MenSuitTrouserTailoredDesktopImageUrl1: trouserTailoredDesktopImageUrl1,
		MenSuitTrouserTailoredMobileImageUrl1: trouserTailoredMobileImageUrl1,
		MenSuitTrouserTailoredDesktopImageUrl2: trouserTailoredDesktopImageUrl2,
		MenSuitTrouserTailoredMobileImageUrl2: trouserTailoredMobileImageUrl2,
		MenSuitTrouserTailoredDesktopImageUrl3: trouserTailoredDesktopImageUrl3,
		MenSuitTrouserTailoredMobileImageUrl3: trouserTailoredMobileImageUrl3,
		MenSuitTrouserTailoredDesktopImageUrl4: trouserTailoredDesktopImageUrl4,
		MenSuitTrouserTailoredMobileImageUrl4: trouserTailoredMobileImageUrl4,
		MenSuitTrouserMeasurementTitle: measurementTitle,
		MenSuitTrouserMeasurementPlpLink: measurementPlpLink,
		MenSuitTrouserMeasurementWCName: waistCircumferenceName,
		MenSuitTrouserMeasurementWCVideoUrl: waistCircumferenceVideoUrl,
		MenSuitTrouserMeasurementWCStepDescription1: waistCircumferenceDescription1,
		MenSuitTrouserMeasurementWCStepDescription2: waistCircumferenceDescription2,
		MenSuitTrouserMeasurementWCStepDescription3: waistCircumferenceDescription3,
		MenSuitTrouserMeasurementWCDesktopImageUrl:
			waistCircumferenceDesktopImageUrl,
		MenSuitTrouserMeasurementWCMobileImageUrl: waistCircumferenceMobileImageUrl,
		MenSuitTrouserMeasurementTCName: thighCircumferenceName,
		MenSuitTrouserMeasurementTCVideoUrl: thighCircumferenceVideoUrl,
		MenSuitTrouserMeasurementTCStepDescription1: thighCircumferenceDescription1,
		MenSuitTrouserMeasurementTCStepDescription2: thighCircumferenceDescription2,
		MenSuitTrouserMeasurementTCStepDescription3: thighCircumferenceDescription3,
		MenSuitTrouserMeasurementTCDesktopImageUrl:
			thighCircumferenceDesktopImageUrl,
		MenSuitTrouserMeasurementTCMobileImageUrl: thighCircumferenceMobileImageUrl,
		MenSuitTrouserMeasurementTHName: hemName,
		MenSuitTrouserMeasurementTHVideoUrl: hemVideoUrl,
		MenSuitTrouserMeasurementTHStepDescription1: hemDescription1,
		MenSuitTrouserMeasurementTHStepDescription2: hemDescription2,
		MenSuitTrouserMeasurementTHStepDescription3: hemDescription3,
		MenSuitTrouserMeasurementTHStepDescription4: hemDescription4,
		MenSuitTrouserMeasurementTHDesktopImageUrl: hemDesktopImageUrl,
		MenSuitTrouserMeasurementTHMobileImageUrl: hemMobileImageUrl,
		MenSuitTrouserMeasurementFRName: frontRiseName,
		MenSuitTrouserMeasurementFRVideoUrl: frontRiseVideoUrl,
		MenSuitTrouserMeasurementFRStepDescription1: frontRiseDescription1,
		MenSuitTrouserMeasurementFRStepDescription2: frontRiseDescription2,
		MenSuitTrouserMeasurementFRStepDescription3: frontRiseDescription3,
		MenSuitTrouserMeasurementFRDesktopImageUrl: frontRiseDesktopImageUrl,
		MenSuitTrouserMeasurementFRMobileImageUrl: frontRiseMobileImageUrl,
		MenSuitTrouserMeasurementILName: insideLegName,
		MenSuitTrouserMeasurementILVideoUrl: insideLegVideoUrl,
		MenSuitTrouserMeasurementILStepDescription1: insideLegDescription1,
		MenSuitTrouserMeasurementILStepDescription2: insideLegDescription2,
		MenSuitTrouserMeasurementILStepDescription3: insideLegDescription3,
		MenSuitTrouserMeasurementILDesktopImageUrl: insideLegDesktopImageUrl,
		MenSuitTrouserMeasurementILMobileImageUrl: insideLegMobileImageUrl,
	} = fitGuideSetting;
	return {
		title: title,
		mobileBannerImage: mobileBannerImageUrl,
		sections: [
			{
				title: styleTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: twoPieceName,
						description1: twoPieceDescription1,
						plpLink: twoPiecePlpLink,
						videoLink: twoPieceVideoUrl,
						isActive: false,
						images: [
							{
								desktop: twoPieceDesktopImageUrl,
								mobile: twoPieceMobileImageUrl,
							},
						],
					},
					{
						name: threePieceName,
						description1: threePieceDescription1,
						plpLink: threePiecePlpLink,
						videoLink: threePieceVideoUrl,
						isActive: true,
						images: [
							{
								desktop: threePieceDesktopImageUrl,
								mobile: threePieceMobileImageUrl,
							},
						],
					},
					{
						name: dbName,
						description1: dbDescription1,
						plpLink: dbPlpLink,
						videoLink: dbVideoUrl,
						isActive: false,
						images: [
							{
								desktop: dbDesktopImageUrl,
								mobile: dbMobileImageUrl,
							},
						],
					},
					{
						name: morningName,
						description1: morningDescription1,
						plpLink: morningPlpLink,
						videoLink: morningVideoUrl,
						isActive: false,
						images: [
							{
								desktop: morningDesktopImageUrl,
								mobile: morningMobileImageUrl,
							},
						],
					},
					{
						name: dinnerName,
						description1: dinnerDescription1,
						plpLink: dinnerPlpLink,
						videoLink: dinnerVideoUrl,
						isActive: false,
						images: [
							{
								desktop: dinnerDesktopImageUrl,
								mobile: dinnerMobileImageUrl,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: fitTitle,
				subFitGuidePageText: fitComparePageText,
				subFitGuidePageDescription: compareFitPageDescription,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: slimName,
						subFitGuideSectionText: slimSubFitGuideText,
						description1: slimDescription1,
						plpLink: slimPlpLink,
						videoLink: slimVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: true,
						images: [
							{
								desktop: slimDesktopImageUrl1,
								mobile: slimMobileImageUrl1,
							},
							{
								desktop: slimDesktopImageUrl2,
								mobile: slimMobileImageUrl2,
							},
							{
								desktop: slimDesktopImageUrl3,
								mobile: slimMobileImageUrl3,
							},
							{
								desktop: slimDesktopImageUrl4,
								mobile: slimMobileImageUrl4,
							},
						],
					},
					{
						name: tailoredName,
						subFitGuideSectionText: tailoredSubFitGuideText,
						description1: tailoredDescription1,
						plpLink: tailoredPlpLink,
						videoLink: tailoredVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: false,
						images: [
							{
								desktop: tailoredDesktopImageUrl1,
								mobile: tailoredMobileImageUrl1,
							},
							{
								desktop: tailoredDesktopImageUrl2,
								mobile: tailoredMobileImageUrl2,
							},
							{
								desktop: tailoredDesktopImageUrl3,
								mobile: tailoredMobileImageUrl3,
							},
							{
								desktop: tailoredDesktopImageUrl4,
								mobile: tailoredMobileImageUrl4,
							},
						],
					},
					{
						name: classicName,
						subFitGuideSectionText: classicSubFitGuideText,
						description1: classicDescription1,
						plpLink: classicPlpLink,
						videoLink: classicVideoUrl,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						isActive: false,
						images: [
							{
								desktop: classicDesktopImageUrl1,
								mobile: classicMobileImageUrl1,
							},
							{
								desktop: classicDesktopImageUrl2,
								mobile: classicMobileImageUrl2,
							},
							{
								desktop: classicDesktopImageUrl3,
								mobile: classicMobileImageUrl3,
							},
							{
								desktop: classicDesktopImageUrl4,
								mobile: classicMobileImageUrl4,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right1 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: jLTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: shortName,
						description1: shortDescription1,
						plpLink: jLPlpLink,
						isActive: true,
						images: [
							{
								desktop: shortDesktopImageUrl1,
								mobile: shortMobileImageUrl1,
							},
						],
					},
					{
						name: regularName,
						description1: regularDescription1,
						plpLink: jLPlpLink,
						isActive: false,
						images: [
							{
								desktop: regularDesktopImageUrl1,
								mobile: regularMobileImageUrl1,
							},
						],
					},
					{
						name: longName,
						description1: longDescription1,
						plpLink: jLPlpLink,
						isActive: false,
						images: [
							{
								desktop: longDesktopImageUrl1,
								mobile: longMobileImageUrl1,
							},
						],
					},
					{
						name: measuringName,
						plpLink: jLPlpLink,
						isActive: false,
						videoLink: measurementBackLengthVideoUrl,
						images: [
							{
								desktop: measurementBackLengthDesktopImageUrl,
								mobile: measurementBackLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: measuringStepDescription1,
							},
							{
								name: stepName2Text,
								description: measuringStepDescription2,
							},
							{
								name: stepName3Text,
								description: measuringStepDescription3,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right2 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: jMTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: chestWidthName,
						plpLink: jMPlpLink,
						videoLink: chestWidthVideoUrl,
						isActive: false,
						images: [
							{
								desktop: chestWidthDesktopImageUrl,
								mobile: chestWidthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: chestWidthDescription1,
							},
							{
								name: stepName2Text,
								description: chestWidthDescription2,
							},
							{
								name: stepName3Text,
								description: chestWidthDescription3,
							},
						],
					},
					{
						name: waistName,
						plpLink: jMPlpLink,
						videoLink: waistVideoUrl,
						isActive: false,
						images: [
							{
								desktop: waistDesktopImageUrl,
								mobile: waistMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: waistDescription1,
							},
							{
								name: stepName2Text,
								description: waistDescription2,
							},
							{
								name: stepName3Text,
								description: waistDescription3,
							},
						],
					},
					{
						name: shoulderWidthName,
						plpLink: jMPlpLink,
						videoLink: shoulderWidthVideoUrl,
						isActive: false,
						images: [
							{
								desktop: shoulderWidthDesktopImageUrl,
								mobile: shoulderWidthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: shoulderWidthDescription1,
							},
							{
								name: stepName2Text,
								description: shoulderWidthDescription2,
							},
							{
								name: stepName3Text,
								description: shoulderWidthDescription3,
							},
						],
					},
					{
						name: sleeveLengthName,
						plpLink: jMPlpLink,
						videoLink: sleeveLengthVideoUrl,
						isActive: true,
						images: [
							{
								desktop: sleeveLengthDesktopImageUrl,
								mobile: sleeveLengthMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: sleeveLengthDescription1,
							},
							{
								name: stepName2Text,
								description: sleeveLengthDescription2,
							},
							{
								name: stepName3Text,
								description: sleeveLengthDescription3,
							},
							{
								name: stepName4Text,
								description: sleeveLengthDescription4,
							},
							{
								name: stepName5Text,
								description: sleeveLengthDescription5,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: waistcoatTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: null,
						plpLink: waistcoatPlpLink,
						isActive: true,
						description1: waistcoatDescription,
						textBetweenArrows: [txtFront, txtBack],
						images: [
							{
								desktop: waistcoatDesktopImageUrl1,
								mobile: waistcoatMobileImageUrl1,
							},
							{
								desktop: waistcoatDesktopImageUrl2,
								mobile: waistcoatMobileImageUrl2,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right2 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: trouserTitle,
				isSectionItemCarousel: true,
				sectionItems: [
					{
						name: trouserSlimName,
						description1: trouserSlimDescription,
						plpLink: trouserSlimPlpLink,
						isActive: true,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: trouserSlimDesktopImageUrl1,
								mobile: trouserSlimMobileImageUrl1,
							},
							{
								desktop: trouserSlimDesktopImageUrl2,
								mobile: trouserSlimMobileImageUrl2,
							},
							{
								desktop: trouserSlimDesktopImageUrl3,
								mobile: trouserSlimMobileImageUrl3,
							},
							{
								desktop: trouserSlimDesktopImageUrl4,
								mobile: trouserSlimMobileImageUrl4,
							},
						],
					},
					{
						name: trouserClassicName,
						description1: trouserClassicDescription,
						plpLink: trouserClassicPlpLink,
						isActive: false,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: trouserClassicDesktopImageUrl1,
								mobile: trouserClassicMobileImageUrl1,
							},
							{
								desktop: trouserClassicDesktopImageUrl2,
								mobile: trouserClassicMobileImageUrl2,
							},
							{
								desktop: trouserClassicDesktopImageUrl3,
								mobile: trouserClassicMobileImageUrl3,
							},
							{
								desktop: trouserClassicDesktopImageUrl4,
								mobile: trouserClassicMobileImageUrl4,
							},
						],
					},
					{
						name: trouserTailoredName,
						description1: trouserTailoredDescription,
						plpLink: trouserTailoredPlpLink,
						isActive: false,
						textBetweenArrows: [txtFront, txtRight, txtBack, txtLeft],
						images: [
							{
								desktop: trouserTailoredDesktopImageUrl1,
								mobile: trouserTailoredMobileImageUrl1,
							},
							{
								desktop: trouserTailoredDesktopImageUrl2,
								mobile: trouserTailoredMobileImageUrl2,
							},
							{
								desktop: trouserTailoredDesktopImageUrl3,
								mobile: trouserTailoredMobileImageUrl3,
							},
							{
								desktop: trouserTailoredDesktopImageUrl4,
								mobile: trouserTailoredMobileImageUrl4,
							},
						],
					},
				],
				cssClasses: {
					customCarouselControlsWrapper:
						'v2-fit-guide-custom-carousel-controls right2 height2',
					customCarouselArrowsWrapper:
						'splide__arrows v2-fit-guide-custom-arrows2',
					carouselPagination:
						'splide__pagination v2-fit-guide-carousel-pagination1',
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: 'mt-heading-proportional',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
			{
				title: measurementTitle,
				isSectionItemCarousel: false,
				sectionItems: [
					{
						name: waistCircumferenceName,
						plpLink: measurementPlpLink,
						videoLink: waistCircumferenceVideoUrl,
						isActive: false,
						images: [
							{
								desktop: waistCircumferenceDesktopImageUrl,
								mobile: waistCircumferenceMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: waistCircumferenceDescription1,
							},
							{
								name: stepName2Text,
								description: waistCircumferenceDescription2,
							},
							{
								name: stepName3Text,
								description: waistCircumferenceDescription3,
							},
						],
					},
					{
						name: thighCircumferenceName,
						plpLink: measurementPlpLink,
						videoLink: thighCircumferenceVideoUrl,
						isActive: true,
						images: [
							{
								desktop: thighCircumferenceDesktopImageUrl,
								mobile: thighCircumferenceMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: thighCircumferenceDescription1,
							},
							{
								name: stepName2Text,
								description: thighCircumferenceDescription2,
							},
							{
								name: stepName3Text,
								description: thighCircumferenceDescription3,
							},
						],
					},
					{
						name: hemName,
						plpLink: measurementPlpLink,
						videoLink: hemVideoUrl,
						isActive: false,
						images: [
							{
								desktop: hemDesktopImageUrl,
								mobile: hemMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: hemDescription1,
							},
							{
								name: stepName2Text,
								description: hemDescription2,
							},
							{
								name: stepName3Text,
								description: hemDescription3,
							},
							{
								name: stepName4Text,
								description: hemDescription4,
							},
						],
					},
					{
						name: frontRiseName,
						plpLink: measurementPlpLink,
						videoLink: frontRiseVideoUrl,
						isActive: false,
						images: [
							{
								desktop: frontRiseDesktopImageUrl,
								mobile: frontRiseMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: frontRiseDescription1,
							},
							{
								name: stepName2Text,
								description: frontRiseDescription2,
							},
							{
								name: stepName3Text,
								description: frontRiseDescription3,
							},
						],
					},
					{
						name: insideLegName,
						plpLink: measurementPlpLink,
						videoLink: insideLegVideoUrl,
						isActive: false,
						images: [
							{
								desktop: insideLegDesktopImageUrl,
								mobile: insideLegMobileImageUrl,
							},
						],
						steps: [
							{
								name: stepName1Text,
								description: insideLegDescription1,
							},
							{
								name: stepName2Text,
								description: insideLegDescription2,
							},
							{
								name: stepName3Text,
								description: insideLegDescription3,
							},
						],
					},
				],
				cssClasses: {
					buttonsAndTextsWrapper: 'v2-fit-guide-section-buttons-and-texts',
					buttonsAndTextsInnerWrapper:
						'v2-fit-guide-section-buttons-and-texts-section flex-column-start',
					shopNowButtonWrapper: '',
					shopNowButton: 'btn-bordered-shop-now',
				},
			},
		],
	};
};

export default buildMenSuitGuideViewModel;
