import {
	Component,
	Inject,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	AfterViewInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import * as $ from "jquery";
import { ViewportService } from "../../viewport.service";
import { ViewPortEnum } from "src/app/enums/device-type-enum";

@Component({
    selector: "app-style-guide-new",
    templateUrl: "./style-guide-new.component.html",
    styleUrls: ["./style-guide-new.component.scss"],
})
export class SizeGuideNewComponent implements OnChanges, AfterViewInit {
    @Input() sizeGuideData: any;
    @Output() changeShowSizeGuide = new EventEmitter<any>();
    selectedMeasurement = "";
    selectedTab = "";
    showSizeChartTable: boolean = true;
    sizeFilters = [];
    sizeHeadings = [];
    isDesktop: boolean = true;
    firstRow: any;
    secondaryRows: any;
    selectedIndexs = [];
    selectedSizeIndex = null;
    selectedFilter = "";
    selectedVal = "";
    selectedSize = "";

    constructor(@Inject(DOCUMENT) private document: Document, private viewportService:ViewportService) { }
    
    ngOnChanges() {
        this.selectedMeasurement = this.selectedMeasurement;
        this.setStyleGuides();
    }  

    ngAfterViewInit() {   
        $('.table-scrollable').mousedown(function (event) {
            $(this)
                .data('down', true)
                .data('x', event.clientX)
                .data('scrollLeft', this.scrollLeft)
                .addClass("isgrabbed");

            return false;
        }).mouseup(function (event) {
            $(this)
                .data('down', false)
                .removeClass("isgrabbed");
        }).mousemove(function (event) {
            if ($(this).data('down') == true) {
                this.scrollLeft = $(this).data('scrollLeft') + $(this).data('x') - event.clientX;
            }
        }).css({
            'overflow': 'hidden',
            'cursor': '-moz-grab'
        });
        $(window).mouseup(function (event) {
            $('.table-scrollable')
                .data('down', false)
                .removeClass("isgrabbed");
        });
    }

    setStyleGuides = () => {
        this.isDesktop = this.viewportService.getDeviceType() !== ViewPortEnum.Mobile ? true : false;
        this.setSizeChart();
        if(this.sizeGuideData && this.sizeGuideData.siteContent && this.sizeGuideData.siteContent.Inches) {
            if(!this.selectedMeasurement) {
                this.selectedMeasurement = this.sizeGuideData.siteContent.Inches;
            }
        }
    }

    setSizeChart = () => {
        if (this.sizeGuideData && this.sizeGuideData.sizeChartContent
            && this.sizeGuideData.sizeChartContent.HasRows && this.sizeGuideData.sizeChartContent.Rows.length == 1
            && this.sizeGuideData.sizeChartContent.Rows[0].HasColumns && this.sizeGuideData.sizeChartContent.Rows[0].Columns.length == 1
            && !this.sizeGuideData.sizeChartContent.Rows[0].Columns[0].Value) {
            this.showSizeChartTable = false;
        }

        if(this.showSizeChartTable) {
            this.firstRow = this.sizeGuideData.sizeChartContent.Rows[0];
            this.secondaryRows = this.sizeGuideData.sizeChartContent.Rows.filter((r: any) => r.RowNumber != this.firstRow.RowNumber);
            if(this.secondaryRows && this.secondaryRows.length) {
                let splitVal = this.secondaryRows[0].Columns[0].Value.split('#');
                if(splitVal.length > 1) {
                    let filterVal = splitVal[0].split('|');
                    if(filterVal.length > 1) {
                        if(!this.selectedFilter) {
                            this.selectedFilter = filterVal[1].trim();
                            this.selectedTab = this.selectedFilter;
                        }else {
                            this.selectedTab = this.selectedFilter;
                        }
                    }
                }
            }
            let allSizeFilters = [];
            this.sizeFilters = [];

            this.sizeGuideData.sizeChartContent.Rows.forEach((row: any) => {
                let filterValue = row.Columns[0].Value;
                if (filterValue) {
                    let splitHash = filterValue.split('#');
                    let splitValues = splitHash[0].split('|');
                    if (splitValues.length === 3) {
                        let dt = splitValues[1].trim() + "<span>" + splitValues[2].trim() + "</span>";
                        if(!allSizeFilters.includes(dt)){
                            allSizeFilters.push(dt);
                            this.sizeFilters.push({heading: dt});
                        }
                    }
                    else if (splitValues.length === 2) {
                        if(!allSizeFilters.includes(splitValues[1].trim())) {
                            allSizeFilters.push(splitValues[1].trim());
                            this.sizeFilters.push({heading: splitValues[1].trim()});
                        }
                    }
                }
            })
            this.changeSelectionOnFilterChange();
        }
    }
    hide = () => {
        this.changeShowSizeGuide.emit(false);
        this.document.body.classList.remove("modal-open", "modal-with-am-fade");
    }

    changeSelectionOnFilterChange() {
        if(this.sizeFilters.length > 0) {

            this.sizeFilters.forEach((item, index) => {

            {
                var innersplit = item.heading.split('<');
                var classname = "";

                if (innersplit.length > 0)
                {
                    if (innersplit[0].trim().toLocaleLowerCase() == this.selectedTab.split(" ")[0].trim().toLocaleLowerCase())
                    {
                        classname = "active " + innersplit[0].trim().toLocaleLowerCase() + " " + this.selectedTab.toLocaleLowerCase();
                    }
                    else
                    {
                        if (index == 0 && !this.selectedTab)
                        {
                            this.selectedTab = innersplit[0].trim();
                            this.onChangeFilter(this.selectedTab);
                            this.changeSelectionOnFilterChange();
                            classname = "active " + innersplit[0].trim().toLocaleLowerCase() + " " + this.selectedTab.toLocaleLowerCase();
                        }
                        else
                        {
                            classname = innersplit[0].trim().toLocaleLowerCase() + " " + this.selectedTab.toLocaleLowerCase();
                        }
                    }
                }
                item.className = classname;
            }
        })
        }
        for(let i=0;i< this.secondaryRows.length; i++) {
                this.selectedIndexs = [];
                let splitVal = this.secondaryRows[i].Columns[0].Value.split('|');
                if(splitVal.length > 1) {
                    if(splitVal[1].toLocaleLowerCase().trim().split(' ')[0] == this.selectedTab.toLocaleLowerCase().split(' ')[0]) {
                        this.secondaryRows[i].Columns.forEach((col, index) => {
                            if(index > 0 && col.Value) {
                                if(!this.selectedSize && this.selectedFilter.toLocaleLowerCase().trim() == this.secondaryRows[i].Columns[0].Value.split('|')[1].toLocaleLowerCase().trim()) {
                                    if(!this.selectedVal) {
                                        this.selectedVal = this.firstRow.Columns[col.ColumnNumber-1].Value;
                                        this.selectedSize = this.selectedVal;
                                    }else {
                                        this.selectedVal = this.firstRow.Columns[col.ColumnNumber-1].Value;
                                        this.selectedSize = this.selectedVal;
                                    }
                                }
                                this.selectedIndexs.push(index-1);
                            }
                        })
                        break;
                    }
                }else {
                    this.secondaryRows[i].Columns.forEach((col, index) => {
                        if(index == 0 && !this.selectedVal) {
                            this.selectedVal = this.firstRow.Columns[1].Value;
                        }
                        if(index > 0 && col.Value) {
                            this.selectedIndexs.push(index-1);
                        }
                    }) 
                }
        }
        if(this.firstRow.RowNumber == 1){
            this.selectedSizeIndex = null;
            for(let i=0;i<this.firstRow.Columns.length;i++) {
                if(this.firstRow.Columns[i].Value == this.selectedVal || this.firstRow.Columns[i].Value.split(" ")[0].trim() == this.selectedVal.split(" ")[0].trim()) {
                    this.selectedSizeIndex = i-1;
                    break;
                }
            }
        }
    }
    
    changeSelectedTab = (tab: any) => {
        const newTab = tab.heading.split('<').length ? tab.heading.split('<')[0] : '' ;
        if(this.selectedTab !== newTab) {
            this.selectedTab = newTab;
            this.onChangeFilter(this.selectedTab);
        }
    }
    
    tableScrollLeft = () => {
        event.preventDefault();
        $(".table-scrollable").animate(
        {
            scrollLeft: "+=200px"
            //scrollLeft: "+="+ columnrightWidth +"px"
        },
        "slow"
        );
    }

    tableScrollRight = () =>{
        event.preventDefault();
        $(".table-scrollable").animate(
        {
            scrollLeft: "-=200px"
            //scrollLeft: "-="+ columnleftWidth +"px"
        },
        "slow"
        );
    }
    onToggleChange = (measurement) => {
        this.selectedMeasurement = measurement;
    }
    onChangeSize = (event) => {
        this.selectedVal = event.Value;
        this.selectedSize = event.Value;
        this.changeSelectionOnFilterChange();
    }
    onChangeFilter = (event) => {
        this.selectedFilter = event;
        this.selectedSize = "";
        this.changeSelectionOnFilterChange();
    }
}
