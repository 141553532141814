import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { AlgoliaProduct } from '../model/product-list';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';
import { Subscription } from 'rxjs';
import { LocalStorageKeyEnum } from 'src/app/enums/local-storage-enum';
import { ViewportService } from 'src/app/shared/viewport.service';
import { SiteContentService } from 'src/app/shared/sitecontent.service';
import { SiteContentDetail } from 'src/app/shared/models/siteContent';
import { LikedProductService } from 'src/app/shared/service/liked-product.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'liked-product,[liked-product]',
  templateUrl: './liked-product.component.html',
  styleUrls: ['./liked-product.component.scss']
})
export class LikedProductComponent implements OnInit, OnDestroy {
  isDataLoaded: Promise<boolean>;
  isPreloading: boolean = false;
  prod: any[];
  private likedItemsSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any, 
    private localStorageService: LocalStorageService, 
    public viewportService: ViewportService, 
    private siteContentService: SiteContentService, 
    private likedProductService: LikedProductService, 
    private sharedService:SharedService) {}

	bannerImageUrl: string;
	OfferTokenCookie: string;
	fredhopperResponseId: string;
  columns = 4;
  deviceType: string;
  siteContent: SiteContentDetail;
  userData;

  ngOnInit(){
    this.getUser();
    this.siteContent = this.siteContentService.getSiteContentData();
    
    this.getLikedProducts();

    this.deviceType = this.viewportService.getDeviceType();
    if(this.deviceType === 'Mobile'){
      this.columns = 2;
    }
  }

  ngOnDestroy() {
    if (this.likedItemsSubscription) {
      this.likedItemsSubscription.unsubscribe();
    }
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  
  getUser() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  getLikedProducts() {
    const likedItemsStr = this.localStorageService.getItem(LocalStorageKeyEnum.LikedItems);

    let productIds: number[] = likedItemsStr ? JSON.parse(likedItemsStr) : [];
    this.sharedService.setLikedStatus();

    if (productIds.length === 0) {
      return;
    }

    this.localStorageService.trigger(LocalStorageKeyEnum.LikedItems);

    this.likedItemsSubscription = this.localStorageService.watchKey(LocalStorageKeyEnum.LikedItems).subscribe(value => {
      const likedItemsStr = this.localStorageService.getItem(LocalStorageKeyEnum.LikedItems);
      productIds = likedItemsStr ? JSON.parse(likedItemsStr) : [];
      this.sharedService.setLikedStatus();
      if (this.userData) {

        this.likedProductService.getLikedProductforUser(productIds).subscribe(
          res => {
            this.prod = res?.desc ?? res ?? [];
            let prodIds = [];
            this.prod.forEach(product => {
              prodIds.push(product.ProductId)
              product.IsLiked = true;
            });
            this.localStorageService.setItem2(LocalStorageKeyEnum.LikedItems, JSON.stringify(prodIds));
          }
        );
      } else {
        this.likedProductService.GetProductDetailsNotLoggedIn(productIds).subscribe(
          res => {
            this.prod = res?.desc ?? res ?? [];
            this.prod.forEach(product => {
              product.IsLiked = true;
            });
          }
        );

      }
    });
  }
}
